import React, { useEffect } from 'react'
import _ from 'lodash'

import {
  CreationHookReturnType,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import { form as baseFormErrors } from '@/constants/errors'
import { customerKindStatuses } from '@/constants/apps.customer'
import {
  CustomerKind,
  CustomerKindCreatePayload,
  CustomerKindModifyPayload,
  ModifyingFormRules,
  CreatingFormRules,
} from '@/apps/customer/@types/customer-kind'
import EditSimpleItemForm from '@/components/modal/edit-simple-item-form/EditSimpleItemForm'
import { curriculumError } from '@/constants/apps.curriculum'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  isVisible: boolean
  selectedItem: CustomerKind | null
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  setModifyingKey: (data: CustomerKind | null) => void
  creating: CreationHookReturnType<CustomerKindCreatePayload, CustomerKind>
  modifying: ModificationHookReturnType<CustomerKindModifyPayload, CustomerKind>
}

const maxLengthOfName = 11
const maxLengthOfDesc = 28

const EditCustomerKindForm: React.FC<PropType> = ({
  isVisible,
  selectedItem,
  onFinish,
  onCancel,
  setModifyingKey,
  creating,
  modifying,
}) => {
  useEffect(() => {
    if (
      modifying.error?.status === 400 &&
      modifying.error?.data?.detail === curriculumError.NOLONGERUSE &&
      onCancel
    ) {
      onCancel()
    }
  }, [modifying.error])

  const handleCreating = () => {
    if (
      serverErrorStatus.includes(creating.error?.status) &&
      creating.error?.data?.detail
    ) {
      creating.error.data.detail = 'internalServerError'
    }

    return creating
  }

  const handleModifying = () => {
    if (
      serverErrorStatus.includes(modifying.error?.status) &&
      modifying.error?.data?.detail
    ) {
      modifying.error.data.detail = 'internalServerError'
    }

    return modifying
  }

  return (
    <EditSimpleItemForm<
      CustomerKind,
      CustomerKindCreatePayload,
      CustomerKindModifyPayload
    >
      isVisible={isVisible}
      item={selectedItem}
      onFinish={onFinish}
      onCancel={onCancel}
      title={getEditModalTitle(!!selectedItem)}
      subTitle={getEditModalSubTitle(!!selectedItem)}
      itemNameInputLabel="고객 유형"
      itemNameInputPlaceholder={`유형명 입력 (최대 노출 글자 수 ${maxLengthOfName}자)`}
      itemDescInputLabel="설명"
      itemDescInputPlaceholder={`설명 입력 (최대 노출 글자 수 ${maxLengthOfDesc}자)`}
      creating={handleCreating()}
      creatingRules={creatingRules}
      modifying={handleModifying()}
      setModifyingKey={setModifyingKey}
      modifyingRules={modifyingRules}
    />
  )
}

const getEditModalTitle = (isModifying: boolean) => {
  return isModifying ? '유형 수정' : '신규 유형 추가'
}

const getEditModalSubTitle = (isModifying: boolean) => {
  return isModifying ? '고객 유형 수정' : '신규 고객 유형 추가'
}

const creatingRules: CreatingFormRules = {
  name: [
    {
      required: true,
      message: baseFormErrors.requiredField,
    },
    {
      type: 'string',
      max: maxLengthOfName,
      message: baseFormErrors.invalidFormat,
    },
  ],
  description: [
    {
      required: true,
      message: baseFormErrors.requiredField,
    },
    {
      type: 'string',
      max: maxLengthOfDesc,
      message: baseFormErrors.invalidFormat,
    },
  ],
}

const statusSet = new Set([
  customerKindStatuses.ACTIVE,
  customerKindStatuses.INACTIVE,
  customerKindStatuses.DISABLED,
])

const modifyingRules: ModifyingFormRules = {
  ...creatingRules,
  status: [
    () => ({
      validator(__, value) {
        return _.isNil(value) || statusSet.has(value)
          ? Promise.resolve()
          : Promise.reject(new Error(baseFormErrors.invalidStatusValue))
      },
    }),
  ],
}

export default EditCustomerKindForm
