import useSWR from 'swr'

import { Nil, NullableString } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.order'
import { CustomerOrderSummary } from '@/apps/order/@types/order'

const fetcher = <CT>(url: NullableString) => {
  if (!url) return
  return get<never, CustomerOrderSummary<CT>>(url)
}

const useCustomerOrderSummary = <CT>(uid: Nil | string) => {
  const { data, error, isValidating, mutate } = useSWR<
    CustomerOrderSummary<CT> | undefined
  >(uid ? URLS.getCustomerOrderSummary(uid) : null, fetcher)
  const isLoading = !data && !error
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  }
}

export default useCustomerOrderSummary
