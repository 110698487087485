import _ from 'lodash'

import { convertToBoolean } from '@/utils/string'
import { ParamTransform } from '@/hooks/useLocationParams/useLocationParams'

type NumberTransformReturnType = [string, number | undefined | unknown]

const unwrapArray = (value: unknown | unknown[]): unknown | undefined => {
  return _.isArray(value) ? _.head(value) : value
}

const parseIntOrOrignal = (value: unknown) => {
  const result = parseInt(value as string, 10)
  return _.isNaN(result) ? value : result
}

export const transformNumberParams: ParamTransform['transfomer'] = ([
  key,
  value,
]) => {
  return [
    key,
    parseIntOrOrignal(unwrapArray(value)),
  ] as NumberTransformReturnType
}

export const transformCommaListParams: ParamTransform['transfomer'] = ([
  key,
  value,
]) => {
  if (_.isEmpty(value)) return [key, null]
  const transfomed = _.chain(
    _.isArray(value) ? value : _.split(value as string, ','),
  )
    .map(_.trim)
    .filter(_.negate(_.isEmpty))
    .map(convertToBoolean)
    .map(parseIntOrOrignal)
    .value()
  return [key, transfomed]
}

export const transformBooleanParams: ParamTransform['transfomer'] = ([
  key,
  value,
]) => {
  return [key, convertToBoolean(unwrapArray(value))]
}

export const transformParamValue = (
  transforms: ParamTransform[],
  [key, value]: [string, unknown],
) => {
  const tr = transforms.find((tr) => tr.paramKeys.includes(key))
  return tr ? tr.transfomer([key, value]) : [key, value]
}
