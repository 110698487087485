import React from 'react'
import {
  Form,
  FormInstance,
  Select,
  Radio,
  Input,
  RadioChangeEvent,
} from 'antd'
import _ from 'lodash'
import classNames from 'classnames/bind'
import style from './QuerySection.module.scss'

export interface OrderOption {
  label: string
  value: string
}

export interface SortOption {
  label: string
  value: boolean
}

export const defaultSortOptions: SortOption[] = [
  {
    label: '내림차순',
    value: true,
  },
  {
    label: '오름차순',
    value: false,
  },
]

const cx = classNames.bind(style)

interface PropType<OT extends string> {
  formRef?: FormInstance
  isLoading?: boolean
  defaultOrderingKey?: OT
  defaultOrderingIsDesc?: boolean
  orderOptions?: OrderOption[]
  orderPlaceholder?: string
  searchPlaceholder?: string
  defaultSearchTerm?: string | undefined
  showSearch?: boolean
  wrapperName?: string
  sortOptions?: SortOption[]
  onChangeOrder?: (key: OT) => void
  onChangeSort?: (e: RadioChangeEvent) => void
  onChangeTerm?: (query: string) => void
}

const QuerySectionView = <OT extends string>(
  props: React.PropsWithChildren<PropType<OT>>,
) => {
  const {
    formRef,
    isLoading,
    orderOptions,
    orderPlaceholder = '정렬',
    sortOptions = defaultSortOptions,
    searchPlaceholder,
    defaultSearchTerm,
    showSearch,
    defaultOrderingKey,
    defaultOrderingIsDesc,
    wrapperName,
    onChangeOrder,
    onChangeSort,
    onChangeTerm,
  } = props

  const showSortOptions =
    !_.isNil(defaultOrderingIsDesc) && !_.isEmpty(sortOptions)
  const showOrderOptions =
    !_.isNil(defaultOrderingKey) && !_.isEmpty(orderOptions)

  const onPressEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    !!onChangeTerm && onChangeTerm((e.target as HTMLInputElement).value)
  }

  return (
    <div className={cx('container')}>
      <Form form={formRef} className="tw-flex tw-space-x-2.5 tw-mr-2.5">
        {showOrderOptions && (
          <Form.Item
            name="ordering"
            className="tw-mb-0"
            initialValue={defaultOrderingKey}
          >
            <Select
              className="tw-w-44"
              placeholder={orderPlaceholder}
              onChange={onChangeOrder}
              loading={isLoading}
              options={orderOptions}
            />
          </Form.Item>
        )}
        {showSortOptions && (
          <Form.Item
            name="isDesc"
            className="tw-mb-0"
            initialValue={defaultOrderingIsDesc}
          >
            <Radio.Group
              options={sortOptions}
              onChange={onChangeSort}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        )}
        {showSearch && (
          <Form.Item
            name="search"
            className="tw-mb-0"
            initialValue={defaultSearchTerm}
          >
            <Input.Search
              className="tw-w-44 term-wrapper"
              placeholder={searchPlaceholder}
              allowClear
              onSearch={onChangeTerm}
              onPressEnter={onPressEnter}
              data-testid={!!wrapperName && `${wrapperName}/search-term`}
            />
          </Form.Item>
        )}
      </Form>
      {props.children}
    </div>
  )
}

export default QuerySectionView
