import { useState } from 'react'
import useSWR from 'swr'

import { post, patch, del } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.careItem'
import {
  buildManipulationHook,
  CreationHookReturnType,
  ModificationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import {
  CareItem,
  CareItemCreatePayload,
  CareItemModifyPayload,
} from '@/apps/careItem/@types/careItem'

export const useCareItemCreation: () => CreationHookReturnType<
  CareItemCreatePayload,
  CareItem
> = () => {
  return buildManipulationHook<CareItemCreatePayload, CareItem>({
    url: URLS.careItemList,
    request: post,
  })
}

export const useCareItemModification: () => ModificationHookReturnType<
  CareItemModifyPayload,
  CareItem
> = () => {
  const [key, setKey] = useState<Key>(null)
  return {
    ...buildManipulationHook<CareItemModifyPayload, CareItem>({
      url: key ? URLS.careItemDetail(key as number) : null,
      request: patch,
    }),
    setKey,
  }
}

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCareItemDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating } = useSWR(
    [key ? URLS.careItemDetail(key as number) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !!key && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey,
  }
}
