import { statuses as baseStatuses } from '@/constants/common'
import { CustomerSupport } from '@/apps/customer/@types/customer'

export const URLS = {
  customerList: '/customers/customers/',
  getCustomerDetail: (key: string) => `/customers/customers/${key}/`,
  syncCafe24: (key: string) => `/customers/customers/${key}/sync-cafe24/`,
  customerKindList: '/customers/kinds/',
  getCustomerKindDetail: (key: number) => `/customers/kinds/${key}/`,
  assignStaff: (key: string) => `/customers/customers/${key}/staff/`,
  getUserChat: (key: string) =>
    `https://desk.channel.io/#/channels/28884/user_chats/${key}`,
  customerCurriculumList: '/curriculums/customer-curriculums/',
  getCustomerCurriculumDetail: (key: string) =>
    `/curriculums/customer-curriculums/${key}/`,
  closeCustomerCurriculum: (key: number) =>
    `/curriculums/customer-curriculums/${key}/close/`,
  resetCustomerCurriculum: (key: number) =>
    `/curriculums/customer-curriculums/${key}/reset/`,
  customerCareList: '/curriculums/customer-cares/',
  customerCareItemList: '/curriculums/customer-care-items/',
  customerCareDetail: (key: number) => `/curriculums/customer-cares/${key}/`,
  customerCareItemDetail: (key: number) =>
    `/curriculums/customer-care-items/${key}/`,
  customerChannelio: (uid: string) => `/channelio/user-chats/${uid}/`,
} as const

export const PAGES = {
  customers: {
    name: '전체 고객 목록',
    title: '전체 고객 목록',
    requiredAuth: true,
    exact: true,
    path: '/customers',
    testId: 'customer-list-page',
  },
  customerDetail: {
    name: '고객 상세페이지',
    title: '전체 고객 목록',
    requiredAuth: true,
    exact: true,
    path: '/customers/:uid',
    testId: 'customer-detail-page',
  },
  myCustomers: {
    name: '담당 고객 목록',
    title: '담당 고객 목록',
    requiredAuth: true,
    exact: true,
    path: '/my-customers',
    testId: 'my-customers-page',
  },
  myCustomerDetail: {
    name: '고객 상세페이지',
    title: '담당 고객 목록',
    requiredAuth: true,
    exact: true,
    path: '/my-customers/:uid',
    testId: 'my-customer-detail-page',
  },
  customerKinds: {
    name: '관리자 메뉴',
    title: '고객 유형 관리',
    requiredAuth: true,
    exact: true,
    path: '/customer-kinds',
    testId: 'customer-kinds-page',
  },
} as const

export const chatService = {
  NONE: 'none',
  CHANNEL: 'channel.io',
} as const

export const genders = {
  N: '선택 안함',
  M: '남자',
  F: '여자',
} as const

export const customerSupport = {
  NONE: 'null',
  ORDER: 'order',
  PASS: 'pass',
  REORDER: 'reorder',
  UNNECESSARY: 'unnecessary',
} as const

export const customerSupportLabels: Record<CustomerSupport, string> = {
  [customerSupport.NONE]: '-',
  [customerSupport.ORDER]: '구매 상담 필요',
  [customerSupport.PASS]: 'PASS',
  [customerSupport.REORDER]: '재주문 상담 필요',
  [customerSupport.UNNECESSARY]: '상담 목록 제외',
} as const

export const noCustomerKindValues = {
  id: -1,
  value: 'null',
  name: '선택 안함',
} as const

export const customersOrderingLabel = {
  mall_synced_at: '최근 동기화 일시',
  last_order_id: '최근 주문번호',
  mall_user_name: '고객명',
  mall_member_id: '아이디',
  staff_first_name: '담당 상담사',
} as const

export const myCustomersOrderingLabel = {
  mall_synced_at: '최근 동기화 일시',
  assigned_at: '배정 일시',
  mall_user_name: '고객명',
  mall_member_id: '아이디',
  last_order_id: '최근 주문번호',
  diary_score: '어제 진척도',
} as const

export const customerKindOrderingLabel = {
  name: '고객 유형',
  created_at: '생성 일자',
  updated_at: '갱신 일자',
} as const

const statuses = {
  ...baseStatuses,
  DISABLED: 'disabled',
} as const

export const customerKindStatuses = statuses

export const customerKindStatusTagLabel = {
  [statuses.ACTIVE]: '사용 중',
  [statuses.DISABLED]: '사용 중단',
} as const

export const customerKindStatusTagColor = {
  [statuses.ACTIVE]: 'blue',
  [statuses.DISABLED]: 'red',
} as const

// customer curriculum
export const customerCurriculumProgress = {
  RESERVED: 'reserved',
  ON_PROGRESS: 'on_progress',
  CLOSED: 'closed',
} as const

export const customerCurriculumListOrderingKeys = ['updated_at'] as const

export const customerCareListOrderingKeys = ['updated_at'] as const
