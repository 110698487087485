import React, { ReactNode } from 'react'
import classNames from 'classnames/bind'

import { Tooltip } from 'antd'

import BasicButton, {
  BasicButtonPropType,
} from '@/components/buttons/BasicButton'

import styles from './LinkChannelTalkButton.module.scss'

const cx = classNames.bind(styles)

interface PropType {
  showTooltip: boolean
  userChatUrl: string
  tooltipLabel?: string | ReactNode
  handleOnClick?: () => void
}

const LinkChannelTalkButtonView: React.FC<PropType & BasicButtonPropType> = ({
  showTooltip,
  tooltipLabel,
  handleOnClick,
  userChatUrl,
  ...props
}) => {
  const channelTalkErrorLabel = (
    <span>
      고객 채널톡 아이디 미생성 상태
      <br />
      고객에게 채널톡 메시지 전송 요청하기
    </span>
  )
  const channelTalkButtonLabel = props.label || '채널톡 바로가기'

  const disabled = !userChatUrl

  const renderChannelTalkButton = () => {
    return (
      <BasicButton
        data-testid="button-with-onClick"
        label={channelTalkButtonLabel}
        onClick={handleOnClick}
        disabled={disabled}
        {...props}
      />
    )
  }

  const renderChannelTalkButtonWithTooltip = () => {
    return (
      <Tooltip title={tooltipLabel || channelTalkErrorLabel} trigger="hover">
        <BasicButton
          data-testid="button-with-tooltip"
          className={cx('fake-disable')}
          label={channelTalkButtonLabel}
          disabled={disabled}
          {...props}
        />
      </Tooltip>
    )
  }

  return (
    <div>
      {showTooltip
        ? renderChannelTalkButtonWithTooltip()
        : renderChannelTalkButton()}
    </div>
  )
}

export default LinkChannelTalkButtonView
