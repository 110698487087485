import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import classNames from 'classnames/bind'

import FormModal from '@/components/modal/FormModal'
import {
  MemoKind,
  CreatingFormRules,
  ModifyingFormRules,
} from '@/apps/memo/@types/memo-kind'

import styles from './EditMemoKindForm.View.module.scss'

const cx = classNames.bind(styles)

export interface PropType {
  isVisible?: boolean
  isLoading?: boolean
  memoKind?: MemoKind | null
  onFinish?: () => void
  onCancel?: () => void
  rules?: CreatingFormRules | ModifyingFormRules
  formRef?: FormInstance // only for testing
}

const { TextArea } = Input

export const EditMemoKindFormView: React.FC<PropType> = ({
  isVisible,
  isLoading,
  memoKind,
  rules,
  onFinish,
  onCancel,
  formRef,
}) => {
  const isModifying = !!memoKind
  const title = isModifying ? '유형 수정' : '신규 유형 추가'
  const subTitle = isModifying ? '메모 유형 수정' : '신규 메모 유형 추가'
  const formInitialValue = memoKind ? { ...memoKind } : undefined

  return (
    <FormModal
      width="700px"
      visible={isVisible}
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <div className={cx('container')}>
        <Form
          form={formRef}
          initialValues={formInitialValue}
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item label="메모 유형">
            <Form.Item name="name" rules={rules?.name}>
              <Input
                className={cx('input-name')}
                placeholder="유형명 입력 (최대 노출 글자 수 11자)"
                data-testid="formMemoKind/name"
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="설명" name="description" rules={rules?.description}>
            <TextArea
              className={cx('input-description')}
              placeholder="설명 입력 (최대 노출 글자 수 28자)"
              rows={2}
              data-testid="formMemoKind/description"
            />
          </Form.Item>
        </Form>
      </div>
    </FormModal>
  )
}

export default EditMemoKindFormView
