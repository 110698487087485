import React, { useEffect, useCallback, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { ArrowLeftOutlined as IconArrowLeftOutlined } from '@ant-design/icons'

import { useUserContext } from '@/contexts/UserContext'
import { useLayoutContext } from '@/contexts/LayoutContext'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { Customer } from '@/apps/customer/@types/customer'
import useCustomerDetail from '@/apps/customer/hooks/useCustomerDetail'
import CustomerDetail from '@/apps/customer/components/CustomerDetail'
import { CustomerActivity } from '@/apps/customer/components/customer-activity'
import { AssignStaffMenu } from '@/apps/customer/components/assign-staff-menu'
import LinkChannelTalkButton from '@/apps/customer/components/link-channeltalk-button/LinkChannelTalkButton'

import IndexView, { PanelItemPropType } from './Index.View'
import CounselingView from './Counseling.View'

const customerDetailIndexKey = {
  info: 'info',
  management: 'management',
  logs: 'logs',
} as const

type CustomerDetailIndexKey =
  typeof customerDetailIndexKey[keyof typeof customerDetailIndexKey]

const customerDetailIndex: Record<CustomerDetailIndexKey, string> = {
  [customerDetailIndexKey.info]: '고객 정보 상세',
  [customerDetailIndexKey.management]: '고객 상담 관리',
  [customerDetailIndexKey.logs]: '고객 활동 상세',
} as const

const initialSelectedIndex: Record<CustomerDetailIndexKey, boolean> = {
  [customerDetailIndexKey.info]: false,
  [customerDetailIndexKey.management]: false,
  [customerDetailIndexKey.logs]: false,
}

type SelectedIndex = typeof initialSelectedIndex

interface RouteParam {
  uid: string
  [key: string]: string
}

const CustomerDetailIndex: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams<RouteParam>()
  const { current } = useUserContext()
  const { setTitleSectionData } = useLayoutContext()
  const { data, isLoading, mutate, error } = useCustomerDetail(params?.uid)
  const [selectedIndex, setSelectedIndex] =
    useState<SelectedIndex>(initialSelectedIndex)

  useEffect(() => {
    return () => {
      setTitleSectionData(null)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      setTitleSectionData({
        title: <PageTitle customer={data} />,
        extraActions: (
          <ExtraActions customer={data} onFinishAction={onFinishAction} />
        ),
      })
    }
  }, [data, isLoading])

  useEffect(() => {
    if (error?.response?.status === 404) {
      const path = location.pathname.split('/')[1]
      history.push(`/${path}/`)
    }
  }, [error])

  if (isLoading) {
    return <div>loading</div>
  }
  if (!data) {
    return <div>customer not found</div>
  }

  const onFinishAction = () => {
    mutate()
  }

  const onChangeIndex = (key: string) => {
    setSelectedIndex({
      info: key === customerDetailIndexKey.info,
      management: key === customerDetailIndexKey.management,
      logs: key === customerDetailIndexKey.logs,
    })
  }

  const panels: PanelItemPropType[] = [
    {
      tab: customerDetailIndex.info,
      key: customerDetailIndexKey.info,
      component: CustomerDetail,
      componentProps: {
        customer: data,
        user: current,
      },
    },
    {
      tab: customerDetailIndex.management,
      key: customerDetailIndexKey.management,
      component: CounselingView,
      componentProps: {
        customer: data,
        user: current,
        foldContent: selectedIndex.management,
      },
    },
    {
      tab: customerDetailIndex.logs,
      key: customerDetailIndexKey.logs,
      component: CustomerActivity,
      componentProps: {
        customer: data,
        user: current,
      },
    },
  ]

  return <IndexView panels={panels} onChangeIndex={onChangeIndex} />
}

interface TitleSectionPropType {
  customer: Customer<Order, User>
  onFinishAction?: () => void
}

const PageTitle: React.FC<TitleSectionPropType> = ({ customer }) => {
  const history = useHistory()
  const onClickBack = useCallback(() => {
    history.goBack()
  }, [])

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-align-middle">
      <IconArrowLeftOutlined className="tw-mr-4" onClick={onClickBack} />
      {customer.mallUserName} 고객
    </div>
  )
}

const ExtraActions: React.FC<TitleSectionPropType> = ({
  customer,
  onFinishAction,
}) => {
  const { chatService, chatUid } = customer

  const onFinishAssign = () => {
    !!onFinishAction && onFinishAction()
  }

  return (
    <div className="tw-flex tw-justify-between">
      <AssignStaffMenu customer={customer} onFinish={onFinishAssign} />
      <LinkChannelTalkButton
        className="tw-ml-4"
        chatService={chatService}
        chatUid={chatUid}
        uid={customer.uid}
      />
    </div>
  )
}

export default CustomerDetailIndex
