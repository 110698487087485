import React, { useEffect } from 'react'

import { useSyncCafe24 } from '@/apps/customer/hooks/useSyncCafe24'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'

import SyncButtonView from './SyncButton.View'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  id: string
  onFinish: () => void
  tooltipLabel: string | null
  isSynchronizable: boolean
}

const SyncButton: React.FC<PropType> = ({
  id,
  onFinish,
  tooltipLabel,
  isSynchronizable,
}) => {
  const { data, error, isLoading, isOneMore, setUserKey, mutate } =
    useSyncCafe24()

  useEffect(() => {
    if (isLoading || !data) return

    notifySuccess({
      message: '동기화 완료',
      description: '동기화 처리를 완료하였습니다.',
    })

    onFinish()
  }, [data, isLoading])

  useEffect(() => {
    if (isLoading || !error) return
    const errorMessage: { message: string; description: string } = {
      message: '동기화 실패',
      description: '동기화 처리를 실패하였습니다. 다시 시도해주세요.',
    }

    if (serverErrorStatus.includes(error?.response?.status)) {
      errorMessage.message = '처리 실패'
      errorMessage.description =
        '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.'
    } else if (error?.response?.data?.detail) {
      errorMessage.message = '요청 실패'
      errorMessage.description =
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.'
    }

    notifyFailure(errorMessage)
  }, [error, isLoading])

  const onClick = () => {
    if (!isSynchronizable) return
    if (isOneMore) {
      mutate()
    } else {
      setUserKey(id)
    }
  }

  return (
    <SyncButtonView
      onClick={onClick}
      loading={isLoading}
      tooltipLabel={tooltipLabel}
      isSynchronizable={isSynchronizable}
    />
  )
}

export default SyncButton
