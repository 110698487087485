import React, { useEffect } from 'react'
import _ from 'lodash'
import { Form, FormInstance } from 'antd'

import envs from '@/config/variables'
import { setErrorFieldsFromServer, hasAnyFieldsError } from '@/utils/antd/form'
import { form as formError } from '@/apps/memo/messages/error'
import {
  MemoKind,
  CreatingFormRules,
  ModifyingFormRules,
} from '@/apps/memo/@types/memo-kind'
import { memoKindStatuses } from '@/apps/memo/constants'
import {
  useMemoKindCreation,
  useMemoKindModification,
} from '@/apps/memo/hooks/useMemoKindManipulation'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'

import EditMemoKindFormView from './EditMemoKindForm.View'
import { memoErrors } from '@/constants/apps.memo'

export interface PropType {
  isVisible?: boolean
  memoKind?: MemoKind | null
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  formRefForTesting?: FormInstance // only for testing
}

export const EditMemoKindForm: React.FC<PropType> = ({
  isVisible,
  memoKind,
  onFinish,
  onCancel,
  formRefForTesting,
}) => {
  const isModifying = !!memoKind
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const creating = useMemoKindCreation()
  const modifying = useMemoKindModification()

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    if (isModifying) {
      modifying.setPayload({
        ...currentFormRef.getFieldsValue(),
        status: undefined,
      })
      modifying.setMemoKindKey(memoKind?.id)
    } else {
      creating.setPayload(currentFormRef.getFieldsValue())
    }
  }

  useEffect(() => {
    currentFormRef.resetFields()
  }, [])

  useEffect(() => {
    currentFormRef.resetFields()
  }, [isVisible])

  useEffect(() => {
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.data) || (isModifying && !modifying.data)) {
      return
    }
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setMemoKindKey(null)
    _notifySuccess(isModifying)
    !!onFinish && onFinish(isModifying)
  }, [
    isModifying,
    creating.data,
    creating.isLoading,
    modifying.data,
    modifying.isLoading,
  ])

  useEffect(() => {
    if (creating.isLoading || modifying.isLoading) return
    if (
      (!isModifying && !creating.error) ||
      (isModifying && !modifying.error)
    ) {
      return
    }

    if (modifying.error?.status === 400 && onCancel) {
      onCancel()
    }
    setErrorFieldsFromServer(
      currentFormRef,
      creating.error?.data ?? modifying.error?.data,
    )
    _notifyFailure(
      isModifying,
      isModifying
        ? modifying.error?.data?.detail ?? ''
        : creating.error?.data?.detail ?? '',
    )
  }, [
    isModifying,
    creating.error,
    creating.isLoading,
    modifying.error,
    modifying.isLoading,
  ])

  return (
    <EditMemoKindFormView
      isVisible={isVisible}
      isLoading={creating.isLoading || modifying.isLoading}
      memoKind={memoKind}
      onFinish={_onFinish}
      onCancel={onCancel}
      rules={getRules(isModifying)}
      formRef={currentFormRef}
    />
  )
}

const _notifySuccess = (isModifying: boolean) => {
  const description = isModifying
    ? '성공적으로 메모 유형을 수정했습니다.'
    : '성공적으로 메모 유형을 추가했습니다.'
  notifySuccess({
    message: isModifying ? '수정 완료' : '저장 완료',
    description,
  })
}

const _notifyFailure = (isModifying: boolean, errorMessage: string) => {
  notifyFailure({
    message: memoErrors[errorMessage]
      ? memoErrors[errorMessage].message
      : isModifying
      ? memoErrors.modify.message
      : memoErrors.store.message,
    description: memoErrors[errorMessage]
      ? memoErrors[errorMessage].description
      : isModifying
      ? memoErrors.modify.description
      : memoErrors.store.description,
  })
}

export const creatingRules: CreatingFormRules = {
  name: [
    {
      required: true,
      message: formError.requiredField,
    },
    {
      type: 'string',
      max: 11,
      message: formError.invalidFormat,
    },
  ],
  description: [
    {
      required: true,
      message: formError.requiredField,
    },
    {
      type: 'string',
      max: 28,
      message: formError.invalidFormat,
    },
  ],
}

const statusSet = new Set([memoKindStatuses.ACTIVE, memoKindStatuses.DISABLED])

export const modifyingRules: ModifyingFormRules = {
  ...creatingRules,
  status: [
    () => ({
      validator(__, value) {
        return _.isNil(value) || statusSet.has(value)
          ? Promise.resolve()
          : Promise.reject(new Error(formError.invalidStatusValue))
      },
    }),
  ],
}

const getRules = (
  isModifying: boolean,
): CreatingFormRules | ModifyingFormRules =>
  isModifying ? modifyingRules : creatingRules

export default EditMemoKindForm
