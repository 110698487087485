import useSWR from 'swr'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.task'
import { PagePaginationResponse } from '@/clients/http/types'
import { TaskKind, TaskKindListParam } from '@/apps/task/@types/task'

const fetcher = (url: string | null, params?: TaskKindListParam) => {
  if (!url) return
  return get<TaskKindListParam, PagePaginationResponse<TaskKind>>(url, params)
}

const useTaskKindList = () => {
  const { data, error, isValidating } = useSWR(URLS.taskKindList, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
  })
  const isLoading = !data && !error
  return {
    data,
    error,
    isLoading,
    isValidating,
  }
}

export default useTaskKindList
