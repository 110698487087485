import { useState } from 'react'

import { patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import { buildManipulationHook } from '@/utils/swr/helpers'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import {
  Customer,
  CustomerModifyPayload,
} from '@/apps/customer/@types/customer'

export const useCustomerModification = () => {
  const [key, setKey] = useState<string | null | undefined>(null)
  return {
    ...buildManipulationHook<CustomerModifyPayload, Customer<Order, User>>({
      url: key ? URLS.getCustomerDetail(key) : null,
      request: patch,
    }),
    setCustomerKey: setKey,
  }
}
