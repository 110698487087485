import useSWR from 'swr'

import { Nil } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.care'
import { Care } from '../@types/care'

const fetcher = (url: string | null) => {
  if (!url) return
  return get<never, Care>(url)
}

const useCareDetail = (id: Nil | number) => {
  const { data, error, isValidating, mutate } = useSWR(
    id ? URLS.careDetail(id) : null,
    fetcher,
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  }
}

export default useCareDetail
