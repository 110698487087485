import React, { useEffect } from 'react'
import _ from 'lodash'
import { Form, FormInstance } from 'antd'

import envs from '@/config/variables'
import { setErrorFieldsFromServer, hasAnyFieldsError } from '@/utils/antd/form'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import { form as formError } from '@/apps/memo/messages/error'
import { memoKindStatuses, memoStatuses } from '@/apps/memo/constants'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import {
  Memo,
  CreatingFormRules,
  ModifyingFormRules,
} from '@/apps/memo/@types/memo'
import { MemoKind } from '@/apps/memo/@types/memo-kind'
import { Customer } from '@/apps/customer/@types/customer'
import {
  useMemoCreation,
  useMemoModification,
} from '@/apps/memo/hooks/useMemoManipulation'

import EditMemoFormView from './EditMemoForm.View'
import { serverErrorStatus } from '@/utils/http'

export interface DataPropType {
  isVisible?: boolean
  customer: Customer<Order, User>
  memo?: Memo | null
  memoKinds: MemoKind[]
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  formRefForTesting?: FormInstance // only for testing
}

type PropType = DataPropType & React.HTMLAttributes<HTMLElement>

export const EditMemoForm: React.FC<PropType> = ({
  isVisible,
  memo,
  customer,
  memoKinds,
  onFinish,
  onCancel,
  formRefForTesting,
}) => {
  const isModifying = !!memo
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const creating = useMemoCreation(customer.uid)
  const modifying = useMemoModification()

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    if (isModifying) {
      modifying.setPayload({
        ...currentFormRef.getFieldsValue(),
        status: undefined,
      })
      modifying.setMemoKey(memo?.id)
    } else {
      creating.setPayload(currentFormRef.getFieldsValue())
    }
  }

  useEffect(() => {
    currentFormRef.resetFields()
  }, [isVisible])

  useEffect(() => {
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.data) || (isModifying && !modifying.data)) {
      return
    }
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setMemoKey(null)
    _notifySuccess(isModifying)
    !!onFinish && onFinish(isModifying)
  }, [creating.data, creating.isLoading, modifying.data, modifying.isLoading])

  useEffect(() => {
    if (creating.isLoading || modifying.isLoading) return
    if (
      (!isModifying && !creating.error) ||
      (isModifying && !modifying.error)
    ) {
      return
    }
    const memoError = isModifying ? modifying.error : creating.error
    setErrorFieldsFromServer(
      currentFormRef,
      creating.error?.data ?? modifying.error?.data,
    )
    onCancel && onCancel()
    if (
      memoError?.status === 403 ||
      memoError?.status === 404 ||
      memoError?.status === 400
    ) {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다.',
      })
    } else if (serverErrorStatus.includes(memoError?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
      })
    } else {
      _notifyFailure(isModifying)
    }
  }, [creating.error, creating.isLoading, modifying.error, modifying.isLoading])

  const defaultMemoKind = memoKinds.find(
    (o) => o.status === memoKindStatuses.ACTIVE,
  )
  if (!defaultMemoKind) return null

  return (
    <EditMemoFormView
      isVisible={isVisible}
      isLoading={creating.isLoading || modifying.isLoading}
      memo={memo}
      memoKinds={memoKinds}
      defaultMemoKind={defaultMemoKind}
      onFinish={_onFinish}
      onCancel={onCancel}
      rules={getRules(isModifying)}
      formRef={currentFormRef}
    />
  )
}

const _notifySuccess = (isModifying: boolean) => {
  const description = isModifying
    ? '성공적으로 메모를 수정했습니다.'
    : '성공적으로 메모를 추가했습니다.'
  notifySuccess({
    message: isModifying ? '수정 완료' : '저장 완료',
    description,
  })
}

const _notifyFailure = (isModifying: boolean) => {
  const description = isModifying
    ? '메모 수정에 실패했습니다. 다시 시도해주세요.'
    : '메모 저장에 실패했습니다. 다시 시도해주세요.'
  notifyFailure({
    message: isModifying ? '수정 실패' : '저장 실패',
    description: `${description} 다시 시도해주세요.`,
  })
}

export const creatingRules: CreatingFormRules = {
  kind: [
    {
      required: true,
      message: formError.requiredKind,
    },
  ],
  content: [
    {
      required: true,
      message: formError.invalidFormat,
    },
  ],
}

const statusSet = new Set([memoStatuses.ACTIVE, memoStatuses.DISABLED])

export const modifyingRules: ModifyingFormRules = {
  ...creatingRules,
  status: [
    () => ({
      validator(__, value) {
        return _.isNil(value) || statusSet.has(value)
          ? Promise.resolve()
          : Promise.reject(new Error(formError.invalidStatusValue))
      },
    }),
  ],
}

const getRules = (
  isModifying: boolean,
): CreatingFormRules | ModifyingFormRules =>
  isModifying ? modifyingRules : creatingRules

export default EditMemoForm
