import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { get } from '@/clients/http/authorized-http-client'
import { AUTH_STORAGE_KEY, URLS } from '@/constants/apps.auth'
import { getDefaultStorage } from '@/utils/storages'

const storage = getDefaultStorage()

export const fetcher = async (url: string | null) => {
  if (!url) return
  return get(url)
}

const useLogout = () => {
  const [url, setUrl] = useState<string | null>(null)
  const { data, error } = useSWR(url, fetcher)
  const isLoading = !!url && !data && !error
  const isLoggedOut = !!url && !isLoading && !error

  const unAuthenticate = () => {
    setUrl(URLS.logout)
    storage.removeItem(AUTH_STORAGE_KEY)
  }

  useEffect(() => {
    if (!isLoggedOut) return
    setUrl(null)
  }, [isLoggedOut])

  return {
    isLoading,
    isLoggedOut,
    data,
    error,
    unAuthenticate,
  }
}

export default useLogout
