import React from 'react'
import { Tabs, Typography } from 'antd'
import classNames from 'classnames/bind'

import { buildKeyValueLabelsByValues } from '@/utils/ui/choice'
import {
  issuedTaskTabNames,
  progresses as allProgresses,
  progressLabels,
} from '@/constants/apps.task'
import { ActiveProgress, ProgressLabel } from '@/apps/task/@types/task'

import styles from './TaskProgressTabs.module.scss'

const cx = classNames.bind(styles)

type TitlesType = Record<string, number | undefined>

interface PropType {
  defaultTabKey?: ActiveProgress
  onChangeTab?: (tab: ActiveProgress) => void
  titles?: TitlesType
}

const TaskProgressTabs: React.FC<PropType> = ({
  onChangeTab,
  defaultTabKey,
  titles,
}) => {
  return (
    <Tabs
      className={cx('progress-tabs')}
      onChange={(v) => !!onChangeTab && onChangeTab(v as ActiveProgress)}
      defaultActiveKey={defaultTabKey}
    >
      {tabItems.map(([key, tab]) => (
        <Tabs.TabPane
          data-testid={`tab-${tab}`}
          tab={tabTitle(tab, titles)}
          key={key}
        >
          <TabTitle progress={key} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

const progresses = [allProgresses.NEW, allProgresses.SENT, allProgresses.DONE]

const tabItems = buildKeyValueLabelsByValues<ProgressLabel, ActiveProgress>(
  progressLabels,
  progresses,
)

const tabTitle = (tab: string, titles?: TitlesType) => {
  if (!titles) return `${tab}`
  return `${tab} (${titles[tab.toLowerCase()]})`
}

interface TabTitlePropType {
  progress: ActiveProgress
}

const TabTitle: React.FC<TabTitlePropType> = ({ progress }) => {
  const title = issuedTaskTabNames[progress]
  if (!title) return null
  return (
    <Typography.Title className="tw-m-0 tw-header-3" level={4}>
      {title}
    </Typography.Title>
  )
}

export default TaskProgressTabs
