import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { buildTableChangeFilter } from '@/utils/antd/table'

import { resolvePath } from '@/utils/url'
import { buildOrderingQueryString } from '@/utils/http'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { PAGES } from '@/constants/apps.customer'
import { orderingParamKey } from '@/constants/common'
import { useUserContext } from '@/contexts/UserContext'
import useLocationParams from '@/hooks/useLocationParams'

import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import {
  Customer,
  CustomerListParam,
  CustomerListOrderingKey,
} from '@/apps/customer/@types/customer'
import useCustomerList from '@/apps/customer/hooks/useCustomerList'
import useCustomerKindList from '@/apps/customer/hooks/useCustomerKindList'
import AssignStaffModal from '@/apps/customer/components/assign-staff-modal/AssignStaffModal'

import MyCustomerListView from './MyCustomerList.View'

type FullCustomer = Customer<Order, User>

const defaultPage = 1
const defaultLimit = 10
export const defaultOrderingKey: CustomerListOrderingKey = 'mall_synced_at'
export const defaultIsDesc = true

const paramKeys: Array<keyof CustomerListParam> = [
  'customerSupport',
  'kind',
  'query',
  'page',
  'limit',
  'ordering',
  'staff',
  'noMallSynced',
  'customerProgressStatus',
]

const defaultListParams: CustomerListParam = {
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CustomerListOrderingKey,
}

const MyCustomerList: React.FC = () => {
  const history = useHistory()
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
  const [selectedForDeletion, setSelectedForDeletion] =
    useState<FullCustomer | null>(null)

  const { current: staff } = useUserContext()

  const customers = useCustomerList({})
  const customerKinds = useCustomerKindList()

  const locParams = useLocationParams<
    CustomerListParam,
    CustomerListOrderingKey
  >(paramKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    hiddenParams: ['staff'],
    requiredParams: ['staff'],
    onSetParams: customers.setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['kind', 'customerSupport', 'customerProgressStatus'],
    tryPreviousPage: true,
    paginatedData: customers.data,
    httpError: customers.error,
  })

  useEffect(() => {
    if (!staff?.username) return
    locParams.setItem('staff', staff.username)
  }, [staff])

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeParam = (
    key: keyof CustomerListParam,
    value: ItemValue<CustomerListParam>,
    historyAction?: ChangeHistoryAction<CustomerListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onFinishSyncCafe24 = async () => {
    await customers.mutate()
  }

  const onClickLinkMemberId = (customer: FullCustomer) => {
    const path = resolvePath<FullCustomer>({
      path: PAGES.myCustomerDetail.path,
      params: customer,
    })
    history.push(path)
  }

  const onClickDelete = (customer: FullCustomer) => {
    setSelectedForDeletion(customer)
    setIsVisibleDeleteModal(true)
  }

  const onFinishDelete = async () => {
    await customers.mutate()
    setSelectedForDeletion(null)
    setIsVisibleDeleteModal(false)
  }

  const onCancelDelete = () => {
    setSelectedForDeletion(null)
    setIsVisibleDeleteModal(false)
  }

  const onChangeFilter = buildTableChangeFilter<FullCustomer>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onChangeShowOnlyNoMallSynced = (value: boolean) => {
    const showNoMallSynced = value ? value.toString() : null
    locParams.setItem('noMallSynced', showNoMallSynced, { type: 'setPage1' })
  }

  const orderingKey = locParams.fullParams.orderingKey as
    | CustomerListOrderingKey
    | undefined

  if (!staff?.username) return null

  return (
    <>
      <MyCustomerListView
        page={locParams.params.page || defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        count={customers.data?.count ?? 0}
        isLoading={customers.isLoading}
        data={customers.data?.results ?? []}
        customerKinds={customerKinds.data?.results ?? []}
        params={locParams.params}
        orderingKey={orderingKey}
        onChangePage={onChangePage}
        onChangeParam={onChangeParam}
        onClickLinkMemberId={onClickLinkMemberId}
        onFinishSyncCafe24={onFinishSyncCafe24}
        onClickDelete={onClickDelete}
        onChangeFilter={onChangeFilter}
        onChangeShowOnlyNoMallSynced={onChangeShowOnlyNoMallSynced}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
      />
      <AssignStaffModal
        customer={selectedForDeletion}
        staff={staff}
        isToMe={false}
        visible={isVisibleDeleteModal}
        assignActionType="delete"
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
      />
    </>
  )
}

export default MyCustomerList
