import React, { useState } from 'react'
import _ from 'lodash'

import { orderingParamKey } from '@/constants/common'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import useLocationParams from '@/hooks/useLocationParams'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { Customer } from '@/apps/customer/@types/customer'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { memoKindStatuses } from '@/apps/memo/constants'
import useMemoList from '@/apps/memo/hooks/useMemoList'
import useMemoKindList from '@/apps/memo/hooks/useMemoKindList'
import EditMemoForm from '@/apps/memo/components/edit-memo-form/EditMemoForm'
import DeleteMemoModal from '@/apps/memo/components/DeleteMemoModal'
import { notifyFailure } from '@/components/antd/Notification'
import {
  Memo,
  MemoListOrderingKey,
  MemoListParam,
} from '@/apps/memo/@types/memo'

import MemoListView from './MemoList.View'

const defaultPage = 1
const defaultLimit = 10
export const defaultOrderingKey: MemoListOrderingKey = 'updated_at'
export const defaultIsDesc = true

const paramKeys = [
  'user',
  'content',
  'kind',
  'status',
  'ordering',
  'page',
  'limit',
]

interface State {
  isVisibleForm: boolean
  isVisibleDeleteModal: boolean
  selected: Memo | null
}

interface DataPropType<T> {
  customer: Customer<Order, User>
  user: User
  defaultListParams?: T
  extraParamKeys?: Array<keyof T> | string[]
  useUrlParam?: boolean
  foldContent?: boolean
}

type PropType<T> = DataPropType<T> & React.HTMLAttributes<HTMLElement>

const MemoList = <T extends MemoListParam>(
  props: React.PropsWithChildren<PropType<T>>,
) => {
  const {
    customer,
    user,
    defaultListParams,
    useUrlParam,
    extraParamKeys = [],
    foldContent = false,
  } = props

  const [{ isVisibleForm, isVisibleDeleteModal, selected }, setState] =
    useState<State>({
      isVisibleForm: false,
      isVisibleDeleteModal: false,
      selected: null,
    })

  const { data, isLoading, setParams, mutate, error } =
    useMemoList(defaultListParams)
  const locParams = useLocationParams<T, MemoListOrderingKey>(
    [...paramKeys, ...extraParamKeys],
    {
      useUrl: useUrlParam,
      defaultParams: defaultListParams,
      orderingParamKey,
      numberParams: ['page', 'limit'],
      onSetParams: setParams,
      tryPreviousPage: true,
      paginatedData: data,
      httpError: error,
    },
  )
  const memoKindList = useMemoKindList()
  const memoKinds = (memoKindList.data?.results ?? []).filter(
    (o) => o.status === memoKindStatuses.ACTIVE,
  )

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onClickEdit = (item: Memo) => {
    setState((prev) => ({ ...prev, isVisibleForm: true, selected: item }))
  }

  const onClickDelete = (item: Memo) => {
    setState((prev) => ({
      ...prev,
      isVisibleDeleteModal: true,
      selected: item,
    }))
  }

  const onFinishDeletion = async () => {
    await mutate()
    onCancelDeletion()
  }

  const onCancelDeletion = () => {
    setState((prev) => ({
      ...prev,
      isVisibleDeleteModal: false,
      selected: null,
    }))
  }

  const onChangeParam = (
    key: keyof T,
    value: ItemValue<T>,
    historyAction?: ChangeHistoryAction<T>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeTable = buildTableChangeFilter<Memo>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddMemo = () => {
    if (!memoKinds.length) {
      notifyFailure({
        message: '메모 작성 불가',
        description: '메모 유형이 존재하지 않습니다.',
      })
      return
    }
    setState((prev) => ({
      ...prev,
      isVisibleForm: true,
      selected: null,
    }))
  }

  const onFinish = async () => {
    await mutate()
    onCancel()
  }

  const onCancel = () => {
    setState((prev) => ({
      ...prev,
      isVisibleForm: false,
      selected: null,
    }))
  }

  const isInChargeOf = customer.staff?.username === user.username
  const orderingKey = locParams.fullParams.orderingKey as
    | MemoListOrderingKey
    | undefined

  return (
    <>
      <MemoListView<T>
        isInChargeOf={isInChargeOf}
        isLoading={isLoading}
        page={locParams.params.page ?? defaultPage}
        count={data?.count ?? 0}
        limit={locParams.params.limit ?? defaultLimit}
        params={locParams.params}
        data={data?.results ?? []}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        memoKinds={memoKinds}
        onChangePage={onChangePage}
        onChangeParam={onChangeParam}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onChangeTable={onChangeTable}
        onClickAddMemo={onClickAddMemo}
        foldContent={foldContent}
      />
      {isVisibleForm && memoKinds.length > 0 && (
        <EditMemoForm
          isVisible={isVisibleForm}
          customer={customer}
          memo={selected}
          memoKinds={memoKinds}
          onFinish={onFinish}
          onCancel={onCancel}
          data-testid="memolist/edit-form"
        />
      )}
      {!!selected && isVisibleDeleteModal && (
        <DeleteMemoModal
          memo={selected}
          visible={isVisibleDeleteModal}
          title="메모 항목 삭제"
          content="목록에서 삭제하시겠습니까?"
          onFinish={onFinishDeletion}
          onCancel={onCancelDeletion}
        />
      )}
    </>
  )
}

export default MemoList
