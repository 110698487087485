import React, { useState } from 'react'

import { useUserContext } from '@/contexts/UserContext'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import AssignStaffModal from '@/apps/customer/components/assign-staff-modal/AssignStaffModal'

import AssignSelfButtonView from './AssignSelfButton.View'

interface PropType {
  customer: Customer<Order, User>
  disabled: boolean
  onFinish: () => void
}

const AssignSelfButton: React.FC<PropType> = ({
  customer,
  disabled,
  onFinish,
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  const { current } = useUserContext()

  const onCancelAssign = () => {
    setIsVisibleModal(false)
  }

  const onFinishAssign = () => {
    setIsVisibleModal(false)
    onFinish && onFinish()
  }

  const onClickAssign = () => {
    setIsVisibleModal(true)
  }

  return (
    <>
      <AssignSelfButtonView
        showTooltip={current?.role === 'admin'}
        disabled={disabled}
        onClick={onClickAssign}
      />
      {isVisibleModal && (
        <AssignStaffModal
          customer={customer}
          staff={current}
          isToMe={true}
          visible={isVisibleModal}
          assignActionType="create"
          onFinish={onFinishAssign}
          onCancel={onCancelAssign}
        />
      )}
    </>
  )
}

export default AssignSelfButton
