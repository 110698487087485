import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { Nil } from '@/@types/composite'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import Divider from '@/components/layout/section/Divider'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { CurriculumCareProgram } from '@/apps/curriculum/components/curriculum-care-program'
import styles from './EssentialCareList.View.module.scss'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import { Care } from '@/apps/care/@types/care'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import CustomerCareProgram from '@/apps/customer/components/customer-care-program/CustomerCareProgram'

interface PropType {
  isMyCustomer?: boolean
  isCustomerCurriculum: boolean
  curriculum?: Curriculum | Nil
  customerCurriculum?: CustomerCurriculum | Nil
  customerCurriculumOpenedAt?: Date | Nil
  caculatedCustomerCurriculumClosedAt?: Date | Nil
  essentialCares: CurriculumCare[] | CustomerCare<Care, CustomerCareItem>[]
  onFinishAddCareItem?: () => void
  onFinishDeleteCare?: () => void
  onFinishDeleteCareItem?: () => void
}

const EssentialCareListView: React.FC<PropType> = ({
  isMyCustomer,
  isCustomerCurriculum,
  curriculum,
  customerCurriculum,
  customerCurriculumOpenedAt,
  caculatedCustomerCurriculumClosedAt,
  essentialCares,
  onFinishAddCareItem,
  onFinishDeleteCare,
  onFinishDeleteCareItem,
}) => {
  if (!curriculum && !customerCurriculum) return null
  return (
    <Section>
      <SectionTitle title="필수 케어" />
      <Divider />
      <div className={cx('container')}>
        {!isCustomerCurriculum &&
          !!curriculum &&
          _.map(essentialCares as CurriculumCare[], (essentialCare) => (
            <CurriculumCareProgram
              key={essentialCare.id}
              isCustomerCurriculum={false}
              curriculum={curriculum}
              curriculumCare={essentialCare}
              onFinishAddCurriculumCareItem={onFinishAddCareItem}
              onFinishDeleteCurriculumCare={onFinishDeleteCare}
              onFinishDeleteCurriculumCareItem={onFinishDeleteCareItem}
            />
          ))}
        {isCustomerCurriculum &&
          !!customerCurriculum &&
          _.map(
            essentialCares as CustomerCare<Care, CustomerCareItem>[],
            (essentialCare) => (
              <CustomerCareProgram
                key={essentialCare.id}
                isMyCustomer={isMyCustomer}
                customerCurriculum={customerCurriculum}
                customerCare={essentialCare}
                customerCurriculumOpenedAt={customerCurriculumOpenedAt}
                caculatedCustomerCurriculumClosedAt={
                  caculatedCustomerCurriculumClosedAt
                }
                onFinishAddCustomerCareItem={onFinishAddCareItem}
                onFinishDeleteCustomerCare={onFinishDeleteCare}
                onFinishDeleteCustomerCareItem={onFinishDeleteCareItem}
              />
            ),
          )}
      </div>
    </Section>
  )
}

export default EssentialCareListView

const cx = classNames.bind(styles)
