import { useState } from 'react'

import { Nil } from '@/@types/composite'
import { post, patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.memo'
import { buildManipulationHook } from '@/utils/swr/helpers'
import {
  Memo,
  MemoModifyPayload,
  MemoCreatePayload,
} from '@/apps/memo/@types/memo'

export const useMemoCreation = (customerUid: string | Nil) => {
  return buildManipulationHook<MemoCreatePayload, Memo>({
    url: customerUid ? URLS.createCustomerMemo(customerUid) : null,
    request: post,
  })
}

export const useMemoModification = () => {
  const [key, setKey] = useState<number | null | undefined>(null)
  return {
    ...buildManipulationHook<MemoModifyPayload, Memo>({
      url: key ? URLS.getMemoDetail(key) : null,
      request: patch,
    }),
    setMemoKey: setKey,
  }
}
