import React from 'react'

import { ReactComponent as ImageLogoText } from '@/assets/logotype.svg'

import { User } from '@/apps/auth/@types/user'
import UserInfo from './UserInfo'
import NavBar from './NavBar'

import './SideBar.scss'

interface PropType {
  user: User
}

const SideBar: React.FC<PropType> = ({ user }) => {
  return (
    <div className="sidebar-container">
      <ImageLogoText className="logo" fill="#fff" />
      <UserInfo user={user} data-testid="userInfo" />
      <NavBar user={user} data-testid="navBar" />
    </div>
  )
}

export default SideBar
