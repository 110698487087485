import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { pathToRegexp } from 'path-to-regexp'
import { Breadcrumb } from 'antd'
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem'
import { PAGES as AUTH_PAGE } from '@/constants/apps.auth'
import { PAGES as DASHBOARD_PAGE } from '@/constants/apps.dashboard'
import { PAGES as CUSTOMER_PAGE } from '@/constants/apps.customer'
import { PAGES as MEMO_PAGE } from '@/constants/apps.memo'
import { PAGES as CURRICULUM_PAGE } from '@/constants/apps.curriculum'
import { PAGES as CARE_PAGE } from '@/constants/apps.care'
import { PAGES as CARE_ITEM_PAGE } from '@/constants/apps.careItem'
import { useLayoutContext } from '@/contexts/LayoutContext'

interface PropType {
  url: string
}

const PageBreadcrumb: React.FC<PropType> = ({ url }) => {
  const [currentPage, ...previousPages] = getPages(url).reverse()
  const history = useHistory()
  const { titleSectionData } = useLayoutContext()

  return (
    <div className="tw-border-t tw-border-b tw-border-gray-5 tw-bg-white">
      <div className="tw-m-5">
        {!!currentPage && (
          <Breadcrumb className="tw-body-3">
            {previousPages.map((page) => (
              <BreadcrumbItem
                className="tw-text-gray-6 tw-cursor-pointer"
                key={page.path}
                onClick={() => history.push(page.path)}
              >
                {page.name}
              </BreadcrumbItem>
            ))}
            {_.split(currentPage.name, ',').map((pageName) => (
              <BreadcrumbItem
                className="tw-text-gray-7 tw-cursor-text"
                key={pageName}
              >
                {pageName.trim()}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}

        <div className="tw-flex tw-justify-between tw-mt-1">
          {!!currentPage?.title && (
            <div className="tw-header-1 tw-m-0 tw-text-gray-9 tw-flex">
              {titleSectionData?.title ?? currentPage.title}
            </div>
          )}
          {titleSectionData?.extraActions}
        </div>
      </div>
    </div>
  )
}

const pages = {
  ...AUTH_PAGE,
  ...DASHBOARD_PAGE,
  ...CUSTOMER_PAGE,
  ...MEMO_PAGE,
  ...CURRICULUM_PAGE,
  ...CARE_PAGE,
  ...CARE_ITEM_PAGE,
}

const getPage = (url: string) => {
  return _.chain(pages)
    .values()
    .find((page) => pathToRegexp(page.path).test(url))
    .value()
}

export const getPages = (url: string) => {
  return _.chain(url.split('/'))
    .reduce<string[]>((acc, curr) => {
      if (!curr) return acc
      acc.push(`${acc.join('/')}/${curr}`)
      return acc
    }, [])
    .map(getPage)
    .filter(_.negate(_.isNil))
    .value()
}

export default PageBreadcrumb
