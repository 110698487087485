import React, { useState, useContext } from 'react'
import { isStaff } from '@/apps/auth/utils/helper'
import { User, UserContext as UserContextType } from '@/apps/auth/@types/user'

export const UserContext = React.createContext<UserContextType>({
  current: null,
  setCurrent: () => undefined,
  isStaffUser: false,
  isAdminUser: false,
})

export const useUserContext = () => useContext(UserContext)

export const UserProvider: React.FC = (props) => {
  const [current, setCurrent] = useState<User | null>(null)
  const isStaffUser = isStaff(current)
  return (
    <UserContext.Provider
      value={{
        current,
        setCurrent,
        isStaffUser,
        isAdminUser: !isStaffUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export const UserConsumer = UserContext.Consumer

export default UserContext
