import React, { useState } from 'react'

import { orderingParamKey } from '@/constants/common'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { Nil } from '@/@types/composite'
import { buildOrderingQueryString } from '@/utils/http'
import useLocationParams from '@/hooks/useLocationParams'
import { editableTaskKinds } from '@/constants/apps.task'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import {
  Task,
  TaskListOrderingKey,
  TaskListParam,
} from '@/apps/task/@types/task'
import useTaskList from '@/apps/task/hooks/useTaskList'
import useTaskKindList from '@/apps/task/hooks/useTaskKindList'
import EditTaskForm from '@/apps/task/components/EditTaskForm'
import DeleteTaskModal from '@/apps/task/components/DeleteTaskModal'
import { paramKeys } from '@/apps/task/constants'

import ScheduledTaskListView from './ScheduledTaskList.View'

interface DataPropType {
  customer: Customer<Order, User>
  user: User | Nil
}

type PropType = DataPropType & React.HTMLAttributes<HTMLElement>

const defaultPage = 1

const defaultLimit = 10

export const defaultOrderingKey = 'active_at'

export const defaultIsDesc = false

const defaultListParams: TaskListParam = {
  user: '',
  isScheduled: 'true',
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as TaskListOrderingKey,
}

const ScheduledTaskList: React.FC<PropType> = ({
  customer,
  user,
  ...props
}) => {
  if (!user) return null

  const [selected, setSelected] = useState<Task | null>(null)
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false)
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
  const tasks = useTaskList(
    customer.staff?.username,
    defaultListParams.isScheduled,
    {
      ...defaultListParams,
      customer: customer.uid,
    },
  )
  const taskKinds = useTaskKindList()
  const locParams = useLocationParams<TaskListParam, TaskListOrderingKey>(
    paramKeys,
    {
      useUrl: false,
      defaultParams: { ...tasks.params },
      orderingParamKey,
      onSetParams: tasks.setParams,
      numberParams: ['page', 'limit'],
      commaListParams: ['kind', 'customerSupport'],
      booleanParams: ['isScheduled'],
      tryPreviousPage: true,
      paginatedData: tasks.data,
      httpError: tasks.error,
    },
  )

  const isInChargeOf =
    !!customer?.staff && !!user && customer?.staff?.username === user?.username

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onClickAddTask = () => {
    setSelected(null)
    setIsVisibleModalForm(true)
  }

  const onClickEditTask = (task: Task) => {
    setSelected(task)
    setIsVisibleModalForm(true)
  }

  const onFinishForm = async () => {
    setIsVisibleModalForm(false)
    setSelected(null)
    await tasks.mutate()
  }

  const onClickDeleteTask = (task: Task) => {
    setSelected(task)
    setIsVisibleDeleteModal(true)
  }

  const onCloseEditForm = () => {
    setIsVisibleModalForm(false)
    setSelected(null)
  }

  const onCloseDeleteModal = () => {
    setIsVisibleDeleteModal(false)
    setSelected(null)
  }

  const onFinishDeletion = async () => {
    setIsVisibleDeleteModal(false)
    setSelected(null)
    await tasks.mutate()
  }

  const onChangeParam = (
    key: keyof TaskListParam,
    value: ItemValue<TaskListParam>,
    historyAction?: ChangeHistoryAction<TaskListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const taskKindsForForm =
    taskKinds.data?.results.filter((o) =>
      editableTaskKinds.includes(o.category),
    ) ?? []

  const orderingKey = locParams.fullParams.orderingKey as
    | TaskListOrderingKey
    | undefined

  return (
    <>
      <ScheduledTaskListView
        isInChargeOf={isInChargeOf}
        page={locParams.params.page || defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        count={tasks.data?.count ?? 0}
        isLoading={tasks.isLoading}
        data={tasks.data?.results ?? []}
        params={locParams.params}
        onClickAddTask={onClickAddTask}
        onClickEditTask={onClickEditTask}
        onClickDeleteTask={onClickDeleteTask}
        onChangePage={onChangePage}
        onChangeParam={onChangeParam}
        taskKinds={taskKinds.data?.results ?? []}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        {...props}
      />
      {isVisibleModalForm && (
        <EditTaskForm
          data-testid="edit-form-modal"
          task={selected}
          customer={customer}
          isVisible={isVisibleModalForm}
          onFinish={onFinishForm}
          onCancel={onCloseEditForm}
          taskKinds={taskKindsForForm}
        />
      )}
      {!!selected && isVisibleDeleteModal && (
        <DeleteTaskModal
          data-testid="delete-modal"
          task={selected}
          title="업무 목록 삭제"
          content="목록에서 삭제하시겠습니까?"
          visible={isVisibleDeleteModal}
          onFinish={onFinishDeletion}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  )
}

export default ScheduledTaskList
