import { useState } from 'react'
import useSWR from 'swr'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import { PagePaginationResponse } from '@/clients/http/types'
import {
  CustomerCare,
  CustomerCareItem,
  CustomerCareListParam,
} from '@/apps/customer/@types/customer.care'
import { Care } from '@/apps/care/@types/care'

const fetcher = (url: string | null, params?: CustomerCareListParam) => {
  if (!url) return
  return get<
    CustomerCareListParam,
    PagePaginationResponse<CustomerCare<Care, CustomerCareItem>>
  >(url, params)
}

const useCustomerCareList = (defaultParams = {}) => {
  const [params, setParams] = useState<CustomerCareListParam>({
    ordering: '-updated_at',
    ...defaultParams,
  })

  const _setParams = (newParams: CustomerCareListParam) => {
    setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    [params.customerUid ? URLS.customerCareList : null, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    setParams: _setParams,
  }
}

export default useCustomerCareList
