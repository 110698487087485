import { CustomerCurriculumErrorMessage } from '@/apps/customer/@types/customer.curriculum'

export const editCustomerCurriculumFormError = {
  required: {
    curriculum: '필수 항목입니다',
    name: '필수 입력 항목입니다',
    opendAt: '날짜를 선택해주세요',
    period: '필수 항목입니다',
  },
  maxLength: {
    name: '이름이 너무 깁니다.',
  },
} as const

export const editCustomerCurriculumFormErrorFromServer = {
  INVALID_NUMBER: '유효한 정수(integer)를 넣어주세요.',
  INVALID_NUMBER_RANGE: '이 값이 1보다 크거나 같은지 확인하십시오.',
} as const

export const editCustomerCurriculumFormErrorMessage: CustomerCurriculumErrorMessage =
  {
    [editCustomerCurriculumFormErrorFromServer.INVALID_NUMBER]:
      '1이상 정수를 입력하세요',
    [editCustomerCurriculumFormErrorFromServer.INVALID_NUMBER_RANGE]:
      '1이상 정수를 입력하세요',
  } as const
