import React, { useState, useEffect } from 'react'

import { HttpError } from '@/clients/http/types'
import BasicButton from '@/components/buttons/BasicButton'
import { useUserActivation } from '@/apps/auth/hooks/useUserActivation'
import { User } from '@/apps/auth/@types/user'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'

interface PropType {
  user: User
  buttonName?: string
  onFinish?: (user: User) => void
  buttonNameToDeactivate?: string
  buttonNameToActivate?: string
  disabled?: boolean
}

const buttonNamesByStatus = {
  true: '비활성화', // means, isActive === true
  false: '활성화', // means, isActive === false
}

const UserActivationButton: React.FC<PropType> = ({
  user,
  onFinish,
  buttonNameToDeactivate = buttonNamesByStatus.true,
  buttonNameToActivate = buttonNamesByStatus.false,
  disabled,
}) => {
  const { data, error, isLoading, setPayload } = useUserActivation()

  const [isVisibleModal, setIsVisibleModal] = useState(false)

  useEffect(() => {
    if (error?.response?.status === 403 || error?.response?.status === 404) {
      onCancelActivation()
    }
  }, [error])

  const status = user.isActive ? buttonNameToDeactivate : buttonNameToActivate

  const userActivationPayload = {
    username: user.username,
    isActive: !user.isActive,
  }

  const toggleModal = (visible: boolean) => {
    setIsVisibleModal(visible)
  }

  const onFinishActivation = () => {
    onFinish && onFinish(user)
    toggleModal(false)
  }

  const onCancelActivation = () => {
    toggleModal(false)
  }

  const action = () => {
    setPayload(userActivationPayload)
  }

  const failNotMessageMapping = (err: HttpError) => {
    if (err?.response?.status === 403 || err?.response?.status === 404) {
      return '요청 실패'
    }
    return `${status} 실패`
  }

  const failNotDescMapping = (err: HttpError) => {
    if (err?.response?.status === 403 || err?.response?.status === 404) {
      return '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.'
    }
    return `계정 ${status}에 실패했습니다. 다시 시도해주세요.`
  }

  const modalTitle = `계정 ${status}`
  const modalContent = `정말로 ${user.firstName} / ${user.username} 계정을 ${status} 하시겠습니까?`
  const successNotiMessage = `${status} 완료`
  const successNotiDesc = `성공적으로 계정을 ${status}했습니다.`

  return (
    <>
      <BasicButton
        label={status}
        type="text"
        disabled={disabled}
        onClick={() => toggleModal(true)}
        data-testid={`user-activation-button-${user.username}`}
      />

      <ConfirmFlowModal<typeof data, typeof error>
        visible={isVisibleModal}
        title={modalTitle}
        content={modalContent}
        successNotiMessage={successNotiMessage}
        successNotiDesc={successNotiDesc}
        failNotiMessage={failNotMessageMapping(error)}
        failNotiDesc={failNotDescMapping(error)}
        response={data}
        error={error}
        action={action}
        isLoadingRequest={isLoading}
        onFinish={onFinishActivation}
        onCancel={onCancelActivation}
      />
    </>
  )
}

export default UserActivationButton
