import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Nil } from '@/@types/composite'
import { useUserContext } from '@/contexts/UserContext'
import { ChangeHistoryAction } from '@/@types/router'
import useLocationParams from '@/hooks/useLocationParams'
import { buildOrderingQueryString, serverErrorStatus } from '@/utils/http'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { getVisibilityManipulation } from '@/utils/visibilityManipulation'
import { orderingParamKey } from '@/constants/common'
import ConfirmFlowModal, {
  DefaultLabelOfConfrimFlowModal,
} from '@/components/modal/ConfrimFlowModal'
import {
  confirmTextUpdatingVisibility,
  curriculumErrorMessage,
  manipulationUrl,
} from '@/constants/apps.curriculum'
import {
  Curriculum,
  CurriculumListParam,
  CurriculumListOrderingKey,
  CurriculumCompilationKind,
  CurriculumVisibility,
  CurriculumVisibilityManipulation,
} from '@/apps/curriculum/@types/curriculum'
import {
  useCurriculumCreation,
  useCurriculumModification,
  useCurriculumDeletion,
} from '@/apps/curriculum/hooks/useCurriculumManipulation'
import { useCurriculumVisibility } from '@/apps/curriculum/hooks/useCurriculumVisibilityManipulation'
import useCurriculumList from '@/apps/curriculum/hooks/useCurriculumList'
import EditCurriculumForm from '@/apps/curriculum/components/edit-curriculum-form/EditCurriculumForm'
import CurriculumListView from './CurriculumList.View'

interface State {
  disableManipulation: boolean
  isVisibleForm: boolean
  isVisibleConfirmVisibilityModal: boolean
  isVisibleConfirmDeletionModal: boolean
  selected: Curriculum | Nil
  selectedVisibility: CurriculumVisibility | Nil
  selectedManipulation: CurriculumVisibilityManipulation | Nil
}

const paramKeys: Array<keyof CurriculumListParam> = [
  'visibility',
  'query',
  'ordering',
  'page',
  'limit',
  'compilationKind',
]
const defaultPage = 1
const defaultLimit = 10
const defaultOrderingKey: CurriculumListOrderingKey = 'updated_at'
const defaultIsDesc = true
const defaultCompilationKind: CurriculumCompilationKind = 'regular'
const defaultListParams: CurriculumListParam = {
  page: defaultPage,
  limit: defaultLimit,
  compilationKind: defaultCompilationKind,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CurriculumListOrderingKey,
}

const CurriculumList: React.FC = () => {
  const [
    {
      disableManipulation,
      isVisibleForm,
      isVisibleConfirmVisibilityModal,
      isVisibleConfirmDeletionModal,
      selected,
      selectedVisibility,
      selectedManipulation,
    },
    setState,
  ] = useState<State>({
    disableManipulation: false,
    isVisibleForm: false,
    isVisibleConfirmVisibilityModal: false,
    isVisibleConfirmDeletionModal: false,
    selected: null,
    selectedVisibility: null,
    selectedManipulation: null,
  })

  const { data, error, isLoading, setParams, mutate } = useCurriculumList()

  const modifying = useCurriculumModification()
  const creating = useCurriculumCreation()
  const deleting = useCurriculumDeletion()
  const visibilityManipulation = useCurriculumVisibility()

  const locParams = useLocationParams<
    CurriculumListParam,
    CurriculumListOrderingKey
  >(paramKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    onSetParams: setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['visibility'],
    requiredParams: ['compilationKind'],
    tryPreviousPage: true,
    paginatedData: data,
    httpError: error,
  })

  const orderingKey = locParams.fullParams.orderingKey as
    | CurriculumListOrderingKey
    | undefined

  const { current: user, isStaffUser } = useUserContext()

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      disableManipulation: isStaffUser,
    }))
  }, [user])

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeParam = (
    key: keyof CurriculumListParam,
    value: unknown,
    historyAction?: ChangeHistoryAction<CurriculumListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeTable = buildTableChangeFilter<Curriculum>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddCurriculum = () => {
    setState((prev) => ({
      ...prev,
      isVisibleForm: true,
    }))
  }

  const onClickEdit = (item: Curriculum) => {
    setState((prev) => ({
      ...prev,
      selected: item,
      isVisibleForm: true,
    }))
  }

  const onCancelEdit = () => {
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleForm: false,
    }))
  }

  const onFinishEdit = async () => {
    await mutate()
    onCancelEdit()
  }

  const onClickDelete = (item: Curriculum) => {
    setState((prev) => ({
      ...prev,
      selected: item,
      isVisibleConfirmDeletionModal: true,
    }))
  }

  const onCancelDelete = () => {
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleConfirmDeletionModal: false,
    }))
  }

  const onFinishDelete = async () => {
    await mutate()
    deleting.setKey(null)
    onCancelDelete()
  }

  const deleteCurriculum = () => {
    deleting.setKey(selected?.id)
  }

  const onClickVisibility = (
    item: Curriculum,
    visibility: CurriculumVisibility,
  ) => {
    if (item.visibility === visibility) return
    setState((prev) => ({
      ...prev,
      selected: item,
      selectedVisibility: visibility,
      selectedManipulation: getVisibilityManipulation(
        item.visibility,
        visibility,
      ),
      isVisibleConfirmVisibilityModal: true,
    }))
  }

  const updateCurriculumVisibility = () => {
    visibilityManipulation.setKey(
      getManipulationUrl(selected?.id, selectedManipulation),
    )
  }

  const onCancelUpdateVisibility = () => {
    setState((prev) => ({
      ...prev,
      selected: null,
      selectedManipulation: null,
      selectedVisibility: null,
      isVisibleConfirmVisibilityModal: false,
    }))
    visibilityManipulation.setKey(null)
  }

  const onFinishUpdateVisibility = async () => {
    await mutate()
    onCancelUpdateVisibility()
  }

  const _setModifyingKey = (data: Curriculum | Nil) => {
    modifying.setKey(data?.id)
  }

  return (
    <>
      <CurriculumListView
        params={locParams.params}
        page={locParams.params.page ?? defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        count={data?.count ?? 0}
        data={data?.results ?? []}
        orderingKey={orderingKey}
        isLoading={isLoading}
        disabled={disableManipulation}
        onChangeParam={onChangeParam}
        onChangePage={onChangePage}
        onChangeTable={onChangeTable}
        onClickAddCurriculum={onClickAddCurriculum}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickVisibility={onClickVisibility}
      />
      <EditCurriculumForm
        isVisible={isVisibleForm}
        selectedItem={selected}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
        setModifyingKey={_setModifyingKey}
        creating={creating}
        modifying={modifying}
      />
      {!!selectedVisibility && (
        <ConfirmFlowModal<
          typeof visibilityManipulation.data,
          typeof visibilityManipulation.error
        >
          notiKey="curriculumVisibility"
          visible={isVisibleConfirmVisibilityModal && !!selected}
          title={visibilityManipulationDefaultMessage.title}
          content={confirmTextUpdatingVisibility[selectedVisibility]}
          successNotiMessage={
            visibilityManipulationDefaultMessage.successNotiMessage
          }
          successNotiDesc={visibilityManipulationDefaultMessage.successNotiDesc}
          failNotiMessage={
            curriculumErrorMessage[
              serverErrorStatus.includes(visibilityManipulation.error?.status)
                ? 'internalServerError'
                : visibilityManipulation.errorDetail
            ]?.message ?? visibilityManipulationDefaultMessage.failNotiMessage
          }
          failNotiDesc={
            curriculumErrorMessage[
              serverErrorStatus.includes(visibilityManipulation.error?.status)
                ? 'internalServerError'
                : visibilityManipulation.errorDetail
            ]?.desc ?? visibilityManipulationDefaultMessage.failNotiDesc
          }
          isFinishOnFail={true}
          response={visibilityManipulation.data}
          error={visibilityManipulation.error}
          isLoadingRequest={visibilityManipulation.isLoading}
          action={updateCurriculumVisibility}
          onFinish={onFinishUpdateVisibility}
          onCancel={onCancelUpdateVisibility}
        />
      )}
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeletionModal && !!selected}
        title={deletingDefaultMessage.title}
        content={deletingDefaultMessage.content}
        successNotiMessage={deletingDefaultMessage.successNotiMessage}
        successNotiDesc={deletingDefaultMessage.successNotiDesc}
        failNotiMessage={
          curriculumErrorMessage[
            serverErrorStatus.includes(deleting.error?.status)
              ? 'internalServerError'
              : deleting.errorDetail
          ]?.message ?? deletingDefaultMessage.failNotiMessage
        }
        failNotiDesc={
          curriculumErrorMessage[
            serverErrorStatus.includes(deleting.error?.status)
              ? 'internalServerError'
              : deleting.errorDetail
          ]?.desc ?? deletingDefaultMessage.failNotiDesc
        }
        isFinishOnFail={true}
        response={deleting.data}
        error={deleting.error}
        isLoadingRequest={deleting.isLoading}
        action={deleteCurriculum}
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
      />
    </>
  )
}

export default CurriculumList

const getManipulationUrl = (
  id: number | Nil,
  manipulation: CurriculumVisibilityManipulation | Nil,
) => {
  if (!id || !manipulation) return null
  return manipulationUrl[manipulation](id)
}

const visibilityManipulationDefaultMessage: DefaultLabelOfConfrimFlowModal = {
  title: '상태 변경',
  successNotiMessage: '변경 완료',
  successNotiDesc: '성공적으로 커리큘럼 상태를 변경했습니다.',
  failNotiMessage: '요청 실패',
  failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
} as const

const deletingDefaultMessage: DefaultLabelOfConfrimFlowModal = {
  title: '항목 삭제',
  content:
    '커리큘럼을 삭제할 경우 다시 복구할 수 없습니다. 정말 커리큘럼을 삭제하시겠습니까?',
  successNotiMessage: '삭제 완료',
  successNotiDesc: '성공적으로 커리큘럼을 삭제했습니다.',
  failNotiMessage: '요청 실패',
  failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
} as const
