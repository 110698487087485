import { useState } from 'react'
import useSWR from 'swr'

import { post } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
// import { buildManipulationHook } from '@/utils/swr/helpers'
import { Customer } from '@/apps/customer/@types/customer'

// export const useSyncCafe24 = () => {
//   const [key, setKey] = useState<string | null | undefined>(null)
//   return {
//     ...buildManipulationHook<never, Customer>({
//       url: key ? URLS.syncCafe24(key) : null,
//       request: post,
//     }),
//     setUserKey: setKey,
//   }
// }

const fetcher = (url: string | null) => {
  if (!url) return
  return post<never, Customer>(url)
}

export const useSyncCafe24 = () => {
  const [key, setKey] = useState<string | null | undefined>(null)
  const { data, error, isValidating, mutate } = useSWR(
    key ? URLS.syncCafe24(key) : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  )
  const isLoading = !!key && !data && !error
  const isOneMore = !!data || !!error
  return {
    data,
    error,
    isLoading,
    isValidating,
    isOneMore,
    mutate,
    setUserKey: setKey,
  }
}

// export default useSyncCafe24
