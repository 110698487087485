import React from 'react'

import { aggregationPeriods } from '@/constants/apps.order'
import useCustomerRepurchaseSummary from '@/apps/order/hooks/useCustomerRepurchaseSummary'
import { Order } from '@/apps/order/@types/order'
import { Customer } from '@/apps/customer/@types/customer'
import { User } from '@/apps/auth/@types/user'

import RepurchaseSummaryView from './RepurchaseSummary.View'

interface PropType {
  customer: Customer<Order, User>
}

const defaultPeriod: keyof typeof aggregationPeriods = '7'

const RepurchaseSummary: React.FC<PropType> = ({ customer }) => {
  const { data, params, setParams } = useCustomerRepurchaseSummary(
    customer.uid,
    defaultPeriod,
  )

  if (!data) return null

  const onChangePeriod = (value: string) => {
    setParams({
      ...params,
      days: value,
    })
  }

  return (
    <RepurchaseSummaryView
      customer={customer}
      summary={data}
      params={params}
      showNotSynced={!customer.mallSyncedAt}
      onChangePeriod={onChangePeriod}
    />
  )
}

export default RepurchaseSummary
