import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import { orderingParamKey } from '@/constants/common'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { ArrowLeftOutlined as IconArrowLeftOutlined } from '@ant-design/icons'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { useLayoutContext } from '@/contexts/LayoutContext'
import { careVisibility, checkKinds } from '@/constants/apps.care'
import {
  careItemErrorMessage,
  confirmTextUpdatingCareItemVisibility,
  deletingDefaultMessage,
  visibilityManipulationDefaultMessage,
} from '@/constants/apps.careItem'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { getVisibilityManipulation } from '@/utils/visibilityManipulation'
import { Care } from '@/apps/care/@types/care'
import {
  CareItem,
  CareItemListParam,
  CareItemVisibility,
  CareItemListOrderingKey,
} from '@/apps/careItem/@types/careItem'
import { buildOrderingQueryString } from '@/utils/http'
import CareItemListView from './CareItemList.View'
import useCareItemList from '@/apps/careItem/hooks/useCareItemList'
import {
  useCareItemCreation,
  useCareItemDeletion,
  useCareItemModification,
} from '@/apps/careItem/hooks/useCareItemManipulation'
import { useCareItemVisibility } from '@/apps/careItem/hooks/useCareItemVisibilityManipulation'
import useLocationParams from '@/hooks/useLocationParams'
import EditCareItemForm from '@/apps/careItem/components/edit-careItem-form/EditCareItemForm'
import useCareDetail from '@/apps/care/hooks/useCareDetail'
import { useUserContext } from '@/contexts/UserContext'

const paramsKeys: Array<keyof CareItemListParam> = [
  'care',
  'point',
  'visibility',
  'ordering',
  'query',
]

const defaultPage = 1
const defaultLimit = 10
const defaultOrderingKey: CareItemListOrderingKey = 'updated_at'
const defaultIsDesc = true
const defaultListParams: CareItemListParam = {
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CareItemListOrderingKey,
}

interface StateInterface {
  selected: CareItem | null
  selectedVisibility: CareItemVisibility | null
  isVisibleEditForm: boolean
  isVisibleConfirmVisibilityModal: boolean
  isVisibleConfirmDeletionModal: boolean
  isStaffUser: boolean
}

const CareItemList: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const urlSearchParams = new URLSearchParams(location.search)
  const { setTitleSectionData } = useLayoutContext()
  const [
    {
      selected,
      selectedVisibility,
      isVisibleEditForm,
      isVisibleConfirmVisibilityModal,
      isVisibleConfirmDeletionModal,
      isStaffUser,
    },
    setState,
  ] = useState<StateInterface>({
    selected: null,
    selectedVisibility: null,
    isVisibleEditForm: false,
    isVisibleConfirmVisibilityModal: false,
    isVisibleConfirmDeletionModal: false,
    isStaffUser: false,
  })

  const {
    data,
    error,
    isLoading,
    setParamsIncludeCare: setParams,
    mutate,
  } = useCareItemList({
    care: Number(params.id),
    page: defaultPage,
  })

  const { data: careData, error: careError } = useCareDetail(
    params.id ? Number(params.id) : null,
  )
  const { current: user, isStaffUser: isStaffUserContext } = useUserContext()

  const modifying = useCareItemModification()
  const creating = useCareItemCreation()
  const deleting = useCareItemDeletion()
  const visibilityManipulation = useCareItemVisibility()

  const locParams = useLocationParams<
    CareItemListParam,
    CareItemListOrderingKey
  >(paramsKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    onSetParams: setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['visibility'],
    tryPreviousPage: true,
    paginatedData: data,
    httpError: error,
  })

  useEffect(() => {
    const name = urlSearchParams.get('name') ?? ''
    const desc = urlSearchParams.get('desc') ?? ''
    setTitleSectionData({
      title: <PageTitle careName={name} careDescription={desc} />,
    })
    return () => {
      setTitleSectionData(null)
    }
  }, [])

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isStaffUser: isStaffUserContext,
    }))
  }, [user])

  useEffect(() => {
    if (deleting.error?.status === 404) {
      onCancelDelete()
      deleting.errorDetail = 'inactive'
    }
  }, [deleting.error])

  useEffect(() => {
    if (careError?.response?.status === 404) {
      history.push('/cares/')
    }
  }, [careError])

  const orderingKey = locParams.fullParams.orderingKey as
    | CareItemListOrderingKey
    | undefined

  const onChangeParam = (
    key: keyof CareItemListParam,
    value: ItemValue<CareItemListParam>,
    historyAction?: ChangeHistoryAction<CareItemListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeTable = buildTableChangeFilter<CareItem>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddCareItem = () => {
    setState((prev) => ({
      ...prev,
      isVisibleEditForm: true,
    }))
  }

  const onClickEditCareItem = (careItem: CareItem) => {
    setState((prev) => ({
      ...prev,
      selected: careItem,
      isVisibleEditForm: true,
    }))
  }

  const onClickDeleteCareItem = (careItem: CareItem) => {
    setState((prev) => ({
      ...prev,
      selected: careItem,
      isVisibleConfirmDeletionModal: true,
    }))
  }

  const onFinishEdit = async () => {
    await mutate()
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleEditForm: false,
    }))
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
  }

  const onCancelEdit = () => {
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleEditForm: false,
    }))
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
  }

  const _setModifyingKey = (data: CareItem | null) => {
    modifying.setKey(data?.id)
  }

  const onClickVisibility = (
    careItem: CareItem,
    newVisibility: CareItemVisibility,
  ) => {
    if (careItem.visibility === newVisibility) return
    setState((prev) => ({
      ...prev,
      selected: careItem,
      selectedVisibility: newVisibility,
      isVisibleConfirmVisibilityModal: true,
    }))
  }

  const updateCareVisibility = () => {
    if (!selected || !selectedVisibility) return
    visibilityManipulation.setState((prev) => ({
      ...prev,
      manipulation: getVisibilityManipulation(
        selected.visibility,
        selectedVisibility,
      ),
      key: selected.id,
    }))
  }

  const onCancelUpdateVisibility = () => {
    visibilityManipulation.setState((prev) => ({
      ...prev,
      manipulation: null,
      key: null,
    }))
    setState((prev) => ({
      ...prev,
      selected: null,
      selectedVisibility: null,
      isVisibleConfirmVisibilityModal: false,
    }))
  }

  const onFinishUpdateVisibility = async () => {
    await mutate()
    onCancelUpdateVisibility()
  }

  const onFinishDelete = async () => {
    await mutate()
    deleting.setKey(null)
    setState((prev) => ({
      ...prev,
      isVisibleConfirmDeletionModal: false,
    }))
  }

  const onCancelDelete = onFinishDelete

  const deleteCareItem = () => {
    deleting.setKey(selected?.id)
  }

  return (
    <>
      <CareItemListView
        data={data?.results ?? []}
        page={locParams.params.page ?? defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        count={data?.count ?? 0}
        isReadOnly={isStaffUser}
        disabledAdd={careData ? getDisabledAdd(careData) : false}
        disabledDelete={careData ? getDisabledDelete(careData) : false}
        disabledManipulationVisibility={
          careData ? getDisabledManipulationVisibility(careData) : false
        }
        isLoading={isLoading}
        params={locParams.params}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        onChangeParam={onChangeParam}
        onChangePage={onChangePage}
        onChangeTable={onChangeTable}
        onClickAddCareItem={onClickAddCareItem}
        onClickEditCareItem={onClickEditCareItem}
        onClickDeleteCareItem={onClickDeleteCareItem}
        onClickVisibility={onClickVisibility}
      />
      <EditCareItemForm
        isVisible={isVisibleEditForm}
        selectedItem={selected}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
        setModifyingKey={_setModifyingKey}
        creating={creating}
        modifying={modifying}
        careID={Number(params.id)}
      />
      {!!selectedVisibility && (
        <ConfirmFlowModal<CareItem, unknown>
          visible={isVisibleConfirmVisibilityModal && !!selected}
          title={visibilityManipulationDefaultMessage.title}
          content={confirmTextUpdatingCareItemVisibility[selectedVisibility]}
          successNotiMessage={
            visibilityManipulationDefaultMessage.successNotiMessage
          }
          successNotiDesc={visibilityManipulationDefaultMessage.successNotiDesc}
          failNotiMessage={
            careItemErrorMessage[visibilityManipulation.errorDetail]?.message ??
            visibilityManipulationDefaultMessage.failNotiMessage
          }
          failNotiDesc={
            careItemErrorMessage[visibilityManipulation.errorDetail]?.desc ??
            visibilityManipulationDefaultMessage.failNotiDesc
          }
          isFinishOnFail={true}
          response={visibilityManipulation.data}
          error={visibilityManipulation.error}
          isLoadingRequest={visibilityManipulation.isLoading}
          action={updateCareVisibility}
          onFinish={onFinishUpdateVisibility}
          onCancel={onCancelUpdateVisibility}
        />
      )}
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeletionModal && !!selected}
        title={deletingDefaultMessage.title}
        content={deletingDefaultMessage.content}
        successNotiMessage={deletingDefaultMessage.successNotiMessage}
        successNotiDesc={deletingDefaultMessage.successNotiDesc}
        failNotiMessage={
          careItemErrorMessage[deleting.errorDetail]?.message ??
          deletingDefaultMessage.failNotiMessage
        }
        failNotiDesc={
          careItemErrorMessage[deleting.errorDetail]?.desc ??
          deletingDefaultMessage.failNotiDesc
        }
        isFinishOnFail={true}
        response={deleting.data}
        error={deleting.error}
        isLoadingRequest={deleting.isLoading}
        action={deleteCareItem}
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
      />
    </>
  )
}

export default CareItemList

export const getDisabledAdd = (care: Care) =>
  care.checkKind === checkKinds.YES_OR_NO ||
  care.visibility === careVisibility.SUSPENDED

export const getDisabledDelete = (care: Care) =>
  care.checkKind === checkKinds.YES_OR_NO

export const getDisabledManipulationVisibility = (care: Care) =>
  care.visibility === careVisibility.SUSPENDED

const PageTitle: React.FC<{
  careName: string
  careDescription: string | null
}> = ({ careName, careDescription }) => {
  const history = useHistory()
  const onClickBack = () => {
    history.goBack()
  }

  return (
    <div className="tw-flex tw-items-center">
      <IconArrowLeftOutlined className="tw-mr-4" onClick={onClickBack} />
      <Typography.Text className="tw-mr-3">{careName}</Typography.Text>
      {!!careDescription && (
        <Typography.Text className="tw-body-3 tw-text-gray-6">
          {careDescription}
        </Typography.Text>
      )}
    </div>
  )
}
