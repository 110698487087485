import React from 'react'
import _ from 'lodash'

import { ButtonProps } from 'antd/lib/button'
import { Button as AntdButton } from 'antd'
import {
  PoweroffOutlined as IconPoweroffOutlined,
  SearchOutlined as IconSearchOutlined,
  PlusCircleOutlined as IconPlusCircleOutlined,
  MinusCircleOutlined as IconMinusCircleOutlined,
  DeleteOutlined as IconDeleteOutlined,
  FormOutlined as IconFormOutlined,
  SyncOutlined as IconSyncOutlined,
  CommentOutlined as IconCommentOutlined,
} from '@ant-design/icons'
import classNames from 'classnames/bind'

import style from './BasicButton.module.scss'

const cx = classNames.bind(style)

type IconAliasNames = keyof typeof iconAliasNames
type ButtonTypes = keyof typeof buttonTypes
type sizeType = 'normal' | 'large'

interface PropType {
  type?: ButtonTypes
  size?: sizeType
  full?: boolean
  bold?: boolean
  disabled?: boolean
  loading?: boolean
  icon?: IconAliasNames
  label?: string | React.ReactNode
  ghost?: boolean
  color?: string
}

export type BasicButtonPropType = PropType &
  Omit<ButtonProps, keyof PropType> &
  React.HTMLAttributes<HTMLElement>

const _icon = (value: IconAliasNames | React.ReactNode) => {
  const Icon = _.isString(value)
    ? iconAliasNames[value as IconAliasNames]
    : null
  return !Icon ? null : <Icon />
}

const _type = (value: ButtonTypes) => {
  const buttonType = _.isString(value)
    ? buttonTypes[value as ButtonTypes]
    : null
  return !buttonType ? buttonTypes.fill : buttonType
}

const BasicButton: React.FC<BasicButtonPropType> = ({
  type = 'fill',
  size = 'normal',
  full = false,
  bold = false,
  disabled = false,
  loading = false,
  icon,
  label,
  className,
  ghost = false,
  color,
  ...props
}) => {
  const baseStyle = ghost ? cx(`${type}-ghost`, color) : cx(type)
  /**
   * 현재 버튼 크기(normal, large)는 text size + padding size로 결정됨
   * 하지만 버튼 타입이 icon 인 경우에는 위의 방식으로는 사이즈 조절이 되지 않음.
   */
  const sizeStyle = type !== 'icon' ? style[size] : ''
  const fullStyle = full ? style.full : ''
  const boldStyle = bold ? style['label-bold'] : ''
  const _className = [
    baseStyle,
    fullStyle,
    boldStyle,
    sizeStyle,
    className,
  ].join(' ')

  return (
    <AntdButton
      type={_type(type)}
      className={_className}
      icon={_icon(icon)}
      loading={loading}
      disabled={disabled}
      {...props}
    >
      {label}
    </AntdButton>
  )
}

const iconAliasNames = {
  power: IconPoweroffOutlined,
  search: IconSearchOutlined,
  plus: IconPlusCircleOutlined,
  edit: IconFormOutlined,
  delete: IconDeleteOutlined,
  sync: IconSyncOutlined,
  chat: IconCommentOutlined,
  minus: IconMinusCircleOutlined,
} as const

const buttonTypes = {
  fill: 'primary',
  outline: 'default',
  text: 'text',
  icon: 'default',
} as const

export default BasicButton
