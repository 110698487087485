import React, { useEffect } from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { ConfigProvider, Table, Typography } from 'antd'
import {
  CurriculumCare,
  CurriculumCareItem,
} from '@/apps/curriculum/@types/curriculum.care'
import { DeleteButton } from '@/components/buttons/Icon'
import BasicButton from '@/components/buttons/BasicButton'
import SpanText from '@/components/typography/SpanText'
import styles from './CurriculumCareProgramView.module.scss'
import {
  careItemReptitionKind,
  careItemVisibility,
} from '@/constants/apps.careItem'
import { weekdaysFromCompositedNumber } from '@/utils/date'

interface PropType {
  curriculumCare: CurriculumCare
  careItems: CurriculumCareItem[]
  disabledDeleteCare: boolean
  disabledAddCareItem: boolean
  disabledDeleteCareItem: boolean
  showDeleteCareButton?: boolean
  showSuspendedCare: boolean
  onClickAddCurriculumCareItem?: () => void
  onClickDeleteCurriculumCareItem?: (
    curriculumCareItem: CurriculumCareItem,
  ) => void
  onClickDeleteCurriculumCare?: (curriculumCare: CurriculumCare) => void
}

const CurriculumCareProgramView: React.FC<PropType> = ({
  curriculumCare,
  careItems,
  disabledDeleteCare,
  disabledAddCareItem,
  disabledDeleteCareItem,
  showDeleteCareButton,
  showSuspendedCare,
  onClickAddCurriculumCareItem,
  onClickDeleteCurriculumCareItem,
  onClickDeleteCurriculumCare,
}) => {
  useEffect(() => {
    const suspended = _.chain(careItems)
      .filter(
        (careItem) => careItem.visibility === careItemVisibility.SUSPENDED,
      )
      .map('id')
      .value()

    _.forEach(suspended, (itemId) => {
      const element = document.querySelector(
        `tr.ant-table-row.ant-table-row-level-0[data-row-key='${itemId}']`,
      )
      if (element) {
        element.className = cx('suspended-care-item')
      }
    })
  }, [careItems])

  return (
    <div>
      <div className={cx('header')}>
        <Typography.Text className={cx('name')}>
          {curriculumCare.name}
        </Typography.Text>
        <Typography.Text className={cx('description')}>
          {curriculumCare.description}
        </Typography.Text>
        <div className={cx('right-container')}>
          {showDeleteCareButton && (
            <BasicButton
              ghost
              className={cx('delete-button')}
              label="케어 유형 삭제"
              icon="minus"
              color="red"
              disabled={disabledDeleteCare}
              onClick={() => onClickDeleteCurriculumCare?.(curriculumCare)}
            />
          )}

          <BasicButton
            ghost
            label="케어 아이템 추가"
            icon="plus"
            disabled={disabledAddCareItem}
            onClick={onClickAddCurriculumCareItem}
          />
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <div className={cx('empty-container')}>
            <span>이 유형의 케어 아이템을 최소 1개 이상 추가해주세요</span>
          </div>
        )}
      >
        <Table
          className={cx('table', {
            suspended: showSuspendedCare,
          })}
          dataSource={careItems}
          rowKey="id"
          pagination={false}
        >
          <Column
            title="번호"
            key="order"
            render={(_value, _record, index) => (
              <SpanText text={`${index + 1}`} />
            )}
          />
          <Column
            title="케어 아이템"
            dataIndex="name"
            render={(value) => <SpanText text={value} />}
          />
          <Column
            className={cx('description')}
            title="설명"
            dataIndex="description"
            render={(value) => <SpanText text={value} />}
          />
          <Column
            title="시작 일자"
            dataIndex="openInDays"
            render={(value) => <OpenInDaysText openInDays={value} />}
          />
          <Column<CurriculumCareItem>
            title="반복 주기"
            dataIndex="repetitionInterval"
            render={(value, recored) => (
              <RepetitionIntervalText curriculumCareItem={recored} />
            )}
          />
          <Column<CurriculumCareItem>
            title="총 수행 횟수"
            dataIndex="totalRepetitions"
            render={(value, record) => (
              <TotalRepetitionText curriculumCareItem={record} />
            )}
          />
          <Column<CurriculumCareItem>
            title="삭제"
            key="delete"
            render={(__, record) => (
              <DeleteButton
                disabled={disabledDeleteCareItem}
                onClick={() => onClickDeleteCurriculumCareItem?.(record)}
              />
            )}
          />
        </Table>
      </ConfigProvider>
    </div>
  )
}

export default CurriculumCareProgramView

const cx = classNames.bind(styles)

const { Column } = Table

const OpenInDaysText: React.FC<{
  openInDays: number | null
}> = ({ openInDays }) => {
  return <SpanText text={openInDays ? `${openInDays}일차` : '-'} />
}

const RepetitionIntervalText: React.FC<{
  curriculumCareItem: CurriculumCareItem
}> = ({ curriculumCareItem }) => {
  const { repetitionInterval, repetitionKind } = curriculumCareItem
  const repetitionIntervalText = repetitionInterval
    ? repetitionKind === careItemReptitionKind.WEEK_DAYS
      ? _.join(weekdaysFromCompositedNumber(repetitionInterval), ', ')
      : `${repetitionInterval}일`
    : '-'
  return <SpanText text={repetitionIntervalText} />
}

const TotalRepetitionText: React.FC<{
  curriculumCareItem: CurriculumCareItem
}> = ({ curriculumCareItem }) => {
  const { totalRepetitions, repetitionKind } = curriculumCareItem
  const totalRepetitionText = !totalRepetitions
    ? repetitionKind === careItemReptitionKind.ONE_TIME
      ? '1회'
      : '무한'
    : `${totalRepetitions}회`
  return <SpanText text={totalRepetitionText} />
}
