import { StaffDailyTaskStatusParam } from '@/apps/dashboard/@types/staff-daily-tasks'

export const paramKeys: Array<keyof StaffDailyTaskStatusParam> = [
  'date',
  'query',
  'customerSupport',
  'ordering',
  'page',
  'limit',
  'customerProgressStatus',
]
