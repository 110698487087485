import React, { useState, useEffect } from 'react'

import _ from 'lodash'

import { Nil } from '@/@types/composite'
import { useUserContext } from '@/contexts/UserContext'
import useUserList from '@/apps/auth/hooks/useUserList'
import { User, UserListParam } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { Customer } from '@/apps/customer/@types/customer'
import AssignStaffModal, {
  AssignActionType,
} from '@/apps/customer/components/assign-staff-modal/AssignStaffModal'

import AssignStaffMenuView, {
  AssginActionDataType,
} from './AssignStaffMenu.View'

interface PropType {
  customer: Customer<Order, User>
  onFinish?: () => void
}

interface StateInteface {
  assignAction: AssignActionType | null
  candidate: User | null
  isVisibleModal: boolean
  isToMe: boolean
  users: User[]
}

const userListParm: UserListParam = {
  ordering: 'username',
  isActive: 'true',
  role: 'staff',
}

const AssignStaffMenu: React.FC<PropType> = ({ customer, onFinish }) => {
  const [{ assignAction, candidate, isVisibleModal, isToMe, users }, setState] =
    useState<StateInteface>({
      assignAction: null,
      candidate: null,
      isVisibleModal: false,
      isToMe: false,
      users: [],
    })

  const { data, isLoading } = useUserList(userListParm)

  const me = useUserContext().current

  const assignedUser = customer.staff

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      users: !data?.results
        ? []
        : _.sortBy(data.results, ['firstName', 'username', 'dateJoined']),
    }))
  }, [data?.results])

  const onChangeCandidate = (assignActionData: AssginActionDataType) => {
    const { candidateId, action } = assignActionData
    if (action === 'none') return
    if (action === 'unassign') {
      showUnassignConfirmModal()
    }
    if (action === 'assign') {
      showAssignConfirmModal(candidateId)
    }
  }

  const onCancelAssign = () => {
    hideConfirmModal()
  }

  const onFinishAssign = () => {
    hideConfirmModal()
    !!onFinish && onFinish()
  }

  const showUnassignConfirmModal = () => {
    setState((prev) => ({
      ...prev,
      assignAction: 'delete',
      isVisibleModal: true,
    }))
  }

  const showAssignConfirmModal = (candiateId: number | Nil) => {
    const candidate = data?.results?.find((user) => user.id === candiateId)
    if (_.isNil(candidate)) return

    setState((prev) => ({
      ...prev,
      candidate: candidate,
      isToMe: candidate.id === me?.id,
      assignAction: assignedUser === null ? 'create' : 'change',
      isVisibleModal: true,
    }))
  }

  const hideConfirmModal = () => {
    setState((prev) => ({
      ...prev,
      isVisibleModal: false,
    }))
  }

  return (
    <>
      <AssignStaffMenuView
        assigned={assignedUser}
        users={users}
        onClickStaffMenu={onChangeCandidate}
        isLoading={isLoading}
      />
      {isVisibleModal && (
        <AssignStaffModal
          customer={customer}
          staff={candidate}
          visible={isVisibleModal}
          isToMe={isToMe}
          assignActionType={assignAction}
          onFinish={onFinishAssign}
          onCancel={onCancelAssign}
        />
      )}
    </>
  )
}

export default AssignStaffMenu
