import React from 'react'
import classNames from 'classnames/bind'

import { SimpleCustomer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import SubSectionTitle from '@/components/layout/section/SubSectionTitle'

import styles from './LatestOrder.module.scss'
import { Typography } from 'antd'
import { buildOrderAdminUrl } from '@/utils/cafe24'

const cx = classNames.bind(styles)

interface PropType {
  order: Order<SimpleCustomer> | null
}

const LatestOrderView: React.FC<PropType> = ({ order }) => {
  return (
    <div className={cx('latest-order-container')}>
      <SubSectionTitle className="tw-pb-5">최근 주문 정보</SubSectionTitle>

      <div className={cx('item-wrapper')}>
        <div className={cx('label')}>최근 주문 번호</div>
        <div className={cx('content')}>
          {!!order?.orderId && (
            <Typography.Link
              href={buildOrderAdminUrl(order.orderId)}
              target="_blank"
            >
              {order.orderId}
            </Typography.Link>
          )}
          {!order?.orderId && '-'}
        </div>
      </div>
    </div>
  )
}

export default LatestOrderView
