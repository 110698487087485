import React from 'react'
import _ from 'lodash'
import { Form, FormInstance, Input } from 'antd'
import classNames from 'classnames/bind'

import { Rule, Rules } from '@/@types/antd'
import FormModal from '@/components/modal/FormModal'

import styles from './EditSimpleItemForm.View.module.scss'

const cx = classNames.bind(styles)

export interface PropType<T> {
  title: string
  subTitle: string
  itemNameInputLabel: string
  itemNameInputPlaceholder: string
  itemDescInputLabel: string
  itemDescInputPlaceholder: string
  isVisible?: boolean
  isLoading?: boolean
  item?: T | null
  onFinish?: () => void
  onCancel?: () => void
  rules?: Rules<string | number | symbol, Rule[]>
  formRef?: FormInstance // only for testing
}

const { TextArea } = Input

export const EditSimpleItemFormView = <T extends unknown>(
  props: React.PropsWithChildren<PropType<T>>,
) => {
  const {
    title,
    subTitle,
    isVisible,
    itemNameInputLabel,
    itemNameInputPlaceholder,
    itemDescInputLabel,
    itemDescInputPlaceholder,
    isLoading,
    item,
    rules,
    onFinish,
    onCancel,
    formRef,
  } = props
  const formInitialValue = item ? _.merge({}, item) : undefined

  return (
    <FormModal
      width="700px"
      visible={isVisible}
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <div className={cx('container')}>
        <Form
          form={formRef}
          initialValues={formInitialValue}
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item label={itemNameInputLabel}>
            <Form.Item name="name" rules={rules?.name}>
              <Input
                className={cx('input-name')}
                placeholder={itemNameInputPlaceholder}
                data-testid="formSimpleItem/name"
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={itemDescInputLabel}
            name="description"
            rules={rules?.description}
          >
            <TextArea
              className={cx('input-description')}
              placeholder={itemDescInputPlaceholder}
              rows={2}
              data-testid="formSimpleItem/description"
            />
          </Form.Item>
        </Form>
      </div>
    </FormModal>
  )
}

export default EditSimpleItemFormView
