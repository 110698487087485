import React from 'react'

import _ from 'lodash'
import { Form, Select } from 'antd'
import parseISO from 'date-fns/parseISO'

import { dateFormat } from '@/utils/date'
import { DatePicker } from '@/components/antd'
import { Nil } from '@/@types/composite'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import BasicButton from '@/components/buttons/BasicButton'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { genders, noCustomerKindValues } from '@/constants/apps.customer'
import { Customer, Gender } from '@/apps/customer/@types/customer'
import { CustomerKind } from '@/apps/customer/@types/customer-kind'

import style from './CustomerInfo.module.scss'

interface PropType {
  customer: Customer<Order, User>
  customerKinds: CustomerKind[] | Nil
  customerKindName?: string
  isEditMode?: boolean
  isLoading?: boolean
  showToggleEditMode?: boolean
  kind?: number
  onClickEditMode: () => void
  onCancelEdit: () => void
  onChangeGender: (value: Gender | null) => void
  onChangeKind: (value: number | null) => void
  onChangeBirthday: (value: Date | null) => void
  onSave: () => void
}

const CustomerInfoView: React.FC<PropType> = ({
  customer,
  customerKinds,
  customerKindName,
  isEditMode = false,
  isLoading = false,
  showToggleEditMode = false,
  kind,
  onClickEditMode,
  onCancelEdit,
  onChangeGender,
  onChangeKind,
  onChangeBirthday,
  onSave,
}) => {
  const gender = customer.gender
    ? _.get(genders, customer.gender, genders.N)
    : genders.N
  const birthday = customer.birthday
    ? parseISO(customer.birthday) ?? undefined
    : undefined

  const formInitialValue = customer ? { ...customer, birthday } : undefined

  const mallCreatedDate = customer.mallCreatedDate
    ? dateFormat(customer.mallCreatedDate)
    : '0000-00-00'

  const switchingCustomerKindView = () => {
    return isEditMode ? (
      <Select<number>
        style={{ width: 110 }}
        placeholder="선택"
        disabled={!isEditMode}
        onChange={onChangeKind}
        value={kind ?? noCustomerKindValues.id}
      >
        {customerKinds?.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <span>{customerKindName ?? noCustomerKindValues.name}</span>
    )
  }

  const switchingBirtydayView = () => {
    return isEditMode ? (
      <DatePicker
        style={{ width: 130 }}
        disabled={!isEditMode}
        onChange={onChangeBirthday}
      />
    ) : (
      <span>{customer.birthday ?? '0000-00-00'}</span>
    )
  }

  const switchingGenderView = () => {
    return isEditMode ? (
      <Select<Gender>
        style={{ width: 110 }}
        placeholder="선택"
        disabled={!isEditMode}
        onChange={onChangeGender}
      >
        {_.toPairs(genders).map(([value, label]) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <span>{gender}</span>
    )
  }

  const showEditButton = () => {
    return (
      isEditMode && (
        <div className={style.footer}>
          <BasicButton
            disabled={isLoading}
            label="취소"
            type="outline"
            onClick={onCancelEdit}
          />
          <BasicButton
            loading={isLoading}
            disabled={isLoading}
            label="저장"
            onClick={onSave}
            data-testid="btn-submit-customer-info"
          />
        </div>
      )
    )
  }

  return (
    <Section className={style['customer-info']} isLargeBottom>
      <SectionTitle title="고객 정보">
        <BasicButton
          label="고객 정보 수정"
          icon="edit"
          onClick={onClickEditMode}
          disabled={isLoading || !showToggleEditMode || isEditMode}
          data-testid="btn-edit-customer-info"
        />
      </SectionTitle>
      <div className={style.body}>
        <Form layout="inline" initialValues={formInitialValue}>
          <CustomerInfoItem label="고객명" name="mallUserName">
            <span>{customer.mallUserName ?? '-'}</span>
          </CustomerInfoItem>
          <CustomerInfoItem label="쇼핑몰 등급" name="mallGroup">
            <span>{customer.mallGroup?.name ?? '-'}</span>
          </CustomerInfoItem>
          <CustomerInfoItem label="고객 유형" name="kind">
            {switchingCustomerKindView()}
          </CustomerInfoItem>
          <CustomerInfoItem label="생년월일" name="birthday">
            {switchingBirtydayView()}
          </CustomerInfoItem>
        </Form>
        <Form
          className="tw-mt-2"
          layout="inline"
          initialValues={formInitialValue}
        >
          <CustomerInfoItem label="아이디" name="mallMemberId">
            <span>{customer.mallMemberId}</span>
          </CustomerInfoItem>
          <CustomerInfoItem label="휴대폰 번호" name="mallCellphone">
            <span>{customer.mallCellphone ?? '-'}</span>
          </CustomerInfoItem>
          <CustomerInfoItem label="쇼핑몰 가입일" name="mallCreatedDate">
            <span>{mallCreatedDate}</span>
          </CustomerInfoItem>
          <CustomerInfoItem label="성별" name="gender">
            {switchingGenderView()}
          </CustomerInfoItem>
        </Form>
      </div>
      {showEditButton()}
    </Section>
  )
}

interface CustomerInfoPropType {
  label: string
  name: string
}
const CustomerInfoItem: React.FC<CustomerInfoPropType> = ({
  label,
  name,
  children,
}) => {
  return (
    <Form.Item label={label} name={name} colon={false}>
      {children}
    </Form.Item>
  )
}
export default CustomerInfoView
