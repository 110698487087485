import React from 'react'

import { PAGES } from '@/constants/apps.dashboard'

import MyCustomerStatus from '@/apps/dashboard/components/my-customer-status/MyCustomerStatus'
import TodayCounselTaskList from '@/apps/dashboard/components/today-counsel-tasks/TodayCounselTaskList'
import styles from './Dashboard.module.scss'

const Dashboard: React.FC = () => {
  return (
    <div className={styles.container} data-testid={PAGES.dashboard.testId}>
      <MyCustomerStatus />
      <TodayCounselTaskList />
    </div>
  )
}

export default Dashboard
