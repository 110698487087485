export const PAGES = {
  dashboard: {
    name: '대시보드 (홈)',
    title: '대시보드 (홈)',
    requiredAuth: true,
    exact: true,
    path: '/dashboard',
    testId: 'dashboard-page',
  },
} as const

export const URLS = {
  staffsCustomerStatus: '/dashboards/staffs-customer-status/tasks/',
  customerPurchaseStatus: '/dashboards/staffs-customer-status/purchases/',
  todayCounselTaskList: '/dashboards/staff-daily-tasks/',
} as const

export const todayCounselTaskOrderingLabel = {
  mall_member_id: '아이디',
  mall_user_name: '고객명',
  diary_score: '어제 진척도',
} as const
