import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from 'antd'
import _ from 'lodash'

import { resolvePath } from '@/utils/url'
import { customerSupportLabels, PAGES } from '@/constants/apps.customer'
import { Customer, SimpleCustomer } from '@/apps/customer/@types/customer'

import SpanText from '../SpanText'

type CustomerType = SimpleCustomer | Customer

interface PropType {
  data: CustomerType
}

type ComponentType = React.FC<PropType>

export const UserName: ComponentType = ({ data }) => {
  return <SpanText text={data.mallUserName} alterText={'-'} />
}

export const MallMemberId: ComponentType = ({ data }) => {
  const history = useHistory()
  const path = resolvePath<CustomerType>({
    path: PAGES.customerDetail.path,
    params: data,
  })
  return (
    <Typography.Link onClick={() => history.push(path)}>
      {data.mallMemberId}
    </Typography.Link>
  )
}

export const CustomerSupportInfo: ComponentType = ({ data }) => {
  return (
    <SpanText
      text={_.get(customerSupportLabels, (data as Customer).customerSupport)}
      alterText={'-'}
    />
  )
}
