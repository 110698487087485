import React from 'react'

import style from './SectionTitle.module.scss'

export type sizeType = 'normal' | 'large'

interface PropType {
  title: string
  subTitle?: string
  size?: sizeType
}

const SectionTitle: React.FC<PropType> = ({
  title,
  subTitle,
  size = 'large',
  children,
}) => {
  return (
    <div className={style['page-title']}>
      <div>
        <div>
          <span className={style[`title-${size}`]}>{title}</span>
        </div>
        <div>
          <span className={style[`sub-title-${size}`]}>
            {!!subTitle && subTitle}
          </span>
        </div>
      </div>
      {children}
    </div>
  )
}

export default SectionTitle
