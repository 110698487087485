import React from 'react'

import { Tooltip } from 'antd'

import BasicButton from '@/components/buttons/BasicButton'
import style from './AssignSelfButton.module.scss'

export const buttonLabel = '나에게 배정하기'
export const tooltipMsg = 'Admin 계정은 사용할 수 없습니다'

interface PropType {
  showTooltip: boolean
  disabled: boolean
  onClick: () => void
}

const AssignSelfButtonView: React.FC<PropType> = ({
  showTooltip,
  disabled,
  onClick,
}) => {
  const renderTooltipButton = () => {
    return (
      <Tooltip title={tooltipMsg} trigger="click">
        <BasicButton className={style['fake-disable']} label={buttonLabel} />
      </Tooltip>
    )
  }

  const renderBasicButton = () => {
    return (
      <BasicButton
        ghost={!disabled}
        label={buttonLabel}
        onClick={onClick}
        disabled={disabled}
      />
    )
  }

  return showTooltip ? renderTooltipButton() : renderBasicButton()
}

export default AssignSelfButtonView
