import React from 'react'
import _ from 'lodash'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import {
  DeleteOutlined as IconDeleteOutlined,
  FormOutlined as IconFormOutlined,
} from '@ant-design/icons'

type IconAliasNames = keyof typeof iconAliasNames

type ButtonPropsWithoutIcon = Omit<ButtonProps, 'icon'>

interface PropType extends ButtonPropsWithoutIcon {
  icon: IconAliasNames | React.ReactNode
}

const BaseIconButton: React.FC<PropType> = (props) => {
  const { icon, children, className = '', ...rest } = props
  const iconNode = renderIcon(props.icon) ?? icon
  return (
    <Button
      icon={iconNode}
      data-testid="btn-icon"
      className={`button-icon tw-h-8 ${className}`}
      {...rest}
    >
      {children}
    </Button>
  )
}

const renderIcon = (value: IconAliasNames | React.ReactNode) => {
  const Icon = _.isString(value)
    ? iconAliasNames[value as IconAliasNames]
    : null
  return !Icon ? null : <Icon />
}

export const EditButton: React.FC<ButtonPropsWithoutIcon> = (props) => {
  return <BaseIconButton icon="edit" {...props} />
}

export const DeleteButton: React.FC<ButtonPropsWithoutIcon> = (props) => {
  return <BaseIconButton icon="delete" {...props} />
}

const iconAliasNames = {
  edit: IconFormOutlined,
  delete: IconDeleteOutlined,
} as const

export default BaseIconButton
