import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, Typography } from 'antd'
import AntIcon, { DownOutlined as IconDownOutlined } from '@ant-design/icons'
import useTodayCounselTaskList from '@/apps/dashboard/hooks/useTodayCounselTaskList'
import { PAGES as DASHBOARDS_PAGE } from '@/constants/apps.dashboard'
import './MenuNav.scss'

interface MenuItem {
  href: string
  title: string
  icon?: typeof AntIcon
  children?: MenuItem[]
}

interface PropType {
  items: readonly MenuItem[]
  defaultSelectedKeys: string[]
  openKeys: string[]
  isStaff?: boolean
}

const MenuNav: React.FC<PropType> = ({
  items,
  defaultSelectedKeys,
  openKeys,
  isStaff,
}) => {
  const history = useHistory()
  const { data: tasks, setParams } = useTodayCounselTaskList()
  const [taskCount, setTaskCount] = useState<number>(0)

  useEffect(() => {
    if (tasks && isStaff) {
      const result = tasks?.results ?? []
      const progressList = result.filter(({ tasks }) => {
        const taskFiltered = tasks.filter(({ progress }) => progress === 'new')
        return taskFiltered.length > 0
      })

      setTaskCount(progressList.length)
    } else if (isStaff) {
      const today = new Date()
      setParams({
        date: `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`,
      })
    } else {
      setTaskCount(0)
    }
  }, [tasks, isStaff])

  const onClickMenu = (to: string) => {
    history.push(to)
  }

  const renderMenu = (item: MenuItem, index: number) => (
    <Menu.Item
      className="menu-item"
      icon={item.icon ? <item.icon /> : undefined}
      key={item.href || index.toString()}
    >
      <Typography.Link
        data-testid={`linkTo:${item.href}`}
        className="menu-item-typo"
        onClick={() => onClickMenu(item.href)}
      >
        {item.title}
      </Typography.Link>
      {!!taskCount && item.title === DASHBOARDS_PAGE.dashboard.title ? (
        <div
          role="cell"
          className="task-noti"
          onKeyDown={() => null}
          onClick={() => onClickMenu(DASHBOARDS_PAGE.dashboard.path)}
        >
          {taskCount}
        </div>
      ) : null}
    </Menu.Item>
  )

  const renderSubMenu = (item: MenuItem, index: number) => (
    <Menu.SubMenu
      className="sub-menu-container"
      icon={item.icon ? <item.icon /> : undefined}
      key={item.href || index.toString()}
      title={item.title}
    >
      {item.children !== undefined &&
        renderSubMenuItem(item.children, index.toString())}
    </Menu.SubMenu>
  )

  const renderSubMenuItem = (items: MenuItem[], key: string) =>
    items.map((item, index) => (
      <Menu.Item
        className="menu-item"
        icon={item.icon ? <item.icon /> : undefined}
        key={item.href || `${key}-${index}`}
      >
        <Typography.Link
          data-testid={`linkTo:${item.href}`}
          className="sub-menu-item-typo"
          onClick={() => onClickMenu(item.href)}
        >
          {item.title}
        </Typography.Link>
      </Menu.Item>
    ))

  return (
    <Menu
      className="menunav-container"
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      openKeys={openKeys}
      expandIcon={<IconDownOutlined />}
    >
      {items.map((item, index) =>
        item.children ? renderSubMenu(item, index) : renderMenu(item, index),
      )}
    </Menu>
  )
}

export type { MenuItem }
export default MenuNav
