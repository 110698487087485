import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.auth'
import { PagePaginationResponse } from '@/clients/http/types'
import { User, UserListParam } from '@/apps/auth/@types/user'

const fetcher = (url: string | null, params?: UserListParam) => {
  if (!url) return
  return get<UserListParam, PagePaginationResponse<User>>(url, params)
}

const useUserList = (initialParams?: UserListParam) => {
  const [params, setParams] = useState<UserListParam>(initialParams ?? {})
  const { data, error, isValidating, mutate } = useSWR(
    [URLS.userList, params],
    fetcher,
  )
  const isLoading = !data && !error

  const _setParams = (newParams: UserListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    mutate,
    setParams: _setParams,
  }
}

export default useUserList

export const defaultParams: UserListParam = {
  page: 1,
  ordering: undefined,
  query: undefined,
}
