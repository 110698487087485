import React from 'react'

import { buildOrderingQueryString } from '@/utils/http'
import { Nil } from '@/@types/composite'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import {
  CustomerMemoListParam,
  MemoListOrderingKey,
} from '@/apps/memo/@types/memo'
import ScheduledTaskList from '@/apps/task/components/ScheduledTaskList'
import MemoList from '@/apps/memo/components/memo-list/MemoList'
import { OrderingKey } from '@/utils/http/types'

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
  foldContent?: boolean
}

export const defaultMemoOrderingKey: MemoListOrderingKey = 'updated_at'
export const defaultMemoIsDesc = true

const memoParamKeys = ['customerUid']

const CounselingView: React.FC<PropType> = ({
  customer,
  user,
  foldContent = false,
}) => {
  if (!user) return null

  const defaultMemoListParams: CustomerMemoListParam = {
    customerUid: customer.uid,
    page: 1,
    limit: 10,
    ordering: buildOrderingQueryString(
      defaultMemoOrderingKey,
      defaultMemoIsDesc,
    ) as OrderingKey<MemoListOrderingKey>,
  }

  return (
    <div>
      <ScheduledTaskList customer={customer} user={user} className="tw-mb-5" />
      <MemoList<CustomerMemoListParam>
        customer={customer}
        user={user}
        defaultListParams={defaultMemoListParams}
        extraParamKeys={memoParamKeys}
        useUrlParam={false}
        foldContent={foldContent}
      />
    </div>
  )
}

export default CounselingView
