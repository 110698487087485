import axios from 'axios'
import { BaseRequestParam } from '@/clients/http/types'
import _ from 'lodash'

import envs from '@/config/variables'
import { stringifyParams } from '@/utils/http'
import { HttpResponse } from '@/clients/http/types'
import { getDefaultStorage } from '@/utils/storages'
import { AUTH_STORAGE_KEY } from '@/constants/apps.auth'
import * as middlewares from './middlewares'

const storage = getDefaultStorage()

const authorizedHttpClient = axios.create({
  baseURL: envs.apiserver.baseUrl,
  withCredentials: envs.apiserver.withCredentials,
})

authorizedHttpClient.interceptors.request.use((config) => {
  const token = storage.getItem(AUTH_STORAGE_KEY, true)
  return middlewares.requestKeysToSnakecase(
    middlewares.setAuthorization(config, token),
  )
})

authorizedHttpClient.interceptors.response.use((config) => {
  return middlewares.responseKeysToCamelcase(config)
})

export const post = async <T, R>(url: string, payload?: T) => {
  const res = await authorizedHttpClient.post<T, HttpResponse<R>>(url, payload)
  return res.data
}

export const get = async <T, R>(url: string, params?: BaseRequestParam) => {
  const fullUrl = _.isEmpty(params) ? url : `${url}?${stringifyParams(params)}`
  const res = await authorizedHttpClient.get<T, HttpResponse<R>>(fullUrl)
  return res.data
}

export const patch = async <T, R>(url: string, payload: T) => {
  const res = await authorizedHttpClient.patch<T, HttpResponse<R>>(url, payload)
  return res.data
}

export const del = async (url: string) => {
  const res = await authorizedHttpClient.delete<never, HttpResponse>(url)
  return res.data
}

export default authorizedHttpClient
