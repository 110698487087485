import React from 'react'
import classNames from 'classnames/bind'
import { Form, FormInstance, Input, Select } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { Nil } from '@/@types/composite'
import { OptionType } from '@/@types/antd'
import SpanText from '@/components/typography/SpanText'
import { DatePicker } from '@/components/antd'
import FormModal from '@/components/modal/FormModal'
import {
  CreatingFormRules,
  ModifyingFormRules,
} from '@/apps/customer/@types/customer.curriculum'
import styles from './EditCustomerCurriculumForm.View.module.scss'
import _ from 'lodash'

interface PropType {
  rules: CreatingFormRules | ModifyingFormRules
  curriculumOptions: OptionType[]
  showModal: boolean
  showLoading: boolean
  title: string
  subTitle: string
  caculatedClosedAt: string | Nil
  disabledPeriod: boolean
  disabledCurriculum: boolean
  isAutonomous: boolean
  onFinish: () => void
  onCancel: () => void
  onChangeOpenedAt: (opendAt: Date | Nil) => void
  onChangePeriod: (period: number) => void
  onChangeCurriculum: (id: number) => void
  disabledDate: (date: Date) => boolean
  formInitialValue: Store | undefined
  formRef?: FormInstance // only for testing
}

const EditCustomerCurriculumFormView: React.FC<PropType> = ({
  rules,
  curriculumOptions,
  showModal,
  showLoading,
  subTitle,
  caculatedClosedAt,
  isAutonomous,
  disabledPeriod,
  disabledCurriculum,
  onFinish,
  onCancel,
  onChangeOpenedAt,
  onChangePeriod,
  onChangeCurriculum,
  disabledDate,
  formInitialValue,
  formRef,
}) => {
  return (
    <FormModal
      visible={showModal}
      isLoading={showLoading}
      title="커리큘럼 설정"
      subTitle={subTitle}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <Form
        form={formRef}
        initialValues={formInitialValue}
        colon={false}
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="커리큘럼 제목"
          name="curriculum"
          className={cx('curriculum-form-item')}
          rules={rules.curriculum}
        >
          <Select
            showSearch
            disabled={disabledCurriculum}
            className={cx('curriculum-select')}
            placeholder="선택 안함"
            options={curriculumOptions}
            onChange={(value) => onChangeCurriculum(value as number)}
            filterOption={(input, option) =>
              (option?.label?.toString().indexOf(input) ?? -1) >= 0
            }
          />
        </Form.Item>

        {isAutonomous && (
          <Form.Item label=" " name="name" rules={rules.name}>
            <Input
              className={cx('curriculum-select')}
              placeholder="제목을 직접 입력해주세요 (최대 입력 글자 수 32자)"
            />
          </Form.Item>
        )}

        <Form.Item label="시작 일자" name="openedAt" rules={rules.openedAt}>
          <DatePicker
            placeholder="날짜 선택"
            disabledDate={(date) => disabledDate(date)}
            onChange={(value) => onChangeOpenedAt(value)}
          />
        </Form.Item>

        <Form.Item label="진행 기간" name="period" rules={rules?.period}>
          <Input
            className={cx('duration')}
            disabled={disabledPeriod}
            placeholder="00"
            type="number"
            suffix="일"
            min={0}
            onChange={(e) => onChangePeriod(_.toNumber(e.target.value))}
          />
        </Form.Item>

        <Form.Item label="종료 일자(예정)">
          <SpanText text={caculatedClosedAt} alterText="0000-00-00" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditCustomerCurriculumFormView

const cx = classNames.bind(styles)
