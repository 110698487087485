export const AUTH_STORAGE_KEY = 'token'

export const URLS = {
  login: '/accounts/login/',
  logout: '/accounts/logout/',
  me: '/accounts/users/@me/',
  userList: '/accounts/users/',
  getUserDetail: (key: string) => `/accounts/users/${key}/`,
  getUserActivation: (key: string) => `/accounts/users/${key}/activation/`,
} as const

export const PAGES = {
  login: {
    name: '로그인',
    title: '로그인',
    requiredAuth: false,
    exact: true,
    path: '/login',
    testId: 'login-page',
  },
  users: {
    name: '관리자 메뉴',
    title: '사용자 계정 관리',
    requiredAuth: true,
    exact: true,
    path: '/users',
    testId: 'user-page',
  },
} as const

export const AUTH_CHECKING_INTERVAL = 60000 * 5 // 5minutes
