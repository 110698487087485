import { Nil } from '@/@types/composite'
import { User } from '@/apps/auth/@types/user'
import { userRoles } from '@/apps/auth/constants'

export const getFullName = (user: User | Nil) =>
  `${user?.lastName}${user?.firstName}`

export const getAccountName = (user: User | Nil) =>
  `${getFullName(user)} / ${user?.username}`

export const isStaff = (user: User | Nil) => user?.role === userRoles.STAFF
