import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { Nil } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.curriculum'
import { BaseRequestParam } from '@/clients/http/types'

interface Params extends BaseRequestParam {
  days: number
}

interface Response {
  averageScore: string
}

const fetcher = (url: string | null, params: Params) => {
  if (!url) return
  return get<Params, Response>(url, params)
}

const useDiariesAverage = (uid: Nil | string) => {
  const [params, setParams] = useState<Params>({
    days: 7,
  })

  const _setParams = (newParams: Params) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    [uid ? URLS.diariesAverage(uid) : null, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error
  return {
    data,
    days: params.days,
    error,
    mutate,
    isLoading,
    isValidating,
    setParams: _setParams,
  }
}

export default useDiariesAverage
