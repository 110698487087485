import React from 'react'
import _ from 'lodash'
import { Table, Pagination, TableProps, Tag, Typography } from 'antd'

import { DataQueryPropType } from '@/@types/data-query'
import { DateTimeISO8601 } from '@/@types/formats'
import { dateFormat } from '@/utils/date'
import { numbering } from '@/utils/ui/pagination'
import { getFilteredValue, getFilters, getOptions } from '@/utils/antd/table'
import {
  careCompilationLabels,
  careOrderingLabel,
  careVisibility,
  careVisibilityTagColor,
  careVisibilityTagLabel,
  checkKindLabels,
  essentialLabels,
} from '@/constants/apps.care'
import {
  Care,
  CareCompilation,
  CareListOrderingKey,
  CareListParam,
  CareVisibility,
  CheckKind,
} from '@/apps/care/@types/care'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import ActionMenuButton from '@/components/buttons/ActionMenuButton'
import { DeleteButton, EditButton } from '@/components/buttons/Icon'
import { BooleanParamValue } from '@/utils/http/types'
import { Link } from 'react-router-dom'

export interface PropType
  extends DataQueryPropType<CareListParam, Care, CareListOrderingKey> {
  disabled: boolean
  onChangeTable?: TableProps<Care>['onChange']
  onClickAddCare?: () => void
  onClickEditCare: (item: Care) => void
  onClickDeleteCare: (item: Care) => void
  onClickVisibility: (item: Care, newVisibility: CareVisibility) => void
}

const CareListView: React.FC<PropType> = ({
  data,
  page,
  limit,
  count,
  disabled,
  isLoading,
  params,
  orderingKey,
  orderingIsDesc,
  onChangeParam,
  onChangePage,
  onChangeTable,
  onClickAddCare,
  onClickEditCare,
  onClickDeleteCare,
  onClickVisibility,
}) => (
  <Section>
    <SectionTitle title="케어 유형 목록" />

    <div className="tw-pb-4">
      <QuerySection<CareListParam, CareListOrderingKey>
        wrapperName="careList"
        params={params}
        onChange={onChangeParam}
        defaultOrderingKey={orderingKey}
        orderOptions={orderOptions}
        searchPlaceholder="케어 유형 검색"
        defaultOrderingIsDesc={orderingIsDesc}
      >
        <BasicButton
          className="tw-ml-auto"
          label="신규 유형 생성"
          icon="plus"
          disabled={disabled}
          data-testid="careList/btn-add"
          onClick={onClickAddCare}
        />
      </QuerySection>
    </div>

    <Table<Care>
      dataSource={data}
      rowKey="id"
      pagination={false}
      onChange={onChangeTable}
    >
      <Column
        title="번호"
        key="order"
        render={(_value, _record, index) => numbering(page, limit, index)}
      />

      <Column
        title="케어 유형"
        width={200}
        dataIndex="name"
        render={(_value, _, idx) => {
          const { id, name, description } = data[idx]
          return (
            <Link
              to={`/cares/${id}?name=${name}&desc=${description}`}
              component={Typography.Link}
            >
              {_value}
            </Link>
          )
        }}
      />

      <Column
        title="설명"
        width={400}
        dataIndex="description"
        render={(value) => <DescriptionText description={value} />}
      />

      <Column
        title="커리큘럼 유형"
        dataIndex="curriculumCompilation"
        filterMultiple={true}
        filters={getFilters(careCompilationLabels)}
        filteredValue={getFilteredValue(params.curriculumCompilation)}
        render={(value) => <CareCompilationText careCompilation={value} />}
      />

      <Column
        title="필수/선택"
        dataIndex="isEssential"
        filterMultiple={false}
        filters={getFilters(essentialLabels)}
        filteredValue={getFilteredValue(params.isEssential)}
        render={(value) => <EssentialText isEssential={value} />}
      />

      <Column
        title="액션 체크 방식"
        dataIndex="checkKind"
        filterMultiple={true}
        filters={getFilters(checkKindLabels)}
        filteredValue={getFilteredValue(params.checkKind)}
        render={(value) => <CheckKindText checkKind={value} />}
      />

      <Column
        title="갱신 일자"
        dataIndex="updatedAt"
        render={(value) => <DateText date={value} />}
      />

      <Column<Care>
        title="상태"
        dataIndex="visibility"
        filterMultiple={true}
        filters={getFilters(careVisibilityTagLabel)}
        filteredValue={getFilteredValue(params.visibility)}
        render={(__, record) => (
          <VisibilityTag
            care={record}
            disabled={disabled}
            onClick={onClickVisibility}
          />
        )}
      />

      <Column<Care>
        title="수정"
        render={(__, record) => (
          <ButtonEdit
            care={record}
            disabled={disabled || getDisabledEdit(record)}
            onClick={onClickEditCare}
          />
        )}
      />

      <Column<Care>
        title="삭제"
        render={(__, record) => (
          <ButtonDelete
            care={record}
            disabled={disabled || getDisabledDelete(record)}
            onClick={onClickDeleteCare}
          />
        )}
      />
    </Table>

    {!!count && (
      <Pagination
        disabled={isLoading}
        defaultCurrent={page ?? 1}
        pageSize={limit}
        defaultPageSize={limit}
        total={count}
        onChange={onChangePage}
      />
    )}
  </Section>
)

export default CareListView

const { Column } = Table

const visibilities = _.map(careVisibility)

const disableVisibilities = {
  [careVisibility.UNPUBLISHED]: [careVisibility.SUSPENDED],
  [careVisibility.PUBLISHED]: [careVisibility.UNPUBLISHED],
  [careVisibility.SUSPENDED]: [careVisibility.UNPUBLISHED],
}

export const orderOptions = getOptions(careOrderingLabel)

const isUsed = (care: Care) =>
  care.useCountOnProgress >= 1 || care.useCountReserved >= 1

const getDisabledEdit = (care: Care) => isUsed(care)

const getDisabledDelete = (care: Care) => isUsed(care)

const DescriptionText: React.FC<{ description?: string }> = ({
  description,
}) => {
  return <span>{description ?? '-'}</span>
}

const CareCompilationText: React.FC<{ careCompilation: CareCompilation }> = ({
  careCompilation,
}) => {
  return <span>{careCompilationLabels[careCompilation]}</span>
}

const EssentialText: React.FC<{ isEssential: boolean }> = ({ isEssential }) => {
  return (
    <span>{essentialLabels[String(isEssential) as BooleanParamValue]} </span>
  )
}

const DateText: React.FC<{ date?: DateTimeISO8601 }> = ({ date }) => {
  const dateString = _.isNil(date)
    ? '0000-00-00'
    : dateFormat(date, 'yyyy-MM-dd')
  return <span>{dateString}</span>
}

const CheckKindText: React.FC<{ checkKind: CheckKind }> = ({ checkKind }) => {
  return <span>{checkKindLabels[checkKind]} </span>
}

interface VisibilityTagPropType {
  care: Care
  disabled: boolean
  onClick: (item: Care, newVisibility: CareVisibility) => void
}

const VisibilityTag: React.FC<VisibilityTagPropType> = ({
  care,
  disabled,
  onClick,
}) => {
  const { visibility } = care
  return (
    <ActionMenuButton<Care, CareVisibility>
      disabled={disabled}
      item={care}
      labels={careVisibilityTagLabel}
      value={visibility}
      values={visibilities}
      disableValues={disableVisibilities}
      onChangeValue={onClick}
    >
      <Tag color={careVisibilityTagColor[visibility]}>
        {careVisibilityTagLabel[visibility]}
      </Tag>
    </ActionMenuButton>
  )
}

interface ManipulationPropType {
  care: Care
  disabled: boolean
  onClick: (care: Care) => void
}

const ButtonEdit: React.FC<ManipulationPropType> = ({
  care,
  disabled,
  onClick,
}) => {
  return (
    <EditButton
      disabled={disabled}
      onClick={() => onClick(care)}
      data-testid={`careList/btn-edit-${care.id}`}
    />
  )
}

const ButtonDelete: React.FC<ManipulationPropType> = ({
  care,
  disabled,
  onClick,
}) => {
  return (
    <DeleteButton
      disabled={disabled}
      onClick={() => onClick(care)}
      data-testid={`careList/btn-delete-${care.id}`}
    />
  )
}
