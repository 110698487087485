import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.memo'
import { PagePaginationResponse } from '@/clients/http/types'
import { MemoKind, MemoKindListParam } from '@/apps/memo/@types/memo-kind'

const fetcher = (url: string | null, params?: MemoKindListParam) => {
  if (!url) return
  return get<MemoKindListParam, PagePaginationResponse<MemoKind>>(url, params)
}

const useMemoKindList = (defaultParams = {}) => {
  const [params, setParams] = useState<MemoKindListParam>({
    page: 1,
    ...defaultParams,
  })

  const _setParams = (newParams: MemoKindListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    [URLS.memoKindList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    setParams: _setParams,
    mutate,
  }
}

export default useMemoKindList
