import React from 'react'
import _ from 'lodash'

import { ModalProps } from 'antd/lib/modal'
import { Modal as AntdModal, Typography as AntdTypo } from 'antd'
import {
  ExclamationCircleOutlined as IconExclamationCircleOutlined,
  CheckCircleOutlined as IconCheckCircleOutlined,
  CloseCircleOutlined as IconCloseCircleOutlined,
} from '@ant-design/icons'

import NotiModalStyle from './NotiModal.module.scss'
import BasicButtonStyle from '../buttons/BasicButton.module.scss'

type NotiTypes = keyof typeof notiTypes

export interface NotiModalPropType {
  visible: boolean
  type?: NotiTypes
  title?: string
  content?: string
  onlyOk?: boolean
  loading?: boolean
  cancelText?: string
  okText?: string
  onOk?: () => void
  onCancel?: () => void
}

const _icon = (notiType: NotiTypes) => {
  const Icon = _.isString(notiType)
    ? iconAliasNames[notiType as NotiTypes]
    : null
  return !Icon ? null : <Icon className={NotiModalStyle[`icon-${notiType}`]} />
}

const NotiModal: React.FC<
  NotiModalPropType & Omit<ModalProps, keyof NotiModalPropType>
> = ({
  visible = true,
  type = 'warn',
  title,
  content,
  onlyOk = false,
  loading = false,
  cancelText = '취소',
  okText = '완료',
  children,
  ...props
}) => {
  const okButtonClassName = BasicButtonStyle.fill
  const cancelButtonClassName = onlyOk
    ? 'tw-invisible'
    : BasicButtonStyle.outline
  const _title = (
    <div className="tw-flex tw-space-x-4">
      {_icon(type)}
      <AntdTypo.Text className={NotiModalStyle.title}>{title}</AntdTypo.Text>
    </div>
  )
  return (
    <AntdModal
      className={NotiModalStyle['noti-modal']}
      visible={visible}
      closable={false}
      confirmLoading={loading}
      okButtonProps={{ className: okButtonClassName }}
      cancelButtonProps={{ className: cancelButtonClassName }}
      okText={okText}
      cancelText={cancelText}
      title={_title}
      centered
      {...props}
    >
      <AntdTypo.Text className={NotiModalStyle.content}>
        {content || children}
      </AntdTypo.Text>
    </AntdModal>
  )
}

const notiTypes = {
  warn: 'warn',
  confirm: 'confirm',
  error: 'error',
} as const

const iconAliasNames = {
  [notiTypes.warn]: IconExclamationCircleOutlined,
  [notiTypes.confirm]: IconCheckCircleOutlined,
  [notiTypes.error]: IconCloseCircleOutlined,
} as const

export default NotiModal
