import React from 'react'
import _ from 'lodash'

import { Dropdown, Menu } from 'antd'
import { buildKeyValueLabelsByValues } from '@/utils/ui/choice'
import BasicButton from './BasicButton'

interface DataPropType<T, VT extends string> {
  disabled: boolean
  item: T
  label?: string
  labels: Record<VT, string>
  value: VT
  values: VT[]
  disableValues?: Record<VT, VT[]>
  onChangeValue: (item: T, newValue: VT) => void
  testId?: string
}

type PropType<T, VT extends string> = DataPropType<T, VT> &
  React.HTMLAttributes<HTMLElement>

const ActionMenuButton = <T extends unknown, VT extends string>(
  props: React.PropsWithChildren<PropType<T, VT>>,
) => {
  const {
    disabled,
    item,
    label,
    labels,
    value,
    values,
    disableValues,
    onChangeValue,
    testId,
    children,
    ...restProps
  } = props

  const menuItems = buildKeyValueLabelsByValues<typeof labels, VT>(
    labels,
    values,
  )
  const menu = (
    <Menu onClick={({ key }) => onChangeValue(item, key as VT)}>
      {menuItems.map(([key, label]) => {
        return (
          <Menu.Item
            key={key}
            disabled={_.includes(disableValues?.[value], key)}
          >
            {label}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const childView = children ?? (
    <BasicButton
      disabled={disabled}
      label={label}
      className={`button-action-menu ${value}`}
      data-testid={testId}
    />
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      disabled={disabled}
      {...restProps}
    >
      {childView}
    </Dropdown>
  )
}

export default ActionMenuButton
