import React from 'react'

import _ from 'lodash'

import { Dropdown, Menu } from 'antd'

import { User } from '@/apps/auth/@types/user'
import { getAccountName } from '@/apps/auth/utils/helper'
import { Nil } from '@/@types/composite'
import BasicButton from '@/components/buttons/BasicButton'

import style from './AssignStaffMenu.module.scss'

export interface AssginActionDataType {
  candidateId?: number
  action: 'assign' | 'unassign' | 'none'
}

interface PropType {
  assigned: User | Nil
  users: User[]
  isLoading: boolean
  onClickStaffMenu: (assignActionData: AssginActionDataType) => void
}

const noAssignedUser = {
  id: null,
  label: '담당자 없음',
}

const AssignStaffMenuView: React.FC<PropType> = ({
  assigned,
  users,
  onClickStaffMenu,
}) => {
  const onClickMenu = (key: string) => {
    const parsedKey = _.parseInt(key)
    const candidateId = _.isNaN(parsedKey) ? null : parsedKey

    if (assigned?.id === candidateId) {
      onClickStaffMenu({
        action: 'none',
      })
      return
    }

    if (_.isNil(candidateId)) {
      onClickStaffMenu({
        action: 'unassign',
      })
      return
    }

    onClickStaffMenu({
      candidateId: candidateId,
      action: 'assign',
    })
  }

  const menu = (
    <Menu onClick={({ key }) => onClickMenu(key)}>
      <Menu.Item key={noAssignedUser.id}>{noAssignedUser.label}</Menu.Item>
      {users.map((user) => {
        return <Menu.Item key={user.id}>{getAccountName(user)}</Menu.Item>
      })}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      overlayClassName={`${style['menu-overlay']}`}
      trigger={['click']}
      placement="bottomRight"
    >
      <BasicButton
        label={assigned ? getAccountName(assigned) : noAssignedUser.label}
        className={
          assigned
            ? `${style['button-assigned']}`
            : `${style['button-assigned-none']}`
        }
        data-testid={`btn-assign-user-${assigned?.id ?? 'none'}`}
      />
    </Dropdown>
  )
}

export default AssignStaffMenuView
