import _ from 'lodash'
import { PAGES } from './apps.dashboard'

export const REDIRECT_URL_AFTER_LOGIN = PAGES.dashboard.path

export const orderingParamKey = 'ordering'

export const statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const

export const visibility = {
  UNPUBLISHED: 'unpublished',
  PUBLISHED: 'published',
  SUSPENDED: 'suspended',
} as const

export const visibilityTagLabel = {
  [visibility.UNPUBLISHED]: '사용 전',
  [visibility.PUBLISHED]: '사용 중',
  [visibility.SUSPENDED]: '사용 중단',
} as const

export const visibilityTagColor = {
  [visibility.UNPUBLISHED]: 'default',
  [visibility.PUBLISHED]: 'blue',
  [visibility.SUSPENDED]: 'red',
} as const

export const weekdaysShortLabel = {
  SUN: '일',
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
} as const

export const weekdaysValue = {
  [weekdaysShortLabel.SUN]: 1 << 0,
  [weekdaysShortLabel.MON]: 1 << 1,
  [weekdaysShortLabel.TUE]: 1 << 2,
  [weekdaysShortLabel.WED]: 1 << 3,
  [weekdaysShortLabel.THU]: 1 << 4,
  [weekdaysShortLabel.FRI]: 1 << 5,
  [weekdaysShortLabel.SAT]: 1 << 6,
} as const

export const weekdays: readonly string[] = [
  ..._.chain(weekdaysValue)
    .toPairs()
    .sortBy([1])
    .map(([key]) => key)
    .value(),
] as const

export const weekdayValuesStartWith0 = {
  ..._.fromPairs(_.zip(weekdays, _.range(0, weekdays.length))),
} as const

export const visibilityManipulation = {
  PUBLISHED: 'published',
  SUSPENDED: 'suspended',
  RESUME: 'resume',
} as const
