import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import {
  excludeParams,
  isSameParams,
  parseOrderingKey,
  parseQueryStringFromUrl,
} from '@/utils/http'

import {
  transformBooleanParams,
  transformCommaListParams,
  transformNumberParams,
  transformParamValue,
} from './transforms'
import changeHistory from './change-history'
import { OrderingKey } from '@/utils/http/types'
import { HttpError, PagePaginationResponse } from '@/clients/http/types'
import { Nil } from '@/@types/composite'
import { hasKeyWithValue } from '@/utils/collections'

export interface ParamTransform {
  paramKeys: string[]
  transfomer: (
    keyValuePair: [
      key: string,
      value: string | number | Array<unknown> | null | undefined | unknown,
    ],
  ) => [string, unknown]
}

type ParamKeysType<T> = Array<keyof T | string>

type OrderingParamKeyType<PT> =
  | keyof PT
  | 'ordering'
  | 'orderingIsDesc'
  | 'orderingQueryString'

interface LocationParamsType<PT> {
  useUrl?: boolean
  defaultParams?: PT
  orderingParamKey?: OrderingParamKeyType<PT>
  orderingIsDesc?: boolean
  hiddenParams?: string[] | ParamKeysType<PT>
  numberParams?: ParamKeysType<PT>
  commaListParams?: ParamKeysType<PT>
  booleanParams?: ParamKeysType<PT>
  transforms?: ParamTransform[]
  onSetParams?: (params: PT) => void
  tryPreviousPage?: boolean
  paginatedData?: PagePaginationResponse | Nil
  httpError?: HttpError | Nil
  requiredParams?: ParamKeysType<PT>
}

export const useLocationParams = <PT, OKT extends never | string>(
  paramKeys: ParamKeysType<PT>,
  options?: LocationParamsType<PT>,
) => {
  const {
    useUrl = true,
    hiddenParams = [],
    numberParams,
    commaListParams,
    booleanParams,
    transforms = [],
    orderingParamKey,
    onSetParams,
    tryPreviousPage = true,
    paginatedData,
    httpError,
    requiredParams = [],
  } = options ?? {}
  if (orderingParamKey) {
    hiddenParams.push('orderingIsDesc')
    hiddenParams.push('orderingKey')
  }
  transforms.push({
    paramKeys: (numberParams ?? []) as string[],
    transfomer: transformNumberParams,
  })
  transforms.push({
    paramKeys: (booleanParams ?? []) as string[],
    transfomer: transformBooleanParams,
  })
  transforms.push({
    paramKeys: (commaListParams ?? []) as string[],
    transfomer: transformCommaListParams,
  })

  const history = useHistory()
  const location = useLocation()
  const [params, setParams] = useState<PT>({} as PT)
  const defaultParams = useRef<PT>(options?.defaultParams ?? ({} as PT))
  const storedParams = useRef<PT>(options?.defaultParams ?? ({} as PT))
  const storedRequiredParams = useRef<PT>({} as PT)
  const _paramKeys = paramKeys.filter(_.negate(_.isNil)) as Array<keyof PT>

  const _setParams = (
    newParams: PT,
    historyAction?: ChangeHistoryAction<PT>,
  ) => {
    const _requiredParams = buildRequiredParams(newParams, requiredParams)
    if (!_.isEmpty(_requiredParams)) {
      _.merge(storedRequiredParams.current, _requiredParams)
    }
    if (isSameParams(params, newParams)) return

    if (!historyAction || !useUrl) {
      if (hasKeyWithValue<PT>(storedParams.current, requiredParams)) {
        setParams(
          buildParams(
            { ...storedParams.current, ...newParams },
            _paramKeys,
            transforms,
          ),
        )
        return
      }
    }

    const orderingParams = orderingParamKey
      ? buildOrderingParam(newParams, orderingParamKey)
      : {}

    const { type, data } = historyAction ?? {}
    const result = changeHistory(location, {
      type,
      data:
        data ??
        excludeParams(
          _.merge(
            parseQueryStringFromUrl(location.search) ?? {},
            storedRequiredParams.current,
            newParams,
            orderingParams,
          ),
          hiddenParams,
        ),
    })

    const builtParams = _.merge(
      {},
      storedRequiredParams.current,
      newParams,
      result?.newParams ?? {},
    )
    if (!hasKeyWithValue<PT>(builtParams, requiredParams)) return
    setParams(builtParams)

    if (result?.type) {
      _.invoke(
        history,
        result.type,
        result.payload ? result.payload : undefined,
      )
    }
  }

  const setItem = (
    key: keyof PT,
    value: ItemValue<PT>,
    historyAction?: ChangeHistoryAction<PT>,
  ) => {
    if (!_paramKeys.includes(key) || _.get(params, key) === value) return
    _setParams(
      _.merge({}, storedParams.current, params, { [key]: value }),
      historyAction,
    )
  }

  const setItems = (newParams: PT, historyAction?: ChangeHistoryAction<PT>) => {
    _setParams(
      _.merge(
        {},
        defaultParams.current,
        storedRequiredParams.current,
        buildParams(newParams, _paramKeys, transforms),
      ),
      historyAction,
    )
  }

  useEffect(() => {
    const current = _.merge(
      {},
      buildParams(defaultParams.current, _paramKeys, transforms),
      storedRequiredParams.current,
      useUrl
        ? buildParams(
            parseQueryStringFromUrl(location.search) ?? {},
            [],
            transforms,
          )
        : {},
    )

    storedParams.current = _.merge(
      {},
      current,
      orderingParamKey
        ? buildOrderingParam<PT, OKT>(current, orderingParamKey)
        : {},
    )
    _setParams(storedParams.current)
  }, [])

  useEffect(() => {
    if (!httpError) return

    if (tryPreviousPage) {
      const [canSet, page] = getPreviousPageWhenHttp404(
        httpError,
        paginatedData,
        paramKeys,
        params,
      )
      canSet && setItem('page' as keyof PT, page as number, { type: 'push' })
    }
  }, [httpError, params])

  useEffect(() => {
    if (!useUrl) return
    const newParams = buildParams(
      parseQueryStringFromUrl(location.search) ?? {},
      [],
      transforms,
    )
    _setParams(
      !_.isEmpty(newParams)
        ? _.merge(
            {},
            defaultParams.current,
            storedRequiredParams.current,
            params,
            newParams,
          )
        : _.merge({}, defaultParams.current, storedRequiredParams.current),
    )
  }, [location.search])

  useEffect(() => {
    !!onSetParams && !_.isEmpty(params) && onSetParams(params)
  }, [params])

  return {
    params: excludeParams(params, hiddenParams),
    fullParams: params as PT & OrderingExtraInfo<OKT>,
    setItem,
    setItems,
  }
}

const buildParams = <PT>(
  params: PT,
  paramKeys: Array<keyof PT>,
  transforms: ParamTransform[],
) => {
  return _.chain(params)
    .toPairs()
    .filter(
      ([key]) => _.isEmpty(paramKeys) || paramKeys.includes(key as keyof PT),
    )
    .map(_.partial(transformParamValue, transforms))
    .fromPairs()
    .value() as unknown as PT
}

interface OrderingExtraInfo<OKT> {
  orderingIsDesc: boolean | undefined
  orderingKey: OKT | string | undefined
}

type OrderingParamReturnType<PT, OKT extends string | never> = Record<
  OrderingParamKeyType<PT>,
  OrderingKey<OKT> | undefined
> &
  OrderingExtraInfo<OKT>

const buildOrderingParam = <PT, OKT extends string | never>(
  params: PT,
  key: OrderingParamKeyType<PT>,
) => {
  const value: undefined | string = _.get(params, key)
  if (!value)
    return { [key]: undefined, isDesc: undefined, queryString: undefined }

  const isDesc = value.startsWith('-')
  return {
    [key]: value,
    orderingIsDesc: isDesc,
    orderingKey: parseOrderingKey(value),
  } as OrderingParamReturnType<PT, OKT>
}

const getPreviousPageWhenHttp404 = <PT>(
  error: HttpError,
  currentData: PagePaginationResponse | Nil,
  paramKeys: ParamKeysType<PT>,
  params: PT,
) => {
  if (
    !paramKeys.includes('page' as keyof PT) ||
    error.response?.status !== 404 ||
    !_.get(parseQueryStringFromUrl(error.config.url), 'page') ||
    !currentData ||
    !currentData?.previous
  )
    return [false, undefined]

  const page = _.get(params, 'page') - 1
  return [_.isInteger(page) && page >= 1, page]
}

const buildRequiredParams = <PT>(params: PT, paramKeys: ParamKeysType<PT>) => {
  return _.isEmpty(paramKeys)
    ? params
    : (_.chain(params)
        .toPairs()
        .filter((v) => paramKeys.includes(v[0] as keyof PT))
        .fromPairs()
        .value() as unknown as PT)
}

export default useLocationParams
