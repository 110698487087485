import React from 'react'
import _ from 'lodash'
import { Dropdown, Menu, Pagination, Table, TableProps, Tag } from 'antd'
import classNames from 'classnames/bind'

import { DataQueryPropType } from '@/@types/data-query'
import { itemToItems } from '@/utils/collections'
import { buildKeyValueLabelsByValues } from '@/utils/ui/choice'
import { numbering } from '@/utils/ui/pagination'
import { dateFormat } from '@/utils/date'
import Section from '@/components/layout/section/Section'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import {
  memoKindOrderingLabel,
  memoKindStatuses,
  memoKindStatusTagColor,
  memoKindStatusTagLabel,
} from '@/apps/memo/constants'
import {
  MemoKind,
  MemoKindListOrderingKey,
  MemoKindListParam,
  MemoKindStatus,
  MemoKindStatusLabel,
} from '@/apps/memo/@types/memo-kind'

import styles from './MemoKindList.View.module.scss'

const cx = classNames.bind(styles)

export interface PropType
  extends DataQueryPropType<
    MemoKindListParam,
    MemoKind,
    MemoKindListOrderingKey
  > {
  onClickEdit: (item: MemoKind) => void
  onClickStatus: (item: MemoKind, newStatus: MemoKindStatus) => void
  onChangeTable?: TableProps<MemoKind>['onChange']
  onClickAddMemoKind?: () => void
}

export const orderOptions = _.chain(memoKindOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const { Column } = Table

const statusColumnFilters = _.chain(memoKindStatusTagLabel)
  .toPairs()
  .map(([value, text]) => ({ text, value }))
  .value()

const MemoKindListView: React.FC<PropType> = ({
  page,
  limit,
  count,
  params,
  data,
  isLoading,
  orderingKey,
  orderingIsDesc,
  onChangePage,
  onChangeParam,
  onClickEdit,
  onClickStatus,
  onChangeTable,
  onClickAddMemoKind,
}) => {
  const statuses = Object.values(memoKindStatuses)
  const filteredStatus = itemToItems<MemoKindStatus>(params.status)

  const renderButtonEdit = (record: MemoKind) => {
    return (
      <BasicButton
        disabled={record.status !== memoKindStatuses.ACTIVE}
        type="text"
        label="수정"
        onClick={() => onClickEdit(record)}
        data-testid={`memokindlist/btn-edit-${record.id}`}
      />
    )
  }

  return (
    <Section className={cx('container')} title="메모 유형 목록">
      <div className=" tw-pb-4">
        <QuerySection<MemoKindListParam, MemoKindListOrderingKey>
          wrapperName="memokindlist"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="메모 유형 검색"
          searchParamKey="query"
          defaultOrderingIsDesc={orderingIsDesc}
        >
          <BasicButton
            className="tw-ml-auto"
            label="신규 유형 추가"
            icon="plus"
            data-testid="memokindlist/btn-add"
            onClick={onClickAddMemoKind}
          />
        </QuerySection>
      </div>

      <Table<MemoKind>
        dataSource={data}
        rowKey="id"
        pagination={false}
        onChange={onChangeTable}
      >
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column title="메모 유형" dataIndex="name" />

        <Column title="유형 설명" dataIndex="description" />

        <Column title="생성 일자" dataIndex="createdAt" render={renderDate} />
        <Column title="갱신 일자" dataIndex="updatedAt" render={renderDate} />

        <Column<MemoKind>
          title="상태"
          dataIndex="status"
          filters={statusColumnFilters}
          filteredValue={filteredStatus}
          render={(__, record) => (
            <StatusActionMenu
              item={record}
              labels={memoKindStatusTagLabel}
              statusValues={statuses}
              onChange={onClickStatus}
            />
          )}
        />

        <Column<MemoKind> title="수정" render={renderButtonEdit} />
      </Table>

      <Pagination
        disabled={isLoading}
        className="tw-mt-10 tw-flex tw-justify-center"
        defaultCurrent={page ?? 1}
        pageSize={limit}
        defaultPageSize={limit}
        total={count}
        onChange={onChangePage}
      />
    </Section>
  )
}

interface StatusActionMenuPropType {
  item: MemoKind
  labels: MemoKindStatusLabel
  statusValues: MemoKindStatus[]
  onChange: (item: MemoKind, newValue: MemoKindStatus) => void
}

const StatusActionMenu: React.FC<StatusActionMenuPropType> = ({
  item,
  labels,
  statusValues,
  onChange,
}) => {
  if (item.status === memoKindStatuses.INACTIVE) return null

  const menuItems = buildKeyValueLabelsByValues<
    MemoKindStatusLabel,
    MemoKindStatus
  >(labels, statusValues)
  const menu = (
    <Menu
      onClick={({ key }) => onChange(item, key as string as MemoKindStatus)}
    >
      {menuItems.map(([key, label]) => {
        return (
          <Menu.Item disabled={item.status === key} key={key}>
            {label}
          </Menu.Item>
        )
      })}
    </Menu>
  )
  const label = labels[item.status]
  const color = memoKindStatusTagColor[item.status]

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Tag color={color} data-testid={`memokindlist/btn-status-${item.id}`}>
        {label}
      </Tag>
    </Dropdown>
  )
}

const renderDate = (value: string) => dateFormat(value, 'yyyy-MM-dd')

export default MemoKindListView
