import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import {
  Form,
  FormInstance,
  Radio,
  Space,
  Table,
  Checkbox,
  Input,
  Typography,
  Pagination,
} from 'antd'
import { numbering } from '@/utils/ui/pagination'
import { careItemReptitionKind } from '@/constants/apps.careItem'
import { weekdaysValue } from '@/constants/common'
import { DatePicker } from '@/components/antd'
import FormModal from '@/components/modal/FormModal'
import {
  CareItem,
  CareItemReptitionKind,
} from '@/apps/careItem/@types/careItem'
import { CreatingCurriculumCareItemFormRules } from '@/apps/curriculum/@types/curriculum.care'
import styles from './AddCurriculumCareItemForm.View.module.scss'
import { CheckKind } from '@/apps/care/@types/care'
import { checkKinds } from '@/constants/apps.care'

export interface ItemDisabledState {
  selectRepetition: boolean
  repetitionDays: boolean
  repetitionWeekDays: boolean
  openInDays: boolean
  totalRepetitions: boolean
  infinitely: boolean
}

export interface PropType {
  page: number
  limit: number
  count: number
  data: CareItem[]
  isVisible?: boolean
  isLoading?: boolean
  isCalendarStartDate?: boolean
  isInfinitely: boolean
  itemDisabled: ItemDisabledState
  onFinish?: () => void
  onCancel?: () => void
  onChangeSelectCareItem?: (id: number) => void
  onChangeSelectRepetition?: (isRepetition: boolean) => void
  onChangeSelectRepetitionKind?: (repetitionKind: CareItemReptitionKind) => void
  onChangeInfinitely?: (isInfinitely: boolean) => void
  onInputRepetitionDays?: (day: number) => void
  onChangeRepetitionWeekDays?: (weekDays: number[]) => void
  onChangePage: (page: number) => void
  disabledDate: (current: Date) => boolean
  rules?: CreatingCurriculumCareItemFormRules
  formRef?: FormInstance // only for testing
  checkKind?: CheckKind
  weekValues: number[]
}

const AddCurriculumCareItemFormView: React.FC<PropType> = ({
  page,
  count,
  limit,
  data,
  isVisible,
  isLoading,
  isCalendarStartDate = true,
  isInfinitely,
  itemDisabled,
  onFinish,
  onCancel,
  onChangeSelectCareItem,
  onChangeSelectRepetition,
  onChangeSelectRepetitionKind,
  onChangeInfinitely,
  onInputRepetitionDays,
  onChangeRepetitionWeekDays,
  onChangePage,
  disabledDate,
  rules,
  formRef,
  checkKind,
  weekValues,
}) => {
  return (
    <FormModal
      className={cx('container')}
      visible={isVisible}
      isLoading={isLoading}
      title="케어 아이템 추가"
      subTitle="케어 아이템 신규 추가"
      okText="저장"
      cancelText="취소"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <div>
        <Form form={formRef} colon={false} requiredMark={false}>
          <Form.Item
            className={cx('care-item')}
            label="케어 아이템 선택"
            name="care_item"
            rules={rules?.careItem}
          >
            <Table<CareItem>
              dataSource={data}
              rowSelection={{
                type: 'radio',
                onChange: (key: React.Key[]) =>
                  onChangeSelectCareItem?.(key[0] as number),
                getCheckboxProps: () => ({
                  disabled: checkKind === checkKinds.YES_OR_NO,
                }),
              }}
              rowKey="id"
              pagination={false}
            >
              <Column
                title="번호"
                key="order"
                render={(_value, _record, index) =>
                  numbering(page, limit, index)
                }
              />
              <Column title="케어 아이템" dataIndex="name" />
              <Column title="설명" dataIndex="description" />
            </Table>
            {!!count && (
              <Pagination
                hideOnSinglePage
                disabled={isLoading}
                defaultCurrent={page ?? 1}
                pageSize={limit}
                defaultPageSize={limit}
                total={count}
                onChange={onChangePage}
              />
            )}
          </Form.Item>

          <Form.Item
            label="반복 설정"
            name="is_repetition"
            rules={rules?.isRepetition}
          >
            <Radio.Group
              options={repetitionOptions}
              onChange={(e) =>
                onChangeSelectRepetition?.(e.target.value as boolean)
              }
            />
          </Form.Item>

          <Form.Item
            label="반복 주기"
            name="repetition"
            rules={rules?.repetition}
          >
            <Radio.Group
              disabled={itemDisabled.selectRepetition}
              onChange={(e) =>
                onChangeSelectRepetitionKind?.(
                  e.target.value as CareItemReptitionKind,
                )
              }
            >
              <Space direction="vertical">
                <Space>
                  <Radio value={repetitionKindOptions[0].value}>
                    {repetitionKindOptions[0].label}
                  </Radio>
                  <Input
                    disabled={itemDisabled.repetitionDays}
                    onChange={(e) =>
                      onInputRepetitionDays?.(_.toNumber(e.target.value))
                    }
                    placeholder="00"
                    min={1}
                    style={{ width: 120 }}
                    type="number"
                    suffix="일(days)"
                  />
                </Space>
                <Space>
                  <Radio value={repetitionKindOptions[1].value}>
                    {repetitionKindOptions[1].label}
                  </Radio>
                  <Checkbox
                    checked={_.isEqual(
                      getWeekDayOptions.length,
                      weekValues.length,
                    )}
                    disabled={itemDisabled.repetitionWeekDays}
                    onChange={() =>
                      onChangeRepetitionWeekDays?.(
                        _.isEqual(getWeekDayOptions.length, weekValues.length)
                          ? []
                          : getWeekDayOptions.map(({ value }) => value),
                      )
                    }
                  >
                    전체 선택
                  </Checkbox>
                  <Typography
                    className={'checkbox-bracket'}
                    style={{
                      color: itemDisabled.repetitionWeekDays
                        ? '#d9d9d9'
                        : '#222222',
                    }}
                  >
                    (
                  </Typography>
                  <Checkbox.Group
                    value={weekValues}
                    options={getWeekDayOptions}
                    disabled={itemDisabled.repetitionWeekDays}
                    onChange={(e) =>
                      onChangeRepetitionWeekDays?.(e as number[])
                    }
                  />
                  <Typography
                    className={'checkbox-bracket'}
                    style={{
                      color: itemDisabled.repetitionWeekDays
                        ? '#d9d9d9'
                        : '#222222',
                    }}
                  >
                    )
                  </Typography>
                </Space>
              </Space>
            </Radio.Group>
          </Form.Item>

          {!isCalendarStartDate && (
            <Form.Item
              label="시작 일자"
              name="open_in_days"
              rules={rules?.openInDays}
            >
              <Space>
                <Typography>커리큘럼 시작일로부터</Typography>
                <Input
                  disabled={itemDisabled.openInDays}
                  placeholder="00"
                  min={1}
                  style={{ width: 90 }}
                  type="number"
                  suffix="일차"
                />
              </Space>
            </Form.Item>
          )}

          {isCalendarStartDate && (
            <Form.Item
              label="시작 일자"
              name="opened_at"
              rules={rules?.openedAt}
            >
              <DatePicker placeholder="날짜 선택" disabledDate={disabledDate} />
            </Form.Item>
          )}

          <Space align="baseline">
            <Form.Item
              label="총 수행 횟수"
              name="total_repetitions"
              rules={rules?.totalRepetitions}
            >
              <Space>
                <Typography>1회차를 포함하여</Typography>
                <Input
                  disabled={itemDisabled.totalRepetitions}
                  placeholder="0"
                  min={1}
                  style={{ width: 150 }}
                  type="number"
                  suffix="회 수행 후 종료"
                />
              </Space>
            </Form.Item>
            <Checkbox
              disabled={itemDisabled.infinitely}
              checked={isInfinitely}
              onChange={(e) => onChangeInfinitely?.(e.target.checked)}
            >
              무한 반복
            </Checkbox>
          </Space>
        </Form>
      </div>
    </FormModal>
  )
}

export default AddCurriculumCareItemFormView

const cx = classNames.bind(styles)

const { Column } = Table

const repetitionOptions = [
  {
    value: true,
    label: '네',
  },
  {
    value: false,
    label: '아니오(1회성)',
  },
]

const repetitionKindOptions = [
  {
    value: careItemReptitionKind.DAYS,
    label: '일 단위 반복',
  },
  {
    value: careItemReptitionKind.WEEK_DAYS,
    label: '요일 단위 반복',
  },
]

const getWeekDayOptions = _.chain(weekdaysValue)
  .toPairs()
  .map(([label, value]) => ({ value: _.toNumber(value), label }))
  .value()
