import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { Form, FormInstance } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { Nil } from '@/@types/composite'
import { OptionType } from '@/@types/antd'
import { DateTimeISO8601 } from '@/@types/formats'
import { ErrorMessage } from '@/@types/error'
import { dateFormat, getMaxClosedAtFromCurriculumCares } from '@/utils/date'
import {
  hasAnyFieldsError,
  setErrorFieldsFromServerWithMessages,
} from '@/utils/antd/form'
import envs from '@/config/variables'
import { PAGES as DASHBOARDS_PAGE } from '@/constants/apps.dashboard'
import {
  curriculumCompilationKind,
  curriculumVisibility,
} from '@/constants/apps.curriculum'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import {
  CreatingFormRules,
  CustomerCurriculum,
  ModifyingFormRules,
} from '@/apps/customer/@types/customer.curriculum'
import { caculateClosedDateOnCurriculum } from '@/apps/customer/utils/helper'
import {
  editCustomerCurriculumFormError,
  editCustomerCurriculumFormErrorMessage,
} from '@/apps/customer/messages/error'
import {
  useCustomerCurriculumCreation,
  useCustomerCurriculumModification,
} from '@/apps/customer/hooks/curriculum/useCustomerCurriculumManipulation'
import useCurriculumList from '@/apps/curriculum/hooks/useCurriculumList'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import EditCustomerCurriculumFormView from './EditCustomerCurriculumForm.View'
import useCurriculumCareList from '@/apps/curriculum/hooks/useCurriculumCareList'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
  customerCurriculum: CustomerCurriculum | Nil
  isVisible: boolean
  onFinish: () => void
  onCancel: () => void
  formRefForTesting?: FormInstance // only for testing
}

interface State {
  caculatedClosedAt: string | Nil
  isModify: boolean
  isAutonomous: boolean
}

const EditCustomerCurriculumForm: React.FC<PropType> = ({
  customer,
  customerCurriculum,
  isVisible,
  onCancel,
  onFinish,
  formRefForTesting,
}) => {
  const history = useHistory()

  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const defaultState: State = {
    caculatedClosedAt: null,
    isModify: false,
    isAutonomous: false,
  }

  const [{ caculatedClosedAt, isModify, isAutonomous }, setState] =
    useState<State>(defaultState)

  const { data, mutate } = useCurriculumList({
    limit: defaultLimit,
    compilationKind: curriculumCompilationKind.REGULAR,
    visibility: curriculumVisibility.PUBLISHED,
  })
  const creation = useCustomerCurriculumCreation()
  const modifying = useCustomerCurriculumModification()
  const fetchingCurriculumCares = useCurriculumCareList()

  useEffect(() => {
    if (isVisible) {
      mutate()
    }
    creation.setPayload(null)
    modifying.setKey(null)
    modifying.setPayload(null)
    currentFormRef.resetFields()
  }, [isVisible])

  useEffect(() => {
    setState(() => ({
      caculatedClosedAt: customerCurriculum?.period
        ? dateFormat(
            caculateClosedDateOnCurriculum(
              new Date(customerCurriculum.openedAt),
              customerCurriculum.period,
            ),
          )
        : null,
      isModify: !!customerCurriculum,
      isAutonomous:
        customerCurriculum?.compilationKind ===
        curriculumCompilationKind.AUTONOMOUS,
    }))

    if (!customerCurriculum) return
    fetchingCurriculumCares.setParams({
      curriculum: customerCurriculum?.curriculum,
    })
  }, [customerCurriculum])

  useEffect(() => {
    if (!isVisible) return
    if (!creation.data || creation.isLoading) return
    _notifySuccess('저장 완료', '커리큘럼 설정을 완료하였습니다.')
    onFinish()
  }, [creation.data, creation.isLoading])

  useEffect(() => {
    if (!isVisible) return
    if (!creation.error || creation.isLoading) return

    const errorData = creation.error?.data
    const errorcode = creation.error?.status

    if (
      !!errorData.curriculum ||
      !!errorData.customer ||
      serverErrorStatus.includes(errorcode)
    ) {
      _notifyFailure('요청 실패', '유효하지 않은 요청입니다.')
      onCancel()
      return
    }

    if (errorcode === 403) {
      _notifyFailure(
        '요청 실패',
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
      )
      onCancel()
      return
    }

    if (errorcode === 403) {
      _notifyFailure(
        '요청 실패',
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
      )
      onCancel()
      return
    }

    if (errorData) {
      setErrorFieldsFromServerWithMessages(
        currentFormRef,
        errorData,
        editCustomerCurriculumFormErrorMessage,
      )
    }
  }, [creation.error, creation.isLoading])

  useEffect(() => {
    if (!isVisible) return
    if (!modifying.data || modifying.isLoading) return
    _notifySuccess('수정 완료', '커리큘럼 설정을 완료하였습니다.')
    onFinish()
  }, [modifying.data, modifying.isLoading])

  useEffect(() => {
    if (!isVisible) return
    if (!modifying.error || modifying.isLoading) return

    const errorData = modifying.error?.data
    const errorcode = modifying.error?.status

    if (
      !!errorData.curriculum ||
      !!errorData.customer ||
      serverErrorStatus.includes(errorcode)
    ) {
      _notifyFailure('요청 실패', '유효하지 않은 요청입니다.')
      !!errorData.customer && history.push(DASHBOARDS_PAGE.dashboard.path)
      onCancel()
      return
    }

    if (errorcode === 403) {
      _notifyFailure(
        '요청 실패',
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
      )
      onCancel()
      return
    }

    if (errorData) {
      setErrorFieldsFromServerWithMessages(
        currentFormRef,
        errorData,
        editCustomerCurriculumFormErrorMessage,
      )
    }
  }, [modifying.error, modifying.isLoading])

  const onChangeCurriculum = (id: number) => {
    const isAutonomous = id === -1
    setState((prev) => ({
      ...prev,
      caculatedClosedAt: null,
      isAutonomous: isAutonomous,
      selectedCurriculumId: id,
    }))

    currentFormRef.setFieldsValue({
      openedAt: null,
      period: null,
    })

    if (!isAutonomous) {
      fetchingCurriculumCares.setParams({
        curriculum: id,
      })
    }
  }

  const onChangeOpenedAt = (openedAt: Date | Nil) => {
    // 시작 일자 clear
    if (!openedAt) {
      setState((prev) => ({
        ...prev,
        isAutonomous: isAutonomous,
        caculatedClosedAt: null,
      }))
      return
    }

    const curPeriod = currentFormRef.getFieldValue('period')
    // case 1. autonomous
    if (isAutonomous) {
      onChangePeriod(curPeriod)
      return
    }

    // case 2. regular
    const cares = fetchingCurriculumCares.data?.results ?? []
    const maxClosedAtOfCareItem = getMaxClosedAtFromCurriculumCares(
      cares,
      openedAt,
    )
    const period = !maxClosedAtOfCareItem
      ? 0
      : differenceInCalendarDays(maxClosedAtOfCareItem, openedAt)
    currentFormRef.setFieldsValue({
      period,
    })
    onChangePeriod(period)
  }

  const onChangePeriod = (period: number) => {
    const openedAt = currentFormRef.getFieldValue('openedAt')
    const closedAt = !openedAt
      ? null
      : isUnlimitedCurriculum(period)
      ? '무한'
      : dateFormat(caculateClosedDateOnCurriculum(openedAt, period))

    setState((prev) => ({
      ...prev,
      caculatedClosedAt: closedAt,
    }))
  }

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    if (isModify) {
      modifying.setKey(customerCurriculum?.id)
      modifying.setPayload(
        buildCustomerCurriculumManipulationPayload(
          currentFormRef,
          isAutonomous,
        ),
      )
    } else {
      creation.setPayload({
        ...buildCustomerCurriculumManipulationPayload(
          currentFormRef,
          isAutonomous,
          true,
        ),
        customer: customer.uid,
      })
    }
  }

  const _onCancel = () => {
    onCancel()
  }

  return (
    <EditCustomerCurriculumFormView
      rules={getRules(isModify, isAutonomous)}
      curriculumOptions={getCurriculumOptions(
        data?.results ?? [],
        customerCurriculum,
      )}
      showModal={isVisible}
      showLoading={false}
      disabledCurriculum={isModify}
      disabledPeriod={!isAutonomous}
      isAutonomous={isAutonomous}
      title="커리큘럼 설정"
      subTitle="커리큘럼 설정 관리"
      caculatedClosedAt={caculatedClosedAt}
      onFinish={_onFinish}
      onCancel={_onCancel}
      onChangeOpenedAt={onChangeOpenedAt}
      onChangePeriod={onChangePeriod}
      onChangeCurriculum={onChangeCurriculum}
      disabledDate={disabledDate}
      formRef={currentFormRef}
      formInitialValue={buildFormInitialValue(customerCurriculum, isAutonomous)}
    />
  )
}

export default EditCustomerCurriculumForm

const defaultLimit = 100

const getCurriculumOptions = (
  curriculums: Curriculum[],
  customerCurriculum: CustomerCurriculum | Nil,
): OptionType[] =>
  _.chain(curriculums)
    .map((curriculum) => {
      return {
        value: curriculum.id,
        label: curriculum.name,
      }
    })
    .push({
      value:
        customerCurriculum?.compilationKind ===
        curriculumCompilationKind.AUTONOMOUS
          ? customerCurriculum.curriculum
          : -1,
      label: '자율 커리큘럼',
    })
    .sortBy('label')
    .value()

const isUnlimitedCurriculum = (period: number) => period === 0

const today = new Date()

const disabledDate = (current: Date) => {
  return !!current && differenceInCalendarDays(current, today) <= 0
}

const buildCustomerCurriculumManipulationPayload = (
  currentFormRef: FormInstance,
  isAutonomous: boolean,
  isCreate?: boolean,
) => {
  const curriculum = currentFormRef.getFieldValue('curriculum')
  const curriculumName = currentFormRef.getFieldValue('name')
  const openedAt = currentFormRef.getFieldValue('openedAt')
  const periodNumber = _.toNumber(currentFormRef.getFieldValue('period'))
  const period = isUnlimitedCurriculum(periodNumber) ? null : periodNumber

  return {
    curriculum: isCreate ? (isAutonomous ? undefined : curriculum) : undefined,
    curriculumName: isAutonomous ? curriculumName : undefined,
    openedAt: dateFormat<DateTimeISO8601>(new Date(openedAt)),
    period: period,
  }
}

const buildFormInitialValue = (
  customerCurriculum: CustomerCurriculum | Nil,
  isAutonomous: boolean,
): Store | undefined => {
  if (!customerCurriculum) return undefined
  return {
    curriculum: customerCurriculum.curriculum,
    name: isAutonomous ? customerCurriculum.name : null,
    openedAt: new Date(customerCurriculum.openedAt),
    period: customerCurriculum.period ?? 0,
    closedAt: customerCurriculum.period
      ? dateFormat(
          caculateClosedDateOnCurriculum(
            new Date(customerCurriculum.openedAt),
            customerCurriculum.period,
          ),
        )
      : null,
  }
}

const _notifySuccess = (defaultMessage: string, defaultDescription: string) => {
  notifySuccess({
    message: defaultMessage,
    description: defaultDescription,
  })
}

const _notifyFailure = (
  defaultMessage: string,
  defaultDescription: string,
  errorMessage?: ErrorMessage,
) => {
  notifyFailure({
    message: errorMessage?.message ?? defaultMessage,
    description: errorMessage?.desc ?? defaultDescription,
  })
}

const maxLengthOfName = 32
export const getCreatingRules = (isAutonomous: boolean): CreatingFormRules => {
  return {
    curriculum: [
      {
        required: true,
        message: editCustomerCurriculumFormError.required.curriculum,
      },
    ],
    name: [
      {
        required: isAutonomous,
        message: editCustomerCurriculumFormError.required.name,
      },
      {
        type: 'string',
        max: maxLengthOfName,
        message: editCustomerCurriculumFormError.maxLength.name,
      },
    ],
    openedAt: [
      {
        required: true,
        message: editCustomerCurriculumFormError.required.opendAt,
      },
    ],
    period: [
      {
        required: true,
        message: editCustomerCurriculumFormError.required.period,
      },
    ],
  }
}

export const getModifyingRules = (
  isAutonomous: boolean,
): ModifyingFormRules => {
  return {
    ...getCreatingRules(isAutonomous),
  }
}

const getRules = (
  isModifying: boolean,
  isAutonomous: boolean,
): CreatingFormRules | ModifyingFormRules =>
  isModifying ? getModifyingRules(isAutonomous) : getCreatingRules(isAutonomous)
