import { useState } from 'react'
import useSWR from 'swr'
import {
  buildManipulationHook,
  CreationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import { del, post } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import {
  CustomerCareItem,
  CustomerCareItemCreatePayload,
} from '@/apps/customer/@types/customer.care'

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCustomerCareItemCreation: () => CreationHookReturnType<
  CustomerCareItemCreatePayload,
  CustomerCareItem
> = () => {
  return buildManipulationHook<CustomerCareItemCreatePayload, CustomerCareItem>(
    {
      url: URLS.customerCareItemList,
      request: post,
    },
  )
}

export const useCustomerCareItemDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating, mutate } = useSWR(
    [key ? URLS.customerCareItemDetail(key as number) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !!key && !data && !error

  const _setKey = (newKey: Key) => {
    if (newKey === key) {
      mutate()
      return
    }
    setKey(newKey)
  }

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey: _setKey,
  }
}
