import useSWR from 'swr'

import { Nil } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.curriculum'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { useState } from 'react'
import { Key } from '@/utils/swr/helpers'
import _ from 'lodash'

const fetcher = (url: string | null) => {
  if (!url) return
  return get<never, Curriculum>(url)
}

const useCurriculumDetail = (id: Nil | string | number) => {
  const [key, setKey] = useState<Key>(id)

  const { data, error, isValidating, mutate } = useSWR(
    key ? URLS.curriculumDetail(key) : null,
    fetcher,
  )

  const _setKey = (id: Nil | string | number) => {
    if (_.isEqual(id, key)) {
      mutate()
    } else {
      setKey(id)
    }
  }

  const isLoading = !data && !error
  return {
    data,
    error,
    mutate,
    setKey: _setKey,
    key,
    isLoading,
    isValidating,
  }
}

export default useCurriculumDetail
