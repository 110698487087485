import React, { useState, useContext } from 'react'
import { Nil } from '@/@types/composite'

interface TitleSectionDataType {
  title: Nil | string | React.ReactElement
  extraActions?: Nil | React.ReactElement
}

interface LayoutContextType {
  titleSectionData: Nil | TitleSectionDataType
  setTitleSectionData: (data: Nil | TitleSectionDataType) => void
}

export const LayoutContext = React.createContext<LayoutContextType>({
  titleSectionData: null,
  setTitleSectionData: () => undefined,
})

export const useLayoutContext = () => useContext(LayoutContext)

export const LayoutProvider: React.FC = (props) => {
  const [titleSectionData, setTitleSectionData] = useState<
    Nil | TitleSectionDataType
  >(null)
  return (
    <LayoutContext.Provider
      value={{
        titleSectionData,
        setTitleSectionData,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}

export const LayoutConsumer = LayoutContext.Consumer

export default LayoutContext
