import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.memo'
import { PagePaginationResponse } from '@/clients/http/types'
import { Memo, MemoListParam } from '@/apps/memo/@types/memo'

const fetcher = (url: string | null, params?: MemoListParam) => {
  if (!url) return
  return get<MemoListParam, PagePaginationResponse<Memo>>(url, params)
}

const useMemoList = (defaultParams = {}) => {
  const [params, setParams] = useState<MemoListParam>({
    page: 1,
    ...defaultParams,
  })

  const _setParams = (newParams: MemoListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    [URLS.memoList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    setParams: _setParams,
    mutate,
  }
}

export default useMemoList
