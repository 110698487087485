import React, { useState } from 'react'
import _ from 'lodash'

import { ChangeHistoryAction } from '@/@types/router'
import { Nil } from '@/@types/composite'
import { orderingParamKey } from '@/constants/common'
import useLocationParams from '@/hooks/useLocationParams'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { buildOrderingQueryString, serverErrorStatus } from '@/utils/http'
import { buildTableChangeFilter } from '@/utils/antd/table'
import {
  CustomerKindListParam,
  CustomerKindListOrderingKey,
  CustomerKind,
  CustomerKindStatus,
} from '@/apps/customer/@types/customer-kind'
import {
  useCustomerKindModification,
  useCustomerKindCreation,
} from '@/apps/customer/hooks/useCustomerKindManipulation'
import useCustomerKindList from '@/apps/customer/hooks/useCustomerKindList'
import EditCustomerKindForm from '@/apps/customer/components/edit-customer-kind-form/EditCustomerKindForm'
import CustomerKindListView from './CustomerKindList.View'

const paramKeys: Array<keyof CustomerKindListParam> = [
  'status',
  'page',
  'limit',
  'query',
  'ordering',
]
const defaultPage = 1
const defaultLimit = 10
const defaultOrderingKey: CustomerKindListOrderingKey = 'updated_at'
const defaultIsDesc = true
const defaultListParams: CustomerKindListParam = {
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CustomerKindListOrderingKey,
}

const CustomerKindList: React.FC = () => {
  const [isVisibleForm, setIsVisibleForm] = useState(false)
  const [isVisibleStatusModal, setIsVisibleStatusModal] = useState(false)
  const [selected, setSelected] = useState<CustomerKind | null>(null)

  const { data, error, isLoading, setParams, mutate } =
    useCustomerKindList(defaultListParams)

  const modifying = useCustomerKindModification()
  const creating = useCustomerKindCreation()

  const locParams = useLocationParams<
    CustomerKindListParam,
    CustomerKindListOrderingKey
  >(paramKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    onSetParams: setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['status'],
    tryPreviousPage: true,
    paginatedData: data,
    httpError: error,
  })

  const orderingKey = locParams.fullParams.orderingKey as
    | CustomerKindListOrderingKey
    | undefined

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeParam = (
    key: keyof CustomerKindListParam,
    value: unknown,
    historyAction?: ChangeHistoryAction<CustomerKindListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeTable = buildTableChangeFilter<CustomerKind>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddCustomerKind = () => {
    setIsVisibleForm(true)
  }

  const onClickEdit = (item: CustomerKind) => {
    setSelected(item)
    setIsVisibleForm(true)
  }

  const onFinishEdit = async () => {
    await mutate()
    setSelected(null)
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
    setIsVisibleForm(false)
  }

  const onCancelEdit = () => {
    setSelected(null)
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
    setIsVisibleForm(false)
  }

  const onClickStatus = (item: CustomerKind, status: CustomerKindStatus) => {
    setSelected(item)
    setIsVisibleStatusModal(true)
    modifying.setPayload({ status })
  }

  const updateCustomerKindStatus = () => {
    if (!selected) return
    modifying.setKey(selected.id)
  }

  const onFinishChangeStatus = async () => {
    await mutate()
    setSelected(null)
    modifying.setPayload(null)
    modifying.setKey(null)
    setIsVisibleStatusModal(false)
  }

  const onCancelChangeStatus = onFinishChangeStatus

  const _setModifyingKey = (data: CustomerKind | null) => {
    modifying.setKey(data?.id)
  }

  return (
    <>
      <CustomerKindListView
        params={locParams.params}
        page={locParams.params.page ?? defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        count={data?.count ?? 0}
        data={data?.results ?? []}
        orderingKey={orderingKey}
        isLoading={isLoading}
        onChangeParam={onChangeParam}
        onChangePage={onChangePage}
        onChangeTable={onChangeTable}
        onClickAddCustomerKind={onClickAddCustomerKind}
        onClickEdit={onClickEdit}
        onClickStatus={onClickStatus}
      />
      <EditCustomerKindForm
        isVisible={isVisibleForm}
        selectedItem={selected}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
        setModifyingKey={_setModifyingKey}
        creating={creating}
        modifying={modifying}
      />
      <ConfirmFlowModal<typeof modifying.data, typeof modifying.error>
        visible={isVisibleStatusModal && !!selected}
        title="상태 변경"
        content={getConfirmTextForChangingStatus(selected)}
        successNotiMessage="변경 완료"
        successNotiDesc="성공적으로 고객 유형 상태를 변경했습니다."
        failNotiMessage={
          serverErrorStatus.includes(modifying.error?.status)
            ? '처리 실패'
            : '변경 실패'
        }
        failNotiDesc={
          serverErrorStatus.includes(modifying.error?.status)
            ? '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.'
            : '상태를 변경에 실패했습니다. 다시 시도해주세요.'
        }
        response={modifying.data}
        error={modifying.error}
        action={updateCustomerKindStatus}
        isLoadingRequest={modifying.isLoading}
        onFinish={onFinishChangeStatus}
        onCancel={onCancelChangeStatus}
      />
    </>
  )
}

const getConfirmTextForChangingStatus = (item: CustomerKind | Nil) => {
  return !item
    ? '변경할 대상을 먼저 선택하세요.'
    : `정말 ${item.name} 고객 유형 상태를 변경하시겠습니까?`
}

export default CustomerKindList
