export interface IStorageAdaptor {
  clear(): void
  getItem(key: string, jsonify?: boolean): string | null
  setItem(key: string, value: string, jsonify?: boolean): void
  removeItem(key: string): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any
}

export const getDefaultStorage = (storage = localStorage): IStorageAdaptor => {
  return StorageAdaptor.getInstance(storage)
}

class StorageAdaptor implements IStorageAdaptor {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-useless-constructor
  private constructor() {}
  private static _instance: StorageAdaptor
  private storage: Storage | null = null

  public static getInstance(storage: Storage) {
    if (!this._instance) {
      this._instance = new this()
      this._instance.storage = storage
    }
    return this._instance
  }

  clear() {
    this.storage?.clear()
  }

  getItem(key: string, jsonify = true) {
    const value = this.storage?.getItem(key)
    return (jsonify ? JSON.parse(value || 'null') : value) || null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: string, value: any, jsonify = true) {
    this.storage?.setItem(key, jsonify ? JSON.stringify(value) : value)
  }

  removeItem(key: string) {
    this.storage?.removeItem(key)
  }
}
