import React from 'react'
import { Nil } from '@/@types/composite'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { CustomerCurriculumInfo } from '@/apps/customer/components/customer-curriculum-info'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import { Customer } from '@/apps/customer/@types/customer'
import { Care } from '@/apps/care/@types/care'
import EssentialCareList from '@/apps/curriculum/components/essential-care-list/EssentialCareList'
import { OptionalCareList } from '@/apps/curriculum/components/optional-care-list'
import styles from './CustomerActivity.View.module.scss'

interface PropType {
  customerCurriculum: CustomerCurriculum | Nil
  customer: Customer<Order, User>
  caculatedCustomerCurriculumClosedAt: Date | Nil
  essentialCustomerCareList: CustomerCare<Care, CustomerCareItem>[]
  optionalCustomerCareList: CustomerCare<Care, CustomerCareItem>[]
  user: User | Nil
  isMyCustomer: boolean
  onFinishManipulationCurriculum: () => void
  onFinishManipulationCare: () => void
  onFinishToggleSunCare: () => void
}

const CustomerActivityView: React.FC<PropType> = ({
  customerCurriculum,
  customer,
  caculatedCustomerCurriculumClosedAt,
  essentialCustomerCareList,
  optionalCustomerCareList,
  user,
  isMyCustomer,
  onFinishManipulationCurriculum,
  onFinishManipulationCare,
  onFinishToggleSunCare,
}) => {
  return (
    <div className={styles.container}>
      <CustomerCurriculumInfo
        customerCurriculum={customerCurriculum}
        caculatedCustomerCurriculumClosedAt={
          caculatedCustomerCurriculumClosedAt
        }
        customer={customer}
        user={user}
        isMyCustomer={isMyCustomer}
        onFinishManipulation={onFinishManipulationCurriculum}
      />

      <EssentialCareList
        isMyCustomer={isMyCustomer}
        customerCurriculum={customerCurriculum}
        essentialCares={essentialCustomerCareList}
        caculatedCustomerCurriculumClosedAt={
          caculatedCustomerCurriculumClosedAt
        }
        onFinishManipulation={onFinishManipulationCare}
      />

      <OptionalCareList
        isMyCustomer={isMyCustomer}
        customerCurriculum={customerCurriculum}
        caculatedCustomerCurriculumClosedAt={
          caculatedCustomerCurriculumClosedAt
        }
        optionalCares={optionalCustomerCareList}
        onFinishManipulation={onFinishManipulationCare}
        onFinishToggleSunCare={onFinishToggleSunCare}
      />
    </div>
  )
}

export default CustomerActivityView
