import React from 'react'
import classNames from 'classnames/bind'
import { Nil } from '@/@types/composite'
import { dateFormat } from '@/utils/date'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import BasicButton from '@/components/buttons/BasicButton'
import SpanText from '@/components/typography/SpanText'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import styles from './CustomerCurriculumInfo.View.module.scss'
import { curriculumVisibility } from '@/constants/apps.curriculum'

interface PropType {
  customerCurriculum: CustomerCurriculum | Nil
  caculatedCustomerCurriculumClosedAt: Date | Nil
  disabledSet: boolean
  disabledReset: boolean
  disabledExit: boolean
  onClickEdit: () => void
  onClickClose: () => void
  onClickReset: () => void
}

const CustomerCurriculumInfoView: React.FC<PropType> = ({
  customerCurriculum,
  caculatedCustomerCurriculumClosedAt,
  disabledSet,
  disabledReset,
  disabledExit,
  onClickEdit,
  onClickClose,
  onClickReset,
}) => {
  return (
    <Section>
      <SectionTitle title="커리큘럼 정보">
        <div className={cx('section-extra-action')}>
          <BasicButton
            disabled={disabledSet}
            label={customerCurriculum ? '커리큘럼 수정' : '커리큘럼 설정'}
            onClick={onClickEdit}
          />
          <BasicButton
            ghost
            label="설정 초기화"
            disabled={disabledReset}
            onClick={onClickReset}
          />
          <BasicButton
            ghost
            color="red"
            label="커리큘럼 종료"
            disabled={disabledExit}
            onClick={onClickClose}
          />
        </div>
      </SectionTitle>
      <div className={cx('row-container')}>
        <Row
          title="커리큘럼 제목"
          content={customerCurriculum?.name}
          alertContent="선택 안함"
        >
          {customerCurriculum?.visibility ===
            curriculumVisibility.SUSPENDED && (
            <span className={cx('alert')}>사용 중단된 커리큘럼 입니다</span>
          )}
        </Row>
        <Row
          title="진행 기간"
          content={
            customerCurriculum
              ? customerCurriculum?.period
                ? `${customerCurriculum.period}일`
                : '무한'
              : undefined
          }
          alertContent="0일"
        />
        <Row
          title="시작 일자"
          content={
            customerCurriculum
              ? dateFormat(customerCurriculum?.openedAt)
              : undefined
          }
          alertContent="0000-00-00"
        />
        <Row
          title="종료 일자(예정일)"
          content={
            caculatedCustomerCurriculumClosedAt
              ? dateFormat(caculatedCustomerCurriculumClosedAt)
              : undefined
          }
          alertContent="0000-00-00"
        />
      </div>
    </Section>
  )
}

export default CustomerCurriculumInfoView

const cx = classNames.bind(styles)

const Row: React.FC<{
  title: string
  content?: string
  alertContent?: string
}> = ({ title, content, alertContent, children }) => {
  return (
    <div className={cx('item-wrapper')}>
      <div className={cx('label')}>{title}</div>
      <SpanText text={content} alterText={alertContent} />
      {children}
    </div>
  )
}
