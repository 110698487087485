import { CareErrorMessage } from '@/apps/care/@types/care'
import { DefaultLabelOfConfrimFlowModal } from '@/components/modal/ConfrimFlowModal'
import {
  visibility,
  visibilityManipulation,
  visibilityTagColor,
  visibilityTagLabel,
} from '@/constants/common'

export const URLS = {
  careList: '/curriculums/cares/',
  careDetail: (key: number) => `/curriculums/cares/${key}/`,
  publishCare: (key: number) => `/curriculums/cares/${key}/publish/`,
  suspendCare: (key: number) => `/curriculums/cares/${key}/suspend/`,
  resumeCare: (key: number) => `/curriculums/cares/${key}/resume/`,
}

export const PAGES = {
  care: {
    name: '관리자 메뉴',
    title: '케어 유형 및 아이템 관리',
    requiredAuth: true,
    exact: true,
    path: '/cares',
    testId: 'care-list-page',
  },
} as const

export const careOrderingLabel = {
  updated_at: '갱신 일자',
  name: '케어 유형',
} as const

export const careVisibility = visibility

export const careVisibilityTagLabel = visibilityTagLabel

export const careVisibilityTagColor = visibilityTagColor

export const checkKinds = {
  SINGULAR: 'singular',
  MULTIPLE: 'multiple',
  YES_OR_NO: 'yes_or_no',
} as const

export const checkKindLabels = {
  [checkKinds.SINGULAR]: '단일 항목 체크',
  [checkKinds.MULTIPLE]: '복수 항목 체크',
  [checkKinds.YES_OR_NO]: '예/아니오 선택',
} as const

const baseOpValue = 0b0001

export const careCompilations = {
  DEFAULT: baseOpValue << 0,
  REGULAR: baseOpValue << 1,
  AUTONOMOUS: baseOpValue << 2,
  // composited progresses
  REGULAR_OR_AUTONOMOUS: (baseOpValue << 1) | (baseOpValue << 2),
  DEFAULT_OR_REGULAR_OR_AUTONOMOUS:
    (baseOpValue << 0) | (baseOpValue << 1) | (baseOpValue << 2),
} as const

export const careCompilationLabels = {
  [careCompilations.REGULAR]: '정규',
  [careCompilations.AUTONOMOUS]: '자율',
  [careCompilations.REGULAR_OR_AUTONOMOUS]: '정규/자율',
} as const

export const essentialLabels = {
  true: '필수',
  false: '선택',
} as const

export const careActionKinds = {
  DAILY_ROUTINE: 'daily-routine',
  DAILY_CARE: 'daily-care',
  CHECKING_UV: 'checking-uv',
} as const

export const actionKindLabels = {
  [careActionKinds.DAILY_ROUTINE]: '오늘의 루틴',
  [careActionKinds.DAILY_CARE]: '오늘의 케어',
  [careActionKinds.CHECKING_UV]: '썬케어',
} as const

export const fieldsForInitializingFormData = [
  'name',
  'description',
  'curriculumCompilation',
  'isEssential',
  'checkKind',
  'actionKind',
] as const

export const curriculumCompilationCheckboxValues = {
  [careCompilations.REGULAR]: [careCompilations.REGULAR.toString()],
  [careCompilations.AUTONOMOUS]: [careCompilations.AUTONOMOUS.toString()],
  [careCompilations.REGULAR_OR_AUTONOMOUS]: [
    careCompilations.REGULAR.toString(),
    careCompilations.AUTONOMOUS.toString(),
  ],
} as const

export const careVisibilityManipulation = visibilityManipulation

export const manipulationUrl = {
  [careVisibilityManipulation.PUBLISHED]: URLS.publishCare,
  [careVisibilityManipulation.SUSPENDED]: URLS.suspendCare,
  [careVisibilityManipulation.RESUME]: URLS.resumeCare,
} as const

export const confirmTextUpdatingCareVisibility = {
  [visibility.UNPUBLISHED]: `[${careVisibilityTagLabel.unpublished}으로 변경할 수 없습니다.]`,
  [visibility.PUBLISHED]: `[${careVisibilityTagLabel.published}]으로 변경할 경우, 고객에게 즉시 설정 가능합니다. 정말 케어 유형 상태를 변경하시겠습니까?`,
  [visibility.SUSPENDED]: `[${careVisibilityTagLabel.suspended}]으로 변경할 경우, 고객에게 설정이 불가능합니다. 정말 케어 유형 상태를 변경하시겠습니까?`,
} as const

export const careError = {
  CARE_STATE_TRANSITION: '해당 케어유형의 상태를 전환할 수 없습니다.',
  CARE_UPDATE_FAILED: '케어유형 수정에 실패했습니다. 다시 시도해주세요.',
  CARE_DELETE_FAILED: '해당 케어유형을 삭제할 수 없습니다.',
  CARE_NAME_DUPLICATE: '이미 존재하는 이름입니다.',
  CARE_NOT_FOUND: '찾을 수 없습니다.',
} as const

export const careErrorMessage: CareErrorMessage = {
  [careError.CARE_STATE_TRANSITION]: {
    message: '변경 실패',
    desc: '상태 변경에 실패했습니다. 다시 시도해주세요.',
  },
  [careError.CARE_UPDATE_FAILED]: {
    message: '수정 실패',
    desc: '케어유형 수정에 실패했습니다. 다시 시도해주세요.',
  },
  [careError.CARE_DELETE_FAILED]: {
    message: '삭제 실패',
    desc: '케어유형 삭제에 실패했습니다. 다시 시도해주세요.',
  },
  [careError.CARE_NAME_DUPLICATE]: {
    message: '생성 실패',
    desc: '이미 해당 커리큘럼 유형에 등록된 제목입니다.',
  },
  [careError.CARE_NOT_FOUND]: {
    message: '요청 실패',
    desc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
  internalServerError: {
    message: '처리 실패',
    desc: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
  },
} as const

export const visibilityManipulationCareDefaultMessage: DefaultLabelOfConfrimFlowModal =
  {
    title: '상태 변경',
    successNotiMessage: '변경 완료',
    successNotiDesc: '성공적으로 케어유형 상태를 변경했습니다.',
    failNotiMessage: '요청 실패',
    failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
  } as const

export const deletingDefaultCareMessage: DefaultLabelOfConfrimFlowModal = {
  title: '항목 삭제',
  content:
    '케어유형을 삭제할 경우 다시 복구할 수 없습니다. 정말 케어유형을 삭제하시겠습니까?',
  successNotiMessage: '삭제 완료',
  successNotiDesc: '성공적으로 케어유형을 삭제했습니다.',
  failNotiMessage: '요청 실패',
  failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
} as const
