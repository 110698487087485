import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'

import { PAGES as AUTH_PAGES } from '@/constants/apps.auth'
import { PAGES as CUSTOMER_PAGES } from '@/constants/apps.customer'
import Section from '@/components/layout/section/Section'
import BasicButton from '@/components/buttons/BasicButton'
import style from './DashboardAuth.module.scss'
import dashboardAuth from '@/assets/dashboardAuth.svg'

const cx = classNames.bind(style)

const DashboardAuth: React.FC = () => {
  const history = useHistory()
  const onClickCustomerListBtn = () => {
    history.push(CUSTOMER_PAGES.customers.path)
  }
  const onClickAdmin = () => {
    history.push(AUTH_PAGES.users.path)
  }

  return (
    <Section className={cx('dashboard-auth')}>
      <div>
        <img alt="dashboard" src={dashboardAuth} />
      </div>
      <div className="dashboard-text">
        <span>안녕하세요,스킨스쿨 컨시어지입니다.</span>
      </div>
      <div className="dashboard-btn">
        <BasicButton
          label="고객 목록 메뉴 바로가기"
          onClick={onClickCustomerListBtn}
        />
        <BasicButton label="관리자 메뉴 바로가기" onClick={onClickAdmin} />
      </div>
    </Section>
  )
}

export default DashboardAuth
