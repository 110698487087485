import React, { useEffect } from 'react'

import _ from 'lodash'

import NotiModal, { NotiModalPropType } from '@/components/modal/NotiModal'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'

import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import { getAccountName as getCustomerAccountName } from '@/apps/customer/utils/helper'
import { getAccountName as getStaffAccountName } from '@/apps/auth/utils/helper'
import {
  useStaffAssignDeletion,
  useStaffAssignChanging,
  useStaffAssignCreation,
} from '@/apps/customer/hooks/useStaffAssignment'
import { serverErrorStatus } from '@/utils/http'

const assignActionTypes = {
  create: 'create',
  change: 'change',
  delete: 'delete',
}

export type AssignActionType = keyof typeof assignActionTypes

interface PropType extends Omit<NotiModalPropType, 'onOk'> {
  customer: Customer<Order, User> | null
  staff: User | null
  assignActionType: AssignActionType | null
  isToMe: boolean
  onFinish?: () => void
}

const AssignStaffModal: React.FC<PropType> = ({
  customer,
  staff,
  assignActionType,
  isToMe,
  onFinish,
  onCancel,
  ...props
}) => {
  const {
    data: creationData,
    error: creationError,
    isLoading: creationIsLoading,
    setPayload: creationSetPayload,
    setCustomerKey: creationSetCustomerKey,
  } = useStaffAssignCreation()

  const {
    data: changingData,
    error: changingError,
    isLoading: changingIsLoading,
    setPayload: changingSetPayload,
    setCustomerKey: changingSetCustomerKey,
  } = useStaffAssignChanging()

  const {
    data: deletionData,
    error: deletionError,
    isLoading: deletionIsLoading,
    setCustomerKey: deletionSetCustomerKey,
  } = useStaffAssignDeletion()

  const setPayloads = {
    [assignActionTypes.create]: creationSetPayload,
    [assignActionTypes.change]: changingSetPayload,
  }

  const setCustomerKeys = {
    [assignActionTypes.create]: creationSetCustomerKey,
    [assignActionTypes.change]: changingSetCustomerKey,
    [assignActionTypes.delete]: deletionSetCustomerKey,
  }

  const getCustomerText = () => {
    return getCustomerAccountName(customer)
  }

  const getStaffText = () => {
    return getStaffAccountName(staff)
  }

  const notiFailCustom: { [key: string]: string } = {
    notFound: '휴면 또는 탈퇴 상태 고객입니다.',
    alreadyMatched: '이미 배정 완료된 고객입니다.',
  }

  const modalTitles = {
    [assignActionTypes.create]: '상담사 배정',
    [assignActionTypes.change]: '상담사 배정',
    [assignActionTypes.delete]: '상담사 해제',
  }

  const modalContents = {
    [assignActionTypes.create]: `${getCustomerText()}를 ${getStaffText()}에게 배정하시겠습니까?`,
    [assignActionTypes.change]: `${getCustomerText()}를 ${getStaffText()}에게 배정하시겠습니까?`,
    [assignActionTypes.delete]: `${getCustomerText()}의 상담사를 해제하시겠습니까?`,
  }

  const notiSuccessMessages = {
    [assignActionTypes.create]: isToMe
      ? '새로운 고객 배정 완료'
      : '고객 배정 완료',
    [assignActionTypes.change]: isToMe
      ? '새로운 고객 배정 완료'
      : '고객 배정 완료',
    [assignActionTypes.delete]: '해제 완료',
  }

  const notiFailMessages = {
    [assignActionTypes.create]: '배정 실패',
    [assignActionTypes.change]: '배정 실패',
    [assignActionTypes.delete]: '처리 실패',
    inactive: '요청 실패',
    iternalServerError: '처리 실패',
    [notiFailCustom.notFound]: '요청 실패',
    [notiFailCustom.alreadyMatched]: '배정 실패',
  }

  const notiSuccessDesc = {
    [assignActionTypes.create]: isToMe
      ? `${getCustomerText()}를 담당 고객 목록에서 확인하세요.`
      : `${getCustomerText()}를 ${getStaffText()}에게 배정하였습니다.`,
    [assignActionTypes.change]: isToMe
      ? `${getCustomerText()}를 담당 고객 목록에서 확인하세요.`
      : `${getCustomerText()}를 ${getStaffText()}에게 배정하였습니다.`,
    [assignActionTypes.delete]: '담당 상담사를 해제 완료했습니다.',
  }

  const notiFailDesc = {
    [assignActionTypes.create]:
      '상담사 배정에 실패하였습니다. 다시 시도해주세요.',
    [assignActionTypes.change]:
      '상담사 배정에 실패하였습니다. 다시 시도해주세요.',
    [assignActionTypes.delete]:
      '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
    inactive: '유효하지 않은 요청입니다.',
    iternalServerError:
      '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
    [notiFailCustom.notFound]: '유효하지 않은 요청입니다.',
    [notiFailCustom.alreadyMatched]: '이미 상담사 배정이 완료되었습니다.',
  }

  const onOk = () => {
    if (assignActionType == null) return
    if (assignActionType === 'create' || assignActionType === 'change') {
      setPayloads[assignActionType]({
        username: staff?.username ?? '',
      })
    }
    setCustomerKeys[assignActionType](_.merge({}, { key: customer?.uid ?? '' }))
  }

  // creation
  useEffect(() => {
    if (creationIsLoading || !creationData) return

    notifySuccess({
      message: notiSuccessMessages[assignActionTypes.create],
      description: notiSuccessDesc[assignActionTypes.create],
    })
    !!onFinish && onFinish()
  }, [creationData, creationIsLoading])

  useEffect(() => {
    if (creationIsLoading || !creationError) return

    let notiCustomKey = serverErrorStatus.includes(
      creationError?.response?.status,
    )
      ? 'iternalServerError'
      : creationError?.response?.data?.detail

    if (
      creationError?.response?.status === 400 ||
      creationError?.response?.status === 403 ||
      creationError?.response?.status === 404
    ) {
      notiCustomKey = 'inactive'
    }

    notifyFailure({
      message:
        notiFailMessages[notiCustomKey] ??
        notiFailMessages[assignActionTypes.create],
      description:
        notiFailDesc[notiCustomKey] ?? notiFailDesc[assignActionTypes.create],
    })
    onCancel && onCancel()
  }, [creationError, creationIsLoading])

  // changing
  useEffect(() => {
    if (changingIsLoading || !changingData) return

    notifySuccess({
      message: notiSuccessMessages[assignActionTypes.change],
      description: notiSuccessDesc[assignActionTypes.change],
    })
    !!onFinish && onFinish()
  }, [changingData, changingIsLoading])

  useEffect(() => {
    if (changingIsLoading || !changingError) return

    notifyFailure({
      message: notiFailMessages[assignActionTypes.change],
      description: notiFailDesc[assignActionTypes.change],
    })
  }, [changingError, changingIsLoading])

  // deletion
  useEffect(() => {
    if (deletionIsLoading || !deletionData) return

    // deletionMutate()
    notifySuccess({
      message: notiSuccessMessages[assignActionTypes.delete],
      description: notiSuccessDesc[assignActionTypes.delete],
    })
    !!onFinish && onFinish()
  }, [deletionData, deletionIsLoading])

  useEffect(() => {
    if (deletionIsLoading || !deletionError) return

    if (serverErrorStatus.includes(deletionError?.response?.status)) {
      notifyFailure({
        message: notiFailMessages.iternalServerError,
        description: notiFailDesc.iternalServerError,
      })
    } else if (
      deletionError?.response?.status === 400 ||
      deletionError?.response?.status === 403 ||
      deletionError?.response?.status === 404
    ) {
      notifyFailure({
        message: notiFailMessages.inactive,
        description: notiFailDesc.inactive,
      })
    } else {
      notifyFailure({
        message: notiFailMessages[assignActionTypes.delete],
        description: notiFailDesc[assignActionTypes.delete],
      })
    }
    onCancel && onCancel()
  }, [deletionError, deletionIsLoading])

  return (
    <>
      {!!assignActionType && (
        <div data-testid={`assign-staff-modal-${assignActionType}`}>
          <NotiModal
            title={modalTitles[assignActionType]}
            content={modalContents[assignActionType]}
            onOk={onOk}
            onCancel={onCancel}
            {...props}
          />
        </div>
      )}
    </>
  )
}

export default AssignStaffModal
