import React from 'react'
import { Tabs } from 'antd'
import classNames from 'classnames/bind'

import styles from './Index.module.scss'

const cx = classNames.bind(styles)

const { TabPane } = Tabs

export interface PanelItemPropType {
  tab: string
  key: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps: any
}

interface PropType {
  panels: PanelItemPropType[]
  onChangeIndex: (key: string) => void
}

const IndexView: React.FC<PropType> = ({ panels, onChangeIndex }) => {
  return (
    <div className={cx('tabs-container')}>
      <Tabs tabPosition="right" tabBarGutter={0} onChange={onChangeIndex}>
        {panels.map((panel) => (
          <TabPane tab={panel.tab} key={panel.key}>
            {<panel.component {...panel.componentProps} />}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default IndexView
