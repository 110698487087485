import React, { useState, useCallback, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import useAuth from '@/apps/auth/hooks/useAuth'
import useLogin from '@/apps/auth/hooks/useLogin'
import { ReactComponent as ImageLogoText } from '@/assets/logotype.svg'
import { ReactComponent as ImageLogoSymbol } from '@/assets/symbol.svg'
import { getDefaultStorage } from '@/utils/storages'
import BasicButton from '@/components/buttons/BasicButton'
import { validateUsername, validatePassword } from '@/apps/auth/validators'
import { PAGES } from '@/constants/apps.auth'
import { REDIRECT_URL_AFTER_LOGIN } from '@/constants/common'
import { notifyFailure } from '@/components/antd/Notification'
import { serverErrorStatus } from '@/utils/http'

import './Login.scss'

const storage = getDefaultStorage()

const Login: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isVisibleRequiredInput, setIsVisibleRequiredInput] = useState({
    username: false,
    password: false,
  })
  const [isShowPasswordText, setIsShowPasswordText] = useState(false)
  const [isVisibleError, setIsVisibleError] = useState(false)
  const loginState = useLogin()
  const { setToken, isAuthenticated } = useAuth(storage)
  const history = useHistory()

  const validate = useCallback(() => {
    return validateUsername(username) && validatePassword(password)
  }, [username, password])

  const showUsernameRequirement = (isVisible: boolean) => {
    setIsVisibleRequiredInput({
      ...isVisibleRequiredInput,
      username: isVisible,
    })
  }

  const showPasswordRequirement = (isVisible: boolean) => {
    setIsVisibleRequiredInput({
      ...isVisibleRequiredInput,
      password: isVisible,
    })
  }

  const showInputError = (isVisible: boolean) => {
    setIsVisibleError(isVisible)
  }

  const togglePasswordVisibility = () => {
    setIsShowPasswordText(!isShowPasswordText)
  }

  const onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const username = value.trim()

    isVisibleError && showInputError(false)
    showUsernameRequirement(!username)
    setUsername(username)
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const password = value.trim()

    isVisibleError && showInputError(false)
    showPasswordRequirement(!password)
    setPassword(password)
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!validate()) {
        return showInputError(true)
      }

      loginState.authenticate({ username, password })
    },
    [username, password],
  )

  const loginErrorMessage = () => {
    if (loginState.error?.response?.status === 400) {
      return '로그인이 불가합니다.'
    }
    return 'ID, 비밀번호 입력오류입니다. 다시 시도해주세요.'
  }

  const loginErrorMapping = () => {
    if (serverErrorStatus.includes(loginState.error?.response?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생했습니다. 다시 시도해주세요.',
      })
    }
    return showInputError(true)
  }

  useEffect(() => {
    if (loginState.data && !loginState.error) {
      setToken(loginState.data.token)
      return history.push(REDIRECT_URL_AFTER_LOGIN, { from: location.pathname })
    }
    if (loginState.error) {
      loginErrorMapping()
    }
  }, [loginState.data, loginState.error])

  return (
    <div className="login-container" data-testid={PAGES.login.testId}>
      {isAuthenticated && (
        <Redirect
          to={{
            pathname: REDIRECT_URL_AFTER_LOGIN,
            state: { from: location.pathname },
          }}
        />
      )}
      <div className="title-container">
        <h2 className="page-title">
          <ImageLogoSymbol className="tw-h-6 tw-mr-2.5" fill="#222" />
          <ImageLogoText className="tw-h-5 tw-mr-4" fill="#000" />
          고객 컨시어지 센터
        </h2>
        <div className="tw-body-2 tw-text-center tw-mb-11">
          <p>
            스킨스쿨 피부 케어 대상 고객을 관리하는 상담사 전용 컨시어지
            서비스입니다.
            <br />
            담당 고객의 피부 케어 커리큘럼과 고객 정보, 상담 내용을
            관리해보세요.
          </p>
        </div>
      </div>

      <form className="login-container" onSubmit={onSubmit} data-testid="form">
        <div
          className={
            isVisibleRequiredInput.username
              ? 'error-form-container'
              : 'normal-form-container'
          }
        >
          <img src="/images/user.svg" alt="user" />
          <input
            data-testid="input-id"
            name="id"
            className="input"
            type="text"
            placeholder="ID"
            onChange={onChangeUsername}
            value={username}
          />
        </div>
        {isVisibleRequiredInput.username && (
          <span data-tesid="id-requirement" className="error-text">
            필수항목 입니다.
          </span>
        )}
        <div
          className={
            isVisibleRequiredInput.password
              ? 'error-form-container'
              : 'normal-form-container'
          }
        >
          <img src="/images/lock.svg" alt="lock" />
          <input
            data-testid="input-pw"
            name="password"
            className="input"
            type={isShowPasswordText ? 'text' : 'password'}
            placeholder="Password"
            onChange={onChangePassword}
            value={password}
          />
          <button
            data-testid="btn-eye"
            type="button"
            onClick={togglePasswordVisibility}
          >
            <img
              data-testid="img-eye"
              src={
                isShowPasswordText
                  ? '/images/eye.svg'
                  : '/images/eyeInVisible.svg'
              }
              alt="showPasswordText"
            />
          </button>
        </div>
        {isVisibleRequiredInput.password && (
          <span className="error-text">필수항목 입니다.</span>
        )}
        <BasicButton
          full
          bold
          size="large"
          label="Login"
          htmlType="submit"
          data-testid="button-submit"
        />
        {isVisibleError && (
          <div className="tw-mt-6 tw-text-center">
            <span className="error-text">{loginErrorMessage()}</span>
          </div>
        )}
      </form>
    </div>
  )
}

export default Login
