import { TransitionPromptHook } from 'history'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import { ChangeHistoryAction, HistoryHookMethod } from '@/@types/router'
import { stringifyParams } from '@/utils/http'

export interface ChangeHistoryReturnType<PT> {
  type: HistoryHookMethod
  payload?: unknown | undefined
  newParams?: PT | undefined
}

const changeHistory = <PT>(
  location: ReturnType<typeof useLocation>,
  action?: ChangeHistoryAction<PT>,
): ChangeHistoryReturnType<PT> | undefined => {
  const { type, data = {} } = action ?? {}
  switch (type) {
    case 'go':
      if (!_.isInteger(data)) return undefined
      return {
        type,
        payload: data,
      }

    case 'push':
    case 'replace':
      return {
        type,
        payload: _.isEmpty(data)
          ? location.pathname
          : _.isString(data)
          ? (data as string)
          : `${location.pathname}?${stringifyParams(data)}`,
      }

    case 'goBack':
    case 'goForward':
      return { type }

    case 'block':
      return {
        type,
        payload: data as boolean | string | TransitionPromptHook | undefined,
      }

    case 'setPage1':
      return {
        type: 'push',
        payload: `${location.pathname}?${stringifyParams({
          ...data,
          page: 1,
        })}`,
        newParams: { ...data, page: 1 } as unknown as PT,
      }
  }

  return undefined
}

export default changeHistory
