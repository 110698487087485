import add from 'date-fns/add'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'

export const getAccountName = (customer: Customer<Order, User> | null) => {
  return `${customer?.mallUserName} / ${customer?.mallMemberId}`
}

export const caculateClosedDateOnCurriculum = (opened: Date, period: number) =>
  add(new Date(opened), {
    days: period - 1,
  })
