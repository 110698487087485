import React, { useEffect } from 'react'

import NotiModal, { NotiModalPropType } from '@/components/modal/NotiModal'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'
import useTaskDeletion from '@/apps/task/hooks/useTaskDeletion'
import { Task } from '@/apps/task/@types/task'
import { serverErrorStatus } from '@/utils/http'

interface PropType extends Omit<NotiModalPropType, 'onOk'> {
  task: Task
  onFinish?: () => void
}

const DeleteTaskModal: React.FC<PropType> = ({ task, onFinish, ...props }) => {
  const { data, error, isLoading, setTaskKey } = useTaskDeletion()

  const onOk = () => {
    setTaskKey(task.id)
  }

  useEffect(() => {
    if (isLoading || !data) return

    notifySuccess({
      message: '삭제 완료',
      description: '성공적으로 업무를 삭제했습니다.',
    })
    !!onFinish && onFinish()
  }, [data, isLoading])

  useEffect(() => {
    if (isLoading || !error) return
    if (
      error?.response?.status === 400 ||
      error?.response?.status === 403 ||
      error?.response?.status === 404
    ) {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      })
    } else if (serverErrorStatus.includes(error?.response?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
      })
    } else {
      notifyFailure({
        message: '삭제 실패',
        description: '업무 삭제에 실패했습니다. 다시 시도해주세요.',
      })
    }
  }, [error, isLoading])

  return <NotiModal onOk={onOk} {...props} />
}

export default DeleteTaskModal
