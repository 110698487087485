export const URLS = {
  getCustomerOrderSummary: (key: string) =>
    `/cafe24/orders/customers-summary/${key}/`,
  getCustomerRepurchaseSummary: (key: string) =>
    `/cafe24/orders/repurchase-summary/${key}/`,
} as const

export const aggregationPeriods: Record<string, string> = {
  '7': '최근 7일',
  '14': '최근 14일',
  '30': '최근 1개월',
  '90': '최근 3개월',
} as const
