import React from 'react'
import classNames from 'classnames/bind'
import _ from 'lodash'
import { Pagination, Table, TableProps, Tag } from 'antd'

import { DataQueryPropType } from '@/@types/data-query'
import { getFilteredValue, getFilters, getOptions } from '@/utils/antd/table'
import { numbering } from '@/utils/ui/pagination'
import {
  careItemOrderingLabel,
  careItemVisibility,
  careItemVisibilityTagLabel,
  careItemVisibilityTagColor,
} from '@/constants/apps.careItem'
import {
  CareItem,
  CareItemListOrderingKey,
  CareItemListParam,
  CareItemVisibility,
} from '@/apps/careItem/@types/careItem'
import ActionMenuButton from '@/components/buttons/ActionMenuButton'
import { DeleteButton, EditButton } from '@/components/buttons/Icon'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import SpanText from '@/components/typography/SpanText'
import DateText from '@/components/typography/DateText'
import styles from './CareItemList.View.module.scss'

export interface PropType
  extends DataQueryPropType<
    CareItemListParam,
    CareItem,
    CareItemListOrderingKey
  > {
  disabledAdd: boolean
  disabledDelete: boolean
  disabledManipulationVisibility: boolean
  isReadOnly: boolean
  onChangeTable?: TableProps<CareItem>['onChange']
  onClickAddCareItem?: () => void
  onClickEditCareItem: (item: CareItem) => void
  onClickDeleteCareItem: (item: CareItem) => void
  onClickVisibility: (item: CareItem, newVisibility: CareItemVisibility) => void
}

const CareItemListView: React.FC<PropType> = ({
  data,
  page,
  limit,
  count,
  disabledAdd,
  disabledDelete,
  disabledManipulationVisibility,
  isReadOnly,
  isLoading,
  params,
  orderingKey,
  orderingIsDesc,
  onChangeParam,
  onChangePage,
  onChangeTable,
  onClickAddCareItem,
  onClickEditCareItem,
  onClickDeleteCareItem,
  onClickVisibility,
}) => {
  return (
    <Section className={cx('care-item-list')}>
      <SectionTitle title="케어 아이템 목록" />

      <div className={cx('query-container')}>
        <QuerySection<CareItemListParam, CareItemListOrderingKey>
          wrapperName="careItemList"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="케어 아이템 검색"
          defaultOrderingIsDesc={orderingIsDesc}
        >
          <BasicButton
            className="tw-ml-auto"
            label="신규 아이템 생성"
            icon="plus"
            disabled={isReadOnly || disabledAdd}
            data-testid="care-item-list/btn-add"
            onClick={onClickAddCareItem}
          />
        </QuerySection>
      </div>

      <Table<CareItem>
        dataSource={data}
        rowKey="id"
        pagination={false}
        onChange={onChangeTable}
      >
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column title="케어 아이템" width={280} dataIndex="name" />

        <Column
          className={cx('description')}
          title="설명"
          width={640}
          dataIndex="description"
          render={(value) => <SpanText text={value} />}
        />

        <Column
          title="갱신 일자"
          dataIndex="updatedAt"
          render={(value) => <DateText date={value} />}
        />

        <Column<CareItem>
          title="상태"
          dataIndex="visibility"
          filterMultiple={true}
          filters={getFilters(careItemVisibilityTagLabel)}
          filteredValue={getFilteredValue(params.visibility)}
          render={(__, record) => (
            <VisibilityTag
              careItem={record}
              disabled={isReadOnly || disabledManipulationVisibility}
              onClick={onClickVisibility}
            />
          )}
        />

        <Column<CareItem>
          title="수정"
          render={(__, record) => (
            <ButtonEdit
              careItem={record}
              disabled={isReadOnly || getDisabledEdit(record)}
              onClick={onClickEditCareItem}
            />
          )}
        />

        <Column<CareItem>
          title="삭제"
          render={(__, record) => (
            <ButtonDelete
              careItem={record}
              disabled={
                isReadOnly || disabledDelete || getDisabledDelete(record)
              }
              onClick={onClickDeleteCareItem}
            />
          )}
        />
      </Table>

      {!!count && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page ?? 1}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

export default CareItemListView

const { Column } = Table

const cx = classNames.bind(styles)

const disableVisibilityValues = {
  [careItemVisibility.UNPUBLISHED]: [
    careItemVisibility.UNPUBLISHED,
    careItemVisibility.SUSPENDED,
  ],
  [careItemVisibility.PUBLISHED]: [
    careItemVisibility.PUBLISHED,
    careItemVisibility.UNPUBLISHED,
  ],
  [careItemVisibility.SUSPENDED]: [
    careItemVisibility.SUSPENDED,
    careItemVisibility.UNPUBLISHED,
  ],
}

export const orderOptions = getOptions(careItemOrderingLabel)

const isUsed = (careItem: CareItem) =>
  careItem.useCountOnProgress >= 1 || careItem.useCountReserved >= 1

const getDisabledEdit = (careItem: CareItem) => isUsed(careItem)

const getDisabledDelete = (careItem: CareItem) => isUsed(careItem)

interface VisibilityTagPropType {
  careItem: CareItem
  disabled: boolean
  onClick: (item: CareItem, newVisibility: CareItemVisibility) => void
}

const VisibilityTag: React.FC<VisibilityTagPropType> = ({
  careItem,
  disabled,
  onClick,
}) => {
  const { visibility } = careItem
  return (
    <ActionMenuButton<CareItem, CareItemVisibility>
      disabled={disabled}
      item={careItem}
      labels={careItemVisibilityTagLabel}
      value={visibility}
      values={_.map(careItemVisibility)}
      disableValues={disableVisibilityValues}
      onChangeValue={onClick}
    >
      <Tag color={careItemVisibilityTagColor[visibility]}>
        {careItemVisibilityTagLabel[visibility]}
      </Tag>
    </ActionMenuButton>
  )
}

interface ManipulationPropType {
  careItem: CareItem
  disabled: boolean
  onClick: (care: CareItem) => void
}

const ButtonEdit: React.FC<ManipulationPropType> = ({
  careItem,
  disabled,
  onClick,
}) => {
  return (
    <EditButton
      disabled={disabled}
      onClick={() => onClick(careItem)}
      data-testid={`care-item-list/btn-edit-${careItem.id}`}
    />
  )
}

const ButtonDelete: React.FC<ManipulationPropType> = ({
  careItem,
  disabled,
  onClick,
}) => {
  return (
    <DeleteButton
      disabled={disabled}
      onClick={() => onClick(careItem)}
      data-testid={`care-item-list/btn-delete-${careItem.id}`}
    />
  )
}
