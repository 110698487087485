import { useState } from 'react'
import useSWR from 'swr'

import { Nil } from '@/@types/composite'
import { patch } from '@/clients/http/authorized-http-client'
import { manipulationUrl } from '@/constants/apps.careItem'
import { Key } from '@/utils/swr/helpers'
import {
  CareItem,
  CareItemVisibilityManipulation,
} from '@/apps/careItem/@types/careItem'

interface CareItemVisibilityState {
  manipulation: CareItemVisibilityManipulation | Nil
  key: Key
}

const getManipulationUrl = (
  key: Key,
  manipulation: CareItemVisibilityManipulation,
) => {
  if (!key) return
  return manipulationUrl[manipulation](key as number)
}

const patchRequest = (url: string | null) => {
  if (!url) return
  return patch<Nil, CareItem>(url, null)
}

export const useCareItemVisibility = () => {
  const [{ manipulation, key }, setState] = useState<CareItemVisibilityState>({
    manipulation: null,
    key: null,
  })
  const { data, error, isValidating, mutate } = useSWR(
    [manipulation ? getManipulationUrl(key, manipulation) : null, key],
    patchRequest,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !!key && !!manipulation && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    mutate,
    setState,
  }
}
