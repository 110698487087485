import React, { useEffect } from 'react'
import { Nil } from '@/@types/composite'
import NotiModal, { NotiModalPropType } from '@/components/modal/NotiModal'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'

interface PropType<DT, ET> extends Omit<NotiModalPropType, 'onOk' | 'loading'> {
  notiKey?: string
  successNotiMessage?: string
  successNotiDesc?: string
  failNotiMessage?: string
  failNotiDesc?: string
  response?: DT | Nil
  error?: ET | Nil
  isFinishOnFail?: boolean
  isLoadingRequest?: boolean
  action?: () => void
  onFinish?: () => void
}

const ConfirmFlowModal = <DT, ET>(
  props: React.PropsWithChildren<PropType<DT, ET>>,
) => {
  const {
    notiKey,
    visible,
    successNotiMessage = '',
    successNotiDesc = '',
    failNotiMessage = '',
    failNotiDesc = '',
    response,
    error,
    isFinishOnFail = false,
    isLoadingRequest,
    action,
    onFinish,
  } = props

  const onOk = () => {
    action?.()
  }

  useEffect(() => {
    if (!visible) return
    if (isLoadingRequest || !response) return

    notifySuccess({
      key: `${notiKey}success`,
      message: successNotiMessage,
      description: successNotiDesc,
    })
    onFinish?.()
  }, [response, isLoadingRequest])

  useEffect(() => {
    if (!visible) return
    if (isLoadingRequest || !error) return

    notifyFailure({
      key: `${notiKey}fail`,
      message: failNotiMessage,
      description: failNotiDesc,
    })
    isFinishOnFail && onFinish?.()
  }, [error, isLoadingRequest])

  return (
    <>
      <div data-testid="confirm-modal">
        <NotiModal onOk={onOk} loading={isLoadingRequest} {...props} />
      </div>
    </>
  )
}

export default ConfirmFlowModal

export type DefaultLabelOfConfrimFlowModal = Pick<
  PropType<never, never>,
  | 'title'
  | 'content'
  | 'successNotiMessage'
  | 'successNotiDesc'
  | 'failNotiMessage'
  | 'failNotiDesc'
>
