import { CareItemErrorMessage } from '@/apps/careItem/@types/careItem'
import { visibility, visibilityManipulation } from '@/constants/common'
import {
  careVisibilityTagColor,
  careVisibilityTagLabel,
} from '@/constants/apps.care'
import { DefaultLabelOfConfrimFlowModal } from '@/components/modal/ConfrimFlowModal'

export const URLS = {
  careItemList: '/curriculums/care-items/',
  careItemDetail: (key: number) => `/curriculums/care-items/${key}/`,
  publishCareItem: (key: number) => `/curriculums/care-items/${key}/publish/`,
  suspendCareItem: (key: number) => `/curriculums/care-items/${key}/suspend/`,
  resumeCareItem: (key: number) => `/curriculums/care-items/${key}/resume/`,
}

export const PAGES = {
  careDetail: {
    name: '케어 유형 및 아이템관리, 케어 유형 상세페이지',
    title: '케어 유형 및 아이템 관리',
    requiredAuth: true,
    exact: true,
    path: '/cares/:id',
    testId: 'care-detail-page',
  },
} as const

export const careItemOrderingLabel = {
  updated_at: '갱신 일자',
  name: '케어 아이템',
} as const

export const careItemVisibility = visibility

export const careItemVisibilityTagLabel = careVisibilityTagLabel

export const careItemVisibilityTagColor = careVisibilityTagColor

export const careItemVisibilityManipulation = visibilityManipulation

export const manipulationUrl = {
  [careItemVisibilityManipulation.PUBLISHED]: URLS.publishCareItem,
  [careItemVisibilityManipulation.SUSPENDED]: URLS.suspendCareItem,
  [careItemVisibilityManipulation.RESUME]: URLS.resumeCareItem,
} as const

export const confirmTextUpdatingCareItemVisibility = {
  [visibility.UNPUBLISHED]: `[${careItemVisibilityTagLabel.unpublished}으로 변경할 수 없습니다.]`,
  [visibility.PUBLISHED]: `[${careItemVisibilityTagLabel.published}]으로 변경할 경우, 고객에게 즉시 설정 가능합니다. 정말 케어 아이템 상태를 변경하시겠습니까?`,
  [visibility.SUSPENDED]: `[${careItemVisibilityTagLabel.suspended}]으로 변경할 경우, 고객에게 설정이 불가능합니다. 정말 케어 아이템 상태를 변경하시겠습니까?`,
} as const

export const careItemError = {
  CARE_ITEM_STATE_TRANSITION: '해당 케어 아이템의 상태를 전환할 수 없습니다.',
  CARE_ITEM_UPDATE_FAILED:
    '케어 아이템 수정에 실패했습니다. 다시 시도해주세요.',
  CARE_ITEM_NAME_DUPLICATE: '이미 존재하는 이름입니다.',
  CARE_ITEM_DELETE_FAILED: '해당 케어 아이템을 삭제할 수 없습니다.',
} as const

export const careItemErrorMessage: CareItemErrorMessage = {
  [careItemError.CARE_ITEM_STATE_TRANSITION]: {
    message: '처리 실패',
    desc: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
  },
  [careItemError.CARE_ITEM_UPDATE_FAILED]: {
    message: '수정 실패',
    desc: '케어아이템 수정에 실패했습니다. 다시 시도해주세요.',
  },
  [careItemError.CARE_ITEM_DELETE_FAILED]: {
    message: '삭제 실패',
    desc: '케어아이템 삭제에 실패했습니다. 다시 시도해주세요.',
  },
  [careItemError.CARE_ITEM_NAME_DUPLICATE]: {
    message: '저장 실패',
    desc: '이미 사용 중인 제목입니다.',
  },
  inactive: {
    message: '요청 실패 ',
    desc: ' 유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
} as const

export const visibilityManipulationDefaultMessage: DefaultLabelOfConfrimFlowModal =
  {
    title: '상태 변경',
    successNotiMessage: '변경 완료',
    successNotiDesc: '성공적으로 케어아이템 상태를 변경했습니다.',
    failNotiMessage: '요청 실패',
    failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
  } as const

export const deletingDefaultMessage: DefaultLabelOfConfrimFlowModal = {
  title: '항목 삭제',
  content:
    '케어아이템을 삭제할 경우 다시 복구할 수 없습니다. 정말 케어아이템을 삭제하시겠습니까?',
  successNotiMessage: '삭제 완료',
  successNotiDesc: '성공적으로 케어아이템을 삭제했습니다.',
  failNotiMessage: '요청 실패',
  failNotiDesc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요',
} as const

// curriculum care item
export const careItemReptitionKind = {
  ONE_TIME: 'one-time',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  WEEK_DAYS: 'week-days',
  INFINITELY: 'infinitely',
  DAYS: 'days',
} as const
