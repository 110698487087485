import { CurriculumErrorMessage } from '@/apps/curriculum/@types/curriculum'
import {
  visibility,
  visibilityManipulation,
  visibilityTagColor,
  visibilityTagLabel,
} from '@/constants/common'

export const URLS = {
  curriculumList: '/curriculums/curriculums/',
  curriculumDetail: (key: number | string) =>
    `/curriculums/curriculums/${key}/`,
  publishCurriculum: (key: number) =>
    `/curriculums/curriculums/${key}/publish/`,
  suspendCurriculum: (key: number) =>
    `/curriculums/curriculums/${key}/suspend/`,
  resumeCurriculum: (key: number) => `/curriculums/curriculums/${key}/resume/`,
  curriculumCareList: '/curriculums/curriculum-cares/',
  curriculumCareDetail: (key: number) =>
    `/curriculums/curriculum-cares/${key}/`,
  curriculumCareItemList: '/curriculums/curriculum-care-items/',
  curriculumCareItemDetail: (key: number) =>
    `/curriculums/curriculum-care-items/${key}/`,
  diariesMonthy: '/curriculums/diaries/@customer-monthly-diaries/',
  diariesAverage: (uid: string) =>
    `/curriculums/diaries/${uid}/active-average/`,
  diaryDetail: (uid: string) => `/curriculums/diaries/${uid}/`,
} as const

export const PAGES = {
  curriculums: {
    name: '관리자 메뉴',
    title: '커리큘럼 관리',
    requiredAuth: true,
    exact: true,
    path: '/curriculums',
    testId: 'curriculum-list-page',
  },
  curriculumDetail: {
    name: '커리큘럼 관리, 커리큘럼 상세페이지',
    title: '커리큘럼 관리',
    requiredAuth: true,
    exact: true,
    path: '/curriculums/:id',
    testId: 'curriculum-detail-page',
  },
} as const

export const curriculumOrderingLabel = {
  updated_at: '갱신 일자',
  name: '커리큘럼 제목',
} as const

export const curriculumCompilationKind = {
  REGULAR: 'regular',
  AUTONOMOUS: 'autonomous',
  DEFAULT: 'default',
} as const

export const curriculumCompilationKindLabel = {
  [curriculumCompilationKind.REGULAR]: '정규 커리큘럼',
  [curriculumCompilationKind.AUTONOMOUS]: '자율 커리큘럼',
  [curriculumCompilationKind.DEFAULT]: '기본 커리큘럼',
} as const

export const curriculumVisibility = visibility

export const curriculumVisibilityTagLabel = visibilityTagLabel

export const curriculumVisibilityTagColor = visibilityTagColor

export const confirmTextUpdatingVisibility = {
  [visibility.UNPUBLISHED]: `[${curriculumVisibilityTagLabel.unpublished}으로 변경할 수 없습니다.]`,
  [visibility.PUBLISHED]: `[${curriculumVisibilityTagLabel.published}]으로 변경할 경우, 고객에게 즉시 설정 가능합니다. 정말 커리큘럼 상태를 변경하시겠습니까?`,
  [visibility.SUSPENDED]: `[${curriculumVisibilityTagLabel.suspended}]으로 변경할 경우, 고객에게 설정이 불가능합니다. 정말 커리큘럼 상태를 변경하시겠습니까?`,
} as const

export const curriculumError = {
  CURRICULUM_STATE_TRANSITION: '해당 커리큘럼의 상태를 전환할 수 없습니다.',
  CURRICULUM_UPDATE_FAILED: '커리큘럼 수정에 실패했습니다. 다시 시도해주세요.',
  CURRICULUM_NAME_DUPLICATE: '이미 존재하는 정규 커리큘럼명입니다.',
  CURRICULUM_DELETE_FAILED: '해당 커리큘럼을 삭제할 수 없습니다.',
  CURRICULUM_INACTIVE: '찾을 수 없습니다.',
  CURRICULUM_CAREITEM:
    '해당 커리큘럼에 대한 필수 케어유형의 케어아이템이 필요합니다.',
  DUPLICATE: '이미 존재하는 이름입니다.',
  NOLONGERUSE: '사용 중단 상태입니다',
} as const

export const curriculumErrorMessage: CurriculumErrorMessage = {
  [curriculumError.CURRICULUM_STATE_TRANSITION]: {
    message: '변경 실패',
    desc: '상태 변경에 실패했습니다. 다시 시도해주세요.',
  },
  [curriculumError.CURRICULUM_UPDATE_FAILED]: {
    message: '수정 실패',
    desc: '커리큘럼 수정에 실패했습니다. 다시 시도해주세요.',
  },
  [curriculumError.CURRICULUM_DELETE_FAILED]: {
    message: '삭제 실패',
    desc: '커리큘럼 삭제에 실패했습니다. 다시 시도해주세요.',
  },
  [curriculumError.CURRICULUM_NAME_DUPLICATE]: {
    message: '저장 실패',
    desc: '이미 사용 중입니다',
  },
  [curriculumError.DUPLICATE]: {
    message: '저장 실패',
    desc: '이미 사용 중입니다',
  },
  [curriculumError.CURRICULUM_INACTIVE]: {
    message: '요청 실패 ',
    desc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
  [curriculumError.CURRICULUM_CAREITEM]: {
    message: '케어 아이템 누락 ',
    desc: '케어 아이템이 누락된 케어 유형이 있습니다. 케어 아이템을 추가한 후 다시 시도하세요.',
  },
  [curriculumError.NOLONGERUSE]: {
    message: '요청 실패 ',
    desc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
  inactive: {
    message: '요청 실패 ',
    desc: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
  internalServerError: {
    message: '처리 실패',
    desc: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
  },
} as const

export const curriculumVisibilityManipulation = visibilityManipulation

export const manipulationUrl = {
  [curriculumVisibilityManipulation.PUBLISHED]: URLS.publishCurriculum,
  [curriculumVisibilityManipulation.SUSPENDED]: URLS.suspendCurriculum,
  [curriculumVisibilityManipulation.RESUME]: URLS.resumeCurriculum,
} as const

export const curriculumCareListOrderingKeys = ['name', 'sequence'] as const
export const customerCurriculumProgressStatus = {
  NORMAL: 'normal',
  NEW: 'new',
  PROCEEDING: 'proceeding',
} as const

export const customerCurriculumProgressLabel = {
  [customerCurriculumProgressStatus.NORMAL]: '일반',
  [customerCurriculumProgressStatus.NEW]: '신규',
  [customerCurriculumProgressStatus.PROCEEDING]: '진행 중',
} as const
