import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { orderingParamKey } from '@/constants/common'
import { PAGES } from '@/constants/apps.customer'
import { resolvePath } from '@/utils/url'
import { buildOrderingQueryString } from '@/utils/http'
import { buildTableChangeFilter } from '@/utils/antd/table'
import useLocationParams from '@/hooks/useLocationParams'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import {
  Customer,
  CustomerListParam,
  CustomerListOrderingKey,
} from '@/apps/customer/@types/customer'
import useCustomerList from '@/apps/customer/hooks/useCustomerList'
import useCustomerKindList from '@/apps/customer/hooks/useCustomerKindList'
import CustomerListView from '@/apps/customer/pages/customer-list/CustomerList.View'

const defaultPage = 1
const defaultLimit = 10

export const defaultOrderingKey: CustomerListOrderingKey = 'mall_synced_at'

export const defaultIsDesc = true

const defaultListParams: CustomerListParam = {
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CustomerListOrderingKey,
}

type FullCustomer = Customer<Order, User>

const paramKeys: Array<keyof CustomerListParam> = [
  'customerSupport',
  'kind',
  'query',
  'page',
  'limit',
  'ordering',
  'isAssigned',
  'noMallSynced',
]

const CustomerList: React.FC = () => {
  const history = useHistory()
  const customers = useCustomerList()
  const customerKinds = useCustomerKindList()
  const locParams = useLocationParams<
    CustomerListParam,
    CustomerListOrderingKey
  >(paramKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    onSetParams: customers.setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['kind', 'customerSupport'],
    tryPreviousPage: true,
    paginatedData: customers.data,
    httpError: customers.error,
  })

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeShowUnassign = (value: boolean) => {
    const showUnassign = value ? (!value).toString() : null
    locParams.setItem('isAssigned', showUnassign, { type: 'setPage1' })
  }

  const onClickLinkMemberId = (customer: FullCustomer) => {
    const path = resolvePath<FullCustomer>({
      path: PAGES.customerDetail.path,
      params: customer,
    })
    history.push(path)
  }

  const onFinishSyncCafe24 = async () => {
    await customers.mutate()
  }

  const onFinishAssignSelf = async () => {
    await customers.mutate()
  }

  const onChangeFilter = buildTableChangeFilter<FullCustomer>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onChangeParam = (
    key: keyof CustomerListParam,
    value: ItemValue<CustomerListParam>,
    historyAction?: ChangeHistoryAction<CustomerListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeShowOnlyNoMallSynced = (value: boolean) => {
    const showNoMallSynced = value ? value.toString() : null
    locParams.setItem('noMallSynced', showNoMallSynced, { type: 'setPage1' })
  }

  const orderingKey = locParams.fullParams.orderingKey as
    | CustomerListOrderingKey
    | undefined

  return (
    <CustomerListView
      isLoading={customers.isLoading}
      data={customers.data?.results ?? []}
      customerKinds={customerKinds.data?.results ?? []}
      params={locParams.params}
      orderingKey={orderingKey}
      orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
      onChangeParam={onChangeParam}
      onChangeShowUnassign={onChangeShowUnassign}
      onClickLinkMemberId={onClickLinkMemberId}
      onFinishSyncCafe24={onFinishSyncCafe24}
      onFinishAssignSelf={onFinishAssignSelf}
      onChangeFilter={onChangeFilter}
      onChangeShowOnlyNoMallSynced={onChangeShowOnlyNoMallSynced}
      page={locParams.params.page ?? defaultPage}
      limit={locParams.params.limit ?? defaultLimit}
      count={customers.data?.count ?? 0}
      onChangePage={onChangePage}
    />
  )
}

export default CustomerList
