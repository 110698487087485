import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { ConfigProvider, Table, TableProps } from 'antd'
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { DataQueryPropType } from '@/@types/data-query'
import { numbering } from '@/utils/ui/pagination'
import { itemToItems } from '@/utils/collections'
import { getFilteredValue, getFilters } from '@/utils/antd/table'

import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import TaskProgressTabs from '@/components/navigation/TaskProgressTabs'
import {
  CustomerSupportInfo,
  MallMemberId,
  UserName,
} from '@/components/typography/customer'
import SpanText from '@/components/typography/SpanText'
import PercentText from '@/components/typography/PercentText'
import TaskProgressBar from '@/components/navigation/TaskProgressBar'

import { ActiveProgress, Task, TaskKind } from '@/apps/task/@types/task'
import { todayCounselTaskOrderingLabel } from '@/constants/apps.dashboard'
import {
  progresses as allProgresses,
  taskKindCategorySortPriority,
} from '@/constants/apps.task'
import {
  StaffDailyTaskStatus,
  StaffDailyTaskStatusOrderingKey,
  StaffDailyTaskStatusParam,
  CountByTaskProgressType,
} from '@/apps/dashboard/@types/staff-daily-tasks'
import { Customer, SimpleCustomer } from '@/apps/customer/@types/customer'
import { customerSupportFilters } from '@/apps/customer/pages/customer-list/CustomerList.View'
import LinkChannelTalkButton from '@/apps/customer/components/link-channeltalk-button/LinkChannelTalkButton'
import IssuedTaskTable from '@/apps/task/components/IssuedTaskTable'

import styles from './TodayCounselTaskList.module.scss'
import { CustomerCurriculumProgressStatus } from '@/apps/curriculum/@types/curriculum'
import { customerCurriculumProgressLabel } from '@/constants/apps.curriculum'

const cx = classNames.bind(styles)

export const orderOptions = _.chain(todayCounselTaskOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const { Column } = Table

interface PropType
  extends DataQueryPropType<
    StaffDailyTaskStatusParam,
    StaffDailyTaskStatus,
    StaffDailyTaskStatusOrderingKey
  > {
  isInChargeOf: boolean
  progresses?: ActiveProgress[]
  defaultTabKey?: ActiveProgress
  taskKinds: TaskKind[]
  countsByProgress: CountByTaskProgressType
  onChangeTab?: (tab: ActiveProgress) => void
  onChangeFilter: TableProps<StaffDailyTaskStatus>['onChange']
  onChangeTaskProgress?: (item: Task) => void
  onDeleteTask?: (item: Task) => void
}

const TodayCounselTaskListView: React.FC<PropType> = ({
  page,
  limit,
  data,
  params,
  onChangeParam,
  orderingKey,
  orderingIsDesc,
  onChangeFilter,
  isInChargeOf,
  progresses = [allProgresses.NEW, allProgresses.SENT, allProgresses.DONE],
  defaultTabKey,
  taskKinds,
  countsByProgress,
  onChangeTab,
  onChangeTaskProgress,
  onDeleteTask,
}) => {
  const filteredCustomerSupport = itemToItems<string>(params.customerSupport)

  return (
    <Section className={cx('container')}>
      <SectionTitle title="오늘의 상담 업무" />
      <TaskProgressTabs
        onChangeTab={onChangeTab}
        defaultTabKey={defaultTabKey}
        titles={countsByProgress}
      />

      <div className="tw-flex tw-justify-between tw-items-center tw-pb-4 tw-mt-5">
        <QuerySection<
          StaffDailyTaskStatusParam,
          StaffDailyTaskStatusOrderingKey
        >
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="업무 내용 검색"
          defaultOrderingIsDesc={orderingIsDesc}
          showSearch={true}
        />
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <div className={cx('empty-container')}>
            <span>상담 업무가 없습니다</span>
          </div>
        )}
      >
        <Table<StaffDailyTaskStatus>
          dataSource={data}
          rowKey="uid"
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <IssuedTaskTable
                data-testid={`embeded-table-${record.uid}`}
                data={sortTasks(record.tasks, taskKinds)}
                isInChargeOf={isInChargeOf}
                progresses={progresses}
                taskKinds={taskKinds}
                page={page}
                limit={limit}
                onChangeTask={onChangeTaskProgress}
                onDeleteTask={onDeleteTask}
                pagination={false}
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <ArrowDownOutlined
                  data-testid={`collapse-row-${record.uid}`}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <ArrowRightOutlined
                  data-testid={`expand-row-${record.uid}`}
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          onChange={onChangeFilter}
        >
          <Column
            title="번호"
            render={(_value, _record, index) => numbering(page, limit, index)}
          />

          <Column<SimpleCustomer>
            title="고객명"
            render={(_value, _record) => <UserName data={_record} />}
          />

          <Column<SimpleCustomer>
            title="아이디"
            dataIndex="mallMemberId"
            render={(value, _record) => <MallMemberId data={_record} />}
          />

          <Column
            title="커리큘럼 진행 상태"
            dataIndex="customerProgressStatus"
            filteredValue={getFilteredValue(params.customerProgressStatus)}
            filters={getFilters(customerCurriculumProgressLabel)}
            render={(_value) => <CurriculumProgressStatus status={_value} />}
          />

          <Column
            title="어제 진척도"
            dataIndex="diaryScore"
            render={(_value) => <PercentText value={_value} alterText="-" />}
          />

          <Column<Customer>
            title="재주문 상담"
            dataIndex="customerSupport"
            render={(_value, _record) => <CustomerSupportInfo data={_record} />}
            filteredValue={filteredCustomerSupport}
            filters={customerSupportFilters}
          />

          <Column<StaffDailyTaskStatus>
            title="업무 진행 요약"
            dataIndex="tasks"
            render={(_value) => <TaskProgressBar tasks={_value} />}
          />

          <Column<StaffDailyTaskStatus>
            title="업무 진행도"
            dataIndex="tasks"
            render={(_value) => <TaskProgressStatus tasks={_value} />}
          />

          <Column<StaffDailyTaskStatus>
            title="채널톡 바로가기"
            render={(_value, _record) => (
              <LinkChannelTalkButton
                chatService={_record.chatService}
                chatUid={_record.chatUid}
                uid={_record.uid}
              />
            )}
          />
        </Table>
      </ConfigProvider>
    </Section>
  )
}

export interface TaskProgressStatusPropType {
  tasks: StaffDailyTaskStatus['tasks']
}

const TaskProgressStatus: React.FC<TaskProgressStatusPropType> = ({
  tasks,
}) => {
  if (!tasks.length) return <span>-</span>

  const onProgressTasks = tasks.filter((o) => o.progress === allProgresses.DONE)
  return (
    <span>
      {onProgressTasks.length} / {tasks.length}
    </span>
  )
}

const sortTasks = (tasks: Task[], kinds: TaskKind[]): Task[] => {
  const priorities = taskKindCategorySortPriority
  return tasks.sort((task1, task2) => {
    const left1 = priorities.findIndex(
      (o) => o === kinds.find((kind) => kind.id === task1.kind)?.category,
    )
    const right1 = priorities.findIndex(
      (o) => o === kinds.find((kind) => kind.id === task2.kind)?.category,
    )
    if (left1 !== right1) return left1 - right1
    return task1.content.charCodeAt(0) - task2.content.charCodeAt(0)
  })
}

const CurriculumProgressStatus: React.FC<{
  status: CustomerCurriculumProgressStatus
}> = ({ status }) => {
  const statusLabel = customerCurriculumProgressLabel[status]
  return <SpanText text={statusLabel} alterText="-" />
}
export default TodayCounselTaskListView
