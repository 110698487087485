import _ from 'lodash'

export const isSameArray = (array1: unknown[], array2: unknown[]) =>
  _.isEmpty(_.xor(array1, array2))

export const itemToItems = <T>(
  item: T | T[] | unknown | unknown[],
  empty: undefined | T[] = undefined,
): T[] | undefined => {
  // eslint-disable-next-line prettier/prettier
  return !item ? empty : _.isArray(item) ? item : [item]
}

export const buildMapProportions = <PT extends number | string | symbol, IT>(
  parts: PT[],
  items: IT[],
  itemPath: number | string | symbol,
) => {
  return _.chain(parts)
    .map((key) => [
      key,
      items.filter((o) => _.get(o, itemPath) === key).length / items.length,
    ])
    .thru((value) => {
      return [...value, ['highest', _.maxBy(value, 1)]]
    })
    .fromPairs()
    .value() as Record<PT, number> & Record<'highest', [PT, number]>
}

export const hasKeyWithValue = <T = Record<string | number | symbol, unknown>>(
  collections: T,
  keys: Array<string | number | symbol>,
): boolean =>
  _.isEmpty(keys)
    ? true
    : _.chain(collections)
        .keys()
        .filter((o) => keys.includes(o))
        .value().length !== 0
