import { TaskListParam } from '@/apps/task/@types/task'

export const paramKeys: Array<keyof TaskListParam> = [
  'isScheduled',
  'activeAt',
  'progress',
  'query',
  'customer',
  'user',
  'ordering',
  'page',
  'limit',
]
