import { useState } from 'react'
import useSWR from 'swr'

import { Nil, NullableString } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.order'
import {
  CustomerRepurchaseSummary,
  CustomerRepurchaseSummaryParam,
} from '@/apps/order/@types/order'

const fetcher = (
  url: NullableString,
  params: CustomerRepurchaseSummaryParam,
) => {
  if (!url) return
  return get<CustomerRepurchaseSummaryParam, CustomerRepurchaseSummary>(
    url,
    params,
  )
}

const useCustomerRepurchaseSummary = (
  uid: Nil | string,
  defaultPeriod: string,
) => {
  const [params, setParams] = useState<CustomerRepurchaseSummaryParam>({
    days: defaultPeriod,
  })
  const { data, error, isValidating, mutate } = useSWR(
    uid ? [URLS.getCustomerRepurchaseSummary(uid), params] : null,
    fetcher,
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
    params,
    setParams,
  }
}

export default useCustomerRepurchaseSummary
