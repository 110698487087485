import React, { useState } from 'react'
import _ from 'lodash'

import { orderingParamKey } from '@/constants/common'
import { progresses as allProgresses } from '@/constants/apps.task'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import useLocationParams from '@/hooks/useLocationParams'
import { Nil } from '@/@types/composite'
import { paramKeys } from '@/apps/task/constants'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import {
  ActiveProgress,
  TaskListOrderingKey,
  TaskListParam,
} from '@/apps/task/@types/task'
import useTaskList from '@/apps/task/hooks/useTaskList'
import useTaskKindList from '@/apps/task/hooks/useTaskKindList'

import IssuedTaskListView from './IssuedTaskList.View'

const defaultPage = 1

const defaultLimit = 200

export const defaultOrderingKey = 'kind_name'
export const defaultIsDesc = true

export const defaultActiveAt = 'today'

const defaultListParams: TaskListParam = {
  user: '',
  isScheduled: 'false',
  page: defaultPage,
  limit: defaultLimit,
  ordering: `${defaultIsDesc ? '-' : ''}${defaultOrderingKey}`,
  activeAt: defaultActiveAt,
}

const progresses = [allProgresses.NEW, allProgresses.SENT, allProgresses.DONE]

const defaultTabKey = allProgresses.NEW

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
}

const IssuedTaskList: React.FC<PropType> = ({ customer, user }) => {
  if (!user) return null

  const [tab, setTab] = useState<ActiveProgress | string>(defaultTabKey)
  const tasks = useTaskList(
    customer.staff?.username,
    defaultListParams.isScheduled,
    {
      ...defaultListParams,
      customer: customer.uid,
    },
  )
  const taskKinds = useTaskKindList()
  const locParams = useLocationParams<TaskListParam, TaskListOrderingKey>(
    paramKeys,
    {
      useUrl: false,
      defaultParams: { ...tasks.params },
      orderingParamKey,
      onSetParams: tasks.setParams,
      numberParams: ['page', 'limit'],
      commaListParams: ['kind', 'customerSupport'],
      tryPreviousPage: true,
      paginatedData: tasks.data,
      httpError: tasks.error,
    },
  )

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeParam = (
    key: keyof TaskListParam,
    value: ItemValue<TaskListParam>,
    historyAction?: ChangeHistoryAction<TaskListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeProgress = () => {
    tasks.mutate().then(_.noop)
  }

  const onDeleteProgress = () => {
    tasks.mutate().then(_.noop)
  }

  const onChangeTab = (tabKey: ActiveProgress | string) => {
    setTab(tabKey)
  }

  const getItems = (tab: ActiveProgress | string) =>
    tasks.data?.results.filter((o) => o.progress === tab) ?? []

  const titles = progresses.reduce((acc, curr) => {
    acc[curr] = getItems(curr).length
    return acc
  }, {} as Record<string, number | undefined>)

  const orderingKey = locParams.fullParams.orderingKey as
    | TaskListOrderingKey
    | undefined

  const isInChargeOf =
    !!customer?.staff && !!user && customer?.staff?.username === user?.username

  return (
    <>
      <IssuedTaskListView
        isInChargeOf={isInChargeOf}
        isLoading={tasks.isLoading}
        data={getItems(tab)}
        params={locParams.params}
        progresses={progresses}
        titles={titles}
        defaultTabKey={defaultTabKey}
        onChangeTab={onChangeTab}
        page={locParams.params.page || defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        count={tasks.data?.count ?? defaultLimit}
        onChangePage={onChangePage}
        onChangeParam={onChangeParam}
        onChangeProgress={onChangeProgress}
        onDeleteTask={onDeleteProgress}
        taskKinds={taskKinds.data?.results ?? []}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
      />
    </>
  )
}

export default IssuedTaskList
