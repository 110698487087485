import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { dateFormat } from '@/utils/date'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.dashboard'
import { PagePaginationResponse } from '@/clients/http/types'
import {
  StaffDailyTaskStatus,
  StaffDailyTaskStatusParam,
} from '@/apps/dashboard/@types/staff-daily-tasks'

const fetcher = (url: string, params: StaffDailyTaskStatusParam) => {
  params.date = dateFormat(new Date())
  return get<
    StaffDailyTaskStatusParam,
    PagePaginationResponse<StaffDailyTaskStatus>
  >(url, params)
}

const useTodayCounselTaskList = () => {
  const [params, setParams] = useState<StaffDailyTaskStatusParam | null>(null)
  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.todayCounselTaskList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      shouldRetryOnError: false,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    params,
    setParams,
  }
}

export default useTodayCounselTaskList
