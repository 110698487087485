export const userRoles = {
  ADMIN: 'admin',
  STAFF: 'staff',
} as const

export const userRoleNames = {
  [userRoles.ADMIN]: '관리자',
  [userRoles.STAFF]: '스킨코치',
} as const

export const userRoleTagLabel = {
  [userRoles.ADMIN]: 'Admin',
  [userRoles.STAFF]: 'Staff',
} as const

export const userRoleTagColor = {
  [userRoles.ADMIN]: 'green',
  [userRoles.STAFF]: 'purple',
} as const

export const userStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const

export const userStatusTagLabel = {
  [userStatus.ACTIVE]: '활성',
  [userStatus.INACTIVE]: '비활성',
} as const

export const userStatusTagColor = {
  [userStatus.ACTIVE]: 'blue',
  [userStatus.INACTIVE]: 'red',
} as const

export const userStatusToggleLabel = {
  [userStatus.ACTIVE]: '비활성화',
  [userStatus.INACTIVE]: '활성화',
} as const

export const readonlyFieldsForModifying = [
  'username',
  'firstName',
  'role',
] as const

export const readonlyFieldsForCreating = [] as const

export const fieldsForInitializingFormData = [
  'username',
  'firstName',
  'role',
] as const

export const fieldsForCreating = [
  'firstName',
  'role',
  'password',
  'passwordAgain',
] as const

export const fieldsForModifying = ['password', 'passwordAgain'] as const
