import _ from 'lodash'
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import { TableProps } from 'antd/lib/table/Table'
import { itemToItems } from '@/utils/collections'

export type TableChangeCallbackValue<RT, AT> = AT extends 'filter'
  ? Record<string, FilterValue | null>
  : AT extends 'sort'
  ? SorterResult<RT> | SorterResult<RT>[]
  : AT extends 'paginate'
  ? TablePaginationConfig
  : undefined

export interface ChangeFilterCallbackParams<RT, AT> {
  values: TableChangeCallbackValue<RT, AT>
  extra: TableCurrentDataSource<RT>
}

export interface TableChangeParams<RT> {
  filter?: (params: ChangeFilterCallbackParams<RT, 'filter'>) => void
  sort?: (params: ChangeFilterCallbackParams<RT, 'sort'>) => void
  paginate?: (params: ChangeFilterCallbackParams<RT, 'paginate'>) => void
}

export const buildTableChangeFilter = <RT>(actions: TableChangeParams<RT>) => {
  const _onTableChange: TableProps<RT>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RT> | SorterResult<RT>[],
    extra: TableCurrentDataSource<RT>,
  ) => {
    switch (extra.action) {
      case 'filter':
        !!actions.filter && actions.filter({ values: filters, extra })
        break
      case 'sort':
        !!actions.sort && actions.sort({ values: sorter, extra })
        break
      case 'paginate':
        !!actions.paginate && actions.paginate({ values: pagination, extra })
        break
    }
  }
  return _onTableChange
}

export const getFilteredValue = <T>(value: T | T[] | undefined) =>
  itemToItems<T>(value)

export const getFilters = (filterLabels: Record<string, string>) =>
  _.chain(filterLabels)
    .toPairs()
    .map(([value, text]) => ({ text, value }))
    .value()

export const getOptions = (optionLabels: Record<string, string>) =>
  _.chain(optionLabels)
    .toPairs()
    .map(([value, label]) => ({ value, label }))
    .value()
