import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { Form, FormInstance } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { ErrorMessage } from '@/@types/error'
import { Nil } from '@/@types/composite'
import envs from '@/config/variables'
import { hasAnyFieldsError } from '@/utils/antd/form'
import { dateFormat, getCareItemClosedAt } from '@/utils/date'
import { checkKinds } from '@/constants/apps.care'
import {
  careItemReptitionKind,
  careItemVisibility,
} from '@/constants/apps.careItem'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import { useCareItemList } from '@/apps/careItem/hooks/useCareItemList'
import { useCurriculumCareItemCreation } from '@/apps/curriculum/hooks/useCurriculumCareItemManipulation'
import {
  CreatingCurriculumCareItemFormRules,
  CurriculumCare,
  CurriculumCareItemCreatePayload,
} from '@/apps/curriculum/@types/curriculum.care'
import { Care, CheckKind } from '@/apps/care/@types/care'
import {
  CareItem,
  CareItemReptitionKind,
} from '@/apps/careItem/@types/careItem'
import {
  addCurriculumCareItem,
  careItemFormErrorMessage,
} from '@/apps/curriculum/messages/error'
import {
  CustomerCare,
  CustomerCareItem,
  CustomerCareItemCreatePayload,
} from '@/apps/customer/@types/customer.care'
import { CustomerCurriculumProgress } from '@/apps/customer/@types/customer.curriculum'
import { customerCurriculumProgress } from '@/constants/apps.customer'
import { useCustomerCareItemCreation } from '@/apps/customer/hooks/curriculum/useCustomerCareItemManipulation'
import AddCurriculumCareItemFormView, {
  ItemDisabledState,
} from './AddCurriculumCareItemForm.View'

interface RepetitionIntervalState {
  days: number | null
  weekValues: number[]
  weekDays: number | null
}

interface PropType {
  isMyCustomer?: boolean
  isVisible: boolean
  isCalendarStartDate: boolean
  curriculumCare?: CurriculumCare
  customerCare?: CustomerCare<Care, CustomerCareItem>
  customerCurriculumOpenedAt?: Date | Nil
  customerCurriculumClosedAt?: Date | Nil
  customerProgress?: CustomerCurriculumProgress
  onFinish: () => void
  onCancel: () => void
  formRefForTesting?: FormInstance // only for testing
}

const defaultRepetitionIntervalState: RepetitionIntervalState = {
  days: null,
  weekValues: [],
  weekDays: null,
}

const defaultItemDisabled: ItemDisabledState = {
  selectRepetition: true,
  repetitionDays: true,
  repetitionWeekDays: true,
  openInDays: true,
  totalRepetitions: true,
  infinitely: true,
}

const AddCurriculumCareItemForm: React.FC<PropType> = ({
  isMyCustomer,
  curriculumCare,
  customerCare,
  isVisible,
  isCalendarStartDate,
  customerCurriculumOpenedAt,
  customerCurriculumClosedAt,
  customerProgress,
  onFinish,
  onCancel,
  formRefForTesting,
}) => {
  if (!!curriculumCare && !!customerCare) return null
  const isCustomerCare = !!customerCare

  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const fetchingCareItem = useCareItemList()

  const [itemDisabled, setItemDisabled] =
    useState<ItemDisabledState>(defaultItemDisabled)
  const [repetitionIntervalState, setRepetitionIntervalState] =
    useState<RepetitionIntervalState>(defaultRepetitionIntervalState)
  const [isInfinitely, setIsInfinitely] = useState<boolean>(false)

  const checkKind = isCustomerCare
    ? customerCare?.care.checkKind
    : curriculumCare?.checkKind

  const basePayload = isCustomerCare
    ? {
        customerCare: customerCare?.id,
      }
    : {
        curriculum: curriculumCare?.curriculum,
        curriculumCare: curriculumCare?.id,
      }

  const creating = isCustomerCare
    ? useCustomerCareItemCreation()
    : useCurriculumCareItemCreation()

  useEffect(() => {
    if (isVisible) {
      fetchingCareItem.setParams({
        page: defaultPage,
        limit: defaultLimit,
        care: isCustomerCare ? customerCare?.care.id : curriculumCare?.care,
        ordering: 'name',
        visibility: careItemVisibility.PUBLISHED,
      })
    } else {
      fetchingCareItem.setParams({})
    }
    currentFormRef.resetFields()
    setItemDisabled(defaultItemDisabled)
    setRepetitionIntervalState(defaultRepetitionIntervalState)
    setIsInfinitely(false)
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) return
    if (!creating.data || creating.isLoading) return
    _notifySuccess('추가 완료', '해당 아이템을 추가 완료했습니다.')
    onFinish()
  }, [creating.data, creating.isLoading])

  useEffect(() => {
    if (!isVisible) return
    if (!creating.error || creating.isLoading) return

    const { data } = creating.error

    if (data) {
      const fieldErrorsFromServer = _.chain(data)
        .toPairs()
        .map(([name, errors]) => {
          return {
            name: getFieldErrorNameFromServer(currentFormRef, name),
            errors: getFieldErrorValueFromServer(errors),
          } as FieldData
        })
        .filter((fieldError) => !_.isEmpty(fieldError.errors))
        .value()

      if (!_.isEmpty(fieldErrorsFromServer)) {
        currentFormRef.setFields(fieldErrorsFromServer)
        return
      }

      _notifyFailure('요청 실패', '유효하지 않은 요청입니다.')
      onCancel()
    } else {
      _notifyFailure(
        '요청 실패',
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      )
      onFinish()
    }
  }, [creating.error, creating.isLoading])

  const onChangePage = (page: number) => {
    fetchingCareItem.setParams({ ...fetchingCareItem.params, page })
  }

  const onChangeSelectCareItem = (id: number) => {
    const fieldsValue = currentFormRef.getFieldsValue()
    currentFormRef.setFieldsValue({
      ...fieldsValue,
      ...{
        care_item: id,
      },
    })
  }

  const onChangeSelectRepetition = (isRepetition: boolean) => {
    if (currentFormRef.getFieldError('repetition')) {
      removeRepetitionIntervalError()
    }

    if (isRepetition) {
      setItemDisabled({
        selectRepetition: false,
        repetitionDays:
          !isSelectedRepetition(currentFormRef) ||
          isSelectedWeekDayRepetitionKind(currentFormRef),
        repetitionWeekDays:
          !isSelectedRepetition(currentFormRef) ||
          isSelectedDayRepetitionKind(currentFormRef),
        openInDays:
          isCalendarStartDate ||
          !isSelectedRepetition(currentFormRef) ||
          isSelectedWeekDayRepetitionKind(currentFormRef),
        totalRepetitions: !isSelectedRepetition(currentFormRef) || isInfinitely,
        infinitely:
          !isSelectedRepetition(currentFormRef) ||
          isClosingCurriculum(customerCurriculumClosedAt),
      })
    } else {
      setItemDisabled({
        ...defaultItemDisabled,
        openInDays: isCalendarStartDate || false,
      })
    }
  }

  const onChangeSelectRepetitionKind = (
    repetitionKind: CareItemReptitionKind,
  ) => {
    if (currentFormRef.getFieldError('repetition')) {
      removeRepetitionIntervalError()
    }
    if (repetitionKind === careItemReptitionKind.DAYS) {
      setItemDisabled({
        openInDays: isCalendarStartDate || false,
        selectRepetition: false,
        repetitionDays: false,
        repetitionWeekDays: true,
        totalRepetitions: isInfinitely,
        infinitely: isClosingCurriculum(customerCurriculumClosedAt),
      })
    } else if (repetitionKind === careItemReptitionKind.WEEK_DAYS) {
      setItemDisabled({
        openInDays: true,
        selectRepetition: false,
        repetitionDays: true,
        repetitionWeekDays: false,
        totalRepetitions: isInfinitely,
        infinitely: isClosingCurriculum(customerCurriculumClosedAt),
      })
    }
  }

  const onChangeInfinitely = (isInfinitely: boolean) => {
    setItemDisabled({
      ...itemDisabled,
      totalRepetitions: isInfinitely,
    })

    setIsInfinitely(isInfinitely)
  }

  const onInputRepetitionDays = (day: number) => {
    if (currentFormRef.getFieldError('repetition')) {
      removeRepetitionIntervalError()
    }
    setRepetitionIntervalState({
      ...repetitionIntervalState,
      days: day,
    })
  }

  const onChangeRepetitionWeekDays = (weekDays: number[]) => {
    if (currentFormRef.getFieldError('repetition')) {
      removeRepetitionIntervalError()
    }
    setRepetitionIntervalState({
      ...repetitionIntervalState,
      weekValues: weekDays,
      weekDays: _.reduce(weekDays, (result, weekDay) => result | weekDay, 0),
    })
  }

  const _onFinish = async () => {
    if (getRepetitionIntervalRequired(repetitionIntervalState, itemDisabled)) {
      setRepetitionIntervalError()
      return
    } else {
      removeRepetitionIntervalError()
    }

    if (isCustomerCare && !isMyCustomer) {
      _notifyFailure(
        '요청 실패',
        '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      )
      onCancel()
      return
    }

    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    const openedAt = currentFormRef.getFieldValue('opened_at')
    const repetitionInfo = getRepetitionInfo()
    if (
      isCustomerCare &&
      !isValidDate(openedAt, customerCurriculumClosedAt, repetitionInfo)
    ) {
      if (repetitionInfo.repetitionKind === careItemReptitionKind.WEEK_DAYS) {
        currentFormRef.setFields([
          {
            name: ['total_repetitions'],
            errors: ['커리큘럼 기간 내로 다시 입력하세요.'],
          } as unknown as FieldData,
        ])
      } else {
        currentFormRef.setFields([
          {
            name: ['repetition'],
            errors: ['시작 일자 이후부터 커리큘럼 기간 이내로 입력하세요.'],
          } as unknown as FieldData,
        ])
      }
      return
    }

    const careItems = fetchingCareItem.data?.results ?? []
    if (isCustomerCare) {
      if (isYesOrNo(checkKind, careItems)) {
        creating.setPayload(
          buildFormPayload(careItems[0]) as CurriculumCareItemCreatePayload,
        )
        return
      }
      creating.setPayload(buildFormPayload() as CurriculumCareItemCreatePayload)
    } else {
      if (isYesOrNo(checkKind, careItems)) {
        creating.setPayload(
          buildFormPayload(careItems[0]) as CustomerCareItemCreatePayload,
        )
        return
      }
      creating.setPayload(buildFormPayload() as CustomerCareItemCreatePayload)
    }
  }

  const _onCancel = () => {
    onCancel()
  }

  const setRepetitionIntervalError = () => {
    currentFormRef.setFields([
      {
        name: ['repetition'],
        errors: [addCurriculumCareItem.required.repetition],
      } as FieldData,
    ])
  }

  const removeRepetitionIntervalError = () => {
    currentFormRef.setFields([
      {
        name: ['repetition'],
        errors: [],
      } as FieldData,
    ])
  }

  const getRepetitionInfo = () => {
    const isRepetition = currentFormRef.getFieldValue('is_repetition')
    const repetitionKind = isRepetition
      ? currentFormRef.getFieldValue('repetition')
      : careItemReptitionKind.ONE_TIME
    const repetitionInterval =
      repetitionKind === careItemReptitionKind.DAYS
        ? repetitionIntervalState.days
        : repetitionIntervalState.weekDays
    const totalRepetitions = currentFormRef.getFieldValue('total_repetitions')
    return {
      repetitionKind,
      repetitionInterval:
        repetitionKind === careItemReptitionKind.ONE_TIME
          ? 1
          : _.toNumber(repetitionInterval),
      totalRepetitions:
        repetitionKind === careItemReptitionKind.ONE_TIME
          ? 1
          : isInfinitely
          ? null
          : _.toNumber(totalRepetitions),
    } as {
      openInDays: number | null
      repetitionKind: CareItemReptitionKind
      repetitionInterval: number | null
      totalRepetitions: number | null
    }
  }

  const buildFormPayload = (item?: CareItem) => {
    const careItem = item ? item.id : currentFormRef.getFieldValue('care_item')
    const openInDays = currentFormRef.getFieldValue('open_in_days')
    const openedAt = currentFormRef.getFieldValue('opened_at')

    const repetitionInfo = getRepetitionInfo()
    const baseFormPayload = {
      ...basePayload,
      ...repetitionInfo,
      careItem,
    }

    if (isCalendarStartDate) {
      return {
        ...baseFormPayload,
        openedAt: dateFormat(openedAt),
      }
    } else {
      return {
        ...baseFormPayload,
        openInDays:
          repetitionInfo.repetitionKind === careItemReptitionKind.WEEK_DAYS
            ? null
            : _.toNumber(openInDays),
      }
    }
  }

  return (
    <AddCurriculumCareItemFormView
      page={fetchingCareItem.params.page ?? defaultPage}
      limit={fetchingCareItem.params.limit ?? defaultLimit}
      count={fetchingCareItem.data?.count ?? 0}
      data={fetchingCareItem.data?.results ?? []}
      isVisible={isVisible}
      isCalendarStartDate={isCalendarStartDate}
      isInfinitely={isInfinitely}
      itemDisabled={itemDisabled}
      onChangeSelectCareItem={onChangeSelectCareItem}
      onChangeSelectRepetition={onChangeSelectRepetition}
      onChangeSelectRepetitionKind={onChangeSelectRepetitionKind}
      onChangeInfinitely={onChangeInfinitely}
      onInputRepetitionDays={onInputRepetitionDays}
      onChangeRepetitionWeekDays={onChangeRepetitionWeekDays}
      onFinish={_onFinish}
      onCancel={_onCancel}
      onChangePage={onChangePage}
      disabledDate={(current: Date) =>
        disabledDate(
          current,
          isCustomerCare,
          customerCurriculumOpenedAt,
          customerCurriculumClosedAt,
          customerProgress,
        )
      }
      rules={getCreatingRules(isCustomerCare, itemDisabled, checkKind)}
      formRef={currentFormRef}
      checkKind={checkKind}
      weekValues={repetitionIntervalState.weekValues}
    />
  )
}

export default AddCurriculumCareItemForm

const defaultPage = 1
const defaultLimit = 5

const isClosingCurriculum = (customerCurriculumClosedAt: Date | Nil) =>
  !!customerCurriculumClosedAt

const isYesOrNo = (checkKind: CheckKind | Nil, careItems: CareItem[]) =>
  checkKind === checkKinds.YES_OR_NO && careItems.length === 2

const isValidDate = <
  T extends {
    openInDays: number | null
    repetitionKind: CareItemReptitionKind
    repetitionInterval: number | null
    totalRepetitions: number | null
  },
>(
  careItemOpenedAt: Date | Nil,
  customerCurriculumClosedAt: Date | Nil,
  careItem: T,
) => {
  if (!careItemOpenedAt) return false
  if (!customerCurriculumClosedAt) return true

  const closedAtOfCareItem = getCareItemClosedAt(careItem, careItemOpenedAt)

  return (
    !closedAtOfCareItem ||
    differenceInCalendarDays(customerCurriculumClosedAt, closedAtOfCareItem) >=
      0
  )
}

const isSelectedRepetition = (currentFormRef: FormInstance) =>
  currentFormRef.getFieldValue('repetition')

const isSelectedWeekDayRepetitionKind = (currentFormRef: FormInstance) =>
  currentFormRef.getFieldValue('repetition') === careItemReptitionKind.WEEK_DAYS

const isSelectedDayRepetitionKind = (currentFormRef: FormInstance) =>
  currentFormRef.getFieldValue('repetition') === careItemReptitionKind.DAYS

export const getCreatingRules = (
  isCustomerCare: boolean,
  itemDisabled: ItemDisabledState,
  checkKind?: CheckKind,
) => {
  return {
    careItem: [
      {
        required: checkKind !== checkKinds.YES_OR_NO,
        message: addCurriculumCareItem.required.careItem,
      },
    ],
    isRepetition: [
      {
        required: true,
        message: addCurriculumCareItem.required.isRepetition,
      },
    ],
    openInDays: [
      {
        required: !itemDisabled.openInDays,
        message: addCurriculumCareItem.required.openInDays,
      },
    ],
    openedAt: [
      {
        required: isCustomerCare,
        message: addCurriculumCareItem.required.openedAt,
      },
    ],
    totalRepetitions: [
      {
        required: !itemDisabled.totalRepetitions,
        message: addCurriculumCareItem.required.totalRepetitions,
      },
    ],
    repetition: [
      {
        required: !itemDisabled.selectRepetition,
        message: addCurriculumCareItem.required.repetition,
      },
    ],
  } as CreatingCurriculumCareItemFormRules
}

const getRepetitionIntervalRequired = (
  repetitionIntervalState: RepetitionIntervalState,
  itemDisabled: ItemDisabledState,
) => {
  if (!itemDisabled.repetitionDays && !repetitionIntervalState.days) return true
  if (!itemDisabled.repetitionWeekDays && !repetitionIntervalState.weekDays)
    return true
  return false
}

const _notifySuccess = (defaultMessage: string, defaultDescription: string) => {
  notifySuccess({
    message: defaultMessage,
    description: defaultDescription,
  })
}

const _notifyFailure = (
  defaultMessage: string,
  defaultDescription: string,
  errorMessage?: ErrorMessage,
) => {
  notifyFailure({
    message: errorMessage?.message ?? defaultMessage,
    description: errorMessage?.desc ?? defaultDescription,
  })
}

const getFieldErrorNameFromServer = (
  currentFormRef: FormInstance,
  name: string,
) => {
  if (name === 'non_field_errors') return 'total_repetitions'
  return name === 'repetition_interval' ||
    (name === 'detail' &&
      currentFormRef.getFieldValue('repetition') ===
        careItemReptitionKind.WEEK_DAYS)
    ? 'repetition'
    : name === 'detail' &&
      currentFormRef.getFieldValue('repetition') !==
        careItemReptitionKind.WEEK_DAYS &&
      !!currentFormRef.getFieldValue('open_in_days')
    ? 'open_in_days'
    : name === 'detail' &&
      currentFormRef.getFieldValue('repetition') !==
        careItemReptitionKind.WEEK_DAYS &&
      !!currentFormRef.getFieldValue('opened_at')
    ? 'opened_at'
    : name === 'detail'
    ? 'total_repetitions'
    : name
}

const getFieldErrorValueFromServer = (errors: string[] | string) => {
  const fieldError = _.isArray(errors)
    ? careItemFormErrorMessage[errors[0]]
    : careItemFormErrorMessage[errors]
  return !fieldError ? [] : [fieldError]
}

const disabledDate = (
  current: Date,
  isCustomerCare: boolean,
  customerCurriculumOpenedAt?: Date | Nil,
  customerCurriculumClosedAt?: Date | Nil,
  customerProgress?: CustomerCurriculumProgress,
) => {
  if (!isCustomerCare || !customerCurriculumOpenedAt || !customerProgress)
    return true
  const isDisableStartDate =
    customerProgress === customerCurriculumProgress.RESERVED
      ? differenceInCalendarDays(current, customerCurriculumOpenedAt) < 0
      : differenceInCalendarDays(current, new Date()) < 0
  const isDisableEndDate = !customerCurriculumClosedAt
    ? false
    : differenceInCalendarDays(current, customerCurriculumClosedAt) > 0

  return isDisableStartDate || isDisableEndDate
}
