import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.careItem'
import { PagePaginationResponse } from '@/clients/http/types'
import { CareItem, CareItemListParam } from '@/apps/careItem/@types/careItem'

const fetcher = (url: string | null, params?: CareItemListParam) => {
  if (!url) return
  return get<CareItemListParam, PagePaginationResponse<CareItem>>(url, params)
}

export const useCareItemList = (defaultParams: CareItemListParam = {}) => {
  const [params, setParams] = useState<CareItemListParam>({
    ...defaultParams,
  })

  const _setParams = (newParams: CareItemListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const setParamsIncludeCare = (newParams: CareItemListParam) => {
    !_.isEqual(params, newParams) &&
      setParams({ ...newParams, care: defaultParams.care })
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.careItemList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )

  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    mutate,
    setParams: _setParams,
    setParamsIncludeCare,
  }
}

export default useCareItemList
