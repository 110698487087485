import React, { useEffect } from 'react'

import {
  CreationHookReturnType,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import { form as baseFormErrors } from '@/constants/errors'
import {
  CareItem,
  CareItemCreatePayload,
  CareItemModifyPayload,
  CreatingFormRules,
} from '@/apps/careItem/@types/careItem'
import EditSimpleItemForm from '@/components/modal/edit-simple-item-form/EditSimpleItemForm'
import { curriculumError } from '@/constants/apps.curriculum'

interface PropType {
  isVisible: boolean
  selectedItem: CareItem | null
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  setModifyingKey: (data: CareItem | null) => void
  creating: CreationHookReturnType<CareItemCreatePayload, CareItem>
  modifying: ModificationHookReturnType<CareItemModifyPayload, CareItem>
  careID: number
}

const maxLengthOfName = 40
const maxLengthOfDesc = 100
const hiddenFields = { compilationKind: 'regular' }

const EditCareItemForm: React.FC<PropType> = ({
  isVisible,
  selectedItem,
  onFinish,
  onCancel,
  setModifyingKey,
  creating,
  modifying,
  careID,
}) => {
  useEffect(() => {
    if (
      creating.error?.status === 400 &&
      onCancel &&
      creating.error.data.detail !== curriculumError.DUPLICATE
    ) {
      onCancel()
    }
    if (creating.error?.status === 404 && onCancel) {
      onCancel()
    }
  }, [creating])

  const handlingCreating = () => {
    if (
      creating.error?.status === 400 &&
      onCancel &&
      creating.error.data.detail !== curriculumError.DUPLICATE
    ) {
      creating.error.data.detail = 'inactive'
    }
    return creating
  }

  return (
    <EditSimpleItemForm<CareItem, CareItemCreatePayload, CareItemModifyPayload>
      isVisible={isVisible}
      item={selectedItem}
      onFinish={onFinish}
      onCancel={onCancel}
      title={getEditModalTitle(!!selectedItem)}
      subTitle={getEditModalSubTitle(!!selectedItem)}
      itemName="케어 아이템"
      itemNameInputLabel="케어 아이템 제목"
      itemNameInputPlaceholder={`케어 아이템 제목 입력 (최대 노출 글자 수 ${maxLengthOfName}자)`}
      itemDescInputLabel="설명"
      itemDescInputPlaceholder={`설명 입력 (최대 노출 글자 수 ${maxLengthOfDesc}자)`}
      creating={handlingCreating()}
      creatingRules={creatingRules}
      modifying={modifying}
      setModifyingKey={setModifyingKey}
      modifyingRules={creatingRules}
      hiddenFields={{ ...hiddenFields, care: careID }}
    />
  )
}

const getEditModalTitle = (isModifying: boolean) => {
  return isModifying ? '케어 아이템 수정' : '신규 아이템 생성'
}

const getEditModalSubTitle = (isModifying: boolean) => {
  return isModifying ? '케어 아이템 수정' : '신규 케어 아이템 추가'
}

const creatingRules: CreatingFormRules = {
  name: [
    {
      required: true,
      message: '케어 아이템을 입력하세요',
    },
    {
      type: 'string',
      max: maxLengthOfName,
      message: baseFormErrors.invalidFormat,
    },
  ],
  description: [
    {
      required: true,
      message: baseFormErrors.invalidFormat,
    },
    {
      type: 'string',
      max: maxLengthOfDesc,
      message: baseFormErrors.invalidFormat,
    },
  ],
}

export default EditCareItemForm
