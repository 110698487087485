import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'

import { buildMapProportions } from '@/utils/collections'
import { activeProgresses } from '@/constants/apps.task'
import { ActiveProgress, Task } from '@/apps/task/@types/task'

import styles from './TaskProgressBar.module.scss'

const cx = classNames.bind(styles)

export interface TaskProgressStatusPropType {
  tasks: Task[]
  emptyText?: string
}

const TaskProgressBar: React.FC<TaskProgressStatusPropType> = ({
  tasks,
  emptyText = '해당 없음',
}) => {
  const taskCount = tasks.length
  if (!taskCount) return <span>{emptyText}</span>

  const result = buildMapProportions<ActiveProgress, Task>(
    activeProgresses,
    tasks,
    'progress',
  )
  const fullLabel =
    _.toInteger(result.highest[1]) === 1 ? result.highest[0].toUpperCase() : ''

  let incremental = 0
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        {activeProgresses.map((progress) => {
          incremental += _.ceil(result[progress] * 100)
          return (
            <div
              key={progress}
              className={cx(`progress ${progress}`)}
              style={{ width: `${incremental}%` }}
            />
          )
        })}
      </div>
      <div className={cx('label')}>{fullLabel}</div>
    </div>
  )
}

export default TaskProgressBar
