import React, { useEffect } from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { ConfigProvider, Table, Typography } from 'antd'
import { dateFormat, weekdaysFromCompositedNumber } from '@/utils/date'
import { DeleteButton } from '@/components/buttons/Icon'
import BasicButton from '@/components/buttons/BasicButton'
import SpanText from '@/components/typography/SpanText'
import {
  careItemReptitionKind,
  careItemVisibility,
} from '@/constants/apps.careItem'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import { Care } from '@/apps/care/@types/care'

import styles from './CustomerCareProgramView.module.scss'
import { DateISO8601 } from '@/@types/formats'

interface PropType {
  customerCare: CustomerCare<Care, CustomerCareItem>
  careItems: CustomerCareItem[]
  curriculumCareKeys: number[]
  curriculumCareItemKeys: number[]
  disabledDeleteCare: boolean
  disabledAddCareItem: boolean
  disabledDeleteCareItem: boolean
  showDeleteCareButton?: boolean
  showSuspendedCare: boolean
  onClickAddCustomerCareItem?: () => void
  onClickDeleteCustomerCareItem?: (customerCareItem: CustomerCareItem) => void
  onClickDeleteCustomerCare?: (
    customerCare: CustomerCare<Care, CustomerCareItem>,
  ) => void
}

const CustomerCareProgramView: React.FC<PropType> = ({
  customerCare,
  careItems,
  curriculumCareKeys,
  curriculumCareItemKeys,
  disabledDeleteCare,
  disabledAddCareItem,
  disabledDeleteCareItem,
  showDeleteCareButton,
  showSuspendedCare,
  onClickAddCustomerCareItem,
  onClickDeleteCustomerCareItem,
  onClickDeleteCustomerCare,
}) => {
  useEffect(() => {
    const suspended = _.chain(careItems)
      .filter(
        (careItem) => careItem.visibility === careItemVisibility.SUSPENDED,
      )
      .map('id')
      .value()

    _.forEach(suspended, (itemId) => {
      const element = document.querySelector(
        `tr.ant-table-row.ant-table-row-level-0[data-row-key='${itemId}']`,
      )
      if (element) {
        element.className = cx('suspended-care-item')
      }
    })
  }, [careItems])

  const isInCurrculumCares = () =>
    _.includes(curriculumCareKeys, customerCare.care.id)

  const isInCurriculumCareItems = (careItemId: number) => {
    return _.includes(curriculumCareItemKeys, careItemId)
  }

  return (
    <div>
      <div className={cx('header')}>
        <Typography.Text className={cx('name')}>
          {customerCare.care.name}
        </Typography.Text>
        <Typography.Text className={cx('description')}>
          {customerCare.care.description}
        </Typography.Text>
        <div className={cx('right-container')}>
          {showDeleteCareButton && (
            <BasicButton
              ghost
              className={cx('delete-button')}
              label="케어 유형 삭제"
              icon="minus"
              color="red"
              disabled={disabledDeleteCare || isInCurrculumCares()}
              onClick={() => onClickDeleteCustomerCare?.(customerCare)}
            />
          )}

          <BasicButton
            ghost
            label="케어 아이템 추가"
            icon="plus"
            disabled={disabledAddCareItem}
            onClick={onClickAddCustomerCareItem}
          />
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <div className={cx('empty-container')}>
            <span>이 유형의 케어 아이템을 최소 1개 이상 추가해주세요</span>
          </div>
        )}
      >
        <Table
          className={cx('table', {
            suspended: showSuspendedCare,
          })}
          dataSource={careItems}
          rowKey="id"
          pagination={false}
        >
          <Column
            title="번호"
            key="order"
            render={(_value, _record, index) => (
              <SpanText text={`${index + 1}`} />
            )}
          />
          <Column
            title="케어 아이템"
            dataIndex="name"
            render={(value) => <SpanText text={value} />}
          />
          <Column
            className={cx('description')}
            title="설명"
            dataIndex="description"
            render={(value) => <SpanText text={value} />}
          />
          <Column
            title="시작 일자"
            dataIndex="openedAt"
            render={(value) => <OpenedAtText openedAt={value} />}
          />
          <Column<CustomerCareItem>
            title="반복 주기"
            dataIndex="repetitionInterval"
            render={(value, recored) => (
              <RepetitionIntervalText customerCareItem={recored} />
            )}
          />
          <Column<CustomerCareItem>
            title="총 수행 횟수"
            dataIndex="totalRepetitions"
            render={(value, record) => (
              <TotalRepetitionText customerCareItem={record} />
            )}
          />
          <Column<CustomerCareItem>
            title="삭제"
            key="delete"
            render={(__, record) => (
              <DeleteButton
                disabled={
                  disabledDeleteCareItem ||
                  isInCurriculumCareItems(record.careItem)
                }
                onClick={() => onClickDeleteCustomerCareItem?.(record)}
              />
            )}
          />
        </Table>
      </ConfigProvider>
    </div>
  )
}

export default CustomerCareProgramView

const cx = classNames.bind(styles)

const { Column } = Table

const OpenedAtText: React.FC<{
  openedAt: DateISO8601
}> = ({ openedAt }) => {
  return (
    <SpanText text={openedAt ? dateFormat(new Date(openedAt)) : '0000-00-00'} />
  )
}

const RepetitionIntervalText: React.FC<{
  customerCareItem: CustomerCareItem
}> = ({ customerCareItem }) => {
  const { repetitionInterval, repetitionKind } = customerCareItem
  const repetitionIntervalText = repetitionInterval
    ? repetitionKind === careItemReptitionKind.WEEK_DAYS
      ? _.join(weekdaysFromCompositedNumber(repetitionInterval), ', ')
      : `${repetitionInterval}일`
    : '-'
  return <SpanText text={repetitionIntervalText} />
}

const TotalRepetitionText: React.FC<{
  customerCareItem: CustomerCareItem
}> = ({ customerCareItem }) => {
  const { totalRepetitions, repetitionKind } = customerCareItem
  const totalRepetitionText = !totalRepetitions
    ? repetitionKind === careItemReptitionKind.ONE_TIME
      ? '1회'
      : '무한'
    : `${totalRepetitions}회`
  return <SpanText text={totalRepetitionText} />
}
