import React from 'react'
import classNames from 'classnames/bind'

import { ExclamationCircleOutlined as IconExclamationCircleOutlined } from '@ant-design/icons'

import Section from '@/components/layout/section/Section'

import styles from './SyncAlert.module.scss'

const cx = classNames.bind(styles)

const SyncAlert: React.FC = () => {
  return (
    <Section>
      <div className={cx('container')}>
        <div>
          <IconExclamationCircleOutlined className={cx('icon')} />
        </div>
        <div>
          <span className={cx('title')}>동기화를 진행해주세요</span>
        </div>
      </div>
    </Section>
  )
}

export default SyncAlert
