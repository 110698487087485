import React from 'react'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'

import style from './ActivitySummary.module.scss'
import Text from 'antd/lib/typography/Text'
import { Select, Space } from 'antd'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import format from 'date-fns/format'
import {
  ActivitiySummaryInterface,
  GradeInterface,
} from '@/apps/customer/@types/customer-activity'
import SpanText from '@/components/typography/SpanText'

interface PropType {
  data: ActivitiySummaryInterface
  grade: GradeInterface
  handelSelectChange: (periodSelected: number) => void
}

const ActivitiySummaryView: React.FC<PropType> = ({
  data: { care, title, status, start, login, recent, period },
  grade: { key, level, percent },
  handelSelectChange,
}) => {
  const { Option } = Select

  const parseCare = () => {
    switch (care) {
      case 'regular':
        return '정규'
      case 'autonomous':
        return '자율'
      default:
        return '-'
    }
  }

  const parseStatus = () => {
    switch (status) {
      case 'new':
        return '신규'
      case 'proceeding':
        return '진행중'
      case 'normal':
        return '일반'
      default:
        return '일반'
    }
  }

  const calcDaysCount = () => {
    const diffDate = differenceInCalendarDays(new Date(), new Date(start))
    return diffDate >= 0 ? diffDate + 1 : 0
  }

  return (
    <Section className={style['activity-summary']} isLargeBottom>
      <SectionTitle title="현재 설정된 커리큘럼 / 케어 활동 요약" />
      <div className={style.body}>
        <Space direction="vertical">
          <div className={style['text-box']}>
            <Text strong>현재 커리큘럼 유형</Text>
            <SpanText text={parseCare()} />
          </div>
          <div className={style['text-box']}>
            <Text strong>현재 커리큘럼 제목</Text>
            <SpanText text={title || '-'} />
          </div>
          <div className={style['text-box']}>
            <Text strong>커리큘럼 진행 상태</Text>
            <SpanText text={parseStatus()} />
          </div>
          <div className={style['text-box']}>
            <Text strong>커리큘럼 시작 일자</Text>
            <SpanText
              text={`${
                start ? format(new Date(start), 'yyyy-MM-dd') : '0000-00-00'
              } ${
                calcDaysCount() === period && calcDaysCount() !== 0
                  ? '종료일'
                  : `${calcDaysCount()}일차`
              }`}
            />
          </div>
          <div className={style['text-box']}>
            <Text strong>앱 최초 로그인 일시</Text>
            <SpanText
              text={
                login ? format(new Date(login), 'yyyy-MM-dd HH:mm:ss') : null
              }
            />
          </div>
          <div className={style['text-box']}>
            <Text strong>앱 마지막 방문 일시</Text>
            <SpanText
              text={
                recent ? format(new Date(recent), 'yyyy-MM-dd HH:mm:ss') : null
              }
            />
          </div>
          <div className={style['text-box']}>
            <Text strong>평균 케어 활동 등급</Text>
            <Text>{`LEVEL${level} (평균 진척도 ${percent.toFixed(1)}%)`}</Text>
            <Select
              size="small"
              value={key}
              className={style['ant-select']}
              onChange={handelSelectChange}
            >
              <Option value={7}>최근 7일</Option>
              <Option value={14}>최근 14일</Option>
              <Option value={30}>최근 30일</Option>
              <Option value={60}>최근 60일</Option>
            </Select>
          </div>
        </Space>
      </div>
    </Section>
  )
}

export default ActivitiySummaryView
