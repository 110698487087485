import axios from 'axios'
import _ from 'lodash'

import { stringifyParams } from '@/utils/http'
import { BaseRequestParam } from '@/clients/http/types'
import envs from '@/config/variables'
import { HttpResponse } from '@/clients/http/types'
import * as middlewares from '@/clients/http/middlewares'

const publicHttpClient = axios.create({
  baseURL: envs.apiserver.baseUrl,
  withCredentials: envs.apiserver.withCredentials,
})

publicHttpClient.interceptors.request.use((config) => {
  return middlewares.requestKeysToSnakecase(config)
})

publicHttpClient.interceptors.response.use((config) => {
  return middlewares.responseKeysToCamelcase(config)
})

export const post = async <T, R>(url: string, payload: T) => {
  const res = await publicHttpClient.post<T, HttpResponse<R>>(url, payload)
  return res.data
}

export const get = async <T, R>(url: string, params?: BaseRequestParam) => {
  const fullUrl = _.isEmpty(params) ? url : `${url}?${stringifyParams(params)}`
  const res = await publicHttpClient.get<T, HttpResponse<R>>(fullUrl)
  return res.data
}

export default publicHttpClient
