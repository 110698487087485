import { useState } from 'react'
import useSWR from 'swr'
import { patch } from '@/clients/http/authorized-http-client'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'

const patchRequest = (url: string | null) => {
  if (!url) return
  return patch<null, Curriculum>(url, null)
}

export const useCurriculumVisibility = () => {
  const [key, setKey] = useState<string | null>(null)
  const { data, error, isValidating, mutate } = useSWR(key, patchRequest, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    refreshInterval: 0,
  })
  const isLoading = (!!key && !data && !error) || (!!key && isValidating)

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    mutate,
    setKey,
  }
}
