import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'

import { Rule, Rules } from '@/@types/antd'
import { ErrorMessage } from '@/@types/error'
import envs from '@/config/variables'
import { setErrorFieldsFromServer, hasAnyFieldsError } from '@/utils/antd/form'
import {
  CreationHookReturnType,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'

import EditSimpleItemFormView from './EditSimpleItemForm.View'
import { curriculumErrorMessage } from '@/constants/apps.curriculum'
import { serverErrorStatus } from '@/utils/http'

interface CreatingPropType<T, CPT> {
  creating: CreationHookReturnType<CPT, T>
  creatingRules: Rules<string | number | symbol, Rule[]>
}

interface ModifyingPropType<T, MPT> {
  modifying: ModificationHookReturnType<MPT, T>
  setModifyingKey: (data: T | null) => void
  modifyingRules: Rules<string | number | symbol, Rule[]>
}

interface DataPropType<T> {
  title: string
  subTitle: string
  itemName?: string
  itemNameInputLabel: string
  itemNameInputPlaceholder: string
  itemDescInputLabel: string
  itemDescInputPlaceholder: string
  isVisible?: boolean
  item?: T | null
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  formRefForTesting?: FormInstance // only for testing
  hiddenFields?: Record<string, unknown>
}

export type PropType<T, CPT, MPT> = DataPropType<T> &
  CreatingPropType<T, CPT> &
  ModifyingPropType<T, MPT> &
  React.HTMLAttributes<HTMLElement>

export const EditSimpleItemForm = <T, CT, MT>(
  props: React.PropsWithChildren<PropType<T, CT, MT>>,
) => {
  const { creating, creatingRules } = props

  const { modifying, setModifyingKey, modifyingRules } = props

  const {
    title,
    subTitle,
    itemName,
    itemNameInputLabel,
    itemNameInputPlaceholder,
    itemDescInputLabel,
    itemDescInputPlaceholder,
    isVisible,
    item,
    onFinish,
    onCancel,
    formRefForTesting,
    hiddenFields,
  } = props

  const isModifying = !!item
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const action = isModifying ? '수정' : '저장'

  const defaultSuccessMessage = `${action} 완료`
  const defaultSuccessDescription = `성공적으로 ${
    itemName ?? itemDescInputLabel
  }을 ${action}했습니다.`

  const defaultFailMessage = `${action} 실패`
  const defaultFailDescription = `${
    itemName ?? itemNameInputLabel
  } ${action}에 실패했습니다. 다시 시도해주세요.`

  useEffect(() => {
    currentFormRef.resetFields()
  }, [])

  useEffect(() => {
    currentFormRef.resetFields()
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) return
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.data) || (isModifying && !modifying.data)) {
      return
    }
    creating.setPayload(null)
    modifying.setPayload(null)
    setModifyingKey(null)

    _notifySuccess(defaultSuccessMessage, defaultSuccessDescription)

    !!onFinish && onFinish(isModifying)
  }, [
    isModifying,
    creating.data,
    creating.isLoading,
    modifying.data,
    modifying.isLoading,
  ])

  useEffect(() => {
    if (!isVisible) return
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.error) || (isModifying && !modifying.error))
      return
    const errorState = isModifying ? creating.error : modifying.error
    if (serverErrorStatus.includes(errorState?.status)) {
      _notifyFailure(
        defaultFailMessage,
        defaultFailDescription,
        curriculumErrorMessage.internalServerError,
      )
    } else {
      const errorData = creating.error?.data ?? modifying.error?.data
      setErrorFieldsFromServer(currentFormRef, errorData)
      _notifyFailure(
        defaultFailMessage,
        defaultFailDescription,
        errorData?.detail
          ? curriculumErrorMessage[errorData?.detail] ??
              curriculumErrorMessage.internalServerError
          : undefined,
      )
    }
  }, [
    isModifying,
    creating.error,
    creating.isLoading,
    modifying.error,
    modifying.isLoading,
  ])

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    if (isModifying) {
      modifying.setPayload({
        ...currentFormRef.getFieldsValue(),
        status: undefined,
      })
      setModifyingKey(item)
    } else {
      creating.setPayload({
        ...currentFormRef.getFieldsValue(),
        ...hiddenFields,
      })
    }
  }

  const getRules = (
    isModifying: boolean,
  ): Rules<string | number | symbol, Rule[]> =>
    isModifying ? modifyingRules : creatingRules

  return (
    <EditSimpleItemFormView
      title={title}
      subTitle={subTitle}
      itemNameInputLabel={itemNameInputLabel}
      itemNameInputPlaceholder={itemNameInputPlaceholder}
      itemDescInputLabel={itemDescInputLabel}
      itemDescInputPlaceholder={itemDescInputPlaceholder}
      isVisible={isVisible}
      isLoading={creating.isLoading || modifying.isLoading}
      item={item}
      onFinish={_onFinish}
      onCancel={onCancel}
      rules={getRules(isModifying)}
      formRef={currentFormRef}
    />
  )
}

export default EditSimpleItemForm

const _notifySuccess = (defaultMessage: string, defaultDescription: string) => {
  notifySuccess({
    message: defaultMessage,
    description: defaultDescription,
  })
}

const _notifyFailure = (
  defaultMessage: string,
  defaultDescription: string,
  errorMessage?: ErrorMessage,
) => {
  notifyFailure({
    message: errorMessage?.message ?? defaultMessage,
    description: errorMessage?.desc ?? defaultDescription,
  })
}
