import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Nil } from '@/@types/composite'
import { careVisibility, checkKinds } from '@/constants/apps.care'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { AddCurriculumCareItemForm } from '@/apps/curriculum/components/add-curriculum-care-item-form'
import { CareItem } from '@/apps/careItem/@types/careItem'
import { useCareItemList } from '@/apps/careItem/hooks/useCareItemList'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import { Care, CheckKind } from '@/apps/care/@types/care'
import useCurriculumCareList from '@/apps/curriculum/hooks/useCurriculumCareList'
import { useCustomerCareDeletion } from '@/apps/customer/hooks/curriculum/useCustomerCareManipulation'
import { useCustomerCareItemDeletion } from '@/apps/customer/hooks/curriculum/useCustomerCareItemManipulation'
import CustomerCareProgramView from './CustomerCareProgram.View'

interface PropType {
  isMyCustomer?: boolean
  showDeleteCareButton?: boolean
  customerCurriculum: CustomerCurriculum
  customerCare: CustomerCare<Care, CustomerCareItem>
  customerCurriculumOpenedAt?: Date | Nil
  caculatedCustomerCurriculumClosedAt?: Date | Nil
  onFinishAddCustomerCareItem?: () => void
  onFinishDeleteCustomerCare?: () => void
  onFinishDeleteCustomerCareItem?: () => void
}

const CustomerCareProgram: React.FC<PropType> = ({
  isMyCustomer,
  customerCurriculum,
  showDeleteCareButton = false,
  customerCare,
  customerCurriculumOpenedAt,
  caculatedCustomerCurriculumClosedAt,
  onFinishAddCustomerCareItem,
  onFinishDeleteCustomerCare,
  onFinishDeleteCustomerCareItem,
}) => {
  const [isVisibleCareItemModal, setIsVisibleCareItemModal] = useState(false)
  const [isVisibleConfirmDeleteCare, setIsVisibleConfirmDeleteCare] =
    useState(false)
  const [isVisibleConfirmDeleteCareItem, setIsVisibleConfirmDeleteCareItem] =
    useState(false)
  const [selectedCare, setSelectedCare] = useState<CustomerCare<
    Care,
    CustomerCareItem
  > | null>(null)
  const [selectedCareItem, setSelectedCareItem] =
    useState<CustomerCareItem | null>(null)

  const customerCareDeletion = useCustomerCareDeletion()
  const customerCareItemDeletion = useCustomerCareItemDeletion()

  const fetchingCareItems = useCareItemList()

  const {
    data: curriculumCareListData,
    setParams: setCurriculumCareListParams,
  } = useCurriculumCareList()

  useEffect(() => {
    setCurriculumCareListParams({
      curriculum: customerCurriculum.curriculum,
    })
  }, [customerCurriculum])

  useEffect(() => {
    if (customerCare.careItems.length < 1) return
    fetchingCareItems.setParams({
      ids: _.chain(customerCare.careItems)
        .map('careItem')
        .uniq()
        .value()
        .map((el) => `${el}`),
    })
  }, [customerCare.careItems])

  const onClickAddCustomerCareItem = () => {
    setIsVisibleCareItemModal(true)
  }

  const _onFinishAddCustomerCareItem = () => {
    setIsVisibleCareItemModal(false)
    onFinishAddCustomerCareItem?.()
  }

  const onCancelAddCustomerCareItem = () => {
    setIsVisibleCareItemModal(false)
  }

  const onClickDeleteCustomerCare = (
    customerCare: CustomerCare<Care, CustomerCareItem>,
  ) => {
    setSelectedCare(customerCare)
    setIsVisibleConfirmDeleteCare(true)
  }

  const _onFinishDeleteCustomerCare = () => {
    setIsVisibleConfirmDeleteCare(false)
    setSelectedCare(null)
    onFinishDeleteCustomerCare?.()
  }

  const onCancelDeleteCustomerCare = () => {
    setIsVisibleConfirmDeleteCare(false)
    setSelectedCare(null)
  }

  const onClickDeleteCustomerCareItem = (
    customerCareItem: CustomerCareItem,
  ) => {
    setSelectedCareItem(customerCareItem)
    setIsVisibleConfirmDeleteCareItem(true)
  }

  const _onFinishDeleteCustomerCareItem = () => {
    setIsVisibleConfirmDeleteCareItem(false)
    setSelectedCareItem(null)
    onFinishDeleteCustomerCareItem?.()
  }

  const onCancelDeleteCustomerCareItem = () => {
    setIsVisibleConfirmDeleteCareItem(false)
    setSelectedCareItem(null)
  }

  const deleteCustomerCare = () => {
    customerCareDeletion.setKey(selectedCare?.id)
  }

  const deleteCustomerCareItem = () => {
    customerCareItemDeletion.setKey(selectedCareItem?.id)
  }

  return (
    <>
      <CustomerCareProgramView
        customerCare={customerCare}
        curriculumCareKeys={_.map(
          curriculumCareListData?.results ?? [],
          (care) => care.care,
        )}
        curriculumCareItemKeys={
          _.find(curriculumCareListData?.results ?? [], {
            care: customerCare.care.id,
          })?.careItems.map((careItem) => careItem.careItem) ?? []
        }
        careItems={buildCustomerCareItems(
          customerCare.careItems,
          fetchingCareItems?.data?.results ?? [],
        )}
        onClickAddCustomerCareItem={onClickAddCustomerCareItem}
        onClickDeleteCustomerCareItem={onClickDeleteCustomerCareItem}
        onClickDeleteCustomerCare={onClickDeleteCustomerCare}
        disabledDeleteCare={!isValidMyCustomer(isMyCustomer)}
        disabledDeleteCareItem={
          !isValidMyCustomer(isMyCustomer) ||
          isYesOrNo(customerCare.care.checkKind)
        }
        disabledAddCareItem={!isValidMyCustomer(isMyCustomer)}
        showDeleteCareButton={showDeleteCareButton}
        showSuspendedCare={
          customerCare.care.visibility === careVisibility.SUSPENDED
        }
      />
      <AddCurriculumCareItemForm
        isMyCustomer={isMyCustomer}
        isCalendarStartDate={true}
        customerCare={customerCare}
        customerCurriculumOpenedAt={customerCurriculumOpenedAt}
        customerCurriculumClosedAt={caculatedCustomerCurriculumClosedAt}
        customerProgress={customerCurriculum.progress}
        isVisible={isVisibleCareItemModal}
        onFinish={_onFinishAddCustomerCareItem}
        onCancel={onCancelAddCustomerCareItem}
      />
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeleteCare && !!selectedCare}
        title="케어 유형 삭제"
        content="케어 유형을 삭제하시겠습니까?"
        successNotiMessage="삭제 완료"
        successNotiDesc="성공적으로 케어 유형을 삭제했습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={customerCareDeletion.error}
        isLoadingRequest={customerCareDeletion.isLoading}
        response={customerCareDeletion.data}
        action={deleteCustomerCare}
        onFinish={_onFinishDeleteCustomerCare}
        onCancel={onCancelDeleteCustomerCare}
      />
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeleteCareItem && !!selectedCareItem}
        title="케어 아이템 삭제"
        content="목록에서 삭제하시겠습니까?"
        successNotiMessage="삭제 완료"
        successNotiDesc="성공적으로 케어 아이템을 삭제했습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={customerCareItemDeletion.error}
        isLoadingRequest={customerCareItemDeletion.isLoading}
        response={customerCareItemDeletion.data}
        action={deleteCustomerCareItem}
        onFinish={_onFinishDeleteCustomerCareItem}
        onCancel={onCancelDeleteCustomerCareItem}
      />
    </>
  )
}

export default CustomerCareProgram

const isValidMyCustomer = (isMyCustomer: boolean | undefined) =>
  !_.isUndefined(isMyCustomer) && isMyCustomer

const isYesOrNo = (checkKind: CheckKind) => checkKind === checkKinds.YES_OR_NO

const buildCustomerCareItems = (
  customerCareItem: CustomerCareItem[],
  careItems: CareItem[],
): CustomerCareItem[] => {
  if (!customerCareItem) return []
  if (customerCareItem.length < 1 || careItems.length < 1) return []

  const keys = _.map(customerCareItem, 'careItem')
  const filteredCareItems = _.chain(careItems)
    .filter((careItem) => _.includes(keys, careItem.id))
    .map((careItem) => {
      return {
        careItem: careItem.id,
        name: careItem.name,
        description: careItem.description,
        visibility: careItem.visibility,
      }
    })
    .value()

  const merged = _.map(customerCareItem, (curriculumCareItem) => {
    const careItem = _.find(
      filteredCareItems,
      (careItem) => careItem.careItem === curriculumCareItem.careItem,
    )
    return {
      ...careItem,
      ...curriculumCareItem,
    }
  })

  return merged as CustomerCareItem[]
}
