import { Nil } from '@/@types/composite'

const prefix = 'REACT_APP_'

const envs: Record<string, string | undefined> = Object.fromEntries(
  Object.entries(process.env)
    .filter(([k]) => k.startsWith(prefix))
    .map(([k, v]) => [k.replace(prefix, ''), v]),
)

export default {
  enviroment: envs.ENV,
  isTesting: process.env.NODE_ENV === 'test',
  apiserver: {
    baseUrl: envs.APISERVER_HOST,
    withCredentials: parseAsBoolean(envs.APISERVER_WITH_CREDENTIALS),
  },
}

function parseAsBoolean(value: string | Nil, defaultValue = 'false'): boolean {
  // eslint-disable-next-line prettier/prettier
  return new Set([
    'true', '1', 'y', 'yes',
    'ㅇㅇ', '참', '트루',
  ]).has(value?.trim()?.toLowerCase() || defaultValue)
}
