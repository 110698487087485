import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { dateFormat } from '@/utils/date'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { Nil } from '@/@types/composite'
import { orderingParamKey } from '@/constants/common'
import { activeProgresses, progresses } from '@/constants/apps.task'
import useLocationParams from '@/hooks/useLocationParams'

import useTodayCounselTaskList from '@/apps/dashboard/hooks/useTodayCounselTaskList'
import { paramKeys } from '@/apps/dashboard/constants'
import {
  StaffDailyTaskStatus,
  StaffDailyTaskStatusOrderingKey,
  StaffDailyTaskStatusParam,
  ItemsByTaskProgressType,
  CountByTaskProgressType,
} from '@/apps/dashboard/@types/staff-daily-tasks'
import { ActiveProgress } from '@/apps/task/@types/task'
import useTaskKindList from '@/apps/task/hooks/useTaskKindList'

import TodayCounselTaskListView from './TodayCounselTaskList.View'

const defaultIsDesc = false
const defaultPage = 1
const defaultLimit = 100
const defaultOrdering = 'mall_member_id'

const TodayCounselTaskList: React.FC = () => {
  const taskKinds = useTaskKindList()
  const customers = useTodayCounselTaskList()
  const locParams = useLocationParams<
    StaffDailyTaskStatusParam,
    StaffDailyTaskStatusOrderingKey
  >(paramKeys, {
    useUrl: false,
    defaultParams: { ordering: defaultOrdering, date: dateFormat(new Date()) },
    orderingParamKey,
    onSetParams: customers.setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['kind', 'customerSupport', 'customerProgressStatus'],
    booleanParams: ['isScheduled'],
    tryPreviousPage: true,
    paginatedData: customers.data,
    httpError: customers.error,
  })
  const [currentProgress, setCurrentProgress] = useState<ActiveProgress>(
    progresses.NEW,
  )
  const [items, setItems] =
    useState<ItemsByTaskProgressType>(itemsByTaskProgress)
  const [counts, setCounts] =
    useState<CountByTaskProgressType>(countsByTaskProgress)

  const onChangeParam = (
    key: keyof StaffDailyTaskStatusParam,
    value: ItemValue<StaffDailyTaskStatusParam>,
    historyAction?: ChangeHistoryAction<StaffDailyTaskStatusParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangeFilter = buildTableChangeFilter<StaffDailyTaskStatus>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeTab = (progress: ActiveProgress) => {
    if (!activeProgresses.includes(progress)) return
    setCurrentProgress(progress)
  }

  const onRefresh = () => {
    customers.mutate().then(_.noop)
  }

  useEffect(() => {
    const available = !!customers.data && !customers.isLoading
    if (!available) return

    const resultItems = _.chain(activeProgresses)
      .map((key) => [key, filterByTaskProgress(customers.data?.results, key)])
      .fromPairs()
      .value() as ItemsByTaskProgressType
    setItems(resultItems)

    const resultCounts = _.chain(resultItems)
      .toPairs()
      .map(([key, value]) => [key, value.length])
      .fromPairs()
      .value() as CountByTaskProgressType
    setCounts(resultCounts)
  }, [customers.data, customers.isLoading])

  const orderingKey = locParams.fullParams.orderingKey as
    | StaffDailyTaskStatusOrderingKey
    | undefined

  return (
    <TodayCounselTaskListView
      isInChargeOf={true}
      isLoading={customers.isLoading}
      data={items[currentProgress]}
      countsByProgress={counts}
      params={locParams.params}
      orderingKey={orderingKey}
      orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
      page={locParams.params.page ?? defaultPage}
      limit={locParams.params.limit ?? defaultLimit}
      count={customers.data?.count ?? 0}
      onChangePage={onChangePage}
      onChangeParam={onChangeParam}
      onChangeFilter={onChangeFilter}
      onChangeTab={onChangeTab}
      onChangeTaskProgress={onRefresh}
      onDeleteTask={onRefresh}
      taskKinds={taskKinds.data?.results ?? []}
    />
  )
}

const countsByTaskProgress: CountByTaskProgressType = {
  [progresses.NEW]: 0,
  [progresses.SENT]: 0,
  [progresses.DONE]: 0,
}

const itemsByTaskProgress: ItemsByTaskProgressType = {
  [progresses.NEW]: [],
  [progresses.SENT]: [],
  [progresses.DONE]: [],
}

type FiltersByTaskProgressType = Record<
  ActiveProgress,
  (items: StaffDailyTaskStatus[]) => StaffDailyTaskStatus[]
>

const newFilter = (o: StaffDailyTaskStatus) =>
  !!o.tasks.find((t) => t.progress === progresses.NEW)

const doneFilter = (o: StaffDailyTaskStatus) =>
  _.every(o.tasks, ['progress', progresses.DONE])

const filtersByTaskProgress: FiltersByTaskProgressType = {
  [progresses.NEW]: (items) => _.chain(items).filter(newFilter).value(),
  [progresses.SENT]: (items) =>
    _.chain(items)
      .filter(_.negate(newFilter))
      .filter(_.negate(doneFilter))
      .value(),
  [progresses.DONE]: (items) => _.chain(items).filter(doneFilter).value(),
}

const filterByTaskProgress = (
  customers: StaffDailyTaskStatus[] | Nil,
  taskProgress: ActiveProgress,
): StaffDailyTaskStatus[] => {
  return filtersByTaskProgress[taskProgress](customers ?? [])
}

export default TodayCounselTaskList
