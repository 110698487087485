import React from 'react'
import classNames from 'classnames/bind'
import { Select } from 'antd'
import _ from 'lodash'

import { aggregationPeriods } from '@/constants/apps.order'
import SubSectionTitle from '@/components/layout/section/SubSectionTitle'
import {
  CustomerRepurchaseSummary,
  CustomerRepurchaseSummaryParam,
  Order,
} from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer } from '@/apps/customer/@types/customer'
import { customerSupportLabels } from '@/constants/apps.customer'

import styles from './RepurchaseSummary.module.scss'

const cx = classNames.bind(styles)

interface PropType {
  customer: Customer<Order, User>
  summary: CustomerRepurchaseSummary
  params: CustomerRepurchaseSummaryParam
  showNotSynced: boolean
  onChangePeriod: (value: string) => void
}

const RepurchaseSummaryView: React.FC<PropType> = ({
  customer,
  summary,
  params,
  showNotSynced,
  onChangePeriod,
}) => {
  const repurchasedLabel =
    !summary.recentCount || summary.recentCount < 1 ? '없음' : '있음'
  const counselingLabel = showNotSynced ? '-' : getCounselingLabel(customer)

  return (
    <div className={cx('repurchase-container')}>
      <SubSectionTitle className="tw-pb-5">재주문 이력</SubSectionTitle>

      <div className={cx('item-wrapper')}>
        <div className={cx('label')}>재주문 여부</div>
        <div className={cx('content')}>{repurchasedLabel}</div>
      </div>

      <div className={cx('item-wrapper')}>
        <div className={cx('label')}>재주문 누적 집계</div>
        <div className={cx('content')}>
          <AggregationPeriod
            defaultValue={params.days}
            disabled={showNotSynced}
            onChange={onChangePeriod}
          />
          <RepurchaseAggregation
            showNotSynced={showNotSynced}
            recentCount={summary.recentCount}
            recentPaymentAmount={summary.recentPaymentAmount}
          />
        </div>
      </div>

      <div className={cx('item-wrapper')}>
        <div className={cx('label')}>재주문 상담 필요</div>
        <div className={cx('content')}>{counselingLabel}</div>
      </div>
    </div>
  )
}

interface AggregationPeriodPropType {
  defaultValue: keyof typeof aggregationPeriods
  disabled: boolean
  onChange: (value: string) => void
}

const AggregationPeriod: React.FC<AggregationPeriodPropType> = ({
  defaultValue = '7',
  disabled,
  onChange,
}) => {
  return (
    <Select
      className={cx('aggregation-period')}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    >
      {_.toPairs(aggregationPeriods).map(([value, label]) => (
        <Select.Option value={value} key={value.toString()}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}

type RepurchaseAggregationPropType = {
  showNotSynced: boolean
} & Pick<CustomerRepurchaseSummary, 'recentCount' | 'recentPaymentAmount'>

const RepurchaseAggregation: React.FC<RepurchaseAggregationPropType> = ({
  recentCount,
  recentPaymentAmount,
  showNotSynced,
}) => {
  const getSumPaymentAmount = () => {
    if (showNotSynced) return 0
    return recentPaymentAmount
  }

  return (
    <table className={cx('aggregation-wrapper')}>
      <tbody>
        <tr>
          <th>누적 재주문 건수</th>
          <td>총 {recentCount} 건</td>
        </tr>
        <tr>
          <th>누적 재주문 금액</th>
          <td>₩ {getSumPaymentAmount()}</td>
        </tr>
      </tbody>
    </table>
  )
}

const getCounselingLabel = (customer: Customer<Order, User>) => {
  return customerSupportLabels[customer.customerSupport] ?? '-'
}

export default RepurchaseSummaryView
