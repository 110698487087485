import React, { useState } from 'react'
import { Nil } from '@/@types/composite'
import { Customer } from '@/apps/customer/@types/customer'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { EditCustomerCurriculumForm } from '@/apps/customer/components/edit-customer-curriculum-form'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import CustomerCurriculumInfoView from './CustomerCurriculumInfo.View'
import { customerCurriculumProgress } from '@/constants/apps.customer'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import {
  useCustomerCurriculumClose,
  useCustomerCurriculumDeletion,
} from '@/apps/customer/hooks/curriculum/useCustomerCurriculumManipulation'

interface PropType {
  customerCurriculum: CustomerCurriculum | Nil
  caculatedCustomerCurriculumClosedAt: Date | Nil
  customer: Customer<Order, User>
  user: User | Nil
  isMyCustomer: boolean
  onFinishManipulation: () => void
}

const CustomerCurriculumInfo: React.FC<PropType> = ({
  customerCurriculum,
  caculatedCustomerCurriculumClosedAt,
  customer,
  user,
  isMyCustomer,
  onFinishManipulation,
}) => {
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
  const [isVisibleCloseModal, setIsVisibleCloseModal] = useState(false)
  const [isVisibleResetModal, setIsVisibleResetModal] = useState(false)

  const closing = useCustomerCurriculumClose()
  const deletion = useCustomerCurriculumDeletion()

  const onClickEdit = () => {
    setIsVisibleEditModal(true)
  }

  const onFinishEdit = () => {
    setIsVisibleEditModal(false)
    onFinishManipulation()
  }

  const onCancelEdit = () => {
    setIsVisibleEditModal(false)
  }

  const onClickClose = () => {
    setIsVisibleCloseModal(true)
  }

  const onFinishClose = async () => {
    setIsVisibleCloseModal(false)
    onFinishManipulation()
  }

  const onCancelClose = () => {
    setIsVisibleCloseModal(false)
  }

  const closeCurriculum = async () => {
    closing.setKey(customerCurriculum?.id)
  }

  const onClickReset = () => {
    setIsVisibleResetModal(true)
  }

  const onFinishReset = async () => {
    setIsVisibleResetModal(false)
    onFinishManipulation()
  }

  const onCancelReset = () => {
    setIsVisibleResetModal(false)
  }

  const resetCurriculum = async () => {
    deletion.setKey(customerCurriculum?.id)
  }

  return (
    <>
      <CustomerCurriculumInfoView
        customerCurriculum={customerCurriculum}
        caculatedCustomerCurriculumClosedAt={
          caculatedCustomerCurriculumClosedAt
        }
        disabledSet={!isMyCustomer || isOnProgressed(customerCurriculum)}
        disabledReset={
          !isMyCustomer ||
          isOnProgressed(customerCurriculum) ||
          isNotSetCurriculum(customerCurriculum)
        }
        disabledExit={
          !isMyCustomer ||
          !isOnProgressed(customerCurriculum) ||
          isNotSetCurriculum(customerCurriculum)
        }
        onClickEdit={onClickEdit}
        onClickClose={onClickClose}
        onClickReset={onClickReset}
      />
      <EditCustomerCurriculumForm
        customer={customer}
        user={user}
        customerCurriculum={customerCurriculum}
        isVisible={isVisibleEditModal}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
      <ConfirmFlowModal<CustomerCurriculum, unknown>
        visible={isVisibleCloseModal}
        title="현재 설정된 커리큘럼을 종료 하시겠습니까?"
        content={closeContent}
        successNotiMessage="종료 완료"
        successNotiDesc="커리큘럼 종료 처리를 완료하였습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={closing.error}
        isLoadingRequest={closing.isLoading}
        response={closing.data}
        action={closeCurriculum}
        onFinish={onFinishClose}
        onCancel={onCancelClose}
      />
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleResetModal}
        title="현재 설정된 커리큘럼을 초기화 하시겠습니까?"
        content="초기화할 경우, 커리큘럼 설정 관련 모든 데이터를 초기화하고 되돌릴 수 없습니다."
        successNotiMessage="초기화 완료"
        successNotiDesc="초기화 작업을 완료하였습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={deletion.error}
        isLoadingRequest={deletion.isLoading}
        response={deletion.data}
        action={resetCurriculum}
        onFinish={onFinishReset}
        onCancel={onCancelReset}
      />
    </>
  )
}

export default CustomerCurriculumInfo

const isNotSetCurriculum = (customerCurriculum: CustomerCurriculum | Nil) =>
  !customerCurriculum

const isOnProgressed = (customerCurriculum: CustomerCurriculum | Nil) =>
  customerCurriculum?.progress === customerCurriculumProgress.ON_PROGRESS

const closeContent = `종료 시, 아래와 같이 진행됩니다. 내용을 꼭 확인해주세요.
- 고객의 커리큘럼 종료일을 오늘로 변경합니다.
- 고객의 커리큘럼 설정 내용을 즉시 초기화합니다.
  (새로운 커리큘럼을 즉시 설정할 수 있습니다)
- 고객에게 새로운 커리큘럼을 설정하지 않은 경우,
  익일 0시부터 기본 커리큘럼 내용을 발행합니다.`
