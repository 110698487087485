import React from 'react'
import { useLocation } from 'react-router'
import _ from 'lodash'

import {
  HomeOutlined as IconHomeOutlined,
  TeamOutlined as IconTeamOutlined,
  UserOutlined as IconUserOutlined,
  SettingOutlined as IconSettingOutlined,
} from '@ant-design/icons'
import MenuNav, { MenuItem } from './MenuNav'
import { User, UserRole } from '@/apps/auth/@types/user'
import { PAGES as DASHBOARDS_PAGE } from '@/constants/apps.dashboard'
import { PAGES as AUTH_PAGE } from '@/constants/apps.auth'
import { PAGES as CUSTOMER_PAGE } from '@/constants/apps.customer'
import { PAGES as MEMO_PAGE } from '@/constants/apps.memo'
import { PAGES as CURRICULUM_PAGE } from '@/constants/apps.curriculum'
import { PAGES as CARE_PAGE } from '@/constants/apps.care'

const adminItems: MenuItem[] = [
  {
    href: DASHBOARDS_PAGE.dashboard.path,
    icon: IconHomeOutlined,
    title: DASHBOARDS_PAGE.dashboard.title,
  },
  {
    href: CUSTOMER_PAGE.customers.path,
    icon: IconTeamOutlined,
    title: CUSTOMER_PAGE.customers.title,
  },
  {
    href: 'alwaysopen',
    icon: IconSettingOutlined,
    title: '관리자 메뉴',
    children: [
      {
        href: AUTH_PAGE.users.path,
        title: AUTH_PAGE.users.title,
      },
      {
        href: CUSTOMER_PAGE.customerKinds.path,
        title: CUSTOMER_PAGE.customerKinds.title,
      },
      {
        href: CARE_PAGE.care.path,
        title: CARE_PAGE.care.title,
      },
      {
        href: CURRICULUM_PAGE.curriculums.path,
        title: CURRICULUM_PAGE.curriculums.title,
      },
      {
        href: MEMO_PAGE.memoKinds.path,
        title: MEMO_PAGE.memoKinds.title,
      },
    ],
  },
]

const staffItems: MenuItem[] = [
  {
    href: DASHBOARDS_PAGE.dashboard.path,
    icon: IconHomeOutlined,
    title: DASHBOARDS_PAGE.dashboard.title,
  },
  {
    href: CUSTOMER_PAGE.customers.path,
    icon: IconTeamOutlined,
    title: CUSTOMER_PAGE.customers.title,
  },
  {
    href: CUSTOMER_PAGE.myCustomers.path,
    icon: IconUserOutlined,
    title: CUSTOMER_PAGE.myCustomers.title,
  },
  {
    href: 'alwaysopen',
    icon: IconSettingOutlined,
    title: '관리자 메뉴',
    children: [
      {
        href: CARE_PAGE.care.path,
        title: CARE_PAGE.care.title,
      },
      {
        href: CURRICULUM_PAGE.curriculums.path,
        title: CURRICULUM_PAGE.curriculums.title,
      },
    ],
  },
]

type ItemsType = Readonly<Record<UserRole, Readonly<MenuItem[]>>>

interface PropType {
  user: User
}

const NavBar: React.FC<PropType> = ({ user }) => {
  const role = user.role
  const items: ItemsType = {
    admin: adminItems,
    staff: staffItems,
  }

  const location = useLocation()
  const selectedMenu = getPath(location.pathname)
  const openMenu = ['alwaysopen']

  return (
    <MenuNav
      data-testid="menu-nav"
      items={items[role]}
      defaultSelectedKeys={selectedMenu}
      openKeys={openMenu}
      isStaff={role === 'staff'}
    />
  )
}

export { adminItems, staffItems }
export default NavBar

export const getPath = (path: string) => {
  return _.chain(path.split('/'))
    .reduce<string[]>((acc, curr) => {
      if (!curr) return acc
      acc.push(`${acc.join('/')}/${curr}`)
      return acc
    }, [])
    .value()
}
