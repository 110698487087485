import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PAGES } from '@/constants/apps.auth'
import Login from '@/apps/auth/pages/login/Login'

const Public = () => {
  return (
    <Switch>
      <Route
        exact={PAGES.login.exact}
        path={PAGES.login.path}
        render={() => <Login />}
      />
    </Switch>
  )
}

export default Public
