import { ActiveProgress } from '@/apps/task/@types/task'

export const URLS = {
  taskList: '/tasks/tasks/',
  getTaskDetail: (key: number) => `/tasks/tasks/${key}/`,
  taskKindList: '/tasks/task-kinds/',
  getTaskKindDetail: (key: number) => `/tasks/task-kinds/${key}/`,
} as const

export const progresses = {
  SCHEDULED: 'scheduled',
  NEW: 'new',
  SENT: 'sent',
  DONE: 'done',
  EXPIRED: 'expired',
} as const

export const activeProgresses = [
  progresses.NEW,
  progresses.SENT,
  progresses.DONE,
]

export const progressLabels = {
  [progresses.SCHEDULED]: 'SCHEDULED',
  [progresses.NEW]: 'NEW',
  [progresses.SENT]: 'SENT',
  [progresses.DONE]: 'DONE',
  [progresses.EXPIRED]: 'EXPIRED',
} as const

export const issuedTaskTabNames: Record<ActiveProgress, string> = {
  [progresses.NEW]: '신규 상담 업무',
  [progresses.SENT]: '진행 중인 상담 업무',
  [progresses.DONE]: '완료한 상담 업무',
} as const

export const editableTaskKinds: Readonly<string[]> = [
  'general-schedule',
  'guide',
] as const

export const issuedTaskOrderingLabel = {
  kind_name: '업무 유형',
} as const

export const scheduledTaskOrderingLabel = {
  active_at: '알람 설정 일자',
  kind_name: '업무 유형',
  created_at: '생성 일자',
} as const

export const taskKindCategories = {
  GUIDE: 'guide',
  REGULAR_CONTACT: 'regular-contact',
  GENERAL_SCHEDULE: 'general-schedule',
  LOW_ACTIVITY: 'low-activity',
} as const

export const taskKindCategoryLabels = {
  [taskKindCategories.GUIDE]: '제품 가이드',
  [taskKindCategories.REGULAR_CONTACT]: '정기 컨택',
  [taskKindCategories.GENERAL_SCHEDULE]: '알람 스케쥴',
  [taskKindCategories.LOW_ACTIVITY]: '활동 저조',
}

export const taskKindCategorySortPriority = [
  taskKindCategories.LOW_ACTIVITY,
  taskKindCategories.REGULAR_CONTACT,
  taskKindCategories.GUIDE,
  taskKindCategories.GENERAL_SCHEDULE,
]
