import React from 'react'
import _ from 'lodash'

import { dateFormat } from '@/utils/date'
import Calendar from '@/components/antd/Calendar'
import style from './ActivityCalendar.module.scss'
import { CalendarDataInterface } from '@/apps/customer/@types/customer-activity'

interface PropType {
  calendarData: CalendarDataInterface
  handleSelectDate: (value: string) => void
  dateSelected: Date
}

const ActivityCalendarView: React.FC<PropType> = ({
  handleSelectDate,
  calendarData,
  dateSelected,
}) => {
  const dateCellRender = (value: Date) => {
    const percent = calendarData[`${dateFormat(value)}`]
    if (_.isNumber(percent) && dateSelected.getMonth() === value.getMonth()) {
      const customColor = () => {
        if (percent >= 0 && percent < 10) {
          return 'lack-status'
        } else if (percent >= 90) {
          return 'fulfill-status'
        } else {
          return 'normal-status'
        }
      }

      return (
        <div className={`activity-progress ${customColor()}`}>{`${
          _.isNumber(percent) ? percent.toFixed(0) : '0'
        }%`}</div>
      )
    }
  }

  return (
    <Calendar
      value={dateSelected}
      className={style['activity-calendar']}
      mode="month"
      dateCellRender={dateCellRender}
      onChange={(val) => {
        handleSelectDate(`${val}`)
      }}
    />
  )
}

export default ActivityCalendarView
