import React from 'react'
import { Form, Select, Input, FormInstance } from 'antd'
import parseISO from 'date-fns/parseISO'

import { DatePicker } from '@/components/antd'
import {
  CreatingFormRules,
  ModifyingFormRules,
  Task,
  TaskKind,
} from '@/apps/task/@types/task'
import FormModal from '@/components/modal/FormModal'

import styles from './EditTaskForm.module.scss'

interface PropType {
  formRef?: FormInstance
  isVisible?: boolean
  isLoading?: boolean
  task?: Task | null
  taskKinds: TaskKind[]
  rules?: ModifyingFormRules | CreatingFormRules
  onChangeKind: (taskKindId: number) => void
  onChangeContent: (value: string) => void
  onChangeActiveAt: (date: Date | null, dateString: string) => void
  onCancel: () => void
  onFinish: () => void
}

const { TextArea } = Input

const EditTaskViewForm: React.FC<PropType> = ({
  formRef,
  isVisible,
  isLoading,
  task,
  taskKinds,
  rules,
  onChangeKind,
  onChangeContent,
  onChangeActiveAt,
  onCancel,
  onFinish,
}) => {
  const title = task ? '업무 내용 수정' : '신규 업무 추가'
  const subTitle = task ? '업무 내용 수정' : '신규 업무 알람 추가'
  const datePlaceholder = '날짜 선택'
  const formInitialValue = task
    ? { ...task, activeAt: parseISO(task.activeAt) }
    : undefined

  return (
    <FormModal
      width="700px"
      visible={isVisible}
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
      contentClassName={styles.container}
    >
      <div className={styles.container}>
        <Form
          initialValues={formInitialValue}
          form={formRef}
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item label="업무 유형" name="kind" rules={rules?.kind}>
            <Select placeholder="선택" onChange={onChangeKind}>
              {taskKinds.map((o) => (
                <Select.Option value={o.id} key={o.id}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="업무 내용" name="content" rules={rules?.content}>
            <TextArea
              onChange={(e) => onChangeContent(e.target.value)}
              placeholder="설명 입력 (최대 노출 글자 수 27자)"
              rows={2}
            />
          </Form.Item>

          <Form.Item label="날짜 선택" name="activeAt" rules={rules?.activeAt}>
            <DatePicker
              placeholder={datePlaceholder}
              onChange={onChangeActiveAt}
            />
          </Form.Item>
        </Form>
      </div>
    </FormModal>
  )
}

export default EditTaskViewForm
