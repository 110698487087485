export const URLS = {
  memoKindList: '/notes/memo-kinds/',
  getMemoKindDetail: (key: number) => `/notes/memo-kinds/${key}/`,
  memoList: '/notes/memos/',
  getMemoDetail: (key: number) => `/notes/memos/${key}/`,
  createCustomerMemo: (key: string) => `/notes/memos/customers/${key}/`,
} as const

export const PAGES = {
  memoKinds: {
    name: '관리자 메뉴',
    title: '메모 유형 관리',
    requiredAuth: true,
    exact: true,
    path: '/memo-kinds',
    testId: 'memo-kinds-page',
  },
} as const

export const memoErrorKind = {
  INACTIVE: '사용 중단 상태입니다',
}

export const memoErrors: {
  [key: string]: { message: string; description: string }
} = {
  [memoErrorKind.INACTIVE]: {
    message: '요청 실패 ',
    description: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
  },
  store: {
    message: '처리 실패',
    description: '메모 유형 추가에 실패했습니다. 다시 시도해주세요.',
  },
  modify: {
    message: '수정 실패',
    description: '메모 유형 수정에 실패했습니다. 다시 시도해주세요.',
  },
}
