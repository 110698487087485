import { useState } from 'react'

import { post, patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.memo'
import { buildManipulationHook } from '@/utils/swr/helpers'
import {
  MemoKind,
  MemoKindModifyPayload,
  MemoKindCreatePayload,
} from '@/apps/memo/@types/memo-kind'

export const useMemoKindCreation = () => {
  return buildManipulationHook<MemoKindCreatePayload, MemoKind>({
    url: URLS.memoKindList,
    request: post,
  })
}

export const useMemoKindModification = () => {
  const [key, setKey] = useState<number | null | undefined>(null)
  return {
    ...buildManipulationHook<MemoKindModifyPayload, MemoKind>({
      url: key ? URLS.getMemoKindDetail(key) : null,
      request: patch,
    }),
    setMemoKindKey: setKey,
  }
}
