import { useState } from 'react'

import { post, patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import {
  buildManipulationHook,
  CreationHookReturnType,
  ModificationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import {
  CustomerKind,
  CustomerKindModifyPayload,
  CustomerKindCreatePayload,
} from '@/apps/customer/@types/customer-kind'

export const useCustomerKindCreation: () => CreationHookReturnType<
  CustomerKindCreatePayload,
  CustomerKind
> = () => {
  return buildManipulationHook<CustomerKindCreatePayload, CustomerKind>({
    url: URLS.customerKindList,
    request: post,
  })
}

export const useCustomerKindModification: () => ModificationHookReturnType<
  CustomerKindModifyPayload,
  CustomerKind
> = () => {
  const [key, setKey] = useState<Key>(null)
  return {
    ...buildManipulationHook<CustomerKindModifyPayload, CustomerKind>({
      url: key ? URLS.getCustomerKindDetail(key as number) : null,
      request: patch,
    }),
    setKey,
  }
}
