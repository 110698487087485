import React from 'react'

import { User } from '@/apps/auth/@types/user'
import { userRoleNames } from '@/apps/auth/constants'
import { getFullName } from '@/apps/auth/utils/helper'

interface PropType {
  user: User
}

const UserInfo: React.FC<PropType> = ({ user }) => {
  const { role, username } = user
  const roleName = userRoleNames[role] || ''
  const fullName = getFullName(user)
  return (
    <div className="tw-m-5 tw-p-2.5 tw-bg-gray-3 tw-bg-opacity-20 tw-border tw-border-white tw-border-opacity-20 tw-rounded-sm tw-mix-blend-normal">
      <p
        data-testid="user-name-and-role"
        className="tw-header-3 tw-text-white tw-mb-0"
      >
        {fullName} / {roleName}
      </p>
      <p data-testid="user-id" className="tw-body-4 tw-text-white tw-mb-0">
        {username}
      </p>
    </div>
  )
}

export default UserInfo
