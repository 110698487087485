import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import { PAGES as LOGIN_PAGES } from '@/constants/apps.auth'
import { PAGES as AUTH_PAGES } from '@/constants/apps.auth'
import { PAGES as DASHBOARD_PAGES } from '@/constants/apps.dashboard'
import { PAGES as CUSTOMER_PAGES } from '@/constants/apps.customer'
import { PAGES as MEMO_PAGES } from '@/constants/apps.memo'
import { PAGES as CURRICULUM_PAGES } from '@/constants/apps.curriculum'
import { PAGES as CARE_PAGES } from '@/constants/apps.care'
import { PAGES as CAREITEM_PAGES } from '@/constants/apps.careItem'

import { getDefaultStorage } from '@/utils/storages'
import { useUserContext } from '@/contexts/UserContext'

import useAuth from '@/apps/auth/hooks/useAuth'
import DefaultLayout from '@/components/layout/Default'
import Dashboard from '@/apps/dashboard/pages/Dashboard'
import UserList from '@/apps/auth/pages/users/UserList'
import CustomerList from '@/apps/customer/pages/customer-list/CustomerList'
import CustomerDetailIndex from '@/apps/customer/pages/customer-detail/Index'
import MyCustomers from '@/apps/customer/pages/my-customers/MyCustomers'
import { MemoKindList } from '@/apps/memo/pages/memo-kind-list'
import { CustomerKindList } from '@/apps/customer/pages/cutomer-kind-list'
import { CurriculumList } from '@/apps/curriculum/pages/curriculum-list'
import { CurriculumDetail } from '@/apps/curriculum/pages/curriculum-detail'
import { CareList } from '@/apps/care/pages/care-list'
import { CareItemList } from '@/apps/careItem/pages/care-item-list'
import DashboardAuth from '@/apps/dashboard/pages/DashboardAuth'
import { isStaff } from '@/apps/auth/utils/helper'

const storage = getDefaultStorage()

const RedirectToDashboard = () => (
  <Redirect to={DASHBOARD_PAGES.dashboard.path} />
)

const Authorized = () => {
  const { data, isAuthenticated, isLoading, setForceUpdate } = useAuth(storage)
  const location = useLocation<{ from: string }>()
  const { current, setCurrent } = useUserContext()

  useEffect(() => {
    if (location.pathname.startsWith(AUTH_PAGES.login.path)) return
    const shouldUpdate: boolean = location.state?.from === AUTH_PAGES.login.path
    setForceUpdate(shouldUpdate)
  }, [location.pathname])

  useEffect(() => {
    if (isLoading || !data || (data.id && current?.id === data.id)) return
    setCurrent(data)
  }, [isLoading, current, data, location.pathname])

  if (isLoading) return null

  const isStaffUser = (): boolean => isStaff(data)
  const isAdminUser = (): boolean => !!data && !isStaff(data)

  return (
    <Switch>
      {!isAuthenticated && (
        <Redirect
          to={{
            pathname: AUTH_PAGES.login.path,
            state: { from: location.pathname },
          }}
        />
      )}
      {isAuthenticated && (
        <DefaultLayout>
          <Switch>
            <Route
              exact={LOGIN_PAGES.login.exact}
              path={LOGIN_PAGES.login.path}
              render={() => (
                <Redirect
                  to={{
                    pathname: AUTH_PAGES.login.path,
                    state: { from: location.pathname },
                  }}
                />
              )}
            />
            <Route
              exact={DASHBOARD_PAGES.dashboard.exact}
              path={DASHBOARD_PAGES.dashboard.path}
              component={isAdminUser() ? DashboardAuth : Dashboard}
            />
            <Route
              exact={CUSTOMER_PAGES.customers.exact}
              path={CUSTOMER_PAGES.customers.path}
              component={CustomerList}
            />
            <Route
              exact={CUSTOMER_PAGES.customerDetail.exact}
              path={CUSTOMER_PAGES.customerDetail.path}
              component={CustomerDetailIndex}
            />
            <Route
              exact={CURRICULUM_PAGES.curriculums.exact}
              path={CURRICULUM_PAGES.curriculums.path}
              component={CurriculumList}
            />
            <Route
              exact={CURRICULUM_PAGES.curriculumDetail.exact}
              path={CURRICULUM_PAGES.curriculumDetail.path}
              component={CurriculumDetail}
            />
            <Route
              exact={CARE_PAGES.care.exact}
              path={CARE_PAGES.care.path}
              component={CareList}
            />
            <Route
              exact={CAREITEM_PAGES.careDetail.exact}
              path={CAREITEM_PAGES.careDetail.path}
              component={CareItemList}
            />
            <Route
              exact={CUSTOMER_PAGES.myCustomers.exact}
              path={CUSTOMER_PAGES.myCustomers.path}
              component={isStaffUser() ? MyCustomers : RedirectToDashboard}
            />
            <Route
              exact={CUSTOMER_PAGES.myCustomerDetail.exact}
              path={CUSTOMER_PAGES.myCustomerDetail.path}
              component={
                isStaffUser() ? CustomerDetailIndex : RedirectToDashboard
              }
            />
            <Route
              exact={AUTH_PAGES.users.exact}
              path={AUTH_PAGES.users.path}
              component={isAdminUser() ? UserList : RedirectToDashboard}
            />
            <Route
              exact={MEMO_PAGES.memoKinds.exact}
              path={MEMO_PAGES.memoKinds.path}
              component={isAdminUser() ? MemoKindList : RedirectToDashboard}
            />
            <Route
              exact={CUSTOMER_PAGES.customerKinds.exact}
              path={CUSTOMER_PAGES.customerKinds.path}
              component={isAdminUser() ? CustomerKindList : RedirectToDashboard}
            />
            <Route path="*">
              <Redirect to={DASHBOARD_PAGES.dashboard.path} />
            </Route>
          </Switch>
        </DefaultLayout>
      )}
    </Switch>
  )
}

export default Authorized
