import React from 'react'
import _ from 'lodash'
import { Nil } from '@/@types/composite'
import { dateFormat } from '@/utils/date'
import { DateISO8601, DateTimeISO8601 } from '@/@types/formats'

const DateText: React.FC<{ date?: DateTimeISO8601 | DateISO8601 | Nil }> = ({
  date,
}) => {
  const dateString = _.isNil(date)
    ? '0000-00-00'
    : dateFormat(date, 'yyyy-MM-dd')
  return <span>{dateString}</span>
}

export default DateText
