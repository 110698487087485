import { compile, ParseOptions, TokensToFunctionOptions } from 'path-to-regexp'

interface ResolvePath<T> {
  path: string
  params: T
  options?: ParseOptions & TokensToFunctionOptions
}

export const resolvePath = <T>(opt: ResolvePath<T>) => {
  return compile(opt.path, opt.options)(opt.params as Record<string, unknown>)
}
