import React from 'react'

import MyCustomerPurchaseStatus from '@/apps/dashboard/components/my-customer-purchase-status/MyCustomerPurchaseStatus'
import { MyCustomerList } from '@/apps/customer/components/my-customer-list'

const MyCustomersView: React.FC = () => {
  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-space-y-5">
      <MyCustomerPurchaseStatus />
      <MyCustomerList />
    </div>
  )
}

export default MyCustomersView
