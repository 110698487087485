import React from 'react'
import _ from 'lodash'
import { Pagination } from 'antd'
import classNames from 'classnames/bind'

import {
  issuedTaskOrderingLabel,
  progresses as allProgresses,
} from '@/constants/apps.task'
import QuerySection from '@/components/table/QuerySection'
import SectionTitle from '@/components/layout/section/SectionTitle'
import Section from '@/components/layout/section/Section'
import {
  BaseTaskListPropType,
  Task,
  ActiveProgress,
  TaskListParam,
  TaskListOrderingKey,
} from '@/apps/task/@types/task'
import TaskProgressTabs from '@/components/navigation/TaskProgressTabs'
import IssuedTaskTable from '@/apps/task/components/IssuedTaskTable'

import styles from './IssuedTaskList.module.scss'

const cx = classNames.bind(styles)

export interface PropType extends BaseTaskListPropType {
  isInChargeOf: boolean
  orderingIsDesc: boolean
  progresses?: ActiveProgress[]
  titles?: Record<string, number | undefined>
  defaultTabKey?: ActiveProgress
  onChangeTab?: (tab: ActiveProgress | string) => void
  onChangeProgress?: (task: Task) => void
  onDeleteTask?: (task: Task) => void
}

export const orderOptions = _.chain(issuedTaskOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const IssuedTaskListView: React.FC<PropType> = ({
  isInChargeOf,
  page,
  count,
  limit,
  data,
  params,
  isLoading,
  onChangePage,
  onChangeParam,
  taskKinds,
  orderingKey,
  orderingIsDesc,
  progresses = [allProgresses.NEW, allProgresses.SENT, allProgresses.DONE],
  defaultTabKey = allProgresses.NEW,
  titles,
  onChangeTab,
  onChangeProgress,
  onDeleteTask,
}) => {
  return (
    <Section className={cx('issued-task-container')}>
      <SectionTitle title="오늘의 상담 업무" />
      <TaskProgressTabs
        onChangeTab={onChangeTab}
        defaultTabKey={defaultTabKey}
        titles={titles}
      />

      <div className="tw-flex tw-justify-between tw-items-center tw-pb-4 tw-mt-5">
        <QuerySection<TaskListParam, TaskListOrderingKey>
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="업무 내용 검색"
          defaultOrderingIsDesc={orderingIsDesc}
          showSearch={false}
        />
      </div>

      <IssuedTaskTable
        data={data}
        pagination={false}
        page={page}
        limit={limit}
        isInChargeOf={isInChargeOf}
        progresses={progresses}
        taskKinds={taskKinds}
        onChangeTask={onChangeProgress}
        onDeleteTask={onDeleteTask}
      />

      {!!onChangePage && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page ?? 1}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

export default IssuedTaskListView
