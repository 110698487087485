import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'tailwindcss/tailwind.css'

import './components/antd/styles/themes/skinschool.less'
import PublicRoutes from '@/routes/Public'
import AuthorizedRoutes from '@/routes/Authorized'
import { UserProvider } from '@/contexts/UserContext'
import { LayoutProvider } from '@/contexts/LayoutContext'

function App() {
  return (
    <Suspense fallback={<div>loading...</div>}>
      <UserProvider>
        <BrowserRouter>
          <PublicRoutes />
          <LayoutProvider>
            <AuthorizedRoutes />
          </LayoutProvider>
        </BrowserRouter>
      </UserProvider>
    </Suspense>
  )
}

export default App
