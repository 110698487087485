import React, { useEffect, useState } from 'react'
import {
  ActivitiySummaryInterface,
  GradeInterface,
} from '@/apps/customer/@types/customer-activity'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import useDiariesAverage from '@/apps/customer/hooks/useDiariesAverage'

import ActivitiySummaryView from './ActivitiySummary.View'

interface PropType {
  customer: Customer<Order, User>
}

interface StateInterface {
  gradeSelected: GradeInterface
  activitySummaryInfo: ActivitiySummaryInterface
}

const ActivitiySummary: React.FC<PropType> = ({
  customer: {
    uid,
    curriculums,
    lastLogin,
    appFirstLoggedInAt,
    customerProgressStatus,
  },
}) => {
  const [{ gradeSelected, activitySummaryInfo }, setState] =
    useState<StateInterface>({
      gradeSelected: {
        key: 7,
        level: 0,
        percent: 0,
      },
      activitySummaryInfo: {
        care: '',
        title: '',
        status: '',
        start: '',
        login: '',
        recent: '',
        period: 0,
      },
    })

  const { data: progressData, days, setParams } = useDiariesAverage(uid)

  const calcLevel = (progress: number): number => {
    if (progress > 0 && progress <= 20) {
      return 1
    } else if (progress > 20 && progress <= 40) {
      return 2
    } else if (progress > 40 && progress <= 60) {
      return 3
    } else if (progress > 60 && progress <= 80) {
      return 4
    } else if (progress > 80) {
      return 5
    } else {
      return 0
    }
  }

  useEffect(() => {
    const curriculumOnProgress = curriculums
      ? curriculums.filter(({ progress }) => progress === 'on_progress')[0]
      : undefined
    const curriculumReserved = curriculums
      ? curriculums.filter(({ progress }) => progress === 'reserved')[0]
      : undefined

    const curriculumInfo = curriculumOnProgress || curriculumReserved

    if (curriculumInfo) {
      const { name, compilationKind, openedAt, period } = curriculumInfo
      setState((prev) => ({
        ...prev,
        activitySummaryInfo: {
          ...activitySummaryInfo,
          care: compilationKind,
          title: name,
          start: openedAt,
          status: customerProgressStatus,
          login: `${appFirstLoggedInAt || ''}`,
          recent: `${lastLogin || ''}`,
          period,
        },
      }))
    } else {
      setState((prev) => ({
        ...prev,
        activitySummaryInfo: {
          ...activitySummaryInfo,
          status: customerProgressStatus,
          login: `${appFirstLoggedInAt || ''}`,
          recent: `${lastLogin || ''}`,
        },
      }))
    }
  }, [curriculums])

  useEffect(() => {
    if (progressData?.averageScore) {
      const { averageScore: score } = progressData
      setState((prev) => ({
        ...prev,
        gradeSelected: {
          key: days,
          level: calcLevel(score ? Number(score) : 0),
          percent: score ? Number(score) : 0,
        },
      }))
    }
  }, [progressData])

  const handelSelectChange = (periodSelected: number) => {
    setParams({
      days: periodSelected,
    })
  }

  return (
    <ActivitiySummaryView
      data={activitySummaryInfo}
      grade={gradeSelected}
      handelSelectChange={handelSelectChange}
    />
  )
}

export default ActivitiySummary
