import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { Form, FormInstance, Radio, Input, Checkbox } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { Rule, Rules } from '@/@types/antd'
import { Care } from '@/apps/care/@types/care'
import FormModal from '@/components/modal/FormModal'
import styles from './EditCareForm.View.module.scss'
import {
  actionKindLabels,
  careCompilationLabels,
  careCompilations,
  checkKindLabels,
  curriculumCompilationCheckboxValues,
  essentialLabels,
  fieldsForInitializingFormData,
} from '@/constants/apps.care'

const cx = classNames.bind(styles)

interface PropType {
  care?: Care | null
  isVisible?: boolean
  isLoading?: boolean
  onFinish?: () => void
  onCancel?: () => void
  rules?: Rules<string | number | symbol, Rule[]>
  formRef?: FormInstance
  careNameInputPlaceholder: string
  careDescInputPlaceholder: string
}

const EditCareFormView: React.FC<PropType> = ({
  care,
  isVisible,
  isLoading,
  onFinish,
  onCancel,
  rules,
  formRef,
  careNameInputPlaceholder,
  careDescInputPlaceholder,
}) => {
  const isModifying = !!care
  const title = isModifying ? '유형 수정' : '신규 유형 생성'
  const subTitle = isModifying ? '케어 유형 수정' : '신규 케어 유형 추가'
  const formInitialValue = care ? buildInitialFormData(care) : undefined

  return (
    <FormModal
      title={title}
      subTitle={subTitle}
      width={700}
      visible={isVisible}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
      isLoading={isLoading}
    >
      <Form
        form={formRef}
        initialValues={formInitialValue}
        colon={false}
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item label="케어 유형" name="name" rules={rules?.name}>
          <Input
            className={cx('input-name')}
            placeholder={careNameInputPlaceholder}
            data-testid="formCare/name"
          />
        </Form.Item>

        <Form.Item label="설명" name="description" rules={rules?.description}>
          <TextArea
            className={cx('input-description')}
            placeholder={careDescInputPlaceholder}
            rows={3}
            data-testid="formCare/description"
          />
        </Form.Item>

        <Form.Item
          label="커리큘럼 유형"
          name="curriculumCompilation"
          rules={rules?.curriculumCompilation}
        >
          <Checkbox.Group options={curriculumCompilationOptions} />
        </Form.Item>

        <Form.Item
          label="필수/선택"
          name="isEssential"
          rules={rules?.isEssential}
        >
          <Radio.Group options={careEssentialOptions} />
        </Form.Item>

        <Form.Item
          label="액션 체크 방식"
          name="checkKind"
          rules={rules?.checkKind}
        >
          <Radio.Group options={careCheckKind} disabled={!!care} />
        </Form.Item>

        <Form.Item
          label="앱 화면 표시"
          name="actionKind"
          rules={rules?.actionKind}
        >
          <Radio.Group options={careActionKind} />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditCareFormView

const buildInitialFormData = (care: Care | null | undefined) => {
  const formData = _.pick(care, fieldsForInitializingFormData)
  return {
    ...formData,
    isEssential:
      typeof care?.isEssential === 'boolean'
        ? `${care.isEssential}`
        : undefined,
    curriculumCompilation: !care?.curriculumCompilation
      ? undefined
      : curriculumCompilationCheckboxValues[care.curriculumCompilation],
  } as unknown as FormData
}

const curriculumCompilationOptions = _.chain(careCompilationLabels)
  .pick([careCompilations.REGULAR, careCompilations.AUTONOMOUS])
  .toPairs()
  .map(([value, label]) => {
    const newLabel = label + ' 커리큘럼'
    return { value, label: newLabel }
  })
  .value()

const careEssentialOptions = _.chain(essentialLabels)
  .toPairs()
  .map(([value, label]) => {
    const newLabel = label + ' 케어'
    return { value, label: newLabel }
  })
  .value()

const careCheckKind = _.chain(checkKindLabels)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const careActionKind = _.chain(actionKindLabels)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()
