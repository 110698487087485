import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { Table, Typography, Pagination, TableProps, Checkbox } from 'antd'
import { ColumnFilterItem } from 'antd/lib/table/interface'

import {
  customerSupportLabels,
  noCustomerKindValues,
  myCustomersOrderingLabel,
} from '@/constants/apps.customer'
import { numbering } from '@/utils/ui/pagination'
import { dateTimeFormat } from '@/utils/date'
import { getFilteredValue, getFilters } from '@/utils/antd/table'
import { buildOrderAdminUrl } from '@/utils/cafe24'
import { itemToItems } from '@/utils/collections'
import { customerCurriculumProgressLabel } from '@/constants/apps.curriculum'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import LinkChannelTalkButton from '@/apps/customer/components/link-channeltalk-button/LinkChannelTalkButton'
import { SyncButton } from '@/apps/customer/components/sync-button'
import { DeleteButton } from '@/components/buttons/Icon'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { CustomerCurriculumProgressStatus } from '@/apps/curriculum/@types/curriculum'
import {
  Customer,
  CustomerListParam,
  CustomerListOrderingKey,
  BaseCustomerListPropType,
  CustomerSupport,
} from '@/apps/customer/@types/customer'
import { CustomerKind } from '@/apps/customer/@types/customer-kind'
import style from './MyCustomerList.module.scss'

const cx = classNames.bind(style)

type FullCustomer = Customer<Order, User>

export const orderOptions = _.chain(myCustomersOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

export const customerSupportFilters = _.chain(customerSupportLabels)
  .toPairs()
  .map(([value, text]) => ({ text, value }))
  .value()

export const getCustomerKindFilters = (customerKinds: CustomerKind[]) => {
  return customerKinds.reduce<ColumnFilterItem[]>(
    (acc, curr) => {
      acc.push({ text: curr.name, value: `${curr.id}` })
      return acc
    },
    [
      {
        text: noCustomerKindValues.name,
        value: noCustomerKindValues.value,
      },
    ],
  )
}

const renderLinkMemberId = (
  customer: FullCustomer,
  onClickLinkMemberId: (customer: FullCustomer) => void,
) => {
  return (
    <Typography.Link onClick={() => onClickLinkMemberId(customer)}>
      {customer.mallMemberId}
    </Typography.Link>
  )
}

const renderUsername = (value?: string) => {
  const username = value || '-'
  return <span>{username}</span>
}

const renderDayProgress = (value?: number) => {
  const dayProgress = _.isNil(value) ? '-' : `${value}%`
  return <span>{dayProgress}</span>
}

const renderLinkOrderId = (customer: Customer<Order>) => {
  const orderId = customer.lastOrder?.orderId
  if (!orderId) return <span>-</span>

  return (
    <Typography.Link href={buildOrderAdminUrl(orderId)} target="_blank">
      {orderId}
    </Typography.Link>
  )
}

const renderCustomerSupport = (value?: CustomerSupport) => {
  const customerSupportName = _.isNil(value)
    ? '-'
    : customerSupportLabels[value]
  return <span>{customerSupportName}</span>
}

const renderChannelTalkButton = (record: FullCustomer) => {
  return (
    <LinkChannelTalkButton
      chatService={record.chatService}
      chatUid={record.chatUid}
      uid={record.uid}
      icon="chat"
      label="채널톡"
      ghost
    />
  )
}

const renderCustomerKind = (customerKinds: CustomerKind[], kind?: number) => {
  const customerKindName = _.isNil(kind)
    ? noCustomerKindValues.name
    : customerKinds.find((customerKind) => customerKind.id === kind)?.name
  return <span>{customerKindName}</span>
}

const renderSyncButton = (
  customer: FullCustomer,
  onFinishSyncCafe24: () => void,
) => {
  return (
    <SyncButton
      id={customer.uid}
      isSynchronizable={customer.isSynchronizable}
      onFinish={onFinishSyncCafe24}
      tooltipLabel={
        customer.mallSyncedAt
          ? `최근 동기화 : ${dateTimeFormat(customer.mallSyncedAt)}`
          : null
      }
    />
  )
}

const renderDeleteButton = (
  customer: FullCustomer,
  onClickDelete: (customer: FullCustomer) => void,
) => {
  return (
    <DeleteButton
      data-testid="delete-my-customer-button"
      onClick={() => onClickDelete(customer)}
    />
  )
}

const renderCurriculumProgressStatus = (
  value?: CustomerCurriculumProgressStatus,
) => {
  const statusLabel = _.isNil(value)
    ? '-'
    : customerCurriculumProgressLabel[value]
  return <span>{statusLabel}</span>
}

const { Column } = Table

interface DataPropType extends BaseCustomerListPropType<Order, User> {
  onClickLinkMemberId: (customer: FullCustomer) => void
  onFinishSyncCafe24: () => void
  onClickDelete: (customer: FullCustomer) => void
  onChangeFilter: TableProps<FullCustomer>['onChange']
  onChangeShowOnlyNoMallSynced: (isShowOnlyNoMallSynced: boolean) => void
}

type PropType = DataPropType & React.HTMLAttributes<HTMLElement>

const MyCustomerListView: React.FC<PropType> = ({
  page,
  count,
  limit,
  data,
  customerKinds,
  params,
  isLoading,
  onChangePage,
  onChangeParam,
  orderingKey,
  orderingIsDesc,
  onClickLinkMemberId,
  onFinishSyncCafe24,
  onClickDelete,
  onChangeFilter,
  onChangeShowOnlyNoMallSynced,
}) => {
  const filteredKind = itemToItems<number>(params.kind)
  const filteredCustomerSupport = itemToItems<string>(params.customerSupport)

  return (
    <Section className={cx('container')}>
      <SectionTitle title="담당 고객 목록" />
      <div className="tw-pb-4">
        <QuerySection<CustomerListParam, CustomerListOrderingKey>
          wrapperName="mycustomerlist"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="ID, 고객명 검색"
          defaultOrderingIsDesc={orderingIsDesc}
        >
          <Checkbox
            onChange={(e) => onChangeShowOnlyNoMallSynced(e.target.checked)}
          >
            미동기화 고객만 보기
          </Checkbox>
        </QuerySection>
      </div>
      <Table<FullCustomer>
        dataSource={data}
        rowKey="uid"
        pagination={false}
        onChange={onChangeFilter}
      >
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column
          title="고객명"
          dataIndex="mallUserName"
          render={renderUsername}
        />

        <Column
          title="아이디"
          dataIndex="mallMemberId"
          render={(_, record: FullCustomer) =>
            renderLinkMemberId(record, onClickLinkMemberId)
          }
        />

        <Column title="최근 주문번호" render={renderLinkOrderId} />

        <Column
          title="고객 유형"
          dataIndex="kind"
          filteredValue={filteredKind}
          render={(value) => renderCustomerKind(customerKinds, value)}
          filters={getCustomerKindFilters(customerKinds)}
        />

        <Column
          title="재주문 상담"
          dataIndex="customerSupport"
          render={renderCustomerSupport}
          filteredValue={filteredCustomerSupport}
          filters={customerSupportFilters}
        />

        <Column
          title="커리큘럼 진행상태"
          dataIndex="customerProgressStatus"
          filters={getFilters(customerCurriculumProgressLabel)}
          filteredValue={getFilteredValue(params.customerProgressStatus)}
          render={renderCurriculumProgressStatus}
        />

        <Column
          title="어제 진척도"
          dataIndex="diaryScore"
          render={renderDayProgress}
        />

        <Column title="채널톡 바로가기" render={renderChannelTalkButton} />

        <Column
          title="동기화"
          render={(_, record: FullCustomer) =>
            renderSyncButton(record, onFinishSyncCafe24)
          }
        />

        <Column
          title="삭제"
          render={(_, record: FullCustomer) =>
            renderDeleteButton(record, onClickDelete)
          }
        />
      </Table>

      {!!count && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

export default React.memo(MyCustomerListView)
