import React from 'react'
import { notification } from 'antd'
import {
  CheckCircleOutlined as IconCheckCircleOutlined,
  CloseCircleOutlined as IconCloseCircleOutlined,
  CloseOutlined as IconCloseOutlined,
} from '@ant-design/icons/lib/icons'

interface notifyHelperParam {
  key?: string
  message: string
  description: string
}

export const notifySuccess = ({
  key,
  message,
  description,
}: notifyHelperParam) => {
  notification.open({
    key,
    message,
    description,
    icon: (
      <IconCheckCircleOutlined
        className="tw-text-blue-primary"
        style={{ verticalAlign: 0 }}
      />
    ),
    closeIcon: <IconCloseOutlined style={{ verticalAlign: 0 }} />,
  })
}

export const notifyFailure = ({
  key,
  message,
  description,
}: notifyHelperParam) => {
  notification.open({
    key,
    message,
    description,
    icon: (
      <IconCloseCircleOutlined
        className="tw-text-red-3"
        style={{ verticalAlign: 0 }}
      />
    ),
    closeIcon: <IconCloseOutlined style={{ verticalAlign: 0 }} />,
  })
}
