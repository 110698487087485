import { useState, useRef } from 'react'
import useSWR from 'swr'

import { get } from '@/clients/http/authorized-http-client'
import { IStorageAdaptor } from '@/utils/storages'
import {
  AUTH_STORAGE_KEY,
  URLS,
  AUTH_CHECKING_INTERVAL,
} from '@/constants/apps.auth'
import { User } from '@/apps/auth/@types/user'

const fetcher = (url: string | null) => {
  if (!url) return
  return get<never, User>(url)
}

const isExpired = (value: number, base: number, interval: number) => {
  return base - value > interval
}

const useAuth = (
  storage: IStorageAdaptor,
  interval = AUTH_CHECKING_INTERVAL,
) => {
  const getToken = () => {
    return storage.getItem(AUTH_STORAGE_KEY, true)
  }
  const setToken = (token: string | null) => {
    if (!token || token === getToken()) return

    storage.setItem(AUTH_STORAGE_KEY, token, true)

    const now = new Date().getTime()
    lastCheckedAt.current = now
    setUrl(`${URLS.me}?${now}`)
  }
  const setForceUpdate = (shouldUpdate?: boolean) => {
    const token = getToken()
    if (!token) return

    const now = new Date().getTime()
    if (shouldUpdate || isExpired(lastCheckedAt.current, now, interval)) {
      lastCheckedAt.current = now
      setUrl(`${URLS.me}?${now}`)
    }
  }

  const token = getToken()
  const lastCheckedAt = useRef<number>(token ? new Date().getTime() : 0)
  const [url, setUrl] = useState<string | null>(token ? URLS.me : null)

  const { data, error, isValidating } = useSWR(url, fetcher)
  const isLoading = !!url && !data && !error
  const isAuthenticated = !!url && !!data && !error

  return {
    data,
    error,
    isAuthenticated,
    getToken,
    setToken,
    setForceUpdate,
    isLoading,
    isValidating,
    lastCheckedAt: lastCheckedAt.current,
  }
}

export default useAuth
