import React from 'react'

import { Nil } from '@/@types/composite'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import IssuedTaskList from '@/apps/task/components/IssuedTaskList'
import { Customer } from '@/apps/customer/@types/customer'
import SyncAlert from '@/apps/customer/components/sync-alert/SyncAlert'
import { CustomerInfo } from '@/apps/customer/components/customer-info'
import { OrderSummary } from '@/apps/customer/components/order-summary'
import { ActivitiySummary } from '@/apps/customer/components/activity-summary'
import { ActivityHistory } from '@/apps/customer/components/activity-history'

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
}

const CustomerDetailView: React.FC<PropType> = ({ customer, user }) => {
  const isSynced = !!customer.mallSyncedAt

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-space-y-5">
      {!isSynced && <SyncAlert />}
      <IssuedTaskList user={user} customer={customer} />
      <CustomerInfo user={user} customer={customer} />
      <OrderSummary customer={customer} />
      <ActivitiySummary customer={customer} />
      <ActivityHistory customer={customer} />
    </div>
  )
}

export default CustomerDetailView
