import { useState } from 'react'

import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import { PagePaginationResponse } from '@/clients/http/types'
import {
  CustomerCurriculum,
  CustomerCurriculumListParam,
} from '@/apps/customer/@types/customer.curriculum'

const fetcher = (url: string | null, params?: CustomerCurriculumListParam) => {
  if (!url) return
  return get<
    CustomerCurriculumListParam,
    PagePaginationResponse<CustomerCurriculum>
  >(url, params)
}

const useCustomerCurriculumList = (defaultParams = {}) => {
  const [params, setParams] = useState<CustomerCurriculumListParam>({
    ...defaultParams,
  })

  const _setParams = (newParams: CustomerCurriculumListParam) => {
    setParams(_.merge({}, newParams))
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.customerCurriculumList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    setParams: _setParams,
    mutate,
  }
}

export default useCustomerCurriculumList
