import _ from 'lodash'
import { Nil } from '@/@types/composite'

const truthyText = new Set(['true', 'yes', 't', 'ㅇㅇ', '참', '트루'])
const falsyText = new Set(['false', 'no', 'f', 'ㄴㄴ', '거짓', '풜스'])

export const convertToBoolean = (value: unknown): boolean | Nil | unknown => {
  if (_.isBoolean(value) || _.isNil(value)) return value
  if (!_.isString(value)) return value

  const trimmed = _.trim(value).toLowerCase()
  return truthyText.has(trimmed)
    ? true
    : falsyText.has(trimmed)
    ? false
    : trimmed
}
