import React from 'react'
import _ from 'lodash'
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Typography,
  FormInstance,
} from 'antd'

import { Rules } from '@/@types/antd'
import { Nil } from '@/@types/composite'
import {
  User,
  UserRole,
  UserCreateFormData,
  UserModifyFormData,
  UserCreatePayload,
  UserModifyPayload,
} from '@/apps/auth/@types/user'
import {
  userRoles,
  userRoleTagLabel,
  fieldsForCreating,
  fieldsForModifying,
  fieldsForInitializingFormData,
  readonlyFieldsForModifying,
  readonlyFieldsForCreating,
} from '@/apps/auth/constants'
import { patternPassword } from '@/apps/auth/validators'
import { form as formError } from '@/apps/auth/messages/error'
import { hasAnyFieldsError } from '@/utils/antd/form'

import './UserEditForm.scss'

interface PropType {
  formRef?: FormInstance
  user?: User | null
  onSubmit?: (values: Payload) => void
  onCancel?: () => void
}

type FormData = UserCreateFormData | UserModifyFormData

type Payload = UserCreatePayload | UserModifyPayload

const roles = Object.values(userRoles)

const readonlyFieldsSetForModifying = new Set<unknown>(
  readonlyFieldsForModifying,
)

const readonlyFieldsSetForCreating = new Set<unknown>(readonlyFieldsForCreating)

const isDisabled = (user: User | Nil, fieldName: unknown | undefined) => {
  return !user
    ? !!fieldName && readonlyFieldsSetForCreating.has(fieldName)
    : !!fieldName && readonlyFieldsSetForModifying.has(fieldName)
}

const validators: Rules<'role' | 'firstName' | 'password' | 'passwordAgain'> = {
  role: [
    {
      required: true,
      message: formError.requiredField,
    },
    () => ({
      validator(__, value) {
        if (new Set(roles).has(value)) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(formError.invalidUserRole))
      },
    }),
  ],
  firstName: [
    {
      required: true,
      message: formError.requiredField,
    },
  ],
  password: [
    {
      required: true,
      message: formError.requiredField,
    },
    {
      pattern: patternPassword,
      message: formError.invalidFormat,
    },
  ],
  passwordAgain: [
    {
      required: true,
      message: formError.requiredField,
    },
    ({ getFieldValue }) => ({
      validator(__, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(formError.mismatchPassword))
      },
    }),
  ],
}

export const buildInitialFormData = (user: User | null | undefined) => {
  return _.pick(user, fieldsForInitializingFormData) as FormData
}

export const buildPayloadForCreating = (formData: FormData) => {
  return _.pick(formData, fieldsForCreating) as UserCreatePayload
}

export const buildPayloadForModifying = (formData: FormData) => {
  return _.pick(formData, fieldsForModifying) as UserModifyPayload
}

const UserEditForm: React.FC<PropType> = ({
  user,
  onSubmit,
  onCancel,
  formRef,
}) => {
  const [form] = Form.useForm()
  const currentFormRef = formRef ?? form

  const onFinish = (values: FormData) => {
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return
    const data = !user ? values : buildPayloadForModifying(values)
    !!onSubmit && onSubmit(data)
  }
  const onChangeUserRole = (value: UserRole) => {
    currentFormRef.setFieldsValue({ role: value })
  }

  return (
    <Form
      className="user-edit-form-container"
      colon={false}
      requiredMark={false}
      form={currentFormRef}
      initialValues={buildInitialFormData(user)}
      onFinish={onFinish}
    >
      <Space className="form-item-wrapper">
        <Form.Item label="계정 유형" name="role" rules={validators.role}>
          <Select
            placeholder="유형 선택"
            onChange={onChangeUserRole}
            disabled={isDisabled(user, 'role')}
            data-testid="formItem/role"
          >
            {roles.map((role) => (
              <Select.Option key={role} value={role}>
                {userRoleTagLabel[role]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space>

      <Space className="form-item-wrapper">
        <Form.Item
          label="사용자명"
          name="firstName"
          rules={validators.firstName}
        >
          <Input
            placeholder="영어, 한글, 숫자를 포함한 6자리 이상 문자열"
            disabled={isDisabled(user, 'firstName')}
            data-testid="formItem/firstName"
          />
        </Form.Item>
      </Space>

      <Space className="form-item-wrapper">
        <Form.Item
          label="비밀번호"
          name="password"
          rules={validators.password}
          data-testid="formItem/password"
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="비밀번호를 입력하세요"
          />
        </Form.Item>
        <Typography className="helpText">
          영어, 숫자, 특수문자를 포함한 10자리 이상 문자열
        </Typography>
      </Space>

      <Space className="form-item-wrapper">
        <Form.Item
          label="비밀번호 확인"
          name="passwordAgain"
          rules={validators.passwordAgain}
          data-testid="formItem/passwordAgain"
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="위와 동일한 비밀번호를 입력하세요"
          />
        </Form.Item>
      </Space>

      <Space className="form-item-wrapper">
        <Form.Item>
          {!!onCancel && (
            <Button
              htmlType="button"
              onClick={onCancel}
              data-testid="button/cancel"
            >
              취소
            </Button>
          )}

          {!!onSubmit && (
            <Button
              type="primary"
              htmlType="submit"
              data-testid="button/submit"
            >
              완료
            </Button>
          )}
        </Form.Item>
      </Space>
    </Form>
  )
}

export default UserEditForm
