import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'

import envs from '@/config/variables'
import {
  careError,
  careErrorMessage,
  curriculumCompilationCheckboxValues,
} from '@/constants/apps.care'
import { setErrorFieldsFromServer, hasAnyFieldsError } from '@/utils/antd/form'
import { convertToBoolean } from '@/utils/string'
import {
  Care,
  CareCreatePayload,
  CareModalFormRules,
  CareModifyPayload,
} from '@/apps/care/@types/care'
import EditCareFormView from './EditCareForm.View'
import _ from 'lodash'
import {
  CreationHookReturnType,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import { ErrorMessage } from '@/@types/error'
import { form as formError } from '@/apps/care/messages/error'

export interface PropType {
  care?: Care | null
  isVisible?: boolean
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  formRefForTesting?: FormInstance // only for testing
  creating: CreationHookReturnType<CareCreatePayload, Care>
  modifying: ModificationHookReturnType<CareModifyPayload, Care>
  setModifyingKey: (data: Care | null) => void
}
const maxLengthOfName = 40
const maxLengthOfDesc = 100

const EditCareForm: React.FC<PropType> = ({
  care,
  isVisible,
  onFinish,
  onCancel,
  formRefForTesting,
  creating,
  modifying,
  setModifyingKey,
}) => {
  const isModifying = !!care
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const action = isModifying ? '수정' : '저장'

  const defaultSuccessMessage = `${action} 완료`
  const defaultSuccessDescription = `성공적으로 케어 유형을 ${action}했습니다.`

  const defaultFailMessage = `${action} 실패`
  const defaultFailDescription = `케어 유형 ${action}에 실패했습니다. 다시 시도해주세요.`

  useEffect(() => {
    currentFormRef.resetFields()
  }, [])

  useEffect(() => {
    currentFormRef.resetFields()
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) return
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.data) || (isModifying && !modifying.data)) {
      return
    }
    creating.setPayload(null)
    modifying.setPayload(null)
    setModifyingKey(null)

    _notifySuccess(defaultSuccessMessage, defaultSuccessDescription)

    !!onFinish && onFinish(isModifying)
  }, [
    isModifying,
    creating.data,
    creating.isLoading,
    modifying.data,
    modifying.isLoading,
  ])

  useEffect(() => {
    if (!isVisible) return
    if (creating.isLoading || modifying.isLoading) return
    if (
      (!isModifying && !creating.error) ||
      (isModifying && !modifying.error)
    ) {
      return
    }
    const errorData = creating.error?.data ?? modifying.error?.data
    if (errorData === careError.CARE_NOT_FOUND && onCancel) {
      onCancel()
    }
    setErrorFieldsFromServer(currentFormRef, errorData)
    _notifyFailure(
      defaultFailMessage,
      defaultFailDescription,
      errorData?.detail
        ? careErrorMessage[errorData.detail] ??
            careErrorMessage.internalServerError
        : undefined,
    )
  }, [
    isModifying,
    creating.error,
    creating.isLoading,
    modifying.error,
    modifying.isLoading,
  ])

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return
    const formData = currentFormRef.getFieldsValue()
    const inverted = _.invert(curriculumCompilationCheckboxValues)

    if (isModifying) {
      modifying.setPayload({
        ...formData,
        id: care.id,
        curriculumCompilation: Number(inverted[formData.curriculumCompilation]),
        isEssential: convertToBoolean(formData.isEssential),
      })
      setModifyingKey(care)
    } else {
      creating.setPayload({
        ...formData,
        curriculumCompilation: Number(inverted[formData.curriculumCompilation]),
        isEssential: convertToBoolean(formData.isEssential),
      })
    }
  }

  useEffect(() => {
    currentFormRef.resetFields()
  }, [])

  useEffect(() => {
    currentFormRef.resetFields()
  }, [isVisible])

  return (
    <EditCareFormView
      care={care}
      isVisible={isVisible}
      onFinish={_onFinish}
      onCancel={onCancel}
      formRef={currentFormRef}
      rules={getRules()}
      careNameInputPlaceholder={`유형명 입력 (최대 노출 글자 수 ${maxLengthOfName}자)`}
      careDescInputPlaceholder={`설명 입력 (최대 노출 글자 수 ${maxLengthOfDesc}자)`}
    />
  )
}

export default EditCareForm

const _notifySuccess = (defaultMessage: string, defaultDescription: string) => {
  notifySuccess({
    message: defaultMessage,
    description: defaultDescription,
  })
}

const _notifyFailure = (
  defaultMessage: string,
  defaultDescription: string,
  errorMessage?: ErrorMessage,
) => {
  notifyFailure({
    message: errorMessage?.message ?? defaultMessage,
    description: errorMessage?.desc ?? defaultDescription,
  })
}

const getRules = (): CareModalFormRules => ({
  name: [
    {
      required: true,
      message: formError.name,
    },
    {
      type: 'string',
      max: maxLengthOfName,
      message: formError.invalidFormat,
    },
  ],
  description: [
    {
      required: true,
      message: formError.description,
    },
    {
      type: 'string',
      max: maxLengthOfDesc,
      message: formError.invalidFormat,
    },
  ],
  curriculumCompilation: [
    {
      required: true,
      message: formError.curriculumCompilation,
    },
  ],
  isEssential: [
    {
      required: true,
      message: formError.isEssential,
    },
  ],
  checkKind: [
    {
      required: true,
      message: formError.checkKind,
    },
  ],
  actionKind: [
    {
      required: true,
      message: formError.actionKind,
    },
  ],
})
