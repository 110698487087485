import React from 'react'
import classNames from 'classnames/bind'
import _ from 'lodash'
import { Table, Typography, Checkbox, TableProps, Pagination } from 'antd'
import { ColumnFilterItem } from 'antd/lib/table/interface'

import {
  customerSupportLabels,
  noCustomerKindValues,
  customersOrderingLabel,
} from '@/constants/apps.customer'
import { numbering } from '@/utils/ui/pagination'
import { buildOrderAdminUrl } from '@/utils/cafe24'
import { itemToItems } from '@/utils/collections'
import { dateTimeFormat } from '@/utils/date'

import Section from '@/components/layout/section/Section'
import QuerySection from '@/components/table/QuerySection'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import {
  Customer,
  CustomerListParam,
  CustomerListOrderingKey,
  BaseCustomerListPropType,
  CustomerSupport,
} from '@/apps/customer/@types/customer'
import { CustomerKind } from '@/apps/customer/@types/customer-kind'
import { getFullName } from '@/apps/auth/utils/helper'
import { SyncButton } from '@/apps/customer/components/sync-button'
import { AssignSelfButton } from '@/apps/customer/components/assign-self-button'

import style from './CustomerList.module.scss'

const cx = classNames.bind(style)

type FullCustomer = Customer<Order, User>

export const orderOptions = _.chain(customersOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

export const customerSupportFilters = _.chain(customerSupportLabels)
  .toPairs()
  .map(([value, text]) => ({ text, value }))
  .value()

const { Column } = Table

interface PropType extends BaseCustomerListPropType<Order, User> {
  onChangeShowUnassign: (isShowOnlyUnassign: boolean) => void
  onClickLinkMemberId: (customer: FullCustomer) => void
  onFinishSyncCafe24: () => void
  onFinishAssignSelf: () => void
  onChangeFilter: TableProps<FullCustomer>['onChange']
  onChangeShowOnlyNoMallSynced: (isShowOnlyNoMallSynced: boolean) => void
}

const CustomerListView: React.FC<PropType> = ({
  page,
  count,
  limit,
  data,
  customerKinds,
  params,
  isLoading,
  onChangePage,
  onChangeParam,
  orderingKey,
  orderingIsDesc,
  onChangeShowUnassign,
  onClickLinkMemberId,
  onFinishSyncCafe24,
  onFinishAssignSelf,
  onChangeFilter,
  onChangeShowOnlyNoMallSynced,
}) => {
  const filteredKind = itemToItems<number>(params.kind)
  const filteredCustomerSupport = itemToItems<string>(params.customerSupport)

  const renderLinkMemberId = (value: string | number, record: FullCustomer) => {
    return (
      <Typography.Link onClick={() => onClickLinkMemberId(record)}>
        {value}
      </Typography.Link>
    )
  }

  const renderCustomerKind = (value?: number) => {
    const customerKindName = _.isNil(value)
      ? noCustomerKindValues.name
      : customerKinds.find((kind) => kind.id === value)?.name
    return <span>{customerKindName}</span>
  }

  const renderSyncButton = (record: Customer) => {
    return (
      <SyncButton
        id={record.uid}
        isSynchronizable={record.isSynchronizable}
        onFinish={onFinishSyncCafe24}
        tooltipLabel={
          record.mallSyncedAt
            ? `최근 동기화 : ${dateTimeFormat(record.mallSyncedAt)}`
            : null
        }
      />
    )
  }

  const renderAssignSelfButton = (record: FullCustomer) => {
    return (
      <AssignSelfButton
        customer={record}
        disabled={!!record.staff}
        onFinish={onFinishAssignSelf}
      />
    )
  }

  return (
    <Section className={cx('customer-list')} title="전체 고객 목록">
      <div className=" tw-pb-4">
        <QuerySection<CustomerListParam, CustomerListOrderingKey>
          wrapperName="customerlist"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="ID, 고객명 검색"
          defaultOrderingIsDesc={orderingIsDesc}
        >
          <Checkbox onChange={(e) => onChangeShowUnassign(e.target.checked)}>
            미할당 고객만 보기
          </Checkbox>
          <Checkbox
            onChange={(e) => onChangeShowOnlyNoMallSynced(e.target.checked)}
          >
            미동기화 고객만 보기
          </Checkbox>
        </QuerySection>
      </div>
      <Table<FullCustomer>
        dataSource={data}
        rowKey="uid"
        pagination={false}
        onChange={onChangeFilter}
      >
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column
          title="고객명"
          dataIndex="mallUserName"
          render={renderUsername}
        />

        <Column
          title="아이디"
          dataIndex="mallMemberId"
          render={renderLinkMemberId}
        />

        <Column
          title="고객 유형"
          dataIndex="kind"
          render={renderCustomerKind}
          filteredValue={filteredKind}
          filters={getCustomerKindFilters(customerKinds)}
        />

        <Column title="담당 상담사" dataIndex="staff" render={renderStaff} />

        <Column
          title="어제 진척도"
          dataIndex="diaryScore"
          render={renderDayProgress}
        />

        <Column title="최근 주문번호" render={renderLinkOrderId} />

        <Column
          title="재주문 상담"
          dataIndex="customerSupport"
          render={renderCustomerSupport}
          filteredValue={filteredCustomerSupport}
          filters={customerSupportFilters}
        />

        <Column
          title="나에게 배정하기"
          dataIndex=""
          render={renderAssignSelfButton}
        />

        <Column title="동기화" render={renderSyncButton} />
      </Table>

      {!!count && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

export const getCustomerKindFilters = (customerKinds: CustomerKind[]) => {
  return customerKinds.reduce<ColumnFilterItem[]>(
    (acc, curr) => {
      acc.push({ text: curr.name, value: `${curr.id}` })
      return acc
    },
    [
      {
        text: noCustomerKindValues.name,
        value: noCustomerKindValues.value,
      },
    ],
  )
}

const renderUsername = (value?: string) => {
  const username = value || '-'
  return <span>{username}</span>
}

const renderStaff = (value?: User) => {
  const fullName = _.isNil(value) ? '-' : getFullName(value)
  const username = value?.username ?? '-'
  return <Typography>{`${fullName} / ${username}`}</Typography>
}

const renderDayProgress = (value?: number) => {
  const dayProgress = _.isNil(value) ? '-' : `${value}%`
  return <span>{dayProgress}</span>
}

const renderLinkOrderId = (customer: Customer<Order>) => {
  const orderId = customer.lastOrder?.orderId
  if (!orderId) return <span>-</span>

  return (
    <Typography.Link href={buildOrderAdminUrl(orderId)} target="_blank">
      {orderId}
    </Typography.Link>
  )
}

const renderCustomerSupport = (value?: CustomerSupport) => {
  const customerSupportName = _.isNil(value)
    ? '-'
    : customerSupportLabels[value]
  return <span>{customerSupportName}</span>
}

export default React.memo(CustomerListView)
