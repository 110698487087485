import React from 'react'
import classNames from 'classnames/bind'
import { Form, FormInstance, Pagination, Table } from 'antd'
import { Nil } from '@/@types/composite'
import { numbering } from '@/utils/ui/pagination'
import { checkKindLabels } from '@/constants/apps.care'
import FormModal from '@/components/modal/FormModal'
import { Care, CheckKind } from '@/apps/care/@types/care'
import { CreatingCurriculumCareFormRules } from '@/apps/curriculum/@types/curriculum.care'
import styles from './AddCurriculumCareForm.View.module.scss'

export interface PropType {
  page: number
  count: number
  limit: number
  data: Care[] | Nil
  isVisible?: boolean
  isLoading?: boolean
  onFinish?: () => void
  onCancel?: () => void
  onChangeSelect?: (id: number) => void
  onChangePage?: (page: number) => void
  rules?: CreatingCurriculumCareFormRules
  formRef?: FormInstance // only for testing
}

const AddCurriculumCareFormView: React.FC<PropType> = ({
  page,
  count,
  limit,
  data,
  isVisible,
  isLoading,
  onFinish,
  onCancel,
  onChangeSelect,
  onChangePage,
  rules,
  formRef,
}) => {
  if (!data) return null
  return (
    <FormModal
      className={cx('container')}
      visible={isVisible}
      isLoading={isLoading}
      title="케어 유형 추가"
      subTitle="케어 유형 신규 추가"
      okText="저장"
      cancelText="취소"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <div>
        <Form
          form={formRef}
          colon={false}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item label="케어 유형 선택" name="care" rules={rules?.care}>
            <Table<Care>
              dataSource={data}
              rowSelection={{
                type: 'radio',
                onChange: (key: React.Key[]) =>
                  onChangeSelect?.(key[0] as number),
              }}
              rowKey="id"
              pagination={false}
            >
              <Column
                title="번호"
                key="order"
                render={(_value, _record, index) =>
                  numbering(page, limit, index)
                }
              />
              <Column title="케어 유형" dataIndex="name" />
              <Column title="설명" dataIndex="description" />
              <Column
                title="액션 체크 방식"
                dataIndex="checkKind"
                render={(value) => <CheckKindText checkKind={value} />}
              />
            </Table>
            {!!count && (
              <Pagination
                hideOnSinglePage
                disabled={isLoading}
                defaultCurrent={page ?? 1}
                pageSize={limit}
                defaultPageSize={limit}
                total={count}
                onChange={onChangePage}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    </FormModal>
  )
}

export default AddCurriculumCareFormView

const cx = classNames.bind(styles)

const { Column } = Table

const CheckKindText: React.FC<{ checkKind: CheckKind }> = ({ checkKind }) => {
  return <span>{checkKindLabels[checkKind]} </span>
}
