import { useState } from 'react'
import useSWR from 'swr'
import { del, patch, post } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import {
  buildManipulationHook,
  CreationHookReturnType,
  Key,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import {
  CustomerCare,
  CustomerCareCreatePayload,
  CustomerCareItem,
  CustomerCareModifyPayload,
} from '@/apps/customer/@types/customer.care'
import { Care } from '@/apps/care/@types/care'

export const useCustomerCareCreation: () => CreationHookReturnType<
  CustomerCareCreatePayload,
  CustomerCare<Care, CustomerCareItem>
> = () => {
  return buildManipulationHook<
    CustomerCareCreatePayload,
    CustomerCare<Care, CustomerCareItem>
  >({
    url: URLS.customerCareList,
    request: post,
  })
}

export const useCustomerCareModification: () => ModificationHookReturnType<
  CustomerCareModifyPayload,
  CustomerCare<Care, CustomerCareItem>
> = () => {
  const [key, setKey] = useState<Key>(null)
  return {
    ...buildManipulationHook<
      CustomerCareModifyPayload,
      CustomerCare<Care, CustomerCareItem>
    >({
      url: key ? URLS.customerCareDetail(key as number) : null,
      request: patch,
    }),
    setKey,
  }
}

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCustomerCareDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating, mutate } = useSWR(
    [key ? URLS.customerCareDetail(key as number) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )

  const _setKey = (newKey: Key) => {
    if (newKey === key) {
      mutate()
      return
    }
    setKey(newKey)
  }

  const isLoading = !!key && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey: _setKey,
  }
}
