import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { orderingParamKey } from '@/constants/common'
import {
  careErrorMessage,
  confirmTextUpdatingCareVisibility,
  deletingDefaultCareMessage,
  visibilityManipulationCareDefaultMessage,
} from '@/constants/apps.care'
import {
  Care,
  CareCompilation,
  CareListOrderingKey,
  CareListParam,
  CareVisibility,
} from '@/apps/care/@types/care'
import EditCareForm from '@/apps/care/components/edit-care-form/EditCareForm'
import useCareList from '@/apps/care/hooks/useCareList'
import CareListView from './CareList.View'
import { buildOrderingQueryString } from '@/utils/http'
import useLocationParams from '@/hooks/useLocationParams'
import {
  useCareCreation,
  useCareDeletion,
  useCareModification,
} from '@/apps/care/hooks/useCareManipulation'
import { useCareVisibility } from '@/apps/care/hooks/useCareVisibilityManipulation'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { getVisibilityManipulation } from '@/utils/visibilityManipulation'
import { useUserContext } from '@/contexts/UserContext'

const paramsKeys: Array<keyof CareListParam> = [
  'page',
  'limit',
  'curriculumCompilation',
  'isEssential',
  'checkKind',
  'visibility',
  'ordering',
  'query',
]

const defaultPage = 1
const defaultLimit = 10
const defaultOrderingKey: CareListOrderingKey = 'updated_at'
const defaultIsDesc = true
const defaultCompilationKind: CareCompilation | CareCompilation[] = 1
const defaultListParams: CareListParam = {
  page: defaultPage,
  limit: defaultLimit,
  curriculumCompilation: defaultCompilationKind,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as CareListOrderingKey,
}

interface StateInteface {
  selected: Care | null
  selectedVisibility: CareVisibility | null
  isVisibleEditForm: boolean
  isVisibleConfirmVisibilityModal: boolean
  isVisibleConfirmDeletionModal: boolean
  isStaffUser: boolean
}

const CareList: React.FC = () => {
  const [
    {
      selected,
      isVisibleEditForm,
      isVisibleConfirmDeletionModal,
      selectedVisibility,
      isVisibleConfirmVisibilityModal,
      isStaffUser,
    },
    setState,
  ] = useState<StateInteface>({
    selected: null,
    selectedVisibility: null,
    isVisibleEditForm: false,
    isVisibleConfirmVisibilityModal: false,
    isVisibleConfirmDeletionModal: false,
    isStaffUser: false,
  })
  const { data, error, isLoading, setParams, mutate } = useCareList()
  const { current: user, isStaffUser: isStaffUserContext } = useUserContext()

  const modifying = useCareModification()
  const creating = useCareCreation()
  const deleting = useCareDeletion()
  const visibilityManipulation = useCareVisibility()

  const locParams = useLocationParams<CareListParam, CareListOrderingKey>(
    paramsKeys,
    {
      defaultParams: defaultListParams,
      orderingParamKey,
      onSetParams: setParams,
      numberParams: ['page', 'limit'],
      commaListParams: ['visibility', 'curriculumCompilation', 'checkKind'],
      booleanParams: ['isEssential'],
      tryPreviousPage: true,
      paginatedData: data,
      httpError: error,
    },
  )

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isStaffUser: isStaffUserContext,
    }))
  }, [user])

  const orderingKey = locParams.fullParams.orderingKey as
    | CareListOrderingKey
    | undefined

  const onChangeParam = (
    key: keyof CareListParam,
    value: ItemValue<CareListParam>,
    historyAction?: ChangeHistoryAction<CareListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onChangeTable = buildTableChangeFilter<Care>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddCare = () => {
    setState((prev) => ({
      ...prev,
      isVisibleEditForm: true,
    }))
  }

  const onClickEditCare = (care: Care) => {
    setState((prev) => ({
      ...prev,
      selected: care,
      isVisibleEditForm: true,
    }))
  }

  const onClickDeleteCare = (care: Care) => {
    setState((prev) => ({
      ...prev,
      selected: care,
      isVisibleConfirmDeletionModal: true,
    }))
  }

  const onClickVisibility = (care: Care, newVisibility: CareVisibility) => {
    if (care.visibility === newVisibility) return
    setState((prev) => ({
      ...prev,
      selected: care,
      selectedVisibility: newVisibility,
      isVisibleConfirmVisibilityModal: true,
    }))
  }

  const onFinishDelete = async () => {
    await mutate()
    deleting.setKey(null)
    setState((prev) => ({
      ...prev,
      isVisibleConfirmDeletionModal: false,
    }))
  }

  const onCancelDelete = onFinishDelete

  const deleteCare = () => {
    deleting.setKey(selected?.id)
  }

  const onFinishEdit = async () => {
    await mutate()
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleEditForm: false,
    }))
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
  }

  const onCancelEdit = () => {
    setState((prev) => ({
      ...prev,
      selected: null,
      isVisibleEditForm: false,
    }))
    creating.setPayload(null)
    modifying.setPayload(null)
    modifying.setKey(null)
  }

  const _setModifyingKey = (data: Care | null) => {
    modifying.setKey(data?.id)
  }

  const updateCareVisibility = () => {
    if (!selected || !selectedVisibility) return
    visibilityManipulation.setState((prev) => ({
      ...prev,
      key: selected?.id,
      manipulation: getVisibilityManipulation(
        selected?.visibility,
        selectedVisibility,
      ),
    }))
  }

  const onCancelUpdateVisibility = () => {
    visibilityManipulation.setState((prev) => ({
      ...prev,
      manipulation: null,
      key: null,
    }))
    setState((prev) => ({
      ...prev,
      selected: null,
      selectedVisibility: null,
      isVisibleConfirmVisibilityModal: false,
    }))
  }

  const onFinishUpdateVisibility = async () => {
    await mutate()
    onCancelUpdateVisibility()
  }

  return (
    <>
      <CareListView
        data={data?.results ? data?.results : []}
        page={locParams.params.page ?? defaultPage}
        limit={locParams.params.limit ?? defaultLimit}
        count={data?.count ?? 0}
        disabled={isStaffUser}
        isLoading={isLoading}
        params={locParams.params}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        onChangeParam={onChangeParam}
        onChangePage={onChangePage}
        onChangeTable={onChangeTable}
        onClickAddCare={onClickAddCare}
        onClickEditCare={onClickEditCare}
        onClickDeleteCare={onClickDeleteCare}
        onClickVisibility={onClickVisibility}
      />
      <EditCareForm
        care={selected}
        isVisible={isVisibleEditForm}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
        creating={creating}
        modifying={modifying}
        setModifyingKey={_setModifyingKey}
      />
      {!!selectedVisibility && (
        <ConfirmFlowModal<Care, unknown>
          visible={isVisibleConfirmVisibilityModal && !!selected}
          title={visibilityManipulationCareDefaultMessage.title}
          content={confirmTextUpdatingCareVisibility[selectedVisibility]}
          successNotiMessage={
            visibilityManipulationCareDefaultMessage.successNotiMessage
          }
          successNotiDesc={
            visibilityManipulationCareDefaultMessage.successNotiDesc
          }
          failNotiMessage={
            visibilityManipulation.errorDetail
              ? careErrorMessage[visibilityManipulation.errorDetail]?.message ??
                careErrorMessage.internalServerError.message
              : visibilityManipulationCareDefaultMessage.failNotiMessage
          }
          failNotiDesc={
            visibilityManipulation.errorDetail
              ? careErrorMessage[visibilityManipulation.errorDetail]?.desc ??
                careErrorMessage.internalServerError.desc
              : visibilityManipulationCareDefaultMessage.failNotiDesc
          }
          isFinishOnFail={true}
          response={visibilityManipulation.data}
          error={visibilityManipulation.error}
          isLoadingRequest={visibilityManipulation.isLoading}
          action={updateCareVisibility}
          onFinish={onFinishUpdateVisibility}
          onCancel={onCancelUpdateVisibility}
        />
      )}
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeletionModal && !!selected}
        title={deletingDefaultCareMessage.title}
        content={deletingDefaultCareMessage.content}
        successNotiMessage={deletingDefaultCareMessage.successNotiMessage}
        successNotiDesc={deletingDefaultCareMessage.successNotiDesc}
        failNotiMessage={
          deleting.errorDetail
            ? careErrorMessage[deleting.errorDetail]?.message ??
              careErrorMessage.internalServerError.message
            : deletingDefaultCareMessage.failNotiMessage
        }
        failNotiDesc={
          deleting.errorDetail
            ? careErrorMessage[deleting.errorDetail]?.desc ??
              careErrorMessage.internalServerError.desc
            : deletingDefaultCareMessage.failNotiDesc
        }
        isFinishOnFail={true}
        response={deleting.data}
        error={deleting.error}
        isLoadingRequest={deleting.isLoading}
        action={deleteCare}
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
      />
    </>
  )
}

export default CareList
