import React from 'react'
import classNames from 'classnames/bind'
import { Pagination, Table, TableProps, Tag } from 'antd'

import { DataQueryPropType } from '@/@types/data-query'
import { dateFormat } from '@/utils/date'
import { itemToItems } from '@/utils/collections'
import Section from '@/components/layout/section/Section'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import { DeleteButton, EditButton } from '@/components/buttons/Icon'
import { SortOption } from '@/components/table/QuerySection.View'
import TruncatedTextBox from '@/components/truncated-text-box/TruncatedTextBox'
import { MemoKind } from '@/apps/memo/@types/memo-kind'
import {
  Memo,
  MemoListOrderingKey,
  MemoListParam,
} from '@/apps/memo/@types/memo'

import styles from './MemoList.View.module.scss'

const cx = classNames.bind(styles)

const { Column } = Table

export const sortOptions: SortOption[] = [
  {
    label: '최신순',
    value: true,
  },
  {
    label: '과거순',
    value: false,
  },
]

export interface DataPropType<PT>
  extends DataQueryPropType<PT, Memo, MemoListOrderingKey> {
  isInChargeOf: boolean
  memoKinds: MemoKind[]
  foldContent: boolean
  onClickEdit: (item: Memo) => void
  onClickDelete: (item: Memo) => void
  onChangeTable?: TableProps<Memo>['onChange']
  onClickAddMemo?: () => void
}

type PropType<PT> = DataPropType<PT> & React.HTMLAttributes<HTMLElement>

const MemoListView = <PT extends MemoListParam>(
  props: React.PropsWithChildren<PropType<PT>>,
) => {
  const {
    isInChargeOf,
    page,
    count,
    limit,
    params,
    data,
    memoKinds,
    orderingKey,
    orderingIsDesc,
    isLoading,
    foldContent,
    onChangePage,
    onChangeParam,
    onClickEdit,
    onClickDelete,
    onChangeTable,
    onClickAddMemo,
  } = props

  const columnFilters = memoKinds.map((o) => ({
    text: o.name,
    value: o.id,
  }))
  const filteredKind = itemToItems<number>(params.kind)

  return (
    <Section title="메모 관리" className={cx('container')}>
      <div className="tw-flex tw-justify-between tw-items-center tw-pb-4">
        <QuerySection<PT, MemoListOrderingKey>
          wrapperName="memolist"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          searchPlaceholder="메모 내용 검색"
          searchParamKey="content"
          defaultOrderingIsDesc={orderingIsDesc}
          sortOptions={sortOptions}
        />
        {!!onClickAddMemo && (
          <BasicButton
            disabled={!isInChargeOf}
            className="tw-ml-auto"
            label="메모 등록"
            icon="plus"
            data-testid="memolist/btn-add"
            onClick={onClickAddMemo}
          />
        )}
      </div>

      <Table<Memo>
        dataSource={data}
        rowKey="id"
        pagination={false}
        onChange={onChangeTable}
      >
        <Column
          title="갱신 일자"
          dataIndex="updatedAt"
          render={renderDate}
          className={cx('col-updated')}
        />

        <Column<Memo>
          title="메모 유형"
          dataIndex="kind"
          render={(__, record) => (
            <MemoKindTag kindId={record.kind} memoKinds={memoKinds} />
          )}
          className={cx('col-kind')}
          filters={columnFilters}
          filteredValue={filteredKind}
        />

        <Column
          title="메모 내용"
          dataIndex="content"
          className={cx('col-content-wrapper')}
          render={(value) => (
            <TruncatedTextBox text={value} isFold={foldContent} />
          )}
        />

        <Column<Memo>
          title="수정"
          render={(__, record) => (
            <ButtonEdit
              memo={record}
              isInChargeOf={isInChargeOf}
              onClick={onClickEdit}
            />
          )}
          className={cx('col-action')}
        />

        <Column<Memo>
          title="삭제"
          render={(__, record) => (
            <ButtonDelete
              memo={record}
              isInChargeOf={isInChargeOf}
              onClick={onClickDelete}
            />
          )}
          className={cx('col-action')}
        />
      </Table>

      {!!count && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page ?? 1}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

const renderDate = (value: string) => dateFormat(value, 'yyyy-MM-dd')

interface MemoKindTagPropType {
  kindId: number
  memoKinds: MemoKind[]
}

const MemoKindTag: React.FC<MemoKindTagPropType> = ({ kindId, memoKinds }) => {
  const memoKind = memoKinds.find((o) => o.id === kindId)
  if (!memoKind) return <span>-</span>
  return (
    <Tag className={cx('tag-memo-kind', memoKind.status)}>{memoKind.name}</Tag>
  )
}

interface ManipulationPropType {
  memo: Memo
  isInChargeOf: boolean
  onClick: (memo: Memo) => void
}

const ButtonEdit: React.FC<ManipulationPropType> = ({
  memo,
  isInChargeOf,
  onClick,
}) => (
  <EditButton
    disabled={!isInChargeOf}
    onClick={() => onClick(memo)}
    data-testid={`memolist/btn-edit-${memo.id}`}
  />
)

const ButtonDelete: React.FC<ManipulationPropType> = ({
  memo,
  isInChargeOf,
  onClick,
}) => (
  <DeleteButton
    disabled={!isInChargeOf}
    onClick={() => onClick(memo)}
    data-testid={`memolist/btn-delete-${memo.id}`}
  />
)

export default MemoListView
