import React from 'react'

import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import { CustomerOrderSummary, Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer, SimpleCustomer } from '@/apps/customer/@types/customer'
import LatestOrderView from './LatestOrder.View'
import RepurchaseSummary from './RepurchaseSummary'

interface PropType<CT> {
  customer: Customer<Order, User>
  summary: CustomerOrderSummary<CT>
}

const OrderSummaryView = <CT extends SimpleCustomer>(
  props: React.PropsWithChildren<PropType<CT>>,
) => {
  const { summary, customer } = props
  return (
    <Section isLargeBottom>
      <SectionTitle title="구매 활동 요약" />
      <LatestOrderView order={summary.latestOrder} />
      <RepurchaseSummary customer={customer} />
    </Section>
  )
}

export default OrderSummaryView
