import { useState } from 'react'
import useSWR from 'swr'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.task'
import { PagePaginationResponse } from '@/clients/http/types'
import { Task, TaskListParam } from '@/apps/task/@types/task'
import { BooleanParamValue } from '@/utils/http/types'

const fetcher = (url: string | null, params?: TaskListParam) => {
  if (!url) return
  return get<TaskListParam, PagePaginationResponse<Task>>(url, params)
}

const useTaskList = (
  user: string | undefined,
  isScheduled: boolean | BooleanParamValue,
  defaultParams = {},
) => {
  const [params, setParams] = useState<TaskListParam>({
    user: !user ? '' : user,
    ...defaultParams,
    isScheduled: String(isScheduled) as BooleanParamValue,
  })

  const _setParams = (params: TaskListParam) => {
    setParams({
      ...params,
      user: !user ? '' : user,
      isScheduled: String(isScheduled) as BooleanParamValue,
    })
  }
  const { data, error, isValidating, mutate } = useSWR(
    !user ? null : [URLS.taskList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    setParams: _setParams,
    mutate,
  }
}

export default useTaskList
