/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import {
  isAfter,
  format,
  getDay,
  add as addDate,
  max as maxDate,
} from 'date-fns'
import { Nil } from '@/@types/composite'
import { WeekdaysShortLabel } from '@/@types/date'
import { cycle } from '@/utils/itertools'
import { weekdaysValue, weekdayValuesStartWith0 } from '@/constants/common'
import { careItemReptitionKind } from '@/constants/apps.careItem'
import { CareItemReptitionKind } from '@/apps/careItem/@types/careItem'
import {
  CurriculumCare,
  CurriculumCareItem,
} from '@/apps/curriculum/@types/curriculum.care'

export const dateFormat = <RT extends string>(
  value: Date | number | string,
  pattern = 'yyyy-MM-dd',
) => {
  return format(_.isString(value) ? new Date(value) : value, pattern) as RT
}

export const dateTimeFormat = <RT extends string>(
  value: Date | number | string,
  pattern = 'yyyy-MM-dd hh:mm:ss',
) => {
  return format(_.isString(value) ? new Date(value) : value, pattern) as RT
}

/**
 * @param {WeekdaysShortLabel[]} days 배열로 담은 요일. i.e) `["월", "수"]`
 * @returns {number} 요일을 비트 연산(`|`)으로 조합한 정수. i.e) `10` = 월 == 2(`1 << 1`) | 수 == 8(`1 << 3`)
 */
export const getCompositedNumberFromWeekdays = (days: WeekdaysShortLabel[]) =>
  _.chain(days)
    .reduce(
      (acc: number, curr: string) => _.get(weekdaysValue, curr, 0) | acc,
      0,
    )
    .value() as number

/**
 * @param {number} value 비트 연산(`|`)으로 조합한 정수. i.e) `10` (== `2 | 8`)
 * @returns {WeekdaysShortLabel[]} 요일을 배열로 반환한다. i.e) `["월", "수"]`
 */
export const weekdaysFromCompositedNumber = (value: number) =>
  _.chain(weekdaysValue)
    .toPairs()
    .sortBy([1])
    .reduce((acc, [label, weekdayValue]) => {
      if ((weekdayValue & value) > 0) acc.push(label)
      return acc
    }, [] as string[])
    .value() as WeekdaysShortLabel[]

/**
 * @param {number} value 비트 연산(`|`)으로 조합한 정수. i.e) `10` (== `2 | 8`)
 * @returns {number[]} 요일의 정수값을 배열로 반환한다. i.e) `[2, 8]`
 */
export const weekdayValuesFromCompositedNumber = (value: number) =>
  _.chain(weekdaysValue)
    .values()
    .reduce((acc, weekdayValue) => {
      if ((weekdayValue & value) > 0) acc.push(weekdayValue)
      return acc
    }, [] as number[])
    .sortBy()
    .value() as number[]

export const calculateClosedAtWeekdays = (
  date: Date,
  totalRepetitions: number,
  repetitionInterval: number,
) => {
  const baseDateWeekday = getDay(date)
  const weekdayKeys = weekdaysFromCompositedNumber(repetitionInterval)
  if (weekdayKeys.length === 0) return date

  const openedWeekdays = _.chain(weekdayKeys)
    .map((v) => weekdayValuesStartWith0[v])
    .value()
  const firstWeekday = openedWeekdays[0]
  const daysForNextWeek = firstWeekday - baseDateWeekday
  const waitingDays = daysForNextWeek + (daysForNextWeek <= 0 ? 7 : 0)

  let result = addDate(date, { days: waitingDays })
  let prevWeekDay: number | null = null
  const openedWeekdaysCycle = cycle<number>(openedWeekdays)
  _.chain(_.range(totalRepetitions))
    .forEach(() => {
      const weekday = openedWeekdaysCycle.next()
      const value = weekday.value as number
      if (prevWeekDay === null) {
        prevWeekDay = value
        return
      }
      if (value === firstWeekday) {
        result = addDate(result, {
          days: Math.abs(7 - prevWeekDay + value),
        })
        prevWeekDay = value
      } else {
        result = addDate(result, {
          days: value - prevWeekDay,
        })
        prevWeekDay = value
      }
    })
    .value()
  return result
}

export const getCareItemClosedAt = <
  T extends {
    openInDays: number | null
    repetitionKind: CareItemReptitionKind
    repetitionInterval: number | null
    totalRepetitions: number | null
  },
>(
  careItem: T,
  openedAt: Date,
): Date | Nil => {
  const { repetitionKind, totalRepetitions, repetitionInterval, openInDays } =
    careItem
  switch (repetitionKind) {
    case careItemReptitionKind.DAILY:
      if (!totalRepetitions) return null
      return addDate(addDate(openedAt, { days: openInDays ?? 1 - 1 }), {
        days: totalRepetitions - 1,
      })

    case careItemReptitionKind.ONE_TIME:
      return addDate(openedAt, { days: openInDays ?? 1 - 1 })

    case careItemReptitionKind.DAYS:
      if (!totalRepetitions || !repetitionInterval) return null
      return addDate(addDate(openedAt, { days: openInDays ?? 1 - 1 }), {
        days: repetitionInterval * totalRepetitions - 1,
      })

    case careItemReptitionKind.WEEK_DAYS:
      if (!totalRepetitions || !repetitionInterval) return null
      return calculateClosedAtWeekdays(
        openedAt,
        totalRepetitions,
        repetitionInterval,
      )
  }
}

export const getMaxClosedAtFromCurriculumCares = (
  cares: CurriculumCare[],
  openedAt: Date,
): Date | null => {
  const dates = _.chain(cares)
    .map((care) => {
      if (_.isEmpty(care.careItems)) return undefined
      return _.reduce(
        care.careItems,
        (acc: any, cur: CurriculumCareItem) => {
          const curClosedAt = getCareItemClosedAt(cur, openedAt)
          if (!curClosedAt) return null
          if (_.isUndefined(acc)) return curClosedAt
          return _.isNull(acc)
            ? null
            : isAfter(acc, curClosedAt)
            ? acc
            : curClosedAt
        },
        undefined,
      )
    })
    .filter((date) => !_.isUndefined(date))
    .value()

  if (_.some(dates, (date) => _.isNull(date))) return null
  return maxDate(dates)
}
