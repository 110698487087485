import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useLayoutContext } from '@/contexts/LayoutContext'
import { PageTitle } from '@/components/layout/page/page-title/PageTitle'
import { Care } from '@/apps/care/@types/care'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import useCurriculumDetail from '@/apps/curriculum/hooks/useCurriculumDetail'
import useCurriculumCareList from '@/apps/curriculum/hooks/useCurriculumCareList'
import useCareList from '@/apps/care/hooks/useCareList'
import CurriculumDetailView from './CurriculumDetail.View'

const CurriculumDetail: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { setTitleSectionData } = useLayoutContext()

  const {
    data: curriculumDetailData,
    isLoading: isCurriculumDetailLoading,
    error: curriculumDetailError,
  } = useCurriculumDetail(params?.id)

  const {
    data: curriculumCareListData,
    isLoading: isCurriculumCareListLoading,
    setParams: setCurriculumCareListParams,
    mutate: mutateCurriculumCareList,
  } = useCurriculumCareList()

  const { data: careListData, setParams: setCareListParams } = useCareList({
    ordering: '-updated_at',
  })

  useEffect(() => {
    return () => {
      setTitleSectionData(null)
    }
  }, [])

  useEffect(() => {
    if (!isCurriculumDetailLoading && curriculumDetailData) {
      setTitleSectionData({
        title: (
          <PageTitle
            pageName={curriculumDetailData.name}
            pageDescription={curriculumDetailData.description}
          />
        ),
      })
      setCurriculumCareListParams({
        curriculum: curriculumDetailData.id,
      })
    }
  }, [curriculumDetailData, isCurriculumDetailLoading])

  useEffect(() => {
    if (!isCurriculumCareListLoading && curriculumCareListData) {
      setCareListParams({
        ids: _.chain(curriculumCareListData.results)
          .map((curriculumCare) => curriculumCare.care.toString())
          .uniq()
          .value(),
      })
    }
  }, [curriculumCareListData, isCurriculumCareListLoading])

  useEffect(() => {
    if (curriculumDetailError?.response?.status === 404) {
      history.push('/curriculums')
    }
  }, [curriculumDetailError])

  const onFinishManipulation = async () => {
    await mutateCurriculumCareList()
  }

  return (
    <>
      {curriculumDetailData && (
        <CurriculumDetailView
          curriculum={curriculumDetailData}
          essentialCares={buildCurriculumCares(
            curriculumCareListData?.results ?? [],
            careListData?.results ?? [],
            true,
          )}
          optionalCares={buildCurriculumCares(
            curriculumCareListData?.results ?? [],
            careListData?.results ?? [],
            false,
          )}
          onFinishManipulation={onFinishManipulation}
        />
      )}
    </>
  )
}

export default CurriculumDetail

const buildCurriculumCares = (
  curriculumCares: CurriculumCare[],
  cares: Care[],
  isEssential: boolean,
): CurriculumCare[] => {
  if (curriculumCares.length < 1 || cares.length < 1) return []

  const keys = _.map(curriculumCares, 'care')

  const filteredCares = _.chain(cares)
    .filter((care) => _.includes(keys, care.id))
    .filter((care) => care.isEssential === isEssential)
    .map((care) => {
      return {
        care: care.id,
        name: care.name,
        description: care.description,
        isEssential: care.isEssential,
        actionKind: care.actionKind,
        checkKind: care.checkKind,
        visibility: care.visibility,
      }
    })
    .value()

  const filteredCurriculumCares = _.filter(curriculumCares, (curriculumCare) =>
    _.find(filteredCares, { care: curriculumCare.care }),
  )

  const merged = _.merge(
    _.keyBy(filteredCares, 'care'),
    _.keyBy(filteredCurriculumCares, 'care'),
  )

  return _.chain(merged).sortBy('name').map().value() as CurriculumCare[]
}
