import React, { useEffect } from 'react'

import NotiModal, { NotiModalPropType } from '@/components/modal/NotiModal'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'
import useMemoDeletion from '@/apps/memo/hooks/useMemoDeletion'
import { Memo } from '@/apps/memo/@types/memo'

interface PropType extends Omit<NotiModalPropType, 'onOk'> {
  memo: Memo
  onFinish?: () => void
}

const DeleteMemoModal: React.FC<PropType> = ({ memo, onFinish, ...props }) => {
  const { data, error, isLoading, setMemoKey } = useMemoDeletion()

  const onOk = () => {
    setMemoKey(memo.id)
  }

  useEffect(() => {
    if (isLoading || !data) return

    notifySuccess({
      message: '삭제 완료',
      description: '성공적으로 메모를 삭제했습니다.',
    })
    !!onFinish && onFinish()
  }, [data, isLoading])

  useEffect(() => {
    if (isLoading || !error) return

    notifyFailure({
      message: '삭제 실패',
      description: '메모 삭제에 실패했습니다. 다시 시도해주세요.',
    })
  }, [error, isLoading])

  return <NotiModal onOk={onOk} loading={isLoading} {...props} />
}

export default DeleteMemoModal
