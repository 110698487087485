import { useState } from 'react'

import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import { PagePaginationResponse } from '@/clients/http/types'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { Customer, CustomerListParam } from '@/apps/customer/@types/customer'

type FullCustomer = Customer<Order, User>

const fetcher = (url: string | null, params?: CustomerListParam) => {
  if (!url) return
  return get<CustomerListParam, PagePaginationResponse<FullCustomer>>(
    url,
    params,
  )
}

const useCustomerList = (defaultParams = {}) => {
  const [params, setParams] = useState<CustomerListParam>({
    ...defaultParams,
  })

  const _setParams = (newParams: CustomerListParam) => {
    !_.isEqual(params, newParams) && setParams(_.merge({}, newParams))
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.customerList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    setParams: _setParams,
    mutate,
  }
}

export default useCustomerList
