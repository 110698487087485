import { AxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const requestKeysToSnakecase = (config: AxiosRequestConfig) => {
  if (config.data) {
    config.data = snakecaseKeys(config.data, { deep: true })
  }
  return config
}

export const responseKeysToCamelcase = (config: AxiosRequestConfig) => {
  config.data = camelcaseKeys(config.data, { deep: true })
  return config
}

export const setAuthorization = (
  config: AxiosRequestConfig,
  token: string | null,
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  config!.headers!.Authorization = `Token ${token}`
  return config
}
