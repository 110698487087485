const statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
} as const

const statusTagLabel = {
  [statuses.ACTIVE]: '사용 중',
  [statuses.DISABLED]: '사용 중단',
} as const

export const memoKindStatuses = statuses

export const memoStatuses = statuses

export const memoKindStatusTagLabel = statusTagLabel

export const memoStatusTagLabel = statusTagLabel

export const memoKindOrderingLabel = {
  name: '메모 유형',
  created_at: '생성 일자',
  updated_at: '갱신 일자',
} as const

export const memoOrderingLabel = {
  updated_at: '갱신 일자',
} as const

export const memoKindStatusTagColor = {
  [statuses.ACTIVE]: 'blue',
  [statuses.DISABLED]: 'red',
} as const

export const memoStatusTagColor = {
  [statuses.ACTIVE]: 'blue',
  [statuses.DISABLED]: 'red',
} as const
