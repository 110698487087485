import _ from 'lodash'

export const buildKeyValueLabelsByValues = <
  LT = Record<string, string>,
  VT = keyof LT,
>(
  labels: LT,
  values: VT[],
) =>
  _.chain(labels)
    .toPairs()
    .filter((value) => values.includes(value[0] as unknown as VT))
    .value() as unknown as [VT, LT[keyof LT]][]
