import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.curriculum'
import { PagePaginationResponse } from '@/clients/http/types'
import {
  CurriculumCare,
  CurriculumCareListParam,
} from '@/apps/curriculum/@types/curriculum.care'

const fetcher = (url: string | null, params?: CurriculumCareListParam) => {
  if (!url) return
  return get<CurriculumCareListParam, PagePaginationResponse<CurriculumCare>>(
    url,
    params,
  )
}

const useCurriculumCareList = (defaultParams = {}) => {
  const [params, setParams] = useState<CurriculumCareListParam>(defaultParams)

  const _setParams = (newParams: CurriculumCareListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.curriculumCareList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    setParams: _setParams,
  }
}

export default useCurriculumCareList
