import React from 'react'

import useStaffsCustomerStatus from '@/apps/dashboard/hooks/useStaffsCustomerStatus'

import MyCustomerStatusView from './MyCustomerStatus.View'

const MyCustomerStatus: React.FC = () => {
  const { data, isLoading } = useStaffsCustomerStatus()

  return <MyCustomerStatusView data={data} isLoading={isLoading} />
}

export default MyCustomerStatus
