import React, { useState, useEffect, useRef } from 'react'

import { subDays, differenceInDays, isSameDay } from 'date-fns'
import { RangeValue } from 'rc-picker/lib/interface'

import { dateFormat } from '@/utils/date'
import useCustomerPurchaseStatus from '@/apps/dashboard/hooks/useCustomerPurchaseStatus'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import MyCustomerPurchaseStatusView from '@/apps/dashboard/components/my-customer-purchase-status/MyCustomerPurchaseStatus.View'

interface PropType {
  startDate?: Date
  endDate?: Date
}

const MyCustomerPurchaseStatus: React.FC<PropType> = ({
  startDate,
  endDate,
}) => {
  const notRequiredShowConfirmNoti = useRef(true)

  const today = new Date()
  const defaultEndDate = endDate || subDays(today, 1)
  const defaultStartDate = startDate || subDays(defaultEndDate, 6)

  const [dates, setDates] = useState<RangeValue<Date>>([
    defaultStartDate,
    defaultEndDate,
  ])

  const { data, error, isLoading, setParams } = useCustomerPurchaseStatus({
    startDate: dateFormat(defaultStartDate),
    endDate: dateFormat(defaultEndDate),
  })

  const onCalendarChange = (dates: RangeValue<Date>) => {
    setDates(dates)
  }

  const disabledDate = (current: Date) => {
    const future =
      !!current &&
      (differenceInDays(current, today) >= 0 || isSameDay(current, today))
    if (future) return true

    const notSelectedDates = !dates || dates.length < 1
    if (notSelectedDates) return false

    const startDate = dates[0]
    const endDate = dates[1]

    const tooLate = !!startDate && differenceInDays(current, startDate) > 60
    const tooEarly = !!endDate && differenceInDays(endDate, current) > 60
    return tooEarly || tooLate
  }

  const onClickSubmitDates = () => {
    if (!dates || !dates[0] || !dates[1]) return

    notRequiredShowConfirmNoti.current = false

    setParams({
      startDate: dateFormat(dates[0]),
      endDate: dateFormat(dates[1]),
    })
  }

  useEffect(() => {
    const waitResponse = isLoading || !data
    if (waitResponse || notRequiredShowConfirmNoti.current) return

    notifySuccess({
      message: '결과 조회 성공',
      description: '설정 기간의 결과값 불러오기를 성공하였습니다.',
    })
  }, [data, isLoading])

  useEffect(() => {
    const waitResponse = isLoading || !error
    if (waitResponse || notRequiredShowConfirmNoti.current) return

    notifyFailure({
      message: '조회 실패',
      description: `결과 조회에 실패하였습니다. 다시 시도하세요`,
    })
  }, [error, isLoading])

  return (
    <MyCustomerPurchaseStatusView
      data={data}
      isLoading={isLoading}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      dates={dates}
      disableSelectDate={disabledDate}
      onCalendarChange={onCalendarChange}
      onClickSubmitDates={onClickSubmitDates}
    />
  )
}

export default MyCustomerPurchaseStatus
