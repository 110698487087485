import { useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.care'
import { PagePaginationResponse } from '@/clients/http/types'
import { Care, CareListParam } from '../@types/care'

const fetcher = (url: string | null, params?: CareListParam) => {
  if (!url) return
  return get<CareListParam, PagePaginationResponse<Care>>(url, params)
}

const useCareList = (defaultParams = {}) => {
  const [params, setParams] = useState<CareListParam>({
    ...defaultParams,
  })

  const _setParams = (newParams: CareListParam) => {
    !_.isEqual(params, newParams) && setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.careList, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  )

  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    params,
    mutate,
    setParams: _setParams,
  }
}

export default useCareList
