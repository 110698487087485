import React, { useState } from 'react'
import _ from 'lodash'
import { Nil } from '@/@types/composite'
import { useUserContext } from '@/contexts/UserContext'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import { AddCurriculumCareForm } from '@/apps/curriculum/components/add-curriculum-care-form'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import { useCustomerCareModification } from '@/apps/customer/hooks/curriculum/useCustomerCareManipulation'
import { Care } from '@/apps/care/@types/care'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import OptionalCareListView from './OptionalCareList.View'

interface SuncareManipulationState {
  care: CustomerCare<Care, CustomerCareItem> | Nil
  isActivate: boolean | Nil
  isVisibleSuncareManipulationModal: boolean | Nil
  label: string | Nil
}

interface PropType {
  isMyCustomer?: boolean
  customerCurriculum?: CustomerCurriculum | Nil
  curriculum?: Curriculum | Nil
  customerCurriculumOpenedAt?: Date | Nil
  caculatedCustomerCurriculumClosedAt?: Date | Nil
  optionalCares: CurriculumCare[] | CustomerCare<Care, CustomerCareItem>[]
  onFinishManipulation?: () => void
  onFinishToggleSunCare?: () => void
}

const OptionalCareList: React.FC<PropType> = ({
  isMyCustomer,
  customerCurriculum,
  curriculum,
  caculatedCustomerCurriculumClosedAt,
  optionalCares,
  onFinishManipulation,
  onFinishToggleSunCare,
}) => {
  if (!curriculum && !customerCurriculum) return null

  const isCustomerCurriculum = !!customerCurriculum

  const [isVisibleCareModal, setIsVisibleCareModal] = useState(false)

  const defaultSelectedSunCare = {
    care: null,
    isVisibleSuncareManipulationModal: null,
    isActivate: null,
    label: null,
    isOriginalActive: null,
  }

  const [selectedSunCare, setSelectedSunCare] =
    useState<SuncareManipulationState>(defaultSelectedSunCare)

  const { isStaffUser } = useUserContext()

  const modification = useCustomerCareModification()

  const onClickAddCare = () => {
    setIsVisibleCareModal(true)
  }

  const onFinishAddCare = () => {
    setIsVisibleCareModal(false)
    onFinishManipulation?.()
  }

  const onCancelAddCare = () => {
    setIsVisibleCareModal(false)
  }

  const onClickToggleSuncare = (
    care: CustomerCare<Care, CustomerCareItem>,
    isActivate: boolean,
  ) => {
    modification.setKey(care.id)
    setSelectedSunCare({
      care,
      isActivate,
      isVisibleSuncareManipulationModal: true,
      label: isActivate ? '활성화' : '비활성화',
    })
  }

  const onFinishActivateSuncare = () => {
    setSelectedSunCare({
      ...defaultSelectedSunCare,
    })
    onFinishToggleSunCare?.()
  }

  const onCancelActivateSuncare = () => {
    setSelectedSunCare({
      ...defaultSelectedSunCare,
    })
  }

  const toggleSuncareActive = () => {
    if (!selectedSunCare?.care || _.isNil(selectedSunCare.isActivate)) return
    modification.setPayload({
      isVisible: selectedSunCare?.isActivate,
    })
  }

  return (
    <>
      <OptionalCareListView
        isMyCustomer={isMyCustomer}
        curriculum={curriculum}
        customerCurriculum={customerCurriculum}
        customerCurriculumOpenedAt={
          customerCurriculum?.openedAt
            ? new Date(customerCurriculum?.openedAt)
            : null
        }
        caculatedCustomerCurriculumClosedAt={
          caculatedCustomerCurriculumClosedAt
        }
        optionalCares={optionalCares}
        disabledAddCare={
          isCustomerCurriculum
            ? !_.isUndefined(isMyCustomer) && !isMyCustomer
            : getDisabledAddCare(isStaffUser, curriculum)
        }
        onClickAddCare={onClickAddCare}
        onFinishAddCareItem={onFinishManipulation}
        onFinishDeleteCare={onFinishManipulation}
        onFinishDeleteCareItem={onFinishManipulation}
        onClickToggleSuncare={onClickToggleSuncare}
      />
      <AddCurriculumCareForm
        curriculum={curriculum ?? customerCurriculum}
        isCustomerCurriculum={isCustomerCurriculum}
        isVisible={isVisibleCareModal}
        onFinish={onFinishAddCare}
        onCancel={onCancelAddCare}
      />
      <ConfirmFlowModal<CustomerCare<Care, CustomerCareItem>, unknown>
        visible={
          !!selectedSunCare?.isVisibleSuncareManipulationModal &&
          !!selectedSunCare?.care
        }
        title={getSuncareManipulationTitle(selectedSunCare?.label ?? '')}
        content={getSuncareManipulationContent(selectedSunCare?.label ?? '')}
        successNotiMessage="처리 완료"
        successNotiDesc={getSuncareManipulationSuccessDesc(
          selectedSunCare?.label ?? '',
        )}
        failNotiMessage="요청실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        response={modification.data}
        error={modification.error}
        isLoadingRequest={modification.isLoading}
        action={toggleSuncareActive}
        onFinish={onFinishActivateSuncare}
        onCancel={onCancelActivateSuncare}
      />
    </>
  )
}

export default OptionalCareList

const getDisabledAddCare = (
  isStaffuser: boolean,
  curriculum: Curriculum | Nil,
) =>
  isStaffuser ||
  (curriculum?.useCountOnProgress ?? 1) > 0 ||
  (curriculum?.useCountReserved ?? 1) > 0

const getSuncareManipulationTitle = (label?: string) => `썬케어 ${label}`

const getSuncareManipulationContent = (label?: string) =>
  `고객의 썬케어를 ${label} 하시겠습니까?`

const getSuncareManipulationSuccessDesc = (label?: string) =>
  `썬케어 ${label}를 완료했습니다.`
