import { form as baseFormErrors } from '@/constants/errors'

export const form = {
  ...baseFormErrors,
  name: '케어 유형을 입력해주세요',
  description: '입력 형식에 맞게 다시 입력하세요',
  curriculumCompilation: '커리큘럼 유형을 선택해주세요',
  isEssential: '필수/선택 을 선택해주세요',
  checkKind: '액션 체크 방식을 선택해주세요',
  actionKind: '필수 항목 입니다',
} as const
