import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Table, Pagination, TableProps, Tag, Typography } from 'antd'

import { DataQueryPropType } from '@/@types/data-query'
import { dateFormat } from '@/utils/date'
import { numbering } from '@/utils/ui/pagination'
import { itemToItems } from '@/utils/collections'
import { resolvePath } from '@/utils/url'
import {
  curriculumOrderingLabel,
  curriculumVisibility,
  curriculumVisibilityTagColor,
  curriculumVisibilityTagLabel,
  PAGES,
} from '@/constants/apps.curriculum'
import {
  Curriculum,
  CurriculumListParam,
  CurriculumListOrderingKey,
  CurriculumVisibility,
} from '@/apps/curriculum/@types/curriculum'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import ActionMenuButton from '@/components/buttons/ActionMenuButton'
import { DeleteButton, EditButton } from '@/components/buttons/Icon'

export interface PropType
  extends DataQueryPropType<
    CurriculumListParam,
    Curriculum,
    CurriculumListOrderingKey
  > {
  disabled: boolean
  onClickAddCurriculum?: () => void
  onChangeTable?: TableProps<Curriculum>['onChange']
  onClickEdit: (item: Curriculum) => void
  onClickDelete: (item: Curriculum) => void
  onClickVisibility: (
    item: Curriculum,
    newVisibility: CurriculumVisibility,
  ) => void
}

const CurriculumListView: React.FC<PropType> = ({
  data,
  page,
  limit,
  count,
  disabled,
  isLoading,
  params,
  onChangeParam,
  orderingKey,
  orderingIsDesc,
  onClickAddCurriculum,
  onChangeTable,
  onClickEdit,
  onClickDelete,
  onClickVisibility,
  onChangePage,
}) => (
  <Section>
    <SectionTitle title="커리큘럼 목록" />
    <div className="tw-pb-4">
      <QuerySection<CurriculumListParam, CurriculumListOrderingKey>
        wrapperName="curriculumList"
        params={params}
        onChange={onChangeParam}
        defaultOrderingKey={orderingKey}
        orderOptions={orderOptions}
        searchPlaceholder="커리큘럼 제목 검색"
        defaultOrderingIsDesc={orderingIsDesc}
      >
        <BasicButton
          className="tw-ml-auto"
          label="신규 커리큘럼 생성"
          icon="plus"
          disabled={disabled}
          data-testid="curriculumList/btn-add"
          onClick={onClickAddCurriculum}
        />
      </QuerySection>
    </div>
    <Table<Curriculum>
      dataSource={data}
      rowKey="id"
      pagination={false}
      onChange={onChangeTable}
    >
      <Column
        title="번호"
        key="order"
        render={(_value, _record, index) => numbering(page, limit, index)}
      />

      <Column<Curriculum>
        title="커리큘럼 제목"
        width={280}
        render={(_, record) => <CurriculumName curriculum={record} />}
      />

      <Column
        title="설명"
        width={640}
        dataIndex="description"
        render={getDescription}
      />

      <Column title="갱신 일자" dataIndex="updatedAt" render={formatDate} />

      <Column<Curriculum>
        title="상태"
        dataIndex="visibility"
        filterMultiple={true}
        filters={visibilityColumnFilters}
        filteredValue={getFilteredStatus(params.visibility)}
        render={(__, record) => (
          <VisibilityTag
            curriculum={record}
            disabled={disabled}
            onClick={onClickVisibility}
          />
        )}
      />

      <Column<Curriculum>
        title="수정"
        render={(__, record) => (
          <ButtonEdit
            curriculum={record}
            disabled={disabled}
            onClick={onClickEdit}
          />
        )}
      />

      <Column<Curriculum>
        title="삭제"
        render={(__, record) => (
          <ButtonDelete
            curriculum={record}
            disabled={disabled}
            onClick={onClickDelete}
          />
        )}
      />
    </Table>

    {!!count && (
      <Pagination
        disabled={isLoading}
        defaultCurrent={page ?? 1}
        pageSize={limit}
        defaultPageSize={limit}
        total={count}
        onChange={onChangePage}
      />
    )}
  </Section>
)

export default CurriculumListView

const { Column } = Table

const visibilities = [
  curriculumVisibility.UNPUBLISHED,
  curriculumVisibility.PUBLISHED,
  curriculumVisibility.SUSPENDED,
]

const disableVisibilities = {
  [curriculumVisibility.UNPUBLISHED]: [curriculumVisibility.SUSPENDED],
  [curriculumVisibility.PUBLISHED]: [curriculumVisibility.UNPUBLISHED],
  [curriculumVisibility.SUSPENDED]: [curriculumVisibility.UNPUBLISHED],
}

const getFilteredStatus = (
  status: CurriculumVisibility | CurriculumVisibility[] | undefined,
) => itemToItems<CurriculumVisibility>(status)

export const orderOptions = _.chain(curriculumOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const isUsed = (curriculum: Curriculum) =>
  curriculum.useCountOnProgress >= 1 || curriculum.useCountReserved >= 1

const getDisabledEdit = (curriculum: Curriculum) => isUsed(curriculum)

const visibilityColumnFilters = _.chain(curriculumVisibilityTagLabel)
  .toPairs()
  .map(([value, text]) => ({ text, value }))
  .value()

const getDescription = (value?: string) => value || '-'

const formatDate = (value?: string) => {
  if (_.isNil(value)) return '0000-00-00'
  return dateFormat(value, 'yyyy-MM-dd')
}

interface VisibilityTagPropType {
  curriculum: Curriculum
  disabled: boolean
  onClick: (item: Curriculum, newVisibility: CurriculumVisibility) => void
}

const CurriculumName: React.FC<{ curriculum: Curriculum }> = ({
  curriculum,
}) => {
  const history = useHistory()
  const path = resolvePath<Curriculum>({
    path: PAGES.curriculumDetail.path,
    params: curriculum,
  })
  return (
    <Typography.Link onClick={() => history.push(path)}>
      {curriculum.name}
    </Typography.Link>
  )
}

const VisibilityTag: React.FC<VisibilityTagPropType> = ({
  curriculum,
  disabled,
  onClick,
}) => {
  const { visibility } = curriculum
  return (
    <ActionMenuButton<Curriculum, CurriculumVisibility>
      disabled={disabled}
      item={curriculum}
      labels={curriculumVisibilityTagLabel}
      value={visibility}
      values={visibilities}
      disableValues={disableVisibilities}
      onChangeValue={onClick}
    >
      <Tag color={curriculumVisibilityTagColor[visibility]}>
        {curriculumVisibilityTagLabel[visibility]}
      </Tag>
    </ActionMenuButton>
  )
}
interface ManipulationPropType {
  curriculum: Curriculum
  disabled: boolean
  onClick: (curriculum: Curriculum) => void
}

const ButtonEdit: React.FC<ManipulationPropType> = ({
  curriculum,
  disabled,
  onClick,
}) => {
  return (
    <EditButton
      disabled={disabled || getDisabledEdit(curriculum)}
      onClick={() => onClick(curriculum)}
      data-testid={`curriculumList/btn-edit-${curriculum.id}`}
    />
  )
}

const ButtonDelete: React.FC<ManipulationPropType> = ({
  curriculum,
  disabled,
  onClick,
}) => {
  const { visibility, useCountOnProgress, useCountReserved } = curriculum

  const isUnPublished = visibility === 'unpublished'
  const isNoBodyUsed = useCountOnProgress === 0 && useCountReserved === 0
  const allowManipulation = isUnPublished || isNoBodyUsed

  return (
    <DeleteButton
      disabled={disabled || !allowManipulation}
      onClick={() => onClick(curriculum)}
      data-testid={`curriculumList/btn-delete-${curriculum.id}`}
    />
  )
}
