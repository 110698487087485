import { Nil } from '@/@types/composite'
import _, { CollectionChain } from 'lodash'
import qs, { IStringifyOptions } from 'qs'

import { isSameArray } from '@/utils/collections'
import { BaseRequestParam } from '@/clients/http/types'
import { ParamPair } from './types'

export const excludeNilValue = ([, value]: ParamPair): boolean =>
  !_.isNil(value)

export const serializeParamKey = ([key, value]: ParamPair) => [
  _.snakeCase(key),
  value,
]

export const deserializeParamKey = ([key, value]: ParamPair): ParamPair => [
  _.camelCase(key),
  value,
]

export const stringifyParams = <T = BaseRequestParam>(
  params: T,
  options: IStringifyOptions = {},
): string => {
  const newOptions: IStringifyOptions = {
    arrayFormat: 'comma',
    ...options,
  }
  return (
    _.chain(params)
      .toPairs()
      .filter(excludeNilValue)
      .map(serializeParamKey)
      .fromPairs()
      .thru((value) => qs.stringify(value, newOptions))
      .value() ?? ''
  )
}

export const parseQueryStringFromUrl = <T extends Record<string, unknown>>(
  value: string | Nil,
  keysToCamelCase = true,
) => {
  if (!value) return undefined

  // eslint-disable-next-line prettier/prettier
  let result = _.chain(value.split('?'))
    .thru(_.tail)
    .join('')
    .thru(qs.parse)
    .toPairs()
    .map(([key, value]) => [key, _.trim(value as string)])
    .filter((v) => !_.isEmpty(v[0]))

  if (keysToCamelCase) {
    result = result.map(deserializeParamKey) as unknown as CollectionChain<
      string[]
    >
  }
  return result.fromPairs().value() as T
}

export const getPrefixForDescending = (isDesc: boolean) => (isDesc ? '-' : '')

export const parseOrderingKey = (value: string) =>
  !value.startsWith('-') ? value : _.slice(value, 1).join('')

export const buildOrderingQueryString = (key: string, isDesc: boolean) =>
  !key.startsWith('-')
    ? `${getPrefixForDescending(isDesc)}${key}`
    : `${getPrefixForDescending(isDesc)}${parseOrderingKey(key)}`

export const isSameParams = <T>(baseParams: T, compareParams: T): boolean => {
  if (!isSameArray(_.keys(baseParams), _.keys(compareParams))) return false

  return !_.chain(baseParams)
    .toPairs()
    .find(([key, value]) => {
      const value2 = _.get(compareParams, key)

      if (_.isNil(value2)) return true

      if (_.isArray(value) && _.isArray(value2) && !isSameArray(value, value2))
        return true

      if (_.isPlainObject(value) && !_.isEqual(value, value2)) return true

      return `${value}` !== `${value2}`
    })
    .value()
}

export const convertPairsValueToNumber =
  (numberParams: string[]) =>
  ([key, value]: [string, unknown]): [string, unknown] =>
    numberParams.includes(key) && _.isString(value)
      ? [key, _.parseInt(value)]
      : [key, value]

export const convertNumberParams = <T>(params: T, numberParams: string[]) =>
  _.chain(params)
    .toPairs()
    .map(convertPairsValueToNumber(numberParams))
    .fromPairs()
    .value() as unknown as T

const excludeParamKeys =
  <T>(excludes: Array<T | string>) =>
  ([key]: [unknown, unknown]) =>
    !_.includes(excludes, key)

export const excludeParams = <T>(
  params: T | Nil,
  excludes: Array<keyof T | string>,
) =>
  _.isEmpty(params)
    ? ({} as T)
    : (_.chain(params)
        .toPairs()
        .filter(excludeParamKeys(excludes))
        .fromPairs()
        .value() as unknown as T)

export const serverErrorStatus = [500, 501, 502, 503, 504]
