import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useUserContext } from '@/contexts/UserContext'
import { useCareItemList } from '@/apps/careItem/hooks/useCareItemList'
import { useCurriculumCareDeletion } from '@/apps/curriculum/hooks/useCurriculumCareManipulation'
import { useCurriculumCareItemDeletion } from '@/apps/curriculum/hooks/useCurriculumCareItemManipulation'
import { careVisibility, checkKinds } from '@/constants/apps.care'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import {
  CurriculumCare,
  CurriculumCareItem,
} from '@/apps/curriculum/@types/curriculum.care'
import { AddCurriculumCareItemForm } from '@/apps/curriculum/components/add-curriculum-care-item-form'
import { CareItem } from '@/apps/careItem/@types/careItem'
import CurriculumCareProgramView from './CurriculumCareProgram.View'

interface PropType {
  isCustomerCurriculum: boolean
  showDeleteCareButton?: boolean
  curriculum: Curriculum
  curriculumCare: CurriculumCare
  onFinishAddCurriculumCareItem?: () => void
  onFinishDeleteCurriculumCare?: () => void
  onFinishDeleteCurriculumCareItem?: () => void
}

const CurriculumCareProgram: React.FC<PropType> = ({
  isCustomerCurriculum,
  showDeleteCareButton = false,
  curriculum,
  curriculumCare,
  onFinishAddCurriculumCareItem,
  onFinishDeleteCurriculumCare,
  onFinishDeleteCurriculumCareItem,
}) => {
  const [isVisibleCareItemModal, setIsVisibleCareItemModal] = useState(false)
  const [isVisibleConfirmDeleteCare, setIsVisibleConfirmDeleteCare] =
    useState(false)
  const [isVisibleConfirmDeleteCareItem, setIsVisibleConfirmDeleteCareItem] =
    useState(false)
  const [selectedCare, setSelectedCare] = useState<CurriculumCare | null>(null)
  const [selectedCareItem, setSelectedCareItem] =
    useState<CurriculumCareItem | null>(null)

  const { isStaffUser } = useUserContext()

  const curriculumCareDeletion = useCurriculumCareDeletion()
  const curriculumCareItemDeletion = useCurriculumCareItemDeletion()

  const fetchingCareItems = useCareItemList()

  useEffect(() => {
    if (curriculumCare.careItems.length < 1) return
    fetchingCareItems.setParams({
      ids: _.chain(curriculumCare.careItems)
        .map('careItem')
        .uniq()
        .value()
        .map((el) => `${el}`),
      ordering: '-updated_at',
    })
  }, [curriculumCare.careItems])

  const onClickAddCurriculumCareItem = () => {
    setIsVisibleCareItemModal(true)
  }

  const _onFinishAddCurriculumCareItem = () => {
    setIsVisibleCareItemModal(false)
    onFinishAddCurriculumCareItem?.()
  }

  const onCancelAddCurriculumCareItem = () => {
    setIsVisibleCareItemModal(false)
  }

  const onClickDeleteCurriculumCare = (curriculumCare: CurriculumCare) => {
    setSelectedCare(curriculumCare)
    setIsVisibleConfirmDeleteCare(true)
  }

  const _onFinishDeleteCurriculumCare = () => {
    setIsVisibleConfirmDeleteCare(false)
    setSelectedCare(null)
    onFinishDeleteCurriculumCare?.()
  }

  const onCancelDeleteCurriculumCare = () => {
    setIsVisibleConfirmDeleteCare(false)
    setSelectedCare(null)
  }

  const onClickDeleteCurriculumCareItem = (
    curriculumCareItem: CurriculumCareItem,
  ) => {
    setSelectedCareItem(curriculumCareItem)
    setIsVisibleConfirmDeleteCareItem(true)
  }

  const _onFinishDeleteCurriculumCareItem = () => {
    setIsVisibleConfirmDeleteCareItem(false)
    setSelectedCareItem(null)
    onFinishDeleteCurriculumCareItem?.()
  }

  const onCancelDeleteCurriculumCareItem = () => {
    setIsVisibleConfirmDeleteCareItem(false)
    setSelectedCareItem(null)
  }

  const deleteCurriculumCare = () => {
    curriculumCareDeletion.setKey(selectedCare?.id)
  }

  const deleteCurriculumCareItem = () => {
    curriculumCareItemDeletion.setKey(selectedCareItem?.id)
  }

  return (
    <>
      <CurriculumCareProgramView
        curriculumCare={curriculumCare}
        careItems={buildCurriculumCareItems(
          curriculumCare.careItems,
          fetchingCareItems.data?.results ?? [],
        )}
        onClickAddCurriculumCareItem={onClickAddCurriculumCareItem}
        onClickDeleteCurriculumCareItem={onClickDeleteCurriculumCareItem}
        onClickDeleteCurriculumCare={onClickDeleteCurriculumCare}
        disabledDeleteCare={getDisabledDeleteCare(isStaffUser, curriculum)}
        disabledDeleteCareItem={getDisabledDeleteCareItem(
          isStaffUser,
          curriculum,
          curriculumCare,
        )}
        disabledAddCareItem={getDisabledAddCareItem(isStaffUser, curriculum)}
        showDeleteCareButton={showDeleteCareButton}
        showSuspendedCare={
          curriculumCare.visibility === careVisibility.SUSPENDED
        }
      />
      <AddCurriculumCareItemForm
        isCalendarStartDate={isCustomerCurriculum}
        curriculumCare={curriculumCare}
        isVisible={isVisibleCareItemModal}
        onFinish={_onFinishAddCurriculumCareItem}
        onCancel={onCancelAddCurriculumCareItem}
      />
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeleteCare && !!selectedCare}
        title="케어 유형 삭제"
        content="케어 유형을 삭제하시겠습니까?"
        successNotiMessage="삭제 완료"
        successNotiDesc="성공적으로 케어 유형을 삭제했습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={curriculumCareDeletion.error}
        isLoadingRequest={curriculumCareDeletion.isLoading}
        response={curriculumCareDeletion.data}
        action={deleteCurriculumCare}
        onFinish={_onFinishDeleteCurriculumCare}
        onCancel={onCancelDeleteCurriculumCare}
      />
      <ConfirmFlowModal<boolean, unknown>
        visible={isVisibleConfirmDeleteCareItem && !!selectedCareItem}
        title="케어 아이템 삭제"
        content="목록에서 삭제하시겠습니까?"
        successNotiMessage="삭제 완료"
        successNotiDesc="성공적으로 케어 아이템을 삭제했습니다."
        failNotiMessage="요청 실패"
        failNotiDesc="유효하지 않은 요청입니다. 페이지를 새로 고침하세요."
        isFinishOnFail={true}
        error={curriculumCareItemDeletion.error}
        isLoadingRequest={curriculumCareItemDeletion.isLoading}
        response={curriculumCareItemDeletion.data}
        action={deleteCurriculumCareItem}
        onFinish={_onFinishDeleteCurriculumCareItem}
        onCancel={onCancelDeleteCurriculumCareItem}
      />
    </>
  )
}

export default CurriculumCareProgram

const buildCurriculumCareItems = (
  curriculumCareItems: CurriculumCareItem[],
  careItems: CareItem[],
): CurriculumCareItem[] => {
  if (!curriculumCareItems) return []
  if (curriculumCareItems.length < 1 || careItems.length < 1) return []

  const keys = _.map(curriculumCareItems, 'careItem')
  const filteredCareItems = _.chain(careItems)
    .filter((careItem) => _.includes(keys, careItem.id))
    .map((careItem) => {
      return {
        careItem: careItem.id,
        name: careItem.name,
        description: careItem.description,
        visibility: careItem.visibility,
      }
    })
    .value()

  const merged = _.chain(curriculumCareItems)
    .map((curriculumCareItem) => {
      const careItem = _.find(
        filteredCareItems,
        (careItem) => careItem.careItem === curriculumCareItem.careItem,
      )
      return {
        ...curriculumCareItem,
        ...careItem,
      }
    })
    .orderBy('createdAt', 'desc')
    .value()

  return merged as CurriculumCareItem[]
}

const getDisabledAddCareItem = (isStaffUser: boolean, curriculum: Curriculum) =>
  isStaffUser ||
  curriculum.useCountOnProgress > 0 ||
  curriculum.useCountReserved > 0

const getDisabledDeleteCare = getDisabledAddCareItem

const getDisabledDeleteCareItem = (
  isStaffUser: boolean,
  curriculum: Curriculum,
  curriculumCare: CurriculumCare,
) =>
  isStaffUser ||
  curriculum.useCountOnProgress > 0 ||
  curriculum.useCountReserved > 0 ||
  curriculumCare.checkKind === checkKinds.YES_OR_NO
