import React from 'react'

interface PropType {
  className?: string
}

const SubSectionTitle = (props: React.PropsWithChildren<PropType>) => {
  const { className = '', children } = props
  return <div className={`tw-m-0 tw-header-3 ${className}`}>{children}</div>
}

export default SubSectionTitle
