import React from 'react'
import { useLocation } from 'react-router'

import SideBar from './sidebar/SideBar'
import TopBar from './topBar/TopBar'
import PageBreadcrumb from './breadcrumb/PageBreadcrumb'
import { useUserContext } from '@/contexts/UserContext'
import { PAGES as LOGIN_PAGES } from '@/constants/apps.auth'

const Default: React.FC = (props) => {
  const { current } = useUserContext()
  const location = useLocation()
  const url = location.pathname

  if (url === LOGIN_PAGES.login.path) {
    return <>{props.children}</>
  }

  return (
    <div className="tw-flex tw-h-screen tw-overflow-y-hidden">
      <aside>
        <div data-testid="sidebar">
          {!!current && <SideBar user={current} />}
        </div>
      </aside>
      <div className="tw-flex tw-flex-col tw-flex-1">
        <header>
          <div data-testid="topbar">
            {!!current && <TopBar user={current} />}
          </div>
          {<PageBreadcrumb url={url} />}
        </header>
        <main className="tw-bg-gray-2 tw-p-5 tw-flex-grow tw-overflow-y-auto">
          <div>{props.children}</div>
        </main>
      </div>
    </div>
  )
}

export default Default
