import React, { useCallback, useRef, useEffect } from 'react'

import _ from 'lodash'
import { Form, FormInstance } from 'antd'

import envs from '@/config/variables'
import { User } from '@/apps/auth/@types/user'
import UserEditForm, {
  buildPayloadForModifying,
} from '@/apps/auth/components/UserEditForm'
import {
  useUserCreation,
  useUserModification,
} from '@/apps/auth/hooks/useUserManipulation'
import { hasAnyFieldsError } from '@/utils/antd/form'
import FormModal from '@/components/modal/FormModal'
import { ModalNotifyUserCompletionProp } from '@/apps/auth/pages/users/ModalNotifyUserCompletion'
import { notifyFailure } from '@/components/antd/Notification'

import './DialogUserForm.scss'

interface PropType {
  user?: User | null
  visible: boolean
  width?: number | string
  formRefForTesting?: FormInstance // only for testing
  onClose: () => void
  onSuccess?: (
    action: 'create' | 'modify',
    completionData: ModalNotifyUserCompletionProp,
  ) => void
}

const defaultWidth = '858px'

const isModifyingExistsUser = _.negate(_.isNil)

const titleSet = {
  create: {
    title: '신규 생성',
    subTitle: '신규 계정 생성',
  },
  modify: {
    title: '계정 정보 수정',
    subTitle: '계정 정보 수정',
  },
} as const

export const statuses = {
  IDLE: 0,
  ON_PROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3,
} as const

export type Status = typeof statuses[keyof typeof statuses]

const DialogUserForm: React.FC<PropType> = ({
  user,
  visible,
  width = defaultWidth,
  formRefForTesting,
  onClose,
  onSuccess,
}) => {
  if (!visible) return null

  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form
  const isModifying = isModifyingExistsUser(user)
  const titles = isModifying ? titleSet.modify : titleSet.create
  const creating = useUserCreation()
  const modifying = useUserModification()
  const status = useRef<Status>(statuses.IDLE)

  const _onClose = useCallback(() => {
    onClose()
    currentFormRef.resetFields()
  }, [currentFormRef])

  const onFinish = useCallback(() => {
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    currentFormRef.submit()
    status.current = statuses.ON_PROGRESS
    if (isModifying) {
      const payload = buildPayloadForModifying(currentFormRef.getFieldsValue())
      modifying.setPayload(payload)
      modifying.setUserKey((user as User)?.username)
    } else {
      const payload = currentFormRef.getFieldsValue()
      creating.setPayload(payload)
    }
  }, [currentFormRef])

  useEffect(() => {
    if (isModifying || !creating.data || creating.isLoading) return
    const payload = currentFormRef.getFieldsValue()
    !!onSuccess &&
      onSuccess('create', {
        data: creating.data,
        payload,
        title: '생성 완료',
        content: `성공적으로 ${creating.data.username} 계정을 생성했습니다`,
        notiTitle: '생성 완료',
        notiContent: `성공적으로 계정을 생성했습니다`,
      })
    _onClose()
  }, [creating.data, creating.isLoading])

  useEffect(() => {
    if (!isModifying || !modifying.data || modifying.isLoading) return
    const payload = currentFormRef.getFieldsValue()
    !!onSuccess &&
      onSuccess('modify', {
        data: modifying.data,
        payload,
        title: '수정 완료',
        content: `성공적으로 ${modifying.data.username} 계정을 수정했습니다`,
        notiTitle: '수정 완료',
        notiContent: `성공적으로 계정을 수정했습니다`,
      })
    _onClose()
  }, [modifying.data, modifying.isLoading])

  useEffect(() => {
    const userError = isModifying ? modifying.error : creating.error
    if (userError) {
      if (userError?.status === 403 || userError?.status === 404) {
        notifyFailure({
          message: '요청 실패',
          description: '유효하지 않은 요청입니다.',
        })
      }
      if (userError?.status === 403 || userError?.status === 404) {
        notifyFailure({
          message: '처리 실패',
          description:
            '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
        })
      }
      _onClose()
    }
  }, [creating.error, modifying.error])

  return (
    <>
      <FormModal
        title={titles.title}
        subTitle={titles.subTitle}
        visible={visible}
        width={width}
        onCancel={_onClose}
        isLoading={creating.isLoading || modifying.isLoading}
        onOk={onFinish}
      >
        <UserEditForm formRef={currentFormRef} user={user} />
      </FormModal>
    </>
  )
}

export default DialogUserForm
