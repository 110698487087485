import React, { useState, useEffect } from 'react'

import { dateFormat } from '@/utils/date'
import { Nil } from '@/@types/composite'
import { noCustomerKindValues } from '@/constants/apps.customer'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'
import { User } from '@/apps/auth/@types/user'
import {
  Customer,
  CustomerModifyFormData,
  Gender,
} from '@/apps/customer/@types/customer'
import { CustomerKind } from '@/apps/customer/@types/customer-kind'
import useCustomerDetail from '@/apps/customer/hooks/useCustomerDetail'
import useCustomerKindList from '@/apps/customer/hooks/useCustomerKindList'
import { useCustomerModification } from '@/apps/customer/hooks/useCustomerManipulation'
import { Order } from '@/apps/order/@types/order'

import CustomerInfoView from './CustomerInfo.View'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  user: User | Nil
  customer: Customer<Order, User>
}

const CustomerInfo: React.FC<PropType> = ({ user, customer }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [payload, setPayload] = useState<CustomerModifyFormData>({})
  const detail = useCustomerDetail(customer.uid)
  const customerKinds = useCustomerKindList()
  const modifying = useCustomerModification()

  const onClickEditMode = () => {
    setIsEditMode(true)
  }

  const onCancelEdit = () => {
    setIsEditMode(false)
    setPayload({})
    modifying.setPayload({})
    modifying.setCustomerKey(null)
  }

  const onChangeBirthday = (value: Date | null) => {
    setPayload({
      ...payload,
      birthday: value ? dateFormat(value) : null,
    })
  }

  const onChangeGender = (value: Gender | null) => {
    const gender = value === 'N' || value === null ? null : value
    setPayload({
      ...payload,
      gender: gender,
    })
  }

  const onChangeKind = (value: number | null) => {
    const kind = value === -1 || value === null ? null : value
    setPayload({
      ...payload,
      kind: kind,
    })
  }

  const onSave = () => {
    if (user?.id === customer.staff?.id) {
      modifying.setPayload(payload)
      modifying.setCustomerKey(customer.uid)
    } else {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      })
      onCancelEdit()
    }
  }

  const getCustomerKind = (onlyActive: boolean) => {
    const noCustomerKind: CustomerKind = {
      id: noCustomerKindValues.id,
      name: noCustomerKindValues.name,
    }
    if (onlyActive) {
      return [
        ...[noCustomerKind],
        ...(customerKinds.data?.results.filter(
          ({ status }) => status === 'active',
        ) ?? []),
      ]
    } else {
      return [...[noCustomerKind], ...(customerKinds.data?.results ?? [])]
    }
  }

  const getShowToggleEditMode = () => {
    const assignedStaff = customer.staff
    if (assignedStaff === null) return false
    return assignedStaff.id === user?.id
  }

  useEffect(() => {
    if (!modifying.data || modifying.isLoading) return
    notifySuccess({
      message: '저장 완료',
      description: '수정하신 내용으로 저장 완료했습니다.',
    })
    onCancelEdit()
    detail.mutate({ ...modifying.data })
  }, [modifying.data, modifying.isLoading])

  useEffect(() => {
    if (!modifying.error || modifying.isLoading) return
    if (
      modifying.error?.status === 400 ||
      modifying.error?.status === 403 ||
      modifying.error?.status === 404
    ) {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다.',
      })
      customerKinds.mutate()
    } else if (serverErrorStatus.includes(modifying.error?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
      })
    } else {
      notifyFailure({
        message: '저장 실패',
        description: '내용 저장을 실패했습니다. 다시 시도해주세요.',
      })
    }
    onCancelEdit()
  }, [modifying.error, modifying.isLoading])

  if (!detail.data) return null
  return (
    <CustomerInfoView
      isEditMode={isEditMode}
      isLoading={customerKinds.isLoading || modifying.isLoading}
      customer={detail.data}
      customerKinds={getCustomerKind(true)}
      customerKindName={
        getCustomerKind(false).find((o) => o.id === customer.kind)?.name
      }
      showToggleEditMode={getShowToggleEditMode()}
      kind={payload?.kind ?? undefined}
      onClickEditMode={onClickEditMode}
      onCancelEdit={onCancelEdit}
      onChangeGender={onChangeGender}
      onChangeKind={onChangeKind}
      onChangeBirthday={onChangeBirthday}
      onSave={onSave}
    />
  )
}

export default CustomerInfo
