import React from 'react'
import { useHistory } from 'react-router-dom'

import LogoutButton from '@/apps/auth/components/LogoutButton'
import { PAGES } from '@/constants/apps.auth'
import { User } from '@/apps/auth/@types/user'

interface PropType {
  user: User
}

export const TopBar: React.FC<PropType> = ({ user }) => {
  const history = useHistory()

  const onFinishLogout = () => {
    history.push(PAGES.login.path)
  }

  return (
    <div className="tw-h-16 tw-px-4 tw-flex tw-justify-end tw-items-center">
      {!!user && (
        <LogoutButton data-testid="btn-logout" onFinish={onFinishLogout} />
      )}
    </div>
  )
}

export default TopBar
