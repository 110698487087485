import { useState } from 'react'
import useSWR from 'swr'
import { post, patch, del } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import {
  buildManipulationHook,
  CreationHookReturnType,
  ModificationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import {
  CustomerCurriculum,
  CustomerCurriculumCreatePayload,
  CustomerCurriculumModifyPayload,
} from '@/apps/customer/@types/customer.curriculum'
import { Nil } from '@/@types/composite'

const fetcher = (url: string | null, params?: Nil) => {
  if (!url) return
  return patch<Nil, CustomerCurriculum>(url, params)
}

export const useCustomerCurriculumCreation: () => CreationHookReturnType<
  CustomerCurriculumCreatePayload,
  CustomerCurriculum
> = () => {
  return buildManipulationHook<
    CustomerCurriculumCreatePayload,
    CustomerCurriculum
  >({
    url: URLS.customerCurriculumList,
    request: post,
  })
}

export const useCustomerCurriculumModification: () => ModificationHookReturnType<
  CustomerCurriculumModifyPayload,
  CustomerCurriculum
> = () => {
  const [key, setKey] = useState<Key>(null)
  return {
    ...buildManipulationHook<
      CustomerCurriculumModifyPayload,
      CustomerCurriculum
    >({
      url: key ? URLS.getCustomerCurriculumDetail(key as string) : null,
      request: patch,
    }),
    setKey,
  }
}

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCustomerCurriculumDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating, mutate } = useSWR(
    [key ? URLS.getCustomerCurriculumDetail(key as string) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )

  const _setKey = (newKey: Key) => {
    if (newKey === key) {
      mutate()
      return
    }
    setKey(newKey)
  }

  const isLoading = !!key && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey: _setKey,
  }
}

export const useCustomerCurriculumClose = () => {
  const [key, setKey] = useState<Key>(null)
  const { data, error, isValidating, mutate } = useSWR(
    [key ? URLS.closeCustomerCurriculum(key as number) : null, null],
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )

  const isLoading = !!key && !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    setKey,
    key,
    mutate,
  }
}
