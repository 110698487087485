import React, { useEffect, useState } from 'react'
import { TableProps } from 'antd'

import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import {
  ActiveProgress,
  BaseTaskListPropType,
  Progress,
  Task,
} from '@/apps/task/@types/task'
import { useTaskModification } from '@/apps/task/hooks/useTaskManipulation'
import DeleteTaskModal from '@/apps/task/components/DeleteTaskModal'

import IssuedTaskTableView from './IssuedTaskTable.View'
import { serverErrorStatus } from '@/utils/http'

interface DataPropType {
  isInChargeOf: boolean
  progresses: ActiveProgress[]
  pagination?: TableProps<Task>['pagination']
  onChangeTask?: (task: Task) => void
  onDeleteTask?: (task: Task) => void
}

type PropType = Pick<
  BaseTaskListPropType,
  'data' | 'page' | 'limit' | 'taskKinds'
> &
  DataPropType

const IssuedTaskTable: React.FC<PropType> = ({
  data,
  page,
  limit,
  isInChargeOf,
  progresses,
  taskKinds,
  pagination,
  onChangeTask,
  onDeleteTask,
}) => {
  const modifying = useTaskModification()

  const [selected, setSelected] = useState<Task | null>(null)
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)

  const onChangeProgress = (task: Task, progress: Progress) => {
    if (task.progress === progress) return
    modifying.setTaskKey(task.id)
    modifying.setPayload({
      progress: progress,
      customer: task.customer,
    })
  }

  const onClickDeleteTask = (task: Task) => {
    setSelected(task)
    setIsVisibleDeleteModal(true)
  }

  const onCloseDeleteModal = () => {
    setIsVisibleDeleteModal(false)
    setSelected(null)
  }

  const onFinishDeletion = async () => {
    setIsVisibleDeleteModal(false)
    !!onDeleteTask && onDeleteTask(selected as Task)
    setSelected(null)
  }

  useEffect(() => {
    if (modifying.isLoading) return
    if (!modifying.data) return
    notifySuccess({
      message: '변경 완료',
      description: `${modifying.data.progress.toUpperCase()} 진행 상태로 변경했습니다.`,
    })
    modifying.setTaskKey(null)
    modifying.setPayload({})

    !!onChangeTask && onChangeTask(modifying.data)
  }, [modifying.data, modifying.isLoading])

  useEffect(() => {
    if (modifying.isLoading) return
    if (!modifying.error) return
    if (
      modifying.error?.status === 400 ||
      modifying.error?.status === 403 ||
      modifying.error?.status === 404 ||
      serverErrorStatus.includes(modifying.error?.status)
    ) {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      })
    } else {
      notifyFailure({
        message: '변경 실패',
        description: '변경을 실패했습니다. 다시 시도해보세요.',
      })
    }
  }, [modifying.error, modifying.isLoading])

  return (
    <>
      <IssuedTaskTableView
        data={data}
        pagination={pagination}
        page={page}
        limit={limit}
        isInChargeOf={isInChargeOf}
        progresses={progresses}
        taskKinds={taskKinds}
        onChangeProgress={onChangeProgress}
        onClickDeleteTask={onClickDeleteTask}
      />
      {!!selected && isVisibleDeleteModal && (
        <DeleteTaskModal
          data-testid="delete-modal"
          task={selected}
          title="업무 삭제"
          content="해당 업무를 삭제할 경우 되돌릴 수 없습니다. 정말로 업무를 삭제하시겠습니까?"
          visible={isVisibleDeleteModal}
          onFinish={onFinishDeletion}
          onCancel={onCloseDeleteModal}
        />
      )}
    </>
  )
}

export default IssuedTaskTable
