import { useState } from 'react'

import { post, patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.task'
import { buildManipulationHook } from '@/utils/swr/helpers'
import {
  Task,
  TaskModifyPayload,
  TaskCreatePayload,
} from '@/apps/task/@types/task'

export const useTaskCreation = () => {
  return buildManipulationHook<TaskCreatePayload, Task>({
    url: URLS.taskList,
    request: post,
  })
}

export const useTaskModification = () => {
  const [key, setKey] = useState<number | null | undefined>(null)
  return {
    ...buildManipulationHook<TaskModifyPayload, Task>({
      url: key ? URLS.getTaskDetail(key) : null,
      request: patch,
    }),
    setTaskKey: setKey,
  }
}
