import React, { useEffect } from 'react'

import {
  CreationHookReturnType,
  ModificationHookReturnType,
} from '@/utils/swr/helpers'
import { form as baseFormErrors } from '@/constants/errors'
import {
  Curriculum,
  CurriculumCreatePayload,
  CurriculumModifyPayload,
  CreatingFormRules,
} from '@/apps/curriculum/@types/curriculum'
import EditSimpleItemForm from '@/components/modal/edit-simple-item-form/EditSimpleItemForm'
import { curriculumError } from '@/constants/apps.curriculum'
import { Nil } from '@/@types/composite'

interface PropType {
  isVisible: boolean
  selectedItem: Curriculum | Nil
  onFinish?: (isModifying: boolean) => void
  onCancel?: () => void
  setModifyingKey: (data: Curriculum | Nil) => void
  creating: CreationHookReturnType<CurriculumCreatePayload, Curriculum>
  modifying: ModificationHookReturnType<CurriculumModifyPayload, Curriculum>
}

const maxLengthOfName = 40
const maxLengthOfDesc = 100
const hiddenFields = { compilationKind: 'regular' }

const EditCurriculumForm: React.FC<PropType> = ({
  isVisible,
  selectedItem,
  onFinish,
  onCancel,
  setModifyingKey,
  creating,
  modifying,
}) => {
  useEffect(() => {
    if (
      modifying.error?.data?.detail === curriculumError.CURRICULUM_INACTIVE &&
      onCancel
    ) {
      onCancel()
    }
  }, [modifying.error])

  return (
    <EditSimpleItemForm<
      Curriculum,
      CurriculumCreatePayload,
      CurriculumModifyPayload
    >
      isVisible={isVisible}
      item={selectedItem}
      onFinish={onFinish}
      onCancel={onCancel}
      title={getEditModalTitle(!!selectedItem)}
      subTitle={getEditModalSubTitle(!!selectedItem)}
      itemName="커리큘럼"
      itemNameInputLabel="커리큘럼 제목"
      itemNameInputPlaceholder={`커리큘럼 제목 입력 (최대 노출 글자 수 ${maxLengthOfName}자)`}
      itemDescInputLabel="설명"
      itemDescInputPlaceholder={`설명 입력 (최대 노출 글자 수 ${maxLengthOfDesc}자)`}
      creating={creating}
      creatingRules={creatingRules}
      modifying={modifying}
      setModifyingKey={setModifyingKey}
      modifyingRules={creatingRules}
      hiddenFields={hiddenFields}
    />
  )
}

const getEditModalTitle = (isModifying: boolean) => {
  return isModifying ? '커리큘럼 수정' : '신규 커리큘럼 생성'
}

const getEditModalSubTitle = (isModifying: boolean) => {
  return isModifying ? '커리큘럼 수정' : '신규 커리큘럼 추가'
}

const creatingRules: CreatingFormRules = {
  name: [
    {
      required: true,
      message: baseFormErrors.requiredField,
    },
    {
      type: 'string',
      max: maxLengthOfName,
      message: baseFormErrors.invalidFormat,
    },
  ],
  description: [
    {
      required: true,
      message: baseFormErrors.requiredField,
    },
    {
      type: 'string',
      max: maxLengthOfDesc,
      message: baseFormErrors.invalidFormat,
    },
  ],
}

export default EditCurriculumForm
