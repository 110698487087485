import React from 'react'
import _ from 'lodash'
import classNames from 'classnames/bind'
import { Radio, Typography } from 'antd'
import { Nil } from '@/@types/composite'
import BasicButton from '@/components/buttons/BasicButton'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import Divider from '@/components/layout/section/Divider'
import { careActionKinds } from '@/constants/apps.care'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import CustomerCareProgram from '@/apps/customer/components/customer-care-program/CustomerCareProgram'
import { Care } from '@/apps/care/@types/care'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import CurriculumCareProgram from '@/apps/curriculum/components/curriculum-care-program/CurriculumCareProgram'
import styles from './OptionalCareList.View.module.scss'

interface PropType {
  isMyCustomer?: boolean
  customerCurriculum?: CustomerCurriculum | Nil
  curriculum?: Curriculum | Nil
  optionalCares: CurriculumCare[] | CustomerCare<Care, CustomerCareItem>[]
  disabledAddCare: boolean
  customerCurriculumOpenedAt?: Date | Nil
  caculatedCustomerCurriculumClosedAt?: Date | Nil
  onClickAddCare: () => void
  onFinishAddCareItem?: () => void
  onFinishDeleteCare?: () => void
  onFinishDeleteCareItem?: () => void
  onClickToggleSuncare: (
    care: CustomerCare<Care, CustomerCareItem>,
    isActivate: boolean,
  ) => void
}

const OptionalCareListView: React.FC<PropType> = ({
  isMyCustomer,
  curriculum,
  customerCurriculum,
  optionalCares,
  disabledAddCare,
  customerCurriculumOpenedAt,
  caculatedCustomerCurriculumClosedAt,
  onClickAddCare,
  onFinishAddCareItem,
  onFinishDeleteCare,
  onFinishDeleteCareItem,
  onClickToggleSuncare,
}) => {
  const showEmptyText = optionalCares.length < 1
  const curriculumCares = optionalCares as CurriculumCare[]
  const customerCares = optionalCares as CustomerCare<Care, CustomerCareItem>[]
  return (
    <Section>
      <SectionTitle title="선택 케어">
        <BasicButton
          label="케어 유형 추가"
          size="large"
          icon="plus"
          disabled={disabledAddCare}
          onClick={onClickAddCare}
        />
      </SectionTitle>
      <Divider />
      <div className={cx('container')}>
        {showEmptyText && <EmptyText />}
        {!showEmptyText &&
          !!curriculum &&
          _.map(curriculumCares, (optionalCare) => {
            return (
              <CurriculumCareProgram
                key={optionalCare.id}
                isCustomerCurriculum={false}
                showDeleteCareButton={true}
                curriculum={curriculum}
                curriculumCare={optionalCare}
                onFinishAddCurriculumCareItem={onFinishAddCareItem}
                onFinishDeleteCurriculumCare={onFinishDeleteCare}
                onFinishDeleteCurriculumCareItem={onFinishDeleteCareItem}
              />
            )
          })}
        {!showEmptyText &&
          !!customerCurriculum &&
          _.map(customerCares, (optionalCare) => {
            if (optionalCare.care.actionKind === careActionKinds.CHECKING_UV)
              return (
                <ActivateSunCare
                  key={optionalCare.id}
                  care={optionalCare}
                  isMyCustomer={isMyCustomer}
                  suncareActivation={optionalCare.isVisible}
                  onClickToggleSuncare={onClickToggleSuncare}
                />
              )
            return (
              <CustomerCareProgram
                key={optionalCare.id}
                isMyCustomer={isMyCustomer}
                showDeleteCareButton={true}
                customerCurriculum={customerCurriculum}
                customerCare={optionalCare}
                customerCurriculumOpenedAt={customerCurriculumOpenedAt}
                caculatedCustomerCurriculumClosedAt={
                  caculatedCustomerCurriculumClosedAt
                }
                onFinishAddCustomerCareItem={onFinishAddCareItem}
                onFinishDeleteCustomerCare={onFinishDeleteCare}
                onFinishDeleteCustomerCareItem={onFinishDeleteCareItem}
              />
            )
          })}
      </div>
    </Section>
  )
}

export default OptionalCareListView

const cx = classNames.bind(styles)

const EmptyText: React.FC = () => (
  <div className={cx('empty')}>
    <span>설정한 아이템이 없습니다. 케어 유형을 먼저 추가하세요.</span>
  </div>
)

const ActivateSunCare: React.FC<{
  care: CustomerCare<Care, CustomerCareItem>
  suncareActivation: boolean
  isMyCustomer?: boolean
  onClickToggleSuncare: (
    care: CustomerCare<Care, CustomerCareItem>,
    isActive: boolean,
  ) => void
}> = ({ care, suncareActivation, isMyCustomer, onClickToggleSuncare }) => {
  return (
    <div>
      <div className={cx('suncare-header')}>
        <Typography.Text className={cx('suncare-name')}>썬케어</Typography.Text>
        <Typography.Text className={cx('suncare-description')}>
          자외선 케어
        </Typography.Text>
      </div>
      <div className={cx('suncare-radio-container')}>
        <Radio.Group
          value={suncareActivation}
          buttonStyle="solid"
          disabled={!(!_.isUndefined(isMyCustomer) && isMyCustomer)}
          onChange={(e) => onClickToggleSuncare(care, e.target.value)}
        >
          <Radio.Button value={false}>썬케어 비활성화</Radio.Button>
          <Radio.Button value={true}>썬케어 활성화</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  )
}
