import React from 'react'
import { Nil } from '@/@types/composite'

interface PropType {
  text?: string | Nil
  alterText?: string
}

export const SpanText: React.FC<PropType> = ({ text, alterText = '-' }) => {
  return <span>{text ?? alterText ?? ''}</span>
}

export default SpanText
