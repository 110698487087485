import { Dispatch, SetStateAction, useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'

import { patch, post } from '@/clients/http/authorized-http-client'
import { NullableString } from '@/@types/composite'

type Request = typeof post | typeof patch

type Response<T> = Promise<T> | undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetcher = <T extends any, RT>(
  request: Request,
  url: NullableString,
  payload: T,
) => {
  if (!url || !payload) return
  return request<T, RT>(url, payload)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildManipulationHook = <PT extends any, RT>({
  url,
  request,
}: {
  url: string | null
  request: Request
}) => {
  const [payload, setPayload] = useState<PT | null>(null)
  const { data, error } = useSWR(
    [url && !_.isEmpty(payload) ? url : null, payload],
    _.partial<[NullableString, PT], Request, Response<RT>>(fetcher, request),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  )

  return {
    data,
    error: error?.response,
    setPayload,
    isLoading: !!url && !!payload && !data && !error,
  }
}

class Wrapper<PT, RT> {
  wrapped(url: string | null, request: Request) {
    return buildManipulationHook<PT, RT>({ url, request })
  }
}

type ManipulationReturnType<PT, RT> = ReturnType<Wrapper<PT, RT>['wrapped']>

export type Key = number | string | null | undefined

export type CreationHookReturnType<PT, RT> = ManipulationReturnType<PT, RT>

export interface ModificationHookReturnType<PT, RT>
  extends ManipulationReturnType<PT, RT> {
  setKey: Dispatch<SetStateAction<Key>>
}
