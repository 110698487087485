import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import _ from 'lodash'

import {
  CalendarDataInterface,
  DiaryDetail,
  ProgressStatus,
} from '@/apps/customer/@types/customer-activity'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'

import ActivityHistoryView, {
  CareProgress,
  CareTableRow,
} from './ActivityHistory.View'
import useDiaries from '../../hooks/useDiaries'
import useDiaryDetail from '../../hooks/useDiaryDetail'

interface PropType {
  customer: Customer<Order, User>
}

interface State {
  calendarData: CalendarDataInterface
  diaryUid: string
  progressData: ProgressStatus
  isFirstRender: boolean
}

const ActivityHistory: React.FC<PropType> = ({ customer: { uid } }) => {
  const [{ calendarData, diaryUid, progressData, isFirstRender }, setState] =
    useState<State>({
      calendarData: {},
      diaryUid: '',
      progressData: {
        dateSelected: '',
        percent: null,
      },
      isFirstRender: true,
    })

  const {
    newDate,
    data: diaryListData,
    isLoading: diaryListLoading,
    setParams: diaryListSetParams,
  } = useDiaries(uid)

  const { data: diaryData, setParams: diarySetParams } = useDiaryDetail()

  useEffect(() => {
    if (!document.getElementsByClassName('ant-today-button')[0]) {
      const datePicker = document.getElementsByClassName(
        'ant-picker-calendar-header',
      )[0]
      const todayBtn = document.createElement('div')
      todayBtn.textContent = 'Today'
      todayBtn.className = 'ant-today-button'
      todayBtn.onclick = () => handleSelectDate('')
      datePicker && datePicker.appendChild(todayBtn)
    }
  }, [])

  useEffect(() => {
    if (!diaryListLoading) {
      updateHistoryState(newDate)
    }
  }, [diaryListData, diaryListLoading])

  useEffect(() => {
    diarySetParams({ uid: diaryUid })
  }, [diaryUid])

  const handleSelectDate = (dateArg: string) => {
    const dateSelectedFormat = format(
      dateArg ? new Date(dateArg) : new Date(),
      'yyyy-MM-dd',
    )

    if (isChangeMonth(dateArg)) {
      diaryListSetParams({
        customerUid: uid,
        date: dateSelectedFormat,
      })
    } else {
      updateHistoryState(dateArg)
    }
  }

  const updateHistoryState = (dateArg: string) => {
    const { dateSelected } = progressData
    const dateSelectedFormat = format(
      dateArg ? new Date(dateArg) : new Date(),
      'yyyy-MM-dd',
    )
    if (dateArg !== dateSelected) {
      setState((prev) => ({
        ...prev,
        diaryUid: searchDiaryUid(dateSelectedFormat) || '',
        calendarData:
          isChangeMonth(dateArg) || isFirstRender
            ? formatCalendarData()
            : calendarData,
        progressData: {
          ...progressData,
          dateSelected: dateSelectedFormat,
          percent: calendarData[dateSelectedFormat],
        },
        isFirstRender: false,
      }))
    }
  }

  const formatCalendarData = () => {
    if (diaryListData) {
      const calendarResultsParsed = diaryListData.results.map(
        (calendarResultPerDay) => {
          if (calendarResultPerDay?.progress?.point?.score >= 0) {
            const {
              progress: {
                point: { score },
              },
            } = calendarResultPerDay
            return {
              ...calendarResultPerDay,
              percent: Math.floor(Number(score) ?? 0),
            }
          } else return {}
        },
      )

      const result = _.chain(calendarResultsParsed)
        .keyBy('date')
        .mapValues('percent')
        .value()

      return result
    } else return {}
  }

  const isChangeMonth = (dateArg: string): boolean => {
    const { dateSelected } = progressData
    const dateParsedPrev = dateSelected ? new Date(dateSelected) : null
    const dateParsedCurr = dateArg ? new Date(dateArg) : new Date()
    return Boolean(
      dateParsedPrev && dateParsedPrev.getMonth() !== dateParsedCurr.getMonth(),
    )
  }

  const searchDiaryUid = (dateArg: string) =>
    diaryListData?.results.find(({ date }) => date === dateArg)?.uid

  const diaryDataProcess = (diary?: DiaryDetail): CareTableRow[] => {
    if (!diary)
      return [{ key: '0000-00-00', title: '-', point: '-', progress: null }]

    const diaryCareActions: { [key: number]: number } = {}

    diary.diaryCareActions.forEach(({ care, point }) => {
      if (diaryCareActions[care]) {
        diaryCareActions[care] = diaryCareActions[care] + point
      } else {
        diaryCareActions[care] = point
      }
    })

    const progressCheck = (
      action: number,
      total: number,
    ): CareProgress | null => {
      if (total === 0) return null
      else if (action === 0) return 'undone'
      else if (action >= total) return 'done'
      else if (action < total) return 'proceeding'
      else return null
    }

    const diaryCares: CareTableRow[] = diary.cares.map(
      ({ id, name, careItems }) => {
        const totalPoint: number = careItems.reduce(
          (acc, { point }) => acc + point,
          0,
        )

        return {
          key: `${diary.uid}-${id}`,
          title: name,
          progress: progressCheck(diaryCareActions[id] ?? 0, totalPoint),
          point: `${diaryCareActions[id] ?? 0}/${totalPoint}`,
        }
      },
    )
    return diaryCares
  }

  return (
    <ActivityHistoryView
      progress={progressData}
      handleSelectDate={handleSelectDate}
      calendarData={calendarData}
      careTableData={diaryDataProcess(diaryData)}
    />
  )
}

export default ActivityHistory
