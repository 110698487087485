import { useState } from 'react'
import useSWR from 'swr'

import { del, patch, post } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'

import {
  StaffAssignmentPayload,
  StaffChagingPayload,
} from '@/apps/customer/@types/customer'

interface AssignmentStaffPathKey {
  key: string
}

type pathKey = AssignmentStaffPathKey

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

const patchRequest = (
  url: string | null,
  payload: StaffChagingPayload | null,
) => {
  if (!url || !payload) return
  return patch<StaffChagingPayload, never>(url, payload).then(() => true)
}

const postRequest = (
  url: string | null,
  payload: StaffChagingPayload | null,
) => {
  if (!url || !payload) return
  return post<StaffAssignmentPayload, never>(url, payload).then(() => true)
}

export const useStaffAssignDeletion = () => {
  const [pathKey, setpathKey] = useState<pathKey | null>(null)

  const { data, error, isValidating } = useSWR(
    [pathKey?.key ? URLS.assignStaff(pathKey.key) : null, pathKey],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    setCustomerKey: setpathKey,
  }
}

export const useStaffAssignChanging = () => {
  const [pathKey, setPathKey] = useState<pathKey | null>(null)
  const [payload, setPayload] = useState<StaffChagingPayload | null>(null)

  const { data, error, isValidating } = useSWR(
    [pathKey?.key ? URLS.assignStaff(pathKey.key) : null, payload],
    patchRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    isLoading,
    isValidating,
    setPayload,
    setCustomerKey: setPathKey,
  }
}

export const useStaffAssignCreation = () => {
  const [pathKey, setPathKey] = useState<pathKey | null>(null)
  const [payload, setPayload] = useState<StaffAssignmentPayload | null>(null)

  const { data, error, isValidating } = useSWR(
    [pathKey?.key ? URLS.assignStaff(pathKey?.key) : null, payload],
    postRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    isLoading,
    isValidating,
    setPayload,
    setCustomerKey: setPathKey,
  }
}
