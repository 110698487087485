import React, { useState, useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { Nil } from '@/@types/composite'
import { ErrorMessage } from '@/@types/error'
import envs from '@/config/variables'
import { hasAnyFieldsError } from '@/utils/antd/form'
import { form as baseFormErrors } from '@/constants/errors'
import { careCompilations, careVisibility } from '@/constants/apps.care'
import { notifyFailure, notifySuccess } from '@/components/antd/Notification'
import useCareList from '@/apps/care/hooks/useCareList'
import { Care, CareListParam } from '@/apps/care/@types/care'
import { CreatingCurriculumCareFormRules } from '@/apps/curriculum/@types/curriculum.care'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { useCurriculumCareCreation } from '@/apps/curriculum/hooks/useCurriculumCareManipulation'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import { useCustomerCareCreation } from '@/apps/customer/hooks/curriculum/useCustomerCareManipulation'
import AddCurriculumCareFormView from './AddCurriculumCareForm.View'
import { curriculumCompilationKind } from '@/constants/apps.curriculum'

interface PropType {
  curriculum: CustomerCurriculum | Curriculum | Nil
  isCustomerCurriculum: boolean
  isVisible: boolean
  onFinish: () => void
  onCancel: () => void
  formRefForTesting?: FormInstance // only for testing
}

const AddCurriculumCareForm: React.FC<PropType> = ({
  curriculum,
  isCustomerCurriculum,
  isVisible,
  onFinish,
  onCancel,
  formRefForTesting,
}) => {
  const [list, setList] = useState<Care[]>([])
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const fetchingCares = useCareList()

  const creating = isCustomerCurriculum
    ? useCustomerCareCreation()
    : useCurriculumCareCreation()

  useEffect(() => {
    if (!fetchingCares.data || fetchingCares.isLoading) return
    setList(fetchingCares.data.results ?? [])
  }, [fetchingCares.data, fetchingCares.isLoading])

  useEffect(() => {
    if (!curriculum) return
    if (isVisible) {
      fetchingCares.setParams(buildQueryParam(isCustomerCurriculum, curriculum))
    } else {
      fetchingCares.setParams({})
    }
    currentFormRef.resetFields()
  }, [isVisible, curriculum])

  useEffect(() => {
    if (!isVisible) return
    if (creating.data && !creating.isLoading) {
      _notifySuccess('추가 완료', '케어 유형을 성공적으로 추가했습니다.')
      onFinish()
    }
  }, [creating.data, creating.isLoading])

  useEffect(() => {
    if (!isVisible) return
    if (creating.error && !creating.isLoading) {
      _notifyFailure('요청 실패', '유효하지 않은 요청입니다.')
      onFinish()
    }
  }, [creating.error, creating.isLoading])

  const getBasePayload = (isCustomerCurriculum: boolean) =>
    isCustomerCurriculum
      ? { customerCurriculum: (curriculum as CustomerCurriculum)?.id }
      : {
          curriculum: (curriculum as Curriculum)?.id,
        }

  const onChangeSelect = (id: number) => {
    currentFormRef.setFieldsValue({
      care: id,
    })
  }

  const onChangePage = (page: number) => {
    fetchingCares.setParams({ ...fetchingCares.params, page })
  }

  const _onFinish = async () => {
    if (fetchingCares.data?.results.length === 0) {
      onCancel()
      return
    }
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return
    creating.setPayload({
      ...getBasePayload(isCustomerCurriculum),
      ...currentFormRef.getFieldsValue(),
    })
  }

  const _onCancel = () => {
    onCancel()
  }

  return (
    <AddCurriculumCareFormView
      page={fetchingCares.params.page ?? defaultPage}
      limit={fetchingCares.params.limit ?? defaultLimit}
      count={fetchingCares.data?.count ?? list.length}
      data={list}
      isVisible={isVisible}
      isLoading={creating.isLoading}
      onChangeSelect={onChangeSelect}
      onChangePage={onChangePage}
      onFinish={_onFinish}
      onCancel={_onCancel}
      rules={creatingRules}
      formRef={currentFormRef}
    />
  )
}

export default AddCurriculumCareForm

const defaultPage = 1
const defaultLimit = 5

export const creatingRules: CreatingCurriculumCareFormRules = {
  care: [
    {
      required: true,
      message: baseFormErrors.requiredField,
    },
  ],
}

const _notifySuccess = (defaultMessage: string, defaultDescription: string) => {
  notifySuccess({
    message: defaultMessage,
    description: defaultDescription,
  })
}

const _notifyFailure = (
  defaultMessage: string,
  defaultDescription: string,
  errorMessage?: ErrorMessage,
) => {
  notifyFailure({
    message: errorMessage?.message ?? defaultMessage,
    description: errorMessage?.desc ?? defaultDescription,
  })
}

const buildQueryParam = (
  isCustomerCurriculum: boolean,
  curriculum: CustomerCurriculum | Curriculum | Nil,
): CareListParam => {
  const base: CareListParam = {
    limit: defaultLimit,
    isEssential: 'false',
    visibility: careVisibility.PUBLISHED,
    ordering: 'name',
    curriculumCompilation: [
      careCompilations.REGULAR_OR_AUTONOMOUS,
      careCompilations.DEFAULT_OR_REGULAR_OR_AUTONOMOUS,
      curriculum?.compilationKind === curriculumCompilationKind.AUTONOMOUS
        ? careCompilations.AUTONOMOUS
        : careCompilations.REGULAR,
    ],
  }
  return isCustomerCurriculum
    ? {
        ...base,
        unusedInCustomerCurriculum: (curriculum as CustomerCurriculum).id,
      }
    : {
        ...base,
        unusedInCurriculum: (curriculum as Curriculum).id,
      }
}
