import React from 'react'

import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'
import { SimpleCustomer, Customer } from '@/apps/customer/@types/customer'
import useCustomerOrderSummary from '@/apps/order/hooks/useCustomerOrderSummary'

import OrderSummaryView from './OrderSummary.View'

interface PropType {
  customer: Customer<Order, User>
}

const OrderSummary: React.FC<PropType> = ({ customer }) => {
  const orderSummary = useCustomerOrderSummary<SimpleCustomer>(customer.uid)

  if (!orderSummary.data) return null

  return <OrderSummaryView customer={customer} summary={orderSummary.data} />
}

export default OrderSummary
