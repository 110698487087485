export function* cycle<T>(iterable: Iterable<T>) {
  const saved = []
  for (const element of iterable) {
    yield element
    saved.push(element)
  }

  while (saved.length > 0) {
    for (const element of saved) {
      yield element
    }
  }
}
