import { useState } from 'react'

import { buildManipulationHook } from '@/utils/swr/helpers'
import { post, patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.auth'
import {
  User,
  UserCreatePayload,
  UserModifyPayload,
} from '@/apps/auth/@types/user'

export const useUserCreation = () => {
  return buildManipulationHook<UserCreatePayload, User>({
    url: URLS.userList,
    request: post,
  })
}

export const useUserModification = () => {
  const [userKey, setUserKey] = useState<string | null | undefined>(null)
  return {
    ...buildManipulationHook<UserModifyPayload, User>({
      url: userKey ? URLS.getUserDetail(userKey) : null,
      request: patch,
    }),
    setUserKey,
  }
}
