import React, { useCallback } from 'react'
import { Table, TableProps } from 'antd'
import {
  ActiveProgress,
  BaseTaskListPropType,
  Progress,
  Task,
} from '@/apps/task/@types/task'
import { numbering } from '@/utils/ui/pagination'
import ProgressActionButton from '@/components/buttons/task/ProgressActionButton'
import { DeleteButton } from '@/components/buttons/Icon'
import _ from 'lodash'
import { renderTaskKind } from '@/apps/task/components/TaskKindTag'

const { Column } = Table

interface DataPropType {
  isInChargeOf: boolean
  progresses: ActiveProgress[]
  pagination?: TableProps<Task>['pagination']
}

interface CallbackPropType {
  onChangeProgress?: (task: Task, newValue: Progress) => void
  onClickDeleteTask?: (task: Task) => void
}

type PropType = Pick<
  BaseTaskListPropType,
  'data' | 'page' | 'limit' | 'taskKinds'
> &
  CallbackPropType &
  DataPropType

const IssuedTaskTableView: React.FC<PropType> = ({
  data,
  page,
  limit,
  isInChargeOf,
  progresses,
  taskKinds,
  onChangeProgress,
  onClickDeleteTask,
  pagination,
}) => {
  const _renderTaskKind = useCallback(_.partial(renderTaskKind, taskKinds), [
    taskKinds,
  ])
  return (
    <Table<Task> dataSource={data} rowKey="id" pagination={pagination}>
      <Column
        title="번호"
        key="order"
        render={(_value, _record, index) => numbering(page, limit, index)}
      />

      <Column title="업무 유형" dataIndex="kind" render={_renderTaskKind} />

      <Column title="업무 내용" dataIndex="content" />

      {!!onChangeProgress && (
        <Column<Task>
          title="진행 상태"
          dataIndex="progress"
          render={(__, record) => (
            <ProgressActionButton
              progresses={progresses}
              item={record}
              isInChargeOf={isInChargeOf}
              onChange={onChangeProgress}
            />
          )}
        />
      )}

      {!!onClickDeleteTask && (
        <Column<Task>
          title="삭제"
          key="delete"
          render={(__, record) => (
            <DeleteButton
              disabled={!isInChargeOf}
              data-testid={`btn-delete-${record.id}`}
              onClick={() => onClickDeleteTask(record)}
            />
          )}
        />
      )}
    </Table>
  )
}

export default IssuedTaskTableView
