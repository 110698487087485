import React from 'react'

import { Nil } from '@/@types/composite'
import { Customer } from '@/apps/customer/@types/customer'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'

import CustomerDetailView from './CustomerDetail.View'

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
}

const CustomerDetail: React.FC<PropType> = ({ customer, user }) => {
  return <CustomerDetailView user={user} customer={customer} />
}

export default CustomerDetail
