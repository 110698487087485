import { visibility, visibilityManipulation } from '@/constants/common'

type Visibility = typeof visibility[keyof typeof visibility]

export const getVisibilityManipulation = (
  currentVisibility: Visibility,
  newVisibility: Visibility,
) => {
  if (!currentVisibility || !newVisibility) return null
  if (
    currentVisibility === visibility.UNPUBLISHED &&
    newVisibility === visibility.PUBLISHED
  )
    return visibilityManipulation.PUBLISHED
  if (
    currentVisibility === visibility.SUSPENDED &&
    newVisibility === visibility.PUBLISHED
  )
    return visibilityManipulation.RESUME
  if (
    currentVisibility === visibility.PUBLISHED &&
    newVisibility === visibility.SUSPENDED
  )
    return visibilityManipulation.SUSPENDED
}
