import React from 'react'

import { Descriptions, Skeleton } from 'antd'
import { RangeValue } from 'rc-picker/lib/interface'

import classNames from 'classnames/bind'

import {
  formatTotalNumberOfPeople,
  formatTotalNumberOfThings,
  formatTotalAmount,
} from '@/apps/dashboard/utils/helper'
import { StaffCustomerPurchaseStatus } from '@/apps/dashboard/@types/customer-purchase-status'
import { DatePicker } from '@/components/antd'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import BasicButton from '@/components/buttons/BasicButton'

import style from './MyCustomerPurchaseStatus.module.scss'

const cx = classNames.bind(style)

export const statusLabels = {
  purchaseCustomersCount: '총 결제 고객 수',
  purchaseOrdersCount: '총 결제 주문 수',
  purchaseTotalAmount: '총 결제 주문 금액',
  repurchaseCustomersCount: '총 재결제 고객 수',
  repurchaseOrdersCount: '총 재결제 주문 수',
  repurchaseTotalAmount: '총 재결제 주문 금액',
}

const { RangePicker } = DatePicker

interface PropType {
  data?: StaffCustomerPurchaseStatus
  isLoading: boolean
  defaultStartDate: Date
  defaultEndDate: Date
  dates?: RangeValue<Date>
  disableSelectDate?: (current: Date) => boolean
  onCalendarChange?: (dates: RangeValue<Date>) => void
  onClickSubmitDates?: () => void
}

const MyCustomerPurchaseStatusView: React.FC<PropType> = ({
  data,
  isLoading,
  defaultStartDate,
  defaultEndDate,
  dates,
  disableSelectDate,
  onCalendarChange,
  onClickSubmitDates,
}) => {
  const visibleSkeleton = isLoading || !data

  return (
    <Section isLargeBottom>
      <SectionTitle title="담당 고객 결제 현황" />
      <RangePicker
        className={cx('range-picker')}
        disabled={isLoading}
        disabledDate={disableSelectDate}
        defaultValue={[defaultStartDate, defaultEndDate]}
        value={dates}
        onCalendarChange={onCalendarChange}
      />
      <BasicButton
        className={cx('submit-dates-button')}
        label="조회"
        onClick={onClickSubmitDates}
      />
      {visibleSkeleton && <Skeleton />}
      {!visibleSkeleton && (
        <Descriptions bordered className={cx('descriptions')}>
          <Descriptions.Item label={statusLabels.purchaseCustomersCount}>
            {formatTotalNumberOfPeople(data.purchaseCustomersCount)}
          </Descriptions.Item>
          <Descriptions.Item label={statusLabels.purchaseOrdersCount}>
            {formatTotalNumberOfThings(data.purchaseOrdersCount)}
          </Descriptions.Item>
          <Descriptions.Item label={statusLabels.purchaseTotalAmount}>
            {formatTotalAmount(data.purchaseTotalAmount)}
          </Descriptions.Item>
          <Descriptions.Item label={statusLabels.repurchaseCustomersCount}>
            {formatTotalNumberOfPeople(data.repurchaseCustomersCount)}
          </Descriptions.Item>
          <Descriptions.Item label={statusLabels.repurchaseOrdersCount}>
            {formatTotalNumberOfThings(data.repurchaseOrdersCount)}
          </Descriptions.Item>
          <Descriptions.Item label={statusLabels.repurchaseTotalAmount}>
            {formatTotalAmount(data.repurchaseTotalAmount)}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Section>
  )
}

export default React.memo(MyCustomerPurchaseStatusView)
