import React from 'react'
import { Tag } from 'antd'

import { Nil } from '@/@types/composite'
import { TaskKind } from '@/apps/task/@types/task'

import styles from './TaskKindTag.module.scss'

interface PropType {
  item: TaskKind | Nil
}

const TaskKindTag: React.FC<PropType> = ({ item }) => {
  if (!item) return null
  return (
    <div className={styles.container}>
      <Tag className={item.category}>{item.name}</Tag>
    </div>
  )
}

export const renderTaskKind = (taskKinds: TaskKind[], key: number) => {
  const item = taskKinds.find((o) => o.id === key)
  return <TaskKindTag item={item} />
}

export default TaskKindTag
