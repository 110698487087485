import React from 'react'
import { Modal, ModalProps, Space, Typography } from 'antd'

import styles from './FormModal.module.scss'
import BasicButton from '@/components/buttons/BasicButton'

interface PropType extends ModalProps {
  subTitle?: string
  isLoading?: boolean
  contentClassName?: string
  footerError?: React.ReactNode
}

const defaultWidth = '858px'

const FormModal: React.FC<PropType> = ({
  isLoading,
  subTitle,
  children,
  width = defaultWidth,
  contentClassName,
  footerError,
  visible,
  ...modalProps
}) => {
  const contentWrapperClassName = [styles.container, contentClassName]
    .filter((v) => !!v)
    .join(' ')

  return (
    <Modal
      visible={visible}
      className={styles['form-modal']}
      width={width}
      footer={
        modalProps.footer ?? (
          <Footer
            key="buttons"
            isLoading={isLoading}
            okText={modalProps.okText}
            cancelText={modalProps.cancelText}
            onOk={modalProps.onOk}
            onCancel={modalProps.onCancel}
            footerError={footerError}
          />
        )
      }
      {...modalProps}
    >
      {!!subTitle && (
        <Typography.Title
          className="tw-header-4 tw-text-gray9 tw-mb-5"
          level={2}
        >
          {subTitle}
        </Typography.Title>
      )}
      <div className={contentWrapperClassName}>{children}</div>
    </Modal>
  )
}

interface FooterPropType
  extends Pick<ModalProps, 'onOk' | 'onCancel' | 'okText' | 'cancelText'> {
  isLoading?: boolean
  footerError?: React.ReactNode
}

const Footer: React.FC<FooterPropType> = ({
  onOk,
  onCancel,
  isLoading = false,
  okText = '완료',
  cancelText = '취소',
  footerError,
}) => {
  return (
    <Space className="footer">
      {footerError}
      <BasicButton
        data-testid="button/cancel"
        key="cancel"
        type="outline"
        loading={isLoading}
        onClick={onCancel}
        label={cancelText}
      />
      <BasicButton
        key="ok"
        data-testid="button/submit"
        loading={isLoading}
        onClick={onOk}
        label={okText}
      />
    </Space>
  )
}

export default FormModal
