import React, { useCallback } from 'react'
import _ from 'lodash'
import { Pagination, Table } from 'antd'

import { numbering } from '@/utils/ui/pagination'
import { dateFormat } from '@/utils/date'
import { EditButton, DeleteButton } from '@/components/buttons/Icon'
import BasicButton from '@/components/buttons/BasicButton'
import QuerySection from '@/components/table/QuerySection'
import Section from '@/components/layout/section/Section'
import {
  Task,
  TaskListParam,
  TaskListOrderingKey,
  BaseTaskListPropType,
} from '@/apps/task/@types/task'
import { scheduledTaskOrderingLabel } from '@/constants/apps.task'

import { renderTaskKind } from './TaskKindTag'

const { Column } = Table

interface DataPropType extends BaseTaskListPropType {
  isInChargeOf: boolean
  orderingIsDesc: boolean
  onClickAddTask?: () => void
  onClickEditTask?: (task: Task) => void
  onClickDeleteTask?: (task: Task) => void
}

type PropType = DataPropType & React.HTMLAttributes<HTMLElement>

export const orderOptions = _.chain(scheduledTaskOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const ScheduledTaskListView: React.FC<PropType> = ({
  isInChargeOf,
  page,
  count,
  limit,
  data,
  params,
  onClickAddTask,
  onClickEditTask,
  onClickDeleteTask,
  onChangePage,
  onChangeParam,
  orderingIsDesc,
  taskKinds,
  orderingKey,
  className,
}) => {
  const _renderTaskKind = useCallback(_.partial(renderTaskKind, taskKinds), [
    taskKinds,
  ])

  return (
    <Section className={className} title="업무 알람 스케줄링">
      <div className="tw-flex tw-justify-between tw-items-center tw-pb-4">
        <QuerySection<TaskListParam, TaskListOrderingKey>
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="업무 내용 검색"
          defaultOrderingIsDesc={orderingIsDesc}
          showSearch={false}
        />
        {!!onClickAddTask && (
          <BasicButton
            label="신규 업무 추가"
            icon="plus"
            onClick={onClickAddTask}
            disabled={!isInChargeOf}
            data-testid="btn-add"
          />
        )}
      </div>
      <Table<Task> dataSource={data} rowKey="id" pagination={false}>
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column
          title="알람 예정일"
          dataIndex="activeAt"
          render={(value) => dateFormat(value)}
        />

        <Column title="업무 유형" dataIndex="kind" render={_renderTaskKind} />

        <Column title="업무 내용" dataIndex="content" />

        <Column
          title="생성 일자"
          dataIndex="createdAt"
          render={(value) => dateFormat(value)}
        />

        <Column
          title="갱신 일자"
          dataIndex="updatedAt"
          render={(value) => dateFormat(value)}
        />

        {!!onClickEditTask && (
          <Column<Task>
            title="수정"
            key="modify"
            render={(__, record) => (
              <EditButton
                disabled={!isInChargeOf}
                data-testid={`btn-modify-${record.id}`}
                onClick={() => onClickEditTask(record)}
              />
            )}
          />
        )}

        {!!onClickDeleteTask && (
          <Column<Task>
            title="삭제"
            key="delete"
            render={(__, record) => (
              <DeleteButton
                disabled={!isInChargeOf}
                data-testid={`btn-delete-${record.id}`}
                onClick={() => onClickDeleteTask(record)}
              />
            )}
          />
        )}
      </Table>

      {!!onChangePage && (
        <Pagination
          defaultCurrent={page ?? 1}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

export default ScheduledTaskListView
