import React from 'react'
import classNames from 'classnames/bind'

import { progressLabels } from '@/constants/apps.task'
import { ActiveProgress, Progress, Task } from '@/apps/task/@types/task'
import ActionMenuButton from '@/components/buttons/ActionMenuButton'
import styles from './ProgressActionButton.module.scss'

const cx = classNames.bind(styles)

interface PropType {
  isInChargeOf: boolean
  item: Task
  progresses: ActiveProgress[]
  onChange: (task: Task, newValue: Progress) => void
}

const ProgressActionButton: React.FC<PropType> = ({
  isInChargeOf,
  item,
  progresses,
  onChange,
}) => {
  return (
    <div className={cx('button-container')}>
      <ActionMenuButton<Task, Progress>
        disabled={!isInChargeOf}
        item={item}
        label={item.progress.toUpperCase()}
        labels={progressLabels}
        value={item.progress}
        values={progresses}
        onChangeValue={onChange}
        testId={`btn-progress-${item.id}`}
      />
    </div>
  )
}

export default ProgressActionButton
