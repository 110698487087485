import React from 'react'

import classNames from 'classnames/bind'
import { Descriptions, Skeleton } from 'antd'

import { StaffsCustomerStatus } from '@/apps/dashboard/@types/customer-status'

import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'

import style from './MyCustomerStatus.module.scss'

export const statusLabels = {
  current: '현재 담당 고객',
  new: '오늘 신규 배정된 고객',
  channelTalk: '어제 채널톡 상담 고객',
  allTasks: '어제 전체 업무 완료한 고객',
  doneItems: '어제 DONE 완료한 아이템',
  noVisit: '7일 이상 미방문 고객',
}

interface PropType {
  data?: StaffsCustomerStatus
  isLoading: boolean
}

const cx = classNames.bind(style)

const MyCustomerStatusView: React.FC<PropType> = ({ data, isLoading }) => {
  const visibleSkeleton = isLoading || !data
  return (
    <>
      {visibleSkeleton && <Skeleton />}
      {!visibleSkeleton && (
        <Section isLargeBottom>
          <SectionTitle title="담당 고객 현황" />
          <Descriptions bordered className={cx('descriptions')}>
            <Descriptions.Item label={statusLabels.current}>
              {data.currentCustomersCount}
            </Descriptions.Item>
            <Descriptions.Item label={statusLabels.new}>
              {data.newlyAssignmentCount}
            </Descriptions.Item>
            <Descriptions.Item label={statusLabels.channelTalk}>
              {data.yesterdayCounselledCount}
            </Descriptions.Item>
            <Descriptions.Item label={statusLabels.allTasks}>
              {data.yesterdayTaskDoneCustomersCount}
            </Descriptions.Item>
            <Descriptions.Item label={statusLabels.doneItems}>
              {data.yesterdayTaskDoneCount}
            </Descriptions.Item>
            <Descriptions.Item label={statusLabels.noVisit}>
              {data.gte7DaysNoUsedCustomersCount}
            </Descriptions.Item>
          </Descriptions>
        </Section>
      )}
    </>
  )
}

export default MyCustomerStatusView
