import React from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import classNames from 'classnames/bind'

import { memoStatuses } from '@/apps/memo/constants'
import FormModal from '@/components/modal/FormModal'
import {
  Memo,
  CreatingFormRules,
  ModifyingFormRules,
} from '@/apps/memo/@types/memo'
import { MemoKind } from '@/apps/memo/@types/memo-kind'

import styles from './EditMemoForm.View.module.scss'

const cx = classNames.bind(styles)

export interface PropType {
  isVisible?: boolean
  isLoading?: boolean
  memo?: Memo | null
  memoKinds: MemoKind[]
  defaultMemoKind: MemoKind
  onFinish?: () => void
  onCancel?: () => void
  rules?: CreatingFormRules | ModifyingFormRules
  formRef?: FormInstance // only for testing
}

const { TextArea } = Input

export const EditMemoFormView: React.FC<PropType> = ({
  isVisible,
  isLoading,
  memo,
  memoKinds,
  defaultMemoKind,
  rules,
  onFinish,
  onCancel,
  formRef,
}) => {
  const isModifying = !!memo
  const title = isModifying ? '메모 수정' : '메모 등록'
  const subTitle = isModifying ? '메모 수정' : '신규 메모 등록'

  const memoKind = memoKinds.find((o) => o.id === memo?.kind) ?? defaultMemoKind
  const formInitialValue = memo ? { ...memo, kind: memoKind.id } : undefined

  return (
    <FormModal
      width="700px"
      visible={isVisible}
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      okText="저장"
      onOk={onFinish}
      onCancel={onCancel}
    >
      <div className={cx('container')}>
        <Form
          form={formRef}
          initialValues={formInitialValue}
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item label="메모 유형">
            <Form.Item name="kind" rules={rules?.kind}>
              <Select
                placeholder="선택"
                className={cx('input-kind')}
                data-testid="formMemo/kind"
              >
                {memoKinds.map((o) => (
                  <Select.Option
                    disabled={o.status !== memoStatuses.ACTIVE}
                    key={o.id}
                    value={o.id}
                  >
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item label="메모 내용" name="content" rules={rules?.content}>
            <TextArea
              className={cx('input-description')}
              placeholder="내용을 입력하세요 (한글, 영문, 숫자, 특수기호 입력 가능)"
              rows={2}
              autoSize={{ minRows: 5 }}
              data-testid="formMemo/content"
            />
          </Form.Item>
        </Form>
      </div>
    </FormModal>
  )
}

export default EditMemoFormView
