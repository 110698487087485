import { post, del } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.curriculum'
import {
  buildManipulationHook,
  CreationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import {
  CurriculumCare,
  CurriculumCareCreatePayload,
} from '@/apps/curriculum/@types/curriculum.care'
import { useState } from 'react'
import useSWR from 'swr'

export const useCurriculumCareCreation: () => CreationHookReturnType<
  CurriculumCareCreatePayload,
  CurriculumCare
> = () => {
  return buildManipulationHook<CurriculumCareCreatePayload, CurriculumCare>({
    url: URLS.curriculumCareList,
    request: post,
  })
}

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCurriculumCareDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating, mutate } = useSWR(
    [key ? URLS.curriculumCareDetail(key as number) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )

  const _setKey = (newKey: Key) => {
    if (newKey === key) {
      mutate()
      return
    }
    setKey(newKey)
  }

  const isLoading = !!key && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey: _setKey,
  }
}
