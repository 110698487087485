import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { Nil } from '@/@types/composite'
import { customerCurriculumProgress } from '@/constants/apps.customer'
import { careActionKinds } from '@/constants/apps.care'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import useCurriculumDetail from '@/apps/curriculum/hooks/useCurriculumDetail'
import { Customer } from '@/apps/customer/@types/customer'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import useCustomerCareList from '@/apps/customer/hooks/curriculum/useCustomerCareList'
import useCustomerCurriculumList from '@/apps/customer/hooks/curriculum/useCustomerCurriculumList'
import { caculateClosedDateOnCurriculum } from '@/apps/customer/utils/helper'
import { Care } from '@/apps/care/@types/care'
import CustomerActivityView from './CustomerActivity.View'

interface PropType {
  customer: Customer<Order, User>
  user: User | Nil
}

const CustomerActivity: React.FC<PropType> = ({ customer, user }) => {
  const [isMyCustomer, setIsMyCustomer] = useState<boolean>(false)
  const [data, setData] = useState<CustomerCurriculum | null>(null)
  const [fetchedCustomerCurriculum, setFetchedCustomerCurriculum] =
    useState<CustomerCurriculum | null>(null)
  const [essentialCustomerCareList, setEssentialCustomerCareList] = useState<
    CustomerCare<Care, CustomerCareItem>[]
  >([])
  const [optionalCustomerCareList, setOptionalCustomerCareList] = useState<
    CustomerCare<Care, CustomerCareItem>[]
  >([])
  const [caculatedClosedAt, setCaculatedClosedAt] = useState<Date | null>(null)

  const {
    data: customerCurriculumListData,
    isLoading: isCustomerCurriculumListDataLoading,
    setParams: setCustomerCurriculumListParam,
  } = useCustomerCurriculumList({
    customerUid: customer.uid,
    progress: [
      customerCurriculumProgress.ON_PROGRESS,
      customerCurriculumProgress.RESERVED,
    ],
  })

  const {
    data: curriculumDetailData,
    isLoading: isCurriculumDetailLoading,
    setKey: setCurriculumDetailKey,
  } = useCurriculumDetail(null)

  const fetchingCareList = useCustomerCareList()

  useEffect(() => {
    setIsMyCustomer(customer?.staff?.id === user?.id)
  }, [customer, user])

  useEffect(() => {
    if (!customerCurriculumListData || isCustomerCurriculumListDataLoading)
      return

    const customerCurriculums = customerCurriculumListData?.results ?? []
    const filteredCustomerCurriculums = _.filter(
      customerCurriculums,
      (customerCurriculum) => {
        if (!customerCurriculum.period || !customerCurriculum.openedAt)
          return true
        const closedAt = caculateClosedDateOnCurriculum(
          new Date(customerCurriculum.openedAt),
          customerCurriculum.period,
        )
        return differenceInCalendarDays(closedAt, new Date()) > 0
      },
    )

    if (_.isEmpty(filteredCustomerCurriculums)) {
      setData(null)
      setFetchedCustomerCurriculum(null)
      setCurriculumDetailKey(null)
      setCaculatedClosedAt(null)
      fetchingCareList.setParams({})
      return
    }

    const validCustomerCurriculum = filteredCustomerCurriculums[0]
    setFetchedCustomerCurriculum(validCustomerCurriculum)
    setCurriculumDetailKey(validCustomerCurriculum.curriculum)
    setCaculatedClosedAt(
      validCustomerCurriculum.period
        ? caculateClosedDateOnCurriculum(
            new Date(validCustomerCurriculum.openedAt),
            validCustomerCurriculum.period,
          )
        : null,
    )
    fetchingCareList.setParams({
      customerUid: validCustomerCurriculum.customer,
      customerCurriculum: validCustomerCurriculum.id,
    })
  }, [customerCurriculumListData, isCustomerCurriculumListDataLoading])

  useEffect(() => {
    if (!curriculumDetailData || isCurriculumDetailLoading) return
    if (!fetchedCustomerCurriculum) {
      setData(null)
      return
    }
    setData(
      buildCustomerCurriculumData(
        fetchedCustomerCurriculum,
        curriculumDetailData,
      ),
    )
  }, [
    fetchedCustomerCurriculum,
    curriculumDetailData,
    isCurriculumDetailLoading,
  ])

  useEffect(() => {
    if (!fetchingCareList.data || fetchingCareList.isLoading) return
    setEssentialCustomerCareList(
      buildCustomerCareList(fetchingCareList.data.results, true),
    )
    setOptionalCustomerCareList(
      buildCustomerCareList(fetchingCareList.data.results, false),
    )
  }, [fetchingCareList.data, fetchingCareList.isLoading])

  const onFinishManipulationCurriculum = async () => {
    setCustomerCurriculumListParam({
      customerUid: customer.uid,
      progress: [
        customerCurriculumProgress.ON_PROGRESS,
        customerCurriculumProgress.RESERVED,
      ],
    })
  }
  const onFinishManipulationCare = async () => {
    setCustomerCurriculumListParam({
      customerUid: customer.uid,
      progress: [
        customerCurriculumProgress.ON_PROGRESS,
        customerCurriculumProgress.RESERVED,
      ],
    })
  }
  const onFinishToggleSunCare = async () => {
    await fetchingCareList.mutate()
  }

  return (
    <CustomerActivityView
      customerCurriculum={data}
      caculatedCustomerCurriculumClosedAt={caculatedClosedAt}
      customer={customer}
      user={user}
      isMyCustomer={isMyCustomer}
      onFinishManipulationCurriculum={onFinishManipulationCurriculum}
      essentialCustomerCareList={essentialCustomerCareList}
      optionalCustomerCareList={optionalCustomerCareList}
      onFinishManipulationCare={onFinishManipulationCare}
      onFinishToggleSunCare={onFinishToggleSunCare}
    />
  )
}

export default CustomerActivity

const buildCustomerCurriculumData = (
  customerCurriculum: CustomerCurriculum | Nil,
  curriculumDetail: Curriculum | Nil,
) => {
  if (!customerCurriculum || !curriculumDetail) return null
  return {
    ...customerCurriculum,
    name: curriculumDetail.name,
    compilationKind: curriculumDetail.compilationKind,
    visibility: curriculumDetail.visibility,
  }
}

const buildCustomerCareList = (
  customerCareList: CustomerCare<Care, CustomerCareItem>[] | Nil,
  isEssential: boolean,
) => {
  if (!customerCareList) return []
  return _.chain(customerCareList)
    .filter((customerCare) => customerCare.care.isEssential === isEssential)
    .sort(
      (
        care1: CustomerCare<Care, CustomerCareItem>,
        care2: CustomerCare<Care, CustomerCareItem>,
      ) => {
        if (care1.care.actionKind === careActionKinds.CHECKING_UV) return -1
        if (care2.care.actionKind === careActionKinds.CHECKING_UV) return 1
        return care1.care.name < care2.care.name ? -1 : 1
      },
    )
    .value()
}
