import { useState } from 'react'
import useSWR from 'swr'

import { del } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.memo'

const fetch = (url: string) => {
  return del(url).then(() => true)
}

const useMemoDeletion = () => {
  const [key, setKey] = useState<number | null>(null)
  const { data, error, isValidating } = useSWR(
    key ? URLS.getMemoDetail(key) : null,
    fetch,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  )
  const isLoading = !data && !error && isValidating
  return {
    data,
    error,
    isLoading,
    isValidating,
    setMemoKey: setKey,
  }
}

export default useMemoDeletion
