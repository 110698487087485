import React from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames/bind'

import BasicButton from '@/components/buttons/BasicButton'

import style from './SyncButton.module.scss'

const cx = classNames.bind(style)

interface PropType {
  tooltipLabel: string | null
  onClick: () => void
  loading: boolean
  isSynchronizable: boolean
}

const SyncButtonView: React.FC<PropType> = ({
  onClick,
  loading,
  tooltipLabel,
  isSynchronizable,
}) => {
  return (
    <Tooltip title={tooltipLabel} trigger="hover">
      <BasicButton
        className={isSynchronizable ? cx('sync-button') : cx('fake-disable')}
        label="동기화"
        type="outline"
        icon="sync"
        onClick={onClick}
        loading={loading}
      />
    </Tooltip>
  )
}

export default SyncButtonView
