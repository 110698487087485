import React from 'react'
import _ from 'lodash'
import { Table, Pagination, TableProps, Tag } from 'antd'

import { DataQueryPropType } from '@/@types/data-query'
import { numbering } from '@/utils/ui/pagination'
import { itemToItems } from '@/utils/collections'
import { dateFormat } from '@/utils/date'
import {
  customerKindOrderingLabel,
  customerKindStatuses,
  customerKindStatusTagColor,
  customerKindStatusTagLabel,
} from '@/constants/apps.customer'
import {
  CustomerKind,
  CustomerKindListParam,
  CustomerKindListOrderingKey,
  CustomerKindStatus,
} from '@/apps/customer/@types/customer-kind'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import QuerySection from '@/components/table/QuerySection'
import BasicButton from '@/components/buttons/BasicButton'
import ActionMenuButton from '@/components/buttons/ActionMenuButton'

export interface PropType
  extends DataQueryPropType<
    CustomerKindListParam,
    CustomerKind,
    CustomerKindListOrderingKey
  > {
  onClickAddCustomerKind?: () => void
  onChangeTable?: TableProps<CustomerKind>['onChange']
  onClickEdit: (item: CustomerKind) => void
  onClickStatus: (item: CustomerKind, newStatus: CustomerKindStatus) => void
}

const CustomerKindListView: React.FC<PropType> = ({
  data,
  page,
  limit,
  count,
  isLoading,
  params,
  onChangeParam,
  orderingKey,
  orderingIsDesc,
  onClickAddCustomerKind,
  onChangeTable,
  onClickEdit,
  onClickStatus,
  onChangePage,
}) => {
  return (
    <Section>
      <SectionTitle title="고객 유형 목록" />
      <div className="tw-pb-4">
        <QuerySection<CustomerKindListParam, CustomerKindListOrderingKey>
          wrapperName="customerKindList"
          params={params}
          onChange={onChangeParam}
          defaultOrderingKey={orderingKey}
          orderOptions={orderOptions}
          searchPlaceholder="고객 유형 검색"
          searchParamKey="query"
          defaultOrderingIsDesc={orderingIsDesc}
        >
          <BasicButton
            className="tw-ml-auto"
            label="신규 유형 추가"
            icon="plus"
            data-testid="customerKindList/btn-add"
            onClick={onClickAddCustomerKind}
          />
        </QuerySection>
      </div>

      <Table<CustomerKind>
        dataSource={data}
        rowKey="id"
        pagination={false}
        onChange={onChangeTable}
      >
        <Column
          title="번호"
          key="order"
          render={(_value, _record, index) => numbering(page, limit, index)}
        />

        <Column title="고객 유형" dataIndex="name" />

        <Column
          title="설명"
          dataIndex="description"
          render={renderDescription}
        />

        <Column title="생성 일자" dataIndex="createdAt" render={renderDate} />

        <Column title="갱신 일자" dataIndex="updatedAt" render={renderDate} />

        <Column<CustomerKind>
          title="상태"
          dataIndex="status"
          filterMultiple={false}
          filters={statusColumnFilters}
          filteredValue={getFilteredStatus(params.status)}
          render={(__, record) => renderStatusTag(record, onClickStatus)}
        />

        <Column<CustomerKind>
          title="수정"
          render={(__, record) =>
            renderButtonEdit(record, () => onClickEdit(record))
          }
        />
      </Table>

      {!!count && (
        <Pagination
          disabled={isLoading}
          defaultCurrent={page ?? 1}
          pageSize={limit}
          defaultPageSize={limit}
          total={count}
          onChange={onChangePage}
        />
      )}
    </Section>
  )
}

const { Column } = Table

const statuses = [customerKindStatuses.ACTIVE, customerKindStatuses.DISABLED]

const getFilteredStatus = (
  status: CustomerKindStatus | CustomerKindStatus[] | undefined,
) => itemToItems<CustomerKindStatus>(status)

export const orderOptions = _.chain(customerKindOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

const statusColumnFilters = _.chain(customerKindStatusTagLabel)
  .toPairs()
  .map(([value, text]) => ({ text, value }))
  .value()

const renderDate = (value?: string) => {
  if (_.isNil(value)) return '0000-00-00'
  return dateFormat(value, 'yyyy-MM-dd')
}

const renderDescription = (value?: string) => value || '-'

const renderButtonEdit = (record: CustomerKind, onClick: () => void) => {
  return (
    <BasicButton
      disabled={record.status === customerKindStatuses.DISABLED}
      type="text"
      label="수정"
      onClick={onClick}
      data-testid={`customerKindList/btn-edit-${record.id}`}
    />
  )
}

const renderStatusTag = (
  record: CustomerKind,
  onClick: (item: CustomerKind, newStatus: CustomerKindStatus) => void,
) => {
  const status = record.status

  if (!status || status === customerKindStatuses.INACTIVE) return

  const color = customerKindStatusTagColor[status]
  const label = customerKindStatusTagLabel[status]
  return (
    <ActionMenuButton<CustomerKind, Exclude<CustomerKindStatus, 'inactive'>>
      disabled={false}
      item={record}
      labels={customerKindStatusTagLabel}
      value={status}
      values={statuses}
      onChangeValue={onClick}
    >
      <Tag color={color}>{label}</Tag>
    </ActionMenuButton>
  )
}
export default CustomerKindListView
