import React, { useEffect } from 'react'
import { Form, RadioChangeEvent } from 'antd'
import _ from 'lodash'

import { orderingParamKey as defaultOrderingParamKey } from '@/constants/common'
import { OrderingKey } from '@/utils/http/types'
import { buildOrderingQueryString, parseOrderingKey } from '@/utils/http'
import { BaseRequestParam } from '@/clients/http/types'
import { ChangeHistoryAction, ItemValue } from '@/@types/router'

import QuerySectionView, { OrderOption, SortOption } from './QuerySection.View'

interface PropType<PT, OT extends string> {
  params: PT
  onChange?: (
    key: keyof PT,
    value: ItemValue<PT>,
    historyAction?: ChangeHistoryAction<PT>,
  ) => void
  defaultOrderingKey?: OT
  orderOptions?: OrderOption[]
  isLoading?: boolean
  searchPlaceholder?: string
  defaultOrderingIsDesc: boolean
  showSearch?: boolean
  orderingParamKey?: string
  searchParamKey?: string
  wrapperName?: string
  sortOptions?: SortOption[]
}

const QuerySection = <PT extends BaseRequestParam, OT extends string>(
  props: React.PropsWithChildren<PropType<PT, OT>>,
) => {
  const {
    params,
    onChange,
    isLoading,
    defaultOrderingKey,
    orderOptions,
    searchPlaceholder,
    showSearch = true,
    orderingParamKey = defaultOrderingParamKey,
    searchParamKey = 'query',
    wrapperName,
    sortOptions,
  } = props

  const [formRef] = Form.useForm()
  const ordering = _.get(params, orderingParamKey, defaultOrderingKey) as
    | OT
    | undefined
  const isDesc = ordering?.startsWith('-')
  const searchTerm = params[searchParamKey] as undefined | string
  const orderingKey =
    !_.isNil(ordering) && !_.isNil(isDesc)
      ? (parseOrderingKey(ordering) as OT)
      : undefined

  const onChangeOrder = (key: OT) => {
    if (_.isNil(ordering) || _.isNil(isDesc) || !onChange) return
    onChange(
      orderingParamKey,
      buildOrderingQueryString(key, isDesc) as OrderingKey<OT>,
    )
  }

  const onChangeSort = (e: RadioChangeEvent) => {
    if (_.isNil(ordering) || _.isNil(isDesc) || !onChange) return
    onChange(
      orderingParamKey,
      buildOrderingQueryString(ordering, e.target.value) as OrderingKey<OT>,
    )
  }

  const onChangeTerm = (query: string) => {
    const value = _.trim(query)
    if (!onChange || params[searchParamKey] === value) return
    onChange(searchParamKey, value, { type: 'setPage1' })
  }

  useEffect(() => {
    formRef?.setFieldsValue({
      ordering: orderingKey,
      isDesc,
      search: searchTerm,
    })
  }, [])
  useEffect(() => {
    formRef?.setFieldsValue({
      search: searchTerm,
    })
  }, [searchTerm])

  return (
    <QuerySectionView<OT>
      formRef={formRef}
      isLoading={isLoading}
      orderOptions={orderOptions}
      sortOptions={sortOptions}
      searchPlaceholder={searchPlaceholder}
      defaultOrderingKey={orderingKey}
      defaultOrderingIsDesc={isDesc}
      showSearch={showSearch}
      wrapperName={wrapperName}
      defaultSearchTerm={searchTerm}
      onChangeOrder={onChangeOrder}
      onChangeSort={onChangeSort}
      onChangeTerm={onChangeTerm}
    >
      {props.children}
    </QuerySectionView>
  )
}

export default QuerySection
