import _ from 'lodash'
import { FormInstance } from 'antd'

import { FieldError } from '@/@types/antd'
import { FieldData } from 'rc-field-form/es/interface'
import { Nil } from '@/@types/composite'
import { InvalidErrorResponse } from '@/clients/http/types'

export const hasAnyFieldsError = (data: FieldError[]) => {
  return data.findIndex((o) => o.errors.length > 0) !== -1
}

export const filterFieldsHasErrors = (errors: FieldError[]) =>
  _.chain(errors)
    .filter((field) => !_.isEmpty(field.errors))
    .value()

export const getFieldNamesFromFieldsError = (errors: FieldError[]) =>
  _.chain(errors)
    .map((field) => field.name)
    .flatten()
    .value()

export const setErrorFieldsFromServer = <FT = Record<string, unknown>>(
  formRef: FormInstance | Nil,
  errors: InvalidErrorResponse<FT> | Nil,
) => {
  if (!formRef || !errors) return
  const fields: FieldData[] = _.chain(errors)
    .toPairs()
    .thru(([name, errors]) => [name, _.isArray(errors) ? errors : [errors]])
    .map(([name, errors]) => ({ name, errors } as FieldData))
    .value()
  formRef.setFields(fields)
}

export const setErrorFieldsFromServerWithMessages = <
  FT = Record<string, unknown>,
>(
  formRef: FormInstance | Nil,
  errors: InvalidErrorResponse<FT> | Nil,
  messages: Record<string, unknown>,
) => {
  if (!formRef || !errors) return
  const fields: FieldData[] = _.chain(errors)
    .toPairs()
    .thru(([name, errors]) => [name, _.isArray(errors) ? errors : [errors]])
    .map(([name, errors]) => {
      return { name, errors: [messages[errors as string]] } as FieldData
    })
    .value()
  formRef.setFields(fields)
}
