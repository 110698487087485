import { useState } from 'react'
import useSWR from 'swr'

import { patch } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.auth'
import { User, UserActivationPayload } from '@/apps/auth/@types/user'

const fetcher = (url: string | null, payload: UserActivationPayload | null) => {
  if (!url || !payload) return
  return patch<UserActivationPayload, User>(url, payload)
}

export const useUserActivation = () => {
  const [payload, setPayload] = useState<UserActivationPayload | null>(null)

  const { data, error } = useSWR(
    [payload ? URLS.getUserActivation(payload.username) : null, payload],
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  )

  const isLoading = !!payload && !data && !error

  return {
    data,
    error,
    setPayload,
    isLoading,
  }
}
