import React from 'react'
import { Nil } from '@/@types/composite'
import { Care } from '@/apps/care/@types/care'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import {
  CustomerCare,
  CustomerCareItem,
} from '@/apps/customer/@types/customer.care'
import { CustomerCurriculum } from '@/apps/customer/@types/customer.curriculum'
import EssentialCareListView from './EssentialCareList.View'

interface PropType {
  isMyCustomer?: boolean
  curriculum?: Curriculum | Nil
  customerCurriculum?: CustomerCurriculum | Nil
  caculatedCustomerCurriculumClosedAt?: Date | Nil
  essentialCares: CurriculumCare[] | CustomerCare<Care, CustomerCareItem>[]
  onFinishManipulation?: () => void
}

const EssentialCareList: React.FC<PropType> = ({
  isMyCustomer,
  curriculum,
  customerCurriculum,
  caculatedCustomerCurriculumClosedAt,
  essentialCares,
  onFinishManipulation,
}) => {
  const isCustomerCurriculum = !!customerCurriculum
  return (
    <EssentialCareListView
      isMyCustomer={isMyCustomer}
      isCustomerCurriculum={isCustomerCurriculum}
      curriculum={curriculum}
      customerCurriculum={customerCurriculum}
      customerCurriculumOpenedAt={
        customerCurriculum?.openedAt
          ? new Date(customerCurriculum?.openedAt)
          : null
      }
      caculatedCustomerCurriculumClosedAt={caculatedCustomerCurriculumClosedAt}
      essentialCares={essentialCares}
      onFinishAddCareItem={onFinishManipulation}
      onFinishDeleteCare={onFinishManipulation}
      onFinishDeleteCareItem={onFinishManipulation}
    />
  )
}

export default EssentialCareList
