import React from 'react'
import classNames from 'classnames/bind'
import { Curriculum } from '@/apps/curriculum/@types/curriculum'
import { CurriculumCare } from '@/apps/curriculum/@types/curriculum.care'
import EssentialCareList from '@/apps/curriculum/components/essential-care-list/EssentialCareList'
import OptionalCareList from '@/apps/curriculum/components/optional-care-list/OptionalCareList'
import styles from './CurriculumDetailView.module.scss'

interface PropType {
  curriculum: Curriculum
  essentialCares: CurriculumCare[]
  optionalCares: CurriculumCare[]
  onFinishManipulation?: () => void
}

const CurriculumDetailView: React.FC<PropType> = ({
  curriculum,
  essentialCares,
  optionalCares,
  onFinishManipulation,
}) => {
  return (
    <>
      <div className={cx('container')}>
        <EssentialCareList
          curriculum={curriculum}
          essentialCares={essentialCares}
          onFinishManipulation={onFinishManipulation}
        />
        <OptionalCareList
          curriculum={curriculum}
          optionalCares={optionalCares}
          onFinishManipulation={onFinishManipulation}
        />
      </div>
    </>
  )
}

export default CurriculumDetailView

const cx = classNames.bind(styles)
