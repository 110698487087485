import { CurriculumCareItemErrorMessage } from '../@types/curriculum.care'

export const addCurriculumCareItem = {
  required: {
    careItem: '추가하고 싶은 케어 아이템을 선택해주세요',
    isRepetition: '반복 설정을 입력하세요',
    openInDays: '시작 일자를 입력하세요',
    openedAt: '시작 일자를 입력하세요',
    totalRepetitions: '총 수행 횟수를 입력하세요',
    repetition: '반복 설정을 입력하세요',
  },
}

export const addCurriculumCareItemFormServer = {
  CARE_INVALID_MIN_THAN_ONE: '이 값이 1보다 크거나 같은지 확인하십시오.',
  CARE_INVALID_MIN_THAN_ZERO: '이 값이 0보다 크거나 같은지 확인하십시오.',
  CARE_INVALID_NUMBER: '유효한 정수(integer)를 넣어주세요.',
  CARE_DUPLICATE_OPEN_DATE: '동일 케어아이템이 같은 날에 겹칩니다.',
  CARE_DUPLICATE_WEEK_DAY: '동일 케어아이템이 같은 날에 겹칩니다.',
  CARE_INVALID_TOTAL_REPETITIONS:
    '총 수행횟수(total_repetitions)는 선택한 요일 수 이상이어야 합니다.',
} as const

export const careItemFormErrorMessage: CurriculumCareItemErrorMessage = {
  [addCurriculumCareItemFormServer.CARE_INVALID_MIN_THAN_ONE]:
    '1이상 정수를 입력하세요',
  [addCurriculumCareItemFormServer.CARE_INVALID_MIN_THAN_ZERO]:
    '1이상 정수를 입력하세요',
  [addCurriculumCareItemFormServer.CARE_INVALID_NUMBER]:
    '1이상 정수를 입력하세요',
  [addCurriculumCareItemFormServer.CARE_DUPLICATE_OPEN_DATE]:
    '동일 케어아이템이 같은 날에 겹칩니다.',
  [addCurriculumCareItemFormServer.CARE_DUPLICATE_WEEK_DAY]:
    '동일 케어아이템이 같은 날에 겹칩니다.',
  [addCurriculumCareItemFormServer.CARE_INVALID_TOTAL_REPETITIONS]:
    '선택한 요일 수 또는 그보다 큰 수를 입력하세요',
} as const
