import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Form, FormInstance } from 'antd'

import envs from '@/config/variables'
import { dateFormat } from '@/utils/date'
import { hasAnyFieldsError } from '@/utils/antd/form'
import { statuses } from '@/constants/common'
import { notifySuccess, notifyFailure } from '@/components/antd/Notification'
import { DateISO8601 } from '@/@types/formats'
import { Customer } from '@/apps/customer/@types/customer'
import { User } from '@/apps/auth/@types/user'
import { Order } from '@/apps/order/@types/order'
import {
  Task,
  TaskKind,
  TaskCreatePayload,
  TaskModifyPayload,
  ModifyingFormRules,
  CreatingFormRules,
} from '@/apps/task/@types/task'
import {
  useTaskCreation,
  useTaskModification,
} from '@/apps/task/hooks/useTaskManipulation'
import { form as formError } from '@/apps/task/messages/error'
import * as validators from '@/apps/task/validators'

import EditTaskViewForm from './EditTaskForm.View'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  customer: Customer<Order, User>
  isVisible?: boolean
  task?: Task | null
  taskKinds: TaskKind[]
  onCancel?: () => void
  onFinish?: (isModifying: boolean) => void
  formRefForTesting?: FormInstance // only for testing
}

const EditTaskForm: React.FC<PropType> = ({
  customer,
  isVisible,
  task,
  taskKinds,
  onCancel,
  onFinish,
  formRefForTesting,
}) => {
  const [form] = Form.useForm()
  const currentFormRef =
    formRefForTesting && envs.isTesting ? formRefForTesting : form

  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState<TaskModifyPayload>({})
  const creating = useTaskCreation()
  const modifying = useTaskModification()

  const isModifying = !!task

  const _onFinish = async () => {
    await currentFormRef.validateFields()
    if (hasAnyFieldsError(currentFormRef.getFieldsError())) return

    if (isModifying) {
      modifying.setPayload({
        ...(payload as TaskModifyPayload),
        customer: customer.uid,
        progress: task?.progress === 'scheduled' ? undefined : task?.progress,
      })
      modifying.setTaskKey(task.id)
    } else {
      creating.setPayload({
        ...(payload as TaskCreatePayload),
        customer: customer.uid,
      })
    }
  }

  const _onCancel = () => {
    !!onCancel && onCancel()
  }

  const onChangeKind = (taskKindId: number) => {
    setPayload({
      ...payload,
      kind: taskKindId,
    })
  }

  const onChangeContent = (value: string) => {
    setPayload({
      ...payload,
      content: value,
    })
  }

  const onChangeActiveAt = (value: Date | null) => {
    if (!value) return
    setPayload({
      ...payload,
      activeAt: dateFormat<DateISO8601>(value, 'yyyy-MM-dd'),
    })
  }

  useEffect(() => {
    if (!isVisible) return
    if (!task) {
      !task && currentFormRef.resetFields()
      setPayload({})
    } else {
      setPayload(task)
    }
  }, [isVisible, task])

  useEffect(() => {
    if (_.isEmpty(payload)) return

    setIsLoading(isModifying ? modifying.isLoading : creating.isLoading)
    if (creating.isLoading || modifying.isLoading) return
    if ((!isModifying && !creating.data) || (isModifying && !modifying.data)) {
      return
    }
    _notifySuccess(isModifying)
    setPayload({})
    !!onFinish && onFinish(isModifying)
  }, [
    isModifying,
    creating.data,
    creating.isLoading,
    modifying.data,
    modifying.isLoading,
  ])

  useEffect(() => {
    if (creating.isLoading || modifying.isLoading) return
    if (
      (!isModifying && !creating.error) ||
      (isModifying && !modifying.error)
    ) {
      return
    }

    const taskError = isModifying ? modifying.error : creating.error
    if (
      taskError?.status === 400 ||
      taskError?.status === 403 ||
      taskError?.status === 404
    ) {
      notifyFailure({
        message: '요청 실패',
        description: '유효하지 않은 요청입니다. 페이지를 새로 고침하세요.',
      })
    } else if (serverErrorStatus.includes(taskError?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생하였습니다. 다시 시도해주세요.',
      })
    } else {
      _notifyFailure(isModifying)
    }
  }, [
    isModifying,
    creating.error,
    creating.isLoading,
    modifying.error,
    modifying.isLoading,
  ])

  return (
    <EditTaskViewForm
      formRef={currentFormRef}
      isVisible={isVisible}
      isLoading={isLoading}
      task={task}
      taskKinds={taskKinds}
      rules={getRules(isModifying)}
      onChangeKind={onChangeKind}
      onChangeContent={onChangeContent}
      onChangeActiveAt={onChangeActiveAt}
      onFinish={_onFinish}
      onCancel={_onCancel}
    />
  )
}

const _notifySuccess = (isModifying: boolean) => {
  const description = isModifying
    ? '성공적으로 업무를 수정했습니다.'
    : '성공적으로 업무를 추가했습니다.'
  notifySuccess({
    message: isModifying ? '수정 완료' : '저장 완료',
    description,
  })
}

const _notifyFailure = (isModifying: boolean) => {
  const description = isModifying
    ? '업무 내용 수정에 실패했습니다.'
    : '업무 추가에 실패했습니다.'
  notifyFailure({
    message: '저장 실패',
    description: `${description} 다시 시도해주세요.`,
  })
}

const statusSet = new Set(Object.values(statuses))

export const modifyingRules: ModifyingFormRules = {
  kind: [
    {
      type: 'number',
      min: 1,
      message: formError.invalidFormat,
    },
  ],
  content: [
    {
      type: 'string',
      max: 27,
      message: formError.invalidTextLength,
    },
  ],
  activeAt: [
    {
      type: 'date',
      message: formError.invalidFormat,
    },
    () => ({
      validator(__, value) {
        return validators.isAfterFromToday(value)
          ? Promise.resolve()
          : Promise.reject(new Error(formError.afterFromToday))
      },
    }),
  ],
  status: [
    () => ({
      validator(__, value) {
        return _.isNil(value) || statusSet.has(value)
          ? Promise.resolve()
          : Promise.reject(new Error(formError.invalidStatusValue))
      },
    }),
  ],
}

export const creatingRules: CreatingFormRules = {
  kind: [
    {
      required: true,
      message: formError.requiredField,
    },
    ...modifyingRules.kind,
  ],
  content: [
    {
      required: true,
      message: formError.requiredField,
    },
    {
      type: 'string',
      min: 1,
      max: 27,
      message: formError.invalidTextLength,
    },
  ],
  activeAt: [
    {
      required: true,
      message: formError.requiredField,
    },
    ...modifyingRules.activeAt,
  ],
}

const getRules = (
  isModifying: boolean,
): ModifyingFormRules | CreatingFormRules =>
  isModifying ? modifyingRules : creatingRules

export default EditTaskForm
