import useSWR from 'swr'

import { Nil } from '@/@types/composite'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.customer'
import { Customer } from '@/apps/customer/@types/customer'
import { Order } from '@/apps/order/@types/order'
import { User } from '@/apps/auth/@types/user'

const fetcher = (url: string | null) => {
  if (!url) return
  return get<never, Customer<Order, User>>(url)
}

const useCustomerDetail = (uid: Nil | string) => {
  const { data, error, isValidating, mutate } = useSWR(
    uid ? URLS.getCustomerDetail(uid) : null,
    fetcher,
  )
  const isLoading = !data && !error
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  }
}

export default useCustomerDetail
