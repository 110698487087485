import React, { useCallback, useEffect, useState } from 'react'

import copyToClipboard from 'copy-to-clipboard'

import { Nil } from '@/@types/composite'
import {
  User,
  UserCreatePayload,
  UserModifyPayload,
} from '@/apps/auth/@types/user'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import { notifySuccess } from '@/components/antd/Notification'

type Payload = UserCreatePayload | UserModifyPayload

export interface ModalNotifyUserCompletionProp {
  data: User | Nil
  payload: Payload
  title: string
  content: string
  notiTitle: string
  notiContent: string
  onClose?: () => void
}

const ModalNotifyUserCompletion: React.FC<ModalNotifyUserCompletionProp> = ({
  data,
  payload,
  title,
  content,
  notiTitle,
  notiContent,
  onClose,
}) => {
  const [isSuccessCopy, setIsSuccessCopy] = useState<boolean | null>(null)
  const [loading, setLoading] = useState(false)

  const onCopyToClipBoard = useCallback(() => {
    if (!data) return
    setLoading(true)
    const clipboardText = [
      `계정 유형 : ${data.role}`,
      `아이디 : ${data.username}`,
      `사용자명 : ${data.firstName}`,
      `비밀번호 : ${payload.password}`,
    ].join('\n')

    setTimeout(() => {
      setLoading(false)
      const isSuccess = copyToClipboard(clipboardText)
      setIsSuccessCopy(isSuccess)
    }, 2000)
  }, [data, payload])

  useEffect(() => {
    notifySuccess({
      message: notiTitle,
      description: notiContent,
    })
  }, [])

  const onFinish = () => {
    !!onClose && onClose()
  }

  const getResponse = () => {
    return isSuccessCopy !== null ? isSuccessCopy : null
  }

  const getError = () => {
    return isSuccessCopy !== null ? !isSuccessCopy : null
  }

  return (
    <ConfirmFlowModal
      type="confirm"
      title={title}
      content={content}
      successNotiMessage="계정 정보 복사 완료"
      successNotiDesc="해당 계정 로그인 정보를 클립보드로 복사하였습니다."
      failNotiMessage="계정 정보 복사 실패"
      failNotiDesc="해당 계정 로그인 정보 복사를 실패하였습니다. 다시 시도해주세요."
      cancelText="닫기"
      okText="계정 정보 복사하기"
      visible={true}
      response={getResponse()}
      error={getError()}
      action={onCopyToClipBoard}
      isLoadingRequest={loading}
      onFinish={onFinish}
      onCancel={onFinish}
    />
  )
}

export default ModalNotifyUserCompletion
