import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from 'antd'
import { ArrowLeftOutlined as IconArrowLeftOutlined } from '@ant-design/icons'

export const PageTitle: React.FC<{
  pageName: string
  pageDescription: string | null
}> = ({ pageName, pageDescription }) => {
  const history = useHistory()

  return (
    <div className="tw-flex tw-items-center">
      <IconArrowLeftOutlined
        className="tw-mr-4"
        onClick={() => history.goBack()}
      />
      <Typography.Text className="tw-mr-3">{pageName}</Typography.Text>
      {!!pageDescription && (
        <Typography.Text className="tw-body-3 tw-text-gray-6">
          {pageDescription}
        </Typography.Text>
      )}
    </div>
  )
}
