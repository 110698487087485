import React from 'react'
import parseISO from 'date-fns/parseISO'
import _ from 'lodash'

import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import { Space, Layout, Divider, Table, Tag, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
  CalendarDataInterface,
  ProgressStatus,
} from '@/apps/customer/@types/customer-activity'
import { dateFormat } from '@/utils/date'
import ActivityCalendarView from './ActivityCalendar.View'
import style from './ActivityHistory.module.scss'

export type CareProgress = 'done' | 'undone' | 'proceeding'

export interface CareTableRow {
  key: string
  title: string
  progress: CareProgress | null
  point: string
}

interface PropType {
  progress: ProgressStatus
  handleSelectDate: (date: string) => void
  calendarData: CalendarDataInterface
  careTableData: CareTableRow[]
}

const ActivityHistoryView: React.FC<PropType> = ({
  progress: { dateSelected, percent },
  handleSelectDate,
  calendarData,
  careTableData,
}) => {
  const { Header, Content } = Layout
  let dateParsed = parseISO(dateSelected)
  if (isNaN(dateParsed.getTime())) {
    dateParsed = new Date()
  }

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      title: '케어 유형명',
    },
    {
      key: 'progress',
      dataIndex: 'progress',
      title: '진행 여부',
      render: (status: CareProgress) => renderTag(status),
    },
    {
      key: 'point',
      dataIndex: 'point',
      title: '포인트',
    },
  ]

  const renderTag = (status: CareProgress) => {
    const customColor = () => {
      switch (status) {
        case 'done':
          return 'blue'
        case 'proceeding':
          return 'yellow'
        case 'undone':
          return 'red'
        default:
          return ''
      }
    }
    const customerText = () => {
      switch (status) {
        case 'done':
          return '완료'
        case 'proceeding':
          return '미완료'
        case 'undone':
          return '안함'
        default:
          return '-'
      }
    }
    return customColor() ? (
      <Tag color={customColor()}>{customerText()}</Tag>
    ) : (
      <span>-</span>
    )
  }

  const parseDateSelected = () => {
    if (dateParsed) {
      return dateFormat(dateParsed, 'yyyy년 MM월 dd일')
    }
    return '0000년 00월 00일'
  }

  return (
    <Section className={style['activity-history']} isLargeBottom>
      <Spin spinning={false} tip="Loading" indicator={<LoadingOutlined spin />}>
        <SectionTitle title="일별 케어 활동 내역" />
        <Space direction="horizontal">
          <ActivityCalendarView
            handleSelectDate={handleSelectDate}
            calendarData={calendarData}
            dateSelected={dateParsed}
          />
          <Layout>
            <Header>{`${parseDateSelected()}`}</Header>
            <Header>{`진척도 ${percent ?? '-'}%`}</Header>
            <Divider />
            <Content>
              <Table
                columns={columns}
                bordered={false}
                rowKey="key"
                dataSource={
                  _.isEmpty(careTableData)
                    ? [
                        {
                          key: '0000-00-00',
                          title: '-',
                          point: '-',
                          progress: null,
                        },
                      ]
                    : careTableData
                }
                pagination={false}
              />
            </Content>
          </Layout>
        </Space>
        <div className={style.footer}>
          <span>
            {
              '포인트 계산식\n- 분자값 : 케어 유형별 고객이 수행 완료한 케어 아이템 수\n- 분모값 : 케어 유형별 고객이 수행해야 할 케어 아이템 수'
            }
          </span>
        </div>
      </Spin>
    </Section>
  )
}

export default ActivityHistoryView
