import { useState } from 'react'
import useSWR from 'swr'
import { isSameDay } from 'date-fns'

import { dateFormat } from '@/utils/date'
import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.dashboard'
import {
  StaffsCustomerStatusParam,
  StaffsCustomerStatus,
} from '@/apps/dashboard/@types/customer-status'

const fetcher = (url: string, date: Date) => {
  const today = new Date()
  const params: StaffsCustomerStatusParam = {
    date: dateFormat(isSameDay(today, date) ? date : today),
  }
  return get<StaffsCustomerStatusParam, StaffsCustomerStatus>(url, params)
}

const useStaffsCustomerStatus = () => {
  const [date, setDate] = useState<Date>(new Date())
  const { data, error, isValidating, mutate } = useSWR(
    URLS.staffsCustomerStatus,
    (url) => {
      const today = new Date()
      !isSameDay(today, date) && setDate(today)
      return fetcher(url, date)
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      shouldRetryOnError: false,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  }
}

export default useStaffsCustomerStatus
