import React from 'react'

import classNames from 'classnames/bind'

import style from './Section.module.scss'
import SectionTitle, { sizeType } from './SectionTitle'

const cx = classNames.bind(style)

interface PropType extends React.HTMLAttributes<HTMLElement> {
  isLargeBottom?: boolean
  title?: string
  subTitle?: string
  titleSize?: sizeType
}

const Section: React.FC<PropType> = ({
  children,
  className,
  isLargeBottom = false,
  title,
  subTitle,
  titleSize,
}) => {
  return (
    <div
      data-testid={`section-${title}`}
      className={cx('section', { 'tw-pb-11': isLargeBottom }, className)}
    >
      {!!title && (
        <SectionTitle title={title} subTitle={subTitle} size={titleSize} />
      )}
      {children}
    </div>
  )
}

export default Section
