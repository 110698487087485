import React, { useState } from 'react'
import _ from 'lodash'
import { Table, Tag, Typography, Pagination } from 'antd'

import { orderingParamKey } from '@/constants/common'
import { useUserContext } from '@/contexts/UserContext'
import { dateFormat } from '@/utils/date'
import { itemToItems } from '@/utils/collections'
import { buildTableChangeFilter } from '@/utils/antd/table'
import { numbering } from '@/utils/ui/pagination'
import { buildOrderingQueryString } from '@/utils/http'
import BasicButton from '@/components/buttons/BasicButton'
import QuerySection from '@/components/table/QuerySection'
import Section from '@/components/layout/section/Section'
import SectionTitle from '@/components/layout/section/SectionTitle'
import useLocationParams from '@/hooks/useLocationParams'
import {
  userRoles,
  userRoleTagLabel,
  userRoleTagColor,
  userStatus,
  userStatusTagLabel,
  userStatusTagColor,
  userStatusToggleLabel,
} from '@/apps/auth/constants'
import { PAGES } from '@/constants/apps.auth'
import { userOrderingLabel } from '@/constants/apps.user'
import {
  User,
  UserListParam,
  UserListOrderingKey,
} from '@/apps/auth/@types/user'
import useUserList from '@/apps/auth/hooks/useUserList'
import { isAdmin } from '@/apps/auth/utils/permission'
import DialogUserForm from '@/apps/auth/pages/users/DialogUserForm'
import UserActivationButton from '@/apps/auth/components/UserActivationButton'
import ModalNotifyUserCompletion, {
  ModalNotifyUserCompletionProp,
} from '@/apps/auth/pages/users/ModalNotifyUserCompletion'

const { Column } = Table

export const defaultOrderingKey: UserListOrderingKey = 'date_joined'

export const defaultOrderingIsDesc = true

export const defaultLimit = 10

const defaultListParams = {
  page: 1,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultOrderingIsDesc,
  ) as UserListOrderingKey,
}

const paramKeys: Array<keyof UserListParam> = [
  'isActive',
  'role',
  'query',
  'page',
  'limit',
  'ordering',
]

const UserList: React.FC = () => {
  const user = useUserContext().current
  const [isVisibleUserForm, setIsVisibleUserForm] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [completionData, setCompletionData] =
    useState<ModalNotifyUserCompletionProp | null>(null)

  const { data, params, setParams, isLoading, mutate, error } =
    useUserList(defaultListParams)

  const locParams = useLocationParams<UserListParam, UserListOrderingKey>(
    paramKeys,
    {
      defaultParams: defaultListParams,
      orderingParamKey,
      onSetParams: setParams,
      numberParams: ['page', 'limit'],
      commaListParams: ['isActive', 'role'],
      tryPreviousPage: true,
      paginatedData: data,
      httpError: error,
    },
  )

  const onChangeFilter = buildTableChangeFilter<User>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const showCreatingUserForm = () => {
    setSelectedUser(null)
    setIsVisibleUserForm(true)
  }

  const showModifyingUserForm = (user: User) => {
    setSelectedUser(user)
    setIsVisibleUserForm(true)
  }

  const closeUserForm = () => {
    setIsVisibleUserForm(false)
  }

  const showCompletionModal = (
    completionData: ModalNotifyUserCompletionProp,
  ) => {
    setCompletionData({
      ...completionData,
      onClose: () => {
        setCompletionData(null)
      },
    })
  }

  const onChangeUserStatusFinish = async () => {
    await mutate()
  }
  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onSuccessEditing = async (
    action: 'create' | 'modify',
    completionData: ModalNotifyUserCompletionProp,
  ) => {
    await mutate()

    showCompletionModal(completionData)
  }

  const orderingKey = locParams.fullParams.orderingKey as
    | UserListOrderingKey
    | undefined

  const filteredIsActive = itemToItems<boolean>(params.isActive)
  const filteredRole = itemToItems<boolean>(params.role)

  return (
    <>
      <Section data-testid={PAGES.users.testId}>
        <SectionTitle title="사용자 계정 목록" />
        <div className=" tw-pb-4">
          <QuerySection<UserListParam, UserListOrderingKey>
            params={locParams.params}
            onChange={locParams.setItem}
            isLoading={isLoading}
            defaultOrderingKey={orderingKey}
            orderOptions={orderOptions}
            searchPlaceholder="ID, 사용자명 검색"
            defaultOrderingIsDesc={
              locParams.fullParams.orderingIsDesc ?? defaultOrderingIsDesc
            }
            wrapperName="userlist"
          >
            <BasicButton
              className="tw-ml-auto"
              label="신규생성"
              onClick={showCreatingUserForm}
              icon="plus"
              disabled={!isAdmin(user)}
            />
          </QuerySection>
        </div>

        <Table<User>
          dataSource={data?.results ?? []}
          rowSelection={{ type: 'checkbox' }}
          pagination={false}
          onChange={onChangeFilter}
          rowKey="id"
        >
          <Column
            title="번호"
            key="order"
            render={(_value, _record, index) =>
              numbering(
                params?.page,
                params.limit ?? defaultListParams.limit,
                index,
              )
            }
          />
          <Column title="사용자명" dataIndex="firstName" />
          <Column title="아이디" dataIndex="username" />
          <Column<User>
            title="계정유형"
            dataIndex="role"
            render={(role: RoleType) => renderRoleTag(role)}
            filteredValue={filteredRole}
            filters={roleFilters}
          />
          <Column
            title="생성 일자"
            dataIndex="dateJoined"
            render={(value) => dateFormat(value)}
          />
          <Column<User>
            title="계정상태"
            dataIndex="isActive"
            render={(status: boolean) => renderStatusTag(status)}
            filterMultiple={false}
            filteredValue={filteredIsActive}
            filters={isActiveFilters}
          />
          <Column<User>
            title="수정"
            key="update"
            render={(__, record) => (
              <Typography.Link
                disabled={!isAdmin(user)}
                onClick={() => showModifyingUserForm(record)}
              >
                수정
              </Typography.Link>
            )}
          />
          <Column<User>
            title="계정 상태 변경"
            key="change-status"
            render={(__, record) =>
              renderStatusToggle({
                user: record,
                onFinish: onChangeUserStatusFinish,
                disabled: !isAdmin(user),
              })
            }
          />
        </Table>

        {!!data?.count && (
          <Pagination
            defaultCurrent={locParams.params.page ?? 1}
            defaultPageSize={locParams.params.limit ?? defaultListParams.limit}
            total={data?.count}
            onChange={onChangePage}
          />
        )}

        <DialogUserForm
          onSuccess={onSuccessEditing}
          onClose={closeUserForm}
          visible={isVisibleUserForm}
          user={selectedUser}
        />
      </Section>
      {!!completionData && <ModalNotifyUserCompletion {...completionData} />}
    </>
  )
}

type RoleType = 'staff' | 'admin'

export const renderRoleTag = (role: RoleType) => {
  const color = userRoleTagColor[role]
  const label = userRoleTagLabel[role]
  return <Tag color={color}>{label}</Tag>
}

export const renderStatusTag = (isActive: boolean) => {
  const status = isActive ? userStatus.ACTIVE : userStatus.INACTIVE
  const color = userStatusTagColor[status]
  const label = userStatusTagLabel[status]
  return <Tag color={color}>{label}</Tag>
}

interface RenderStatusToggle {
  user: User
  onFinish: (user: User) => void
  disabled?: boolean
}

export const renderStatusToggle = ({
  user,
  onFinish,
  disabled,
}: RenderStatusToggle) => {
  return (
    <UserActivationButton
      disabled={disabled}
      user={user}
      onFinish={onFinish}
      buttonNameToDeactivate={userStatusToggleLabel.active}
      buttonNameToActivate={userStatusToggleLabel.inactive}
    />
  )
}

export const orderOptions = _.chain(userOrderingLabel)
  .toPairs()
  .map(([value, label]) => ({ value, label }))
  .value()

export const roleFilters = [
  {
    text: userRoleTagLabel.admin,
    value: userRoles.ADMIN,
  },
  {
    text: userRoleTagLabel.staff,
    value: userRoles.STAFF,
  },
]

export const isActiveFilters = [
  {
    text: '활성',
    value: true,
  },
  {
    text: '비활성',
    value: false,
  },
]

export default UserList
