import { useState } from 'react'
import useSWR from 'swr'

import { post } from '@/clients/http/public-http-client'
import { URLS } from '@/constants/apps.auth'

export interface Payload {
  username: string
  password: string
}

export interface AuthenticationData {
  token: string
}

export const fetcher = async (url: string | null, payload: Payload | null) => {
  if (!url || !payload) return
  return post<Payload, AuthenticationData>(url, payload)
}

const useLogin = () => {
  const [payload, setPayload] = useState<Payload | null>(null)
  const url = payload ? URLS.login : null
  const { data, mutate, error } = useSWR([url, payload], fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  })
  const isLoading = !!url && !data && !error
  const isLoggedOut = !!url && error && error.status === 403

  return {
    isLoading,
    isLoggedOut,
    data,
    mutate,
    error,
    authenticate: setPayload,
  }
}

export default useLogin
