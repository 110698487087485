import React, { useCallback, useEffect } from 'react'

import useLogout from '@/apps/auth/hooks/useLogout'
import BasicButton from '@/components/buttons/BasicButton'
import { notifyFailure } from '@/components/antd/Notification'
import { serverErrorStatus } from '@/utils/http'

interface PropType {
  onFinish: () => void
}

const LogoutButton: React.FC<PropType> = ({ onFinish }) => {
  const { isLoading, unAuthenticate, error } = useLogout()

  useEffect(() => {
    if (serverErrorStatus.includes(error?.response?.status)) {
      notifyFailure({
        message: '처리 실패',
        description: '처리 과정에서 오류가 발생했습니다. 다시 시도해주세요.',
      })
    }
  }, [error])

  const logout = useCallback(() => {
    if (isLoading) return
    unAuthenticate()
    onFinish()
  }, [isLoading])

  return (
    <BasicButton
      label="로그아웃"
      type="outline"
      size="normal"
      icon="power"
      onClick={logout}
      loading={isLoading}
    />
  )
}

export default LogoutButton
