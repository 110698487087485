import React, { useState, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'

import styles from './TruncatedTextBox.module.scss'

const cx = classNames.bind(styles)

interface PropType extends React.HTMLAttributes<HTMLElement> {
  text: string
  moreButtonName?: string
  lessButtonName?: string
  isFold?: boolean
  onMore?: () => void
  onLess?: () => void
}

const wrapperClassName = 'truncated-text-box'

const TruncatedTextBox: React.FC<PropType> = ({
  text,
  moreButtonName = '더보기',
  lessButtonName = '간략히',
  isFold = false,
  onMore,
  onLess,
}) => {
  const [isMore, setIsMore] = useState(false)

  useLayoutEffect(() => {
    setIsMore(false)
  }, [isFold])

  const onClickMore = () => {
    setIsMore(true)
    !!onMore && onMore()
  }

  const onClickLess = () => {
    setIsMore(false)
    !!onLess && onLess()
  }

  return (
    <div
      className={cx(wrapperClassName, {
        'is-more': isMore,
        'is-less': !isMore,
      })}
    >
      <div className={cx('content')}>{text}</div>
      {!isMore && (
        <button className={cx('btn', 'btn-more')} onClick={onClickMore}>
          {moreButtonName}
        </button>
      )}
      {isMore && (
        <button className={cx('btn', 'btn-less')} onClick={onClickLess}>
          {lessButtonName}
        </button>
      )}
    </div>
  )
}

export default TruncatedTextBox
