import React from 'react'
import { Nil } from '@/@types/composite'
import _ from 'lodash'

interface PropType {
  value?: number | string | Nil
  alterText?: string
}

export const PercentText: React.FC<PropType> = ({ value, alterText = '-' }) => {
  const newValue =
    _.isNil(value) || isNaN(_.toNumber(value)) ? alterText : `${value}%`
  return <span>{newValue}</span>
}

export default PercentText
