import { useState } from 'react'

import useSWR from 'swr'
import _ from 'lodash'

import { get } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.dashboard'
import {
  StaffCustomerPurchaseStatusParam,
  StaffCustomerPurchaseStatus,
} from '@/apps/dashboard/@types/customer-purchase-status'

const fetcher = (url: string, params: StaffCustomerPurchaseStatusParam) => {
  return get<StaffCustomerPurchaseStatusParam, StaffCustomerPurchaseStatus>(
    url,
    params,
  )
}

const useCustomerPurchaseStatus = (
  defaultParams: StaffCustomerPurchaseStatusParam,
) => {
  const [params, setParams] = useState<StaffCustomerPurchaseStatusParam>({
    ...defaultParams,
  })

  const _setParams = (newParams: StaffCustomerPurchaseStatusParam) => {
    setParams(newParams)
  }

  const { data, error, isValidating, mutate } = useSWR(
    _.isEmpty(params) ? null : [URLS.customerPurchaseStatus, params],
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: false,
    },
  )
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
    isValidating,
    setParams: _setParams,
    mutate,
  }
}

export default useCustomerPurchaseStatus
