import { useState } from 'react'
import useSWR from 'swr'

import { post, patch, del } from '@/clients/http/authorized-http-client'
import { URLS } from '@/constants/apps.care'
import {
  buildManipulationHook,
  CreationHookReturnType,
  ModificationHookReturnType,
  Key,
} from '@/utils/swr/helpers'
import {
  Care,
  CareCreatePayload,
  CareModifyPayload,
} from '@/apps/care/@types/care'

export const useCareCreation: () => CreationHookReturnType<
  CareCreatePayload,
  Care
> = () => {
  return buildManipulationHook<CareCreatePayload, Care>({
    url: URLS.careList,
    request: post,
  })
}

export const useCareModification: () => ModificationHookReturnType<
  CareModifyPayload,
  Care
> = () => {
  const [key, setKey] = useState<Key>(null)
  return {
    ...buildManipulationHook<CareModifyPayload, Care>({
      url: key ? URLS.careDetail(key as number) : null,
      request: patch,
    }),
    setKey,
  }
}

const delRequest = (url: string | null) => {
  if (!url) return
  return del(url).then(() => true)
}

export const useCareDeletion = () => {
  const [key, setKey] = useState<Key>(null)

  const { data, error, isValidating } = useSWR(
    [key ? URLS.careDetail(key as number) : null, key],
    delRequest,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  )
  const isLoading = !!key && !data && !error

  return {
    data,
    error: error?.response,
    errorDetail: error?.response?.data?.detail,
    isLoading,
    isValidating,
    setKey,
  }
}
