import React from 'react'

import { Customer, CustomerChannelio } from '@/apps/customer/@types/customer'
import { URLS, chatService as ChatService } from '@/constants/apps.customer'
import { BasicButtonPropType } from '@/components/buttons/BasicButton'
import LinkChannelTalkButtonView from './LinkChannelTalkButton.View'
import { get } from '@/clients/http/authorized-http-client'
import { notifyFailure } from '@/components/antd/Notification'

type PropType = Pick<Customer, 'chatService' | 'chatUid' | 'uid'>

const LinkChannelTalkButton: React.FC<PropType & BasicButtonPropType> = ({
  chatService,
  chatUid,
  uid,
  ...props
}) => {
  const showTooltip = chatService === ChatService.NONE || chatUid === null

  const handleOnClick = async () => {
    if (!showTooltip) {
      await get<never, CustomerChannelio>(URLS.customerChannelio(uid))
        .then((rsp) => {
          window.open(rsp.url, '_blank')
        })
        .catch((e) => {
          if (e?.response?.status === 422) {
            notifyFailure({
              message: '요청 실패',
              description:
                e?.response?.data?.detail || '채널톡 요청에 실패하였습니다',
            })
          } else {
            notifyFailure({
              message: '요청 실패',
              description: '채널톡 요청에 실패하였습니다',
            })
          }
        })
    }
  }

  return (
    <LinkChannelTalkButtonView
      showTooltip={showTooltip}
      userChatUrl={URLS.getUserChat(chatUid ?? '')}
      handleOnClick={handleOnClick}
      {...props}
    />
  )
}

export default LinkChannelTalkButton
