import React, { useState } from 'react'
import _ from 'lodash'

import { orderingParamKey } from '@/constants/common'
import { Nil } from '@/@types/composite'
import { buildOrderingQueryString } from '@/utils/http'
import useLocationParams from '@/hooks/useLocationParams'
import { ChangeHistoryAction } from '@/@types/router'
import { buildTableChangeFilter } from '@/utils/antd/table'
import ConfirmFlowModal from '@/components/modal/ConfrimFlowModal'
import {
  MemoKind,
  MemoKindListOrderingKey,
  MemoKindListParam,
  MemoKindStatus,
} from '@/apps/memo/@types/memo-kind'
import { useMemoKindModification } from '@/apps/memo/hooks/useMemoKindManipulation'
import useMemoKindList from '@/apps/memo/hooks/useMemoKindList'
import EditMemoKindForm from '@/apps/memo/components/edit-memo-kind-form/EditMemoKindForm'

import MemoKindListView from './MemoKindList.View'

const defaultPage = 1
const defaultLimit = 10
export const defaultOrderingKey: MemoKindListOrderingKey = 'updated_at'
export const defaultIsDesc = true

const paramKeys = [
  'content',
  'kind',
  'status',
  'ordering',
  'page',
  'limit',
  'query',
]

const defaultListParams: MemoKindListParam = {
  page: defaultPage,
  limit: defaultLimit,
  ordering: buildOrderingQueryString(
    defaultOrderingKey,
    defaultIsDesc,
  ) as MemoKindListOrderingKey,
}

const MemoKindList: React.FC = () => {
  const [isVisibleForm, setIsVisibleForm] = useState(false)
  const [isVisibleStatusModal, setIsVisibleStatusModal] = useState(false)
  const [selected, setSelected] = useState<MemoKind | null>(null)

  const { data, isLoading, setParams, mutate, error } =
    useMemoKindList(defaultListParams)

  const locParams = useLocationParams<
    MemoKindListParam,
    MemoKindListOrderingKey
  >(paramKeys, {
    defaultParams: defaultListParams,
    orderingParamKey,
    onSetParams: setParams,
    numberParams: ['page', 'limit'],
    commaListParams: ['status'],
    tryPreviousPage: true,
    paginatedData: data,
    httpError: error,
  })
  const modifying = useMemoKindModification()

  const onChangePage = (page: number) => {
    locParams.setItem('page', page, { type: 'push' })
  }

  const onClickEdit = (item: MemoKind) => {
    setSelected(item)
    setIsVisibleForm(true)
  }

  const onClickStatus = (item: MemoKind, status: MemoKindStatus) => {
    setSelected(item)
    setIsVisibleStatusModal(true)
    modifying.setPayload({ status })
  }

  const updateMemoKindStatus = () => {
    if (!selected) return
    modifying.setMemoKindKey(selected.id)
  }

  const onFinishChangeStatus = async () => {
    await mutate()
    setSelected(null)
    modifying.setPayload(null)
    modifying.setMemoKindKey(null)
    setIsVisibleStatusModal(false)
  }

  const onCancelChangeStatus = onFinishChangeStatus

  const onChangeTable = buildTableChangeFilter<MemoKind>({
    filter: ({ values }) => {
      if (!values || _.isEmpty(values)) return
      locParams.setItems(
        {
          ...locParams.params,
          ...values,
        },
        { type: 'setPage1' },
      )
    },
  })

  const onClickAddMemoKind = () => {
    setIsVisibleForm(true)
  }

  const onFinish = async () => {
    await mutate()
    setSelected(null)
    setIsVisibleForm(false)
  }

  const onCancel = () => {
    setSelected(null)
    setIsVisibleForm(false)
  }

  const onChangeParam = (
    key: keyof MemoKindListParam,
    value: unknown,
    historyAction?: ChangeHistoryAction<MemoKindListParam>,
  ) => {
    locParams.setItem(key, value, historyAction ?? { type: 'push' })
  }

  const orderingKey = locParams.fullParams.orderingKey as
    | MemoKindListOrderingKey
    | undefined

  return (
    <>
      <MemoKindListView
        page={locParams.params.page ?? defaultPage}
        count={data?.count ?? 0}
        limit={locParams.params.limit ?? defaultLimit}
        params={locParams.params}
        data={data?.results ?? []}
        orderingKey={orderingKey}
        orderingIsDesc={locParams.fullParams.orderingIsDesc ?? defaultIsDesc}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeParam={onChangeParam}
        onClickEdit={onClickEdit}
        onClickStatus={onClickStatus}
        onChangeTable={onChangeTable}
        onClickAddMemoKind={onClickAddMemoKind}
      />
      <EditMemoKindForm
        isVisible={isVisibleForm}
        memoKind={selected}
        onFinish={onFinish}
        onCancel={onCancel}
      />
      <ConfirmFlowModal<typeof modifying.data, typeof modifying.error>
        visible={isVisibleStatusModal && !!selected}
        title="상태 변경"
        content={getConfirmTextForChangingStatus(selected)}
        successNotiMessage="변경 완료"
        successNotiDesc="성공적으로 메모 유형 상태를 변경했습니다."
        failNotiMessage="변경 실패"
        failNotiDesc="상태를 변경에 실패했습니다. 다시 시도해주세요."
        response={modifying.data}
        error={modifying.error}
        action={updateMemoKindStatus}
        isLoadingRequest={modifying.isLoading}
        onFinish={onFinishChangeStatus}
        onCancel={onCancelChangeStatus}
      />
    </>
  )
}

const getConfirmTextForChangingStatus = (item: MemoKind | Nil) => {
  return !item
    ? '변경할 대상을 먼저 선택하세요.'
    : `정말 ${item.name} 메모 유형 상태를 변경하시겠습니까?`
}

export default MemoKindList
